import React, { memo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import CloseIcon from 'assets/img/icons/close-icon.svg';
import NewCommentIcon from 'assets/img/icons/deal-desk/new-comment-icon.svg';
import classes from './CommentFeature.module.scss';
import SendIcon from 'assets/img/icons/deal-desk/send-message-icon.svg';
import { ShowComments } from './ShowComments';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';

import {
  callPostAddCommentDealDesk,
  callGetDealDeskById,
} from 'store/actions/dealDeskActions';

const CommentFeature = ({ field, label, value }) => {
  const dispatch = useDispatch();
  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );
  const data = dealDesk?.data;

  const id = data?.id;

  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);

  const user = useSelector(({ auth }) => get(auth, 'user'));

  const handleChange = e => {
    setText(e.target.value);
  };

  const onSubmit = async () => {
    setSubmitted(true);
    await dispatch(
      callPostAddCommentDealDesk(id, text, `<b>${label}: </b> ${value}`, field)
    );
    dispatch(callGetDealDeskById(id));
  };

  const renderContent = () => {
    if (isSubmitted) return renderCommment();

    return (
      <form name="new-comment" onSubmit={onSubmit}>
        <div className={classes.commentBox}>
          <div className={classes.title}>
            <div>New Comment</div>
            <button
              aria-label="Close"
              type="button"
              onClick={() => setShow(false)}
            >
              <img src={CloseIcon} alt="" />
            </button>
          </div>

          <textarea
            value={text}
            onChange={handleChange}
            placeholder="Add Comment"
          />

          <button
            className={classes.btSubmit}
            style={{ float: 'right' }}
            type="submit"
          >
            <img src={SendIcon} />
          </button>
        </div>
      </form>
    );
  };

  const renderCommment = () => {
    return (
      <div className={classes.commentBox}>
        <div className={classes.title}>
          <div>{user.name}</div>
          <div className={classes.date}>{moment().format('MM/DD/YYYY')}</div>
        </div>
        <div className={classes.content}>{text}</div>
      </div>
    );
  };

  return (
    <>
      <ShowComments field={field} />
      <OutsideClickHandler
        onOutsideClick={() => {
          setShow(false);
        }}
      >
        <div className={classes.commentFeature}>
          <img
            action="button"
            src={NewCommentIcon}
            onClick={() => setShow(true)}
          />

          {show && renderContent()}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default memo(CommentFeature);
