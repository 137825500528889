import Types from 'store/types/fulfillment';
import get from 'lodash/get';

const initialState = {
  interactions: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  studyList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  recentlyViewedStudiesList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  financialYearStatusList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  studyInteractions: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  documentBucketStatusList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  resolveInteraction: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  updateFinancialYearStatus: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  unResolveInteraction: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  updateDocumentBucketStatus: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  downloadAllDocuments: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  allDocumentList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  allDocumentFolder: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_INTERACTIONS_LIST_INPROGRESS:
      return {
        ...state,
        interactions: {
          ...state.interactions,
          isInProgress: true,
        },
      };
    case Types.FETCH_INTERACTIONS_LIST_SUCCESS:
      return {
        ...state,
        interactions: {
          ...state.interactions,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_INTERACTIONS_LIST_FAILURE:
      return {
        ...state,
        interactions: {
          ...state.interactions,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_STUDY_LIST_INPROGRESS:
      return {
        ...state,
        studyList: {
          ...state.studyList,
          isInProgress: true,
        },
      };
    case Types.FETCH_STUDY_LIST_SUCCESS:
      return {
        ...state,
        studyList: {
          ...state.studyList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_STUDY_LIST_FAILURE:
      return {
        ...state,
        studyList: {
          ...state.studyList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_INPROGRESS:
      return {
        ...state,
        recentlyViewedStudiesList: {
          ...state.recentlyViewedStudiesList,
          isInProgress: true,
        },
      };
    case Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_SUCCESS:
      return {
        ...state,
        recentlyViewedStudiesList: {
          ...state.recentlyViewedStudiesList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_FAILURE:
      return {
        ...state,
        recentlyViewedStudiesList: {
          ...state.recentlyViewedStudiesList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_INPROGRESS:
      return {
        ...state,
        financialYearStatusList: {
          ...state.financialYearStatusList,
          isInProgress: true,
        },
      };
    case Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_SUCCESS:
      return {
        ...state,
        financialYearStatusList: {
          ...state.financialYearStatusList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_STUDY_INTERACTIONS_LIST_INPROGRESS:
      return {
        ...state,
        studyInteractions: {
          ...state.studyInteractions,
          isInProgress: true,
        },
      };
    case Types.FETCH_STUDY_INTERACTIONS_LIST_SUCCESS:
      return {
        ...state,
        studyInteractions: {
          ...state.studyInteractions,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_FAILURE:
      return {
        ...state,
        financialYearStatusList: {
          ...state.financialYearStatusList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_STUDY_INTERACTIONS_LIST_FAILURE:
      return {
        ...state,
        studyInteractions: {
          ...state.studyInteractions,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_INPROGRESS:
      return {
        ...state,
        documentBucketStatusList: {
          ...state.documentBucketStatusList,
          isInProgress: true,
        },
      };
    case Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_SUCCESS:
      return {
        ...state,
        documentBucketStatusList: {
          ...state.documentBucketStatusList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.POST_INTERACTION_AS_RESOLVED_INPROGRESS:
      return {
        ...state,
        resolveInteraction: {
          ...state.resolveInteraction,
          isInProgress: true,
        },
      };
    case Types.POST_INTERACTION_AS_RESOLVED_SUCCESS:
      return {
        ...state,
        resolveInteraction: {
          ...state.resolveInteraction,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_FAILURE:
      return {
        ...state,
        documentBucketStatusList: {
          ...state.documentBucketStatusList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.POST_INTERACTION_AS_RESOLVED_FAILURE:
      return {
        ...state,
        resolveInteraction: {
          ...state.resolveInteraction,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_FINANCIAL_YEAR_STATUS_INPROGRESS:
      return {
        ...state,
        updateFinancialYearStatus: {
          ...state.updateFinancialYearStatus,
          isInProgress: true,
        },
      };
    case Types.UPDATE_FINANCIAL_YEAR_STATUS_SUCCESS:
      return {
        ...state,
        updateFinancialYearStatus: {
          ...state.updateFinancialYearStatus,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.POST_INTERACTION_AS_UNRESOLVED_INPROGRESS:
      return {
        ...state,
        unResolveInteraction: {
          ...state.unResolveInteraction,
          isInProgress: true,
        },
      };
    case Types.POST_INTERACTION_AS_UNRESOLVED_SUCCESS:
      return {
        ...state,
        unResolveInteraction: {
          ...state.unResolveInteraction,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.UPDATE_FINANCIAL_YEAR_STATUS_FAILURE:
      return {
        ...state,
        updateFinancialYearStatus: {
          ...state.updateFinancialYearStatus,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.POST_INTERACTION_AS_UNRESOLVED_FAILURE:
      return {
        ...state,
        unResolveInteraction: {
          ...state.unResolveInteraction,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_DOCUMENT_BUCKET_STATUS_INPROGRESS:
      return {
        ...state,
        updateDocumentBucketStatus: {
          ...state.updateDocumentBucketStatus,
          isInProgress: true,
        },
      };
    case Types.UPDATE_DOCUMENT_BUCKET_STATUS_SUCCESS:
      return {
        ...state,
        updateDocumentBucketStatus: {
          ...state.updateDocumentBucketStatus,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_DOWNLOAD_ALL_DOCUMENTS_INPROGRESS:
      return {
        ...state,
        downloadAllDocuments: {
          ...state.downloadAllDocuments,
          isInProgress: true,
        },
      };
    case Types.FETCH_DOWNLOAD_ALL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        downloadAllDocuments: {
          ...state.downloadAllDocuments,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.UPDATE_DOCUMENT_BUCKET_STATUS_FAILURE:
      return {
        ...state,
        updateDocumentBucketStatus: {
          ...state.updateDocumentBucketStatus,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_DOWNLOAD_ALL_DOCUMENTS_FAILURE:
      return {
        ...state,
        downloadAllDocuments: {
          ...state.downloadAllDocuments,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_ALL_DOCUMENTS_LIST_INPROGRESS:
      return {
        ...state,
        allDocumentList: {
          ...state.allDocumentList,
          isInProgress: true,
        },
      };
    case Types.FETCH_ALL_DOCUMENTS_LIST_SUCCESS:
      return {
        ...state,
        allDocumentList: {
          ...state.allDocumentList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_ALL_DOCUMENTS_LIST_FAILURE:
      return {
        ...state,
        allDocumentList: {
          ...state.allDocumentList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_ALL_DOCUMENTS_FOLDER_INPROGRESS:
      return {
        ...state,
        allDocumentFolder: {
          ...state.allDocumentFolder,
          isInProgress: true,
        },
      };
    case Types.FETCH_ALL_DOCUMENTS_FOLDER_SUCCESS:
      return {
        ...state,
        allDocumentFolder: {
          ...state.allDocumentFolder,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_ALL_DOCUMENTS_FOLDER_FAILURE:
      return {
        ...state,
        allDocumentFolder: {
          ...state.allDocumentFolder,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
};
