import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  Media,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import Avatar from '../Avatar';
import ContentEditor from '../ContentEditor';
import classes from './CommentStudies.module.scss';
import { customFromNow } from 'helpers/times';

const CommentStudies = ({
  commentId,
  commentToScroll,
  commentRef,
  avatarClassName,
  user,
  content,
  createdAt,
  dropdownOptions = [],
  isEdited = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleMouseEnter = () => setShowDropdown(true);

  const handleMouseLeave = () => {
    setShowDropdown(false);
    setDropdownOpen(false);
  };

  return (
    <div
      {...(commentId === parseInt(commentToScroll) ? { ref: commentRef } : {})}
      id={commentId}
      className={classes.comment}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Media className={cs('w-100', classes.mediaWrapper)}>
        {user.id && (
          <UncontrolledTooltip
            hideArrow
            placement="bottom-start"
            target={`avatar-hover-tooltip-${user.id}`}
            className={cs('custom-tooltip', classes.tooltipCustom)}
          >
            <div className={classes.tooltipContent}>
              <Avatar
                url={user.avatar}
                className={cs(classes.avatar, avatarClassName)}
              />
              <div className={classes.info}>
                <p>{user.name}</p>
              </div>
            </div>
          </UncontrolledTooltip>
        )}
        <Avatar
          id={`avatar-hover-tooltip-${user.id}`}
          url={user.avatar}
          className={cs(classes.avatar, avatarClassName)}
        />
        <Media className={cs('w-100', classes.media)}>
          <div className={cs('w-100', classes.content)}>
            <div className={classes.commentHeading}>
              <div className={classes.commentAuthor}>
                <span className={classes.date}>
                  {customFromNow(moment(createdAt).tz(userTimezone))}
                </span>
                <small>
                  {isEdited ? (
                    <i className="fas fa-pencil-alt text-light ml-1" />
                  ) : null}
                </small>
              </div>
              {showDropdown && dropdownOptions.length ? (
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle
                    caret={false}
                    size="sm"
                    className={cs('text-light ml-1', classes.commentsDropdown)}
                    color=""
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {dropdownOptions.map((item, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            item.onClick();
                          }}
                        >
                          {item.text}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              ) : null}
            </div>
            <ContentEditor
              style={{
                '--before-content': `"${
                  user.id === userId ? 'You' : user.name
                }: "`,
              }}
              className={cs('text-sm lh-160 w-100 text-break', classes.text)}
              content={content}
            />
          </div>
        </Media>
      </Media>
    </div>
  );
};

export default CommentStudies;
