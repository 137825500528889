import Button from 'components/Button';
import React, { useState } from 'react';
import { Badge, Card, CardBody, CardHeader, Progress } from 'reactstrap';
import classes from './ActiveStudies.module.scss';
import { partition } from 'lodash';

import cx from 'classnames';
import history from 'helpers/history';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import NoActivityImg from 'assets/img/theme/No_Projects_List.png';
import BaseTable from 'components/Table';
import Loading from 'components/Loading';

const ActiveStudies = () => {
  const activeStudies =
    useGetFieldFromObjects('project', 'projectState.data.data', []) ?? [];

  const isInProgress =
    useGetFieldFromObjects('project', 'projectState.isInProgress', false) ??
    false;

  const [ertcStudies, rAndDStudies] = partition(
    activeStudies,
    study => study?.type === 'ertc'
  );

  const rowEvents = {
    onClick: async (e, row) => {
      history.push(`/admin/studies/${row.id}`, {
        companyId: row.companyId,
        years: row.years,
      });
    },
  };

  const NoDataIndication = () => {
    return (
      <div
        className={cx(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className={classes.imageWrapper}>
            <img
              className={cx('m-auto w-100', classes.defaultImage)}
              src={NoActivityImg}
              alt="No Messages"
            />
          </div>
          <h4
            className={cx(
              'display-4',
              'mb-0',
              'text-center',
              'px-2',
              classes.defaultText
            )}
          >
            No Recent Studies
          </h4>
        </div>
      </div>
    );
  };

  return (
    <Card className={classes.studiesCard}>
      <CardHeader className={classes.cardHeader}>
        <p>Active Studies</p>
        <Button
          onClick={() => history.push(`/admin/studies`)}
          className={classes.button}
          color="link"
        >
          Go to Studies
        </Button>
      </CardHeader>
      {ertcStudies.length === 0 && rAndDStudies.length === 0 ? (
        <CardBody>
          {isInProgress ? (
            <Loading />
          ) : (
            <div className={classes.noStudies}>
              <div className={classes.content}>
                <h3>Currently there are no Active Studies</h3>
                <p>
                  Message the Team or Book a Meeting if you’re ready to start a
                  new Study.
                </p>
              </div>
            </div>
          )}
        </CardBody>
      ) : (
        <>
          {rAndDStudies.length !== 0 && (
            <CardBody className={classes.cardBody}>
              <div className={classes.studyType}>
                <p>R&D</p>
              </div>
              <div className={classes.studies}>
                <BaseTable
                  keyField="id"
                  remote
                  noDataIndication={NoDataIndication}
                  search={false}
                  bordered={false}
                  loading={isInProgress}
                  paginationOptions={false}
                  data={rAndDStudies}
                  classes="mb-0"
                  wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
                  columns={[
                    {
                      dataField: 'name',
                      text: 'COMPANY',
                      formatter: (cell, row) => {
                        return (
                          <>
                            <span className={classes.companyName}>{cell}</span>
                          </>
                        );
                      },
                    },
                    {
                      dataField: 'study_years',
                      text: 'YEARS',
                      formatter: (cell, row) => {
                        return (
                          <>
                            <span className={classes.years}>{cell}</span>
                          </>
                        );
                      },
                    },
                    {
                      dataField: 'progress_percentage',
                      text: 'PROGRESS',
                      headerClasses: classes.priorityHeader,
                      formatter: cell => {
                        return (
                          <div className={classes.progressBar}>
                            <p>{cell}% Complete</p>
                            <Progress
                              value={cell}
                              className={cx(classes.bar)}
                            ></Progress>
                          </div>
                        );
                      },
                    },
                    // {
                    //   dataField: 'message_count',
                    //   text: 'MESSAGE',
                    //   formatter: cell => {
                    //     return (
                    //       <div className={classes.badgeWrapper}>
                    //         <Badge className={classes.messages}>
                    //           <span>{cell}</span>
                    //         </Badge>
                    //       </div>
                    //     );
                    //   },
                    // },
                    {
                      dataField: '',
                      text: '',
                      formatter: cell => {
                        return (
                          <div className="d-flex justify-content-end">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </div>
                        );
                      },
                    },
                  ]}
                  rowEvents={rowEvents}
                />
              </div>
            </CardBody>
          )}
          {ertcStudies.length !== 0 && (
            <CardBody className={classes.cardBody}>
              <div className={classes.studyType}>
                <p>ERTC</p>
              </div>
              <div className={classes.studies}>
                <BaseTable
                  keyField="id"
                  remote
                  noDataIndication={NoDataIndication}
                  search={false}
                  bordered={false}
                  loading={isInProgress}
                  paginationOptions={false}
                  data={ertcStudies}
                  classes="mb-0"
                  wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
                  columns={[
                    {
                      dataField: 'name',
                      text: 'COMPANY',
                      formatter: (cell, row) => {
                        return (
                          <>
                            <span className={classes.companyName}>{cell}</span>
                          </>
                        );
                      },
                    },
                    {
                      dataField: 'study_years',
                      text: 'YEARS',
                      formatter: (cell, row) => {
                        return (
                          <>
                            <span className={classes.years}>{cell}</span>
                          </>
                        );
                      },
                    },
                    {
                      dataField: 'progress_percentage',
                      text: 'PROGRESS',
                      headerClasses: classes.priorityHeader,
                      formatter: cell => {
                        return (
                          <div className={classes.progressBar}>
                            <p>{cell}% Complete</p>
                            <Progress
                              value={cell}
                              className={cx(classes.bar)}
                            ></Progress>
                          </div>
                        );
                      },
                    },
                    {
                      dataField: 'message_count',
                      text: 'MESSAGE',
                      formatter: cell => {
                        return (
                          <div className={classes.badgeWrapper}>
                            <Badge className={classes.messages}>
                              <span>{cell}</span>
                            </Badge>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </div>
                        );
                      },
                    },
                  ]}
                  rowEvents={rowEvents}
                />
              </div>
            </CardBody>
          )}
        </>
      )}
    </Card>
  );
};

export default ActiveStudies;
