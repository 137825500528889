import React, { useEffect, useState } from 'react';
import classes from './DocumentFilter.module.scss';
import Input from 'components/FormFields/Input';
import RSelect from 'components/FormFields/RSelect';
import { components } from 'react-select';
import DateRangePicker from 'components/FormFields/DateRangePicker';
import Button from 'components/Button';
import { get, debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeamMembers } from 'store/actions/clientProfile';
import cx from 'classnames';

const docBucketOptions = [
  { value: 'all', label: 'All' },
  { value: 'Tax Returns', label: 'Tax Returns' },
  { value: 'General Ledger', label: 'General Ledger' },
  { value: '1099 Forms', label: '1099 Forms' },
  { value: 'Employee Roster', label: 'Employee Roster' },
  { value: 'Employee Payroll Reports', label: 'Employee Payroll Reports' },
  { value: 'Other', label: 'Other' },
];

const DocumentFilter = ({ handleSetFilters }) => {
  const dispatch = useDispatch();
  const clientId = useSelector(({ project }) =>
    get(project, 'getProject.data.data.client.id', null)
  );

  const [searchText, setSearchText] = useState('');
  const [clientUser, setClientUser] = useState([]);
  const [docBucket, setDocBucket] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange, setDateRange] = useState();

  const [clientUserOptions, setClientUserOptions] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      const resp = await dispatch(fetchTeamMembers(clientId));
      if (resp.status === 1) {
        const data = resp.data;
        const updatedValue = [
          { value: 'all', label: 'All' },
          ...data.map(data => ({
            label: data.name,
            value: data.id,
          })),
        ];
        setClientUserOptions(updatedValue);
      }
    };
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  useEffect(() => {
    const filter = {
      user_ids: clientUser
        ?.filter(data => data.value !== 'all')
        ?.map(d => d.value),
      buckets: docBucket
        ?.filter(data => data.value !== 'all')
        ?.map(d => d.value),
      start_date: startDate ? startDate.format('YYYY-MM-DD') : null,
      end_date: endDate ? endDate.format('YYYY-MM-DD') : null,
      keyword: searchText,
    };
    handleSetFilters(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docBucket, searchText, startDate, clientUser]);

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <Input
            label={props.label}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
        </components.Option>
      </div>
    );
  };

  const MenuWithHeader = ({ children, clearValue = () => {}, ...props }) => {
    return (
      <components.Menu {...props}>
        <div className={classes.menuHeader}>
          <Button onClick={clearValue} color="link">
            Clear
          </Button>
        </div>
        {children}
      </components.Menu>
    );
  };

  const MoreSelectedBadge = ({ items }) => {
    const style = {
      marginLeft: 'auto',
      background: '#d4eefa',
      borderRadius: '4px',
      fontFamily: 'Open Sans',
      fontSize: '11px',
      padding: '3px',
      order: 99,
    };

    const title = items.join(', ');
    const length = items.length;
    const label = `+ ${length} more`;

    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 1;
    const overflow = getValue()
      .slice(maxToShow)
      .map(x => x.label);

    return index < maxToShow ? (
      <components.MultiValue
        className={cx('multi-value_label_default', {
          'multi-value_label': overflow?.length >= 1,
        })}
        {...props}
      />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };

  const rSelectStyles = {
    menu: ({ width, ...css }) => ({
      ...css,
      width: 'max-content',
      minWidth: '100%',
    }),
  };

  const searchInputChanged = e => {
    setSearchText(e.target.value);
  };

  const handleChange = e => {
    e.persist();

    delayedSearch(e);
  };

  const delayedSearch = debounce(searchInputChanged, 500);

  return (
    <div className={classes.wrapper}>
      <div className={classes.search}>
        <Input
          label="Search"
          placeholder="Start typing..."
          onChange={handleChange}
          rightIcon={<i className="fa fa-search" aria-hidden="true"></i>}
        />
      </div>
      <div className={classes.selectWrapper}>
        <div className={classes.select}>
          <RSelect
            isMulti
            isClearable={false}
            closeMenuOnSelect={false}
            name="client_user"
            label="Client User:"
            options={clientUserOptions}
            getOptionLabel={opt => opt.label}
            getOptionValue={opt => opt.value}
            styles={rSelectStyles}
            hideSelectedOptions={false}
            value={clientUser}
            onChange={(values, option) => {
              const isAllSelected = values?.findIndex(v => v.value === 'all');
              if (isAllSelected !== -1 && option.action !== 'remove-value') {
                setClientUser(clientUserOptions);
              } else {
                setClientUser(values || []);
              }
            }}
            components={{
              Option: Option,
              MultiValue: MultiValue,
              Menu: MenuWithHeader,
            }}
          />
        </div>
        <div className={classes.select}>
          <RSelect
            isMulti
            isClearable={false}
            name="doc_bucket"
            label="Doc Bucket:"
            options={docBucketOptions}
            styles={rSelectStyles}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            getOptionLabel={opt => opt.label}
            getOptionValue={opt => opt.value}
            value={docBucket}
            onChange={(values, option) => {
              const isAllSelected = values?.findIndex(v => v.value === 'all');
              if (isAllSelected !== -1 && option.action !== 'remove-value') {
                setDocBucket(docBucketOptions);
              } else {
                setDocBucket(values || []);
              }
            }}
            components={{
              Option: Option,
              MultiValue: MultiValue,
              Menu: MenuWithHeader,
            }}
          />
        </div>
        <div className={classes.select}>
          <DateRangePicker
            label="Date:"
            placeholder="Date"
            showIcon={false}
            startDate={startDate}
            datePickerClass={classes.datePicker}
            endDate={endDate}
            value={dateRange}
            onChange={dates => {
              if (dates.startDate && dates.endDate) {
                setStartDate(dates.startDate);
                setEndDate(dates.endDate);
                const rangeDate =
                  dates.startDate.format('YYYY-MM-DD') +
                  ' - ' +
                  dates.endDate.format('YYYY-MM-DD');
                setDateRange(rangeDate);
              } else {
                setDateRange(dateRange);
              }
            }}
          />
        </div>
      </div>
      <div className={classes.infoSection}>
        <label>Number of Docs</label>
        <p>9</p>
      </div>
    </div>
  );
};

export default DocumentFilter;
