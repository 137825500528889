import React, { useState } from 'react';
import { ModalFooter } from 'reactstrap';

import classes from './DealDeskModal.module.scss';
import SendIcon from 'assets/img/icons/deal-desk/send-message-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { callPostAddCommentDealDesk } from 'store/actions/dealDeskActions';
import get from 'lodash/get';

const DealDeskModalAddComment = ({ type }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useSelector(({ auth }) => auth.user);
  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );
  const data = dealDesk?.data;
  const id = data?.id;

  const handleChange = e => {
    setMessage(e.target.value);
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (loading) return '';
    setLoading(true);
    await dispatch(
      callPostAddCommentDealDesk(
        id,
        message,
        null,
        null,
        type == 'deals' ? 'Initiative' : 'PartnerReferral'
      )
    );
    setMessage('');
    setLoading(false);
  };

  return (
    <form name="create-message" onSubmit={onSubmit}>
      <ModalFooter className={classes.chatBox}>
        <div className={classes.avatar}>
          {user?.avatar ? (
            <img src={user?.avatar} />
          ) : (
            `${user?.first_name?.charAt(0)}
              ${user?.last_name?.charAt(0)}`
          )}
        </div>
        <div className={classes.box}>
          <textarea
            placeholder="Add a comment"
            value={message}
            onChange={handleChange}
          />
          <button type="submit" disabled={loading}>
            <img src={SendIcon} />
          </button>
        </div>
      </ModalFooter>
    </form>
  );
};

export default DealDeskModalAddComment;
