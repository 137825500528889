import React, { useEffect, useState } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import {
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  UncontrolledDropdown,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { doLogout } from 'store/actions/authActions';
import { fetchNotificationsList } from 'store/actions/notifications';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import NotificationList from 'components/NotificationList';
import NotificationBadge from 'components/NotificationList/NotificationBadge';
import classes from './AdminNavbar.module.scss';
import polygonIcon from 'assets/img/icons/common/polygon.svg';
import Timer from 'components/Timer';
import history from 'helpers/history';
import { useAccess, permissions } from 'helpers/permission';
import useBreakPoint from 'helpers/useBreakPoint';
import {
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import { showSupportForm } from 'store/actions/app';
import analytics, { analyticsConstants } from 'helpers/analytics';

import { exitImpersonateUser } from 'store/actions/users';
import isEmpty from 'lodash/isEmpty';
import { clearPartnerDashboard } from 'store/actions/partnerDashboard';
import BreadCrumbs from './BreadCrumbs';

const AdminNavbar = props => {
  const dispatch = useDispatch();

  const [notificationDropdownOpen, setNotificationDropdown] = useState(false);
  const auth = useSelector(({ auth }) => auth);
  const impersonateUser = useSelector(({ user }) => {
    return get(user, 'impersonateState.data', null);
  });

  const fetchStudyInProgress = useSelector(({ project }) =>
    get(project, 'getProject.isInProgress', false)
  );

  const impersonateGuestPartner = useSelector(({ user }) => {
    return get(user, 'impersonateState.guestPartner', null);
  });

  const partnerName =
    get(impersonateUser, 'name', '') ||
    get(impersonateGuestPartner, 'name', '');

  const isPartner = get(auth, 'user.is_partner', false) || !!partnerName;

  const closeLeadName = get(impersonateUser, 'close_lead.name', '');
  const isMobile = useBreakPoint('xs', 'down');
  const useGetSelectedNotificationProperty = (property, defaultValue) =>
    useSelector(
      ({ notification }) =>
        get(notification, `notificationState.${property}`, defaultValue),
      isEqual
    );
  const notifications = useGetSelectedNotificationProperty('data.data', []);
  const shouldMakeCall = useGetSelectedNotificationProperty(
    'shouldMakeCall',
    true
  );
  const notificationCount = useGetSelectedNotificationProperty(
    'data.count.unread',
    0
  );
  const isNotificationLoading = useGetSelectedNotificationProperty(
    'isInProgress',
    false
  );

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );
  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.notifications,
      ...rest,
    });
  };
  const toggleNotification = () => {
    if (!notificationDropdownOpen)
      analyticsSendEvent({
        action: analyticsConstants.action.view_notifications,
      });
    setNotificationDropdown(prevState => !prevState);
  };

  useEffect(() => {
    if (!props.isMockUp) dispatch(fetchNotificationsList(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateQuickStart = e => {
    e.stopPropagation();

    analytics.sendEvent({
      category: analyticsConstants.category.onboarding,
      action: analyticsConstants.action.open_onboarding_drawer,
    });

    dispatch(
      setUserPreference({
        ...onBoardingTour,
        value: {
          ...get(onBoardingTour, 'value', {}),
          status: 'Active',
        },
      })
    );
  };

  const canSubmitDocuments = useAccess(permissions.SUBMIT_DOCUMENTS);
  const shouldShowStartQuickStart = () => {
    if (!canSubmitDocuments) return false;
    const allowedRoles = ['client'];
    return (
      !isMobile &&
      allowedRoles.findIndex(
        k => onBoardingTour && k === onBoardingTour.key
      ) !== -1
    );
  };

  const exitImpersonateAction = async () => {
    await dispatch(exitImpersonateUser());
    await dispatch(clearPartnerDashboard());
    setTimeout(() => {
      window.location.href = '/admin/partners';
    }, 100);
  };

  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand border-bottom',
          {
            'navbar-dark bg-primary': props.theme === 'dark',
          },
          {
            'navbar-light bg-secondary': props.theme === 'light',
          },
          classes.navBar
        )}
      >
        <Container fluid className={classes.root}>
          <Collapse navbar isOpen>
            <div className="d-xl-none">
              <div
                className={classnames(
                  'pr-3 sidenav-toggler',
                  {
                    active: props.sidenavOpen,
                  },
                  {
                    'sidenav-toggler-dark': props.theme === 'dark',
                  }
                )}
                onClick={props.toggleSidenav}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </div>
            {props.isClient && !fetchStudyInProgress && (
              <BreadCrumbs props={props} />
            )}
            {/* {useAccess([permissions.CREATE_TIMELOGS]) && <Timer />} */}
            {/* <Form
              className={classnames(
                'navbar-search form-inline mr-sm-3',
                { 'navbar-search-light': props.theme === 'dark' },
                { 'navbar-search-dark': props.theme === 'light' }
              )}
            >
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden>×</span>
              </button>
            </Form> */}
            {(!isEmpty(impersonateUser) ||
              !isEmpty(impersonateGuestPartner)) && (
              <Nav className="align-items-center" navbar>
                <NavItem>
                  <div className={classes.impersonateBar}>
                    <span className={classes.norm}>
                      Currently Impersonating:
                    </span>
                    <span className={classes.bold}>
                      {' '}
                      {partnerName} {closeLeadName && `from ${closeLeadName}`}
                    </span>
                    <span
                      className={classes.exitBtn}
                      onClick={() => exitImpersonateAction()}
                    >
                      Exit Impersonation Mode
                    </span>
                  </div>
                </NavItem>
              </Nav>
            )}
            <Nav className="align-items-center ml-md-auto" navbar>
              {/* <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    'pr-3 sidenav-toggler',
                    { active: props.sidenavOpen },
                    { 'sidenav-toggler-dark': props.theme === 'dark' }
                  )}
                  onClick={props.toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem> */}
              {/* <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem> */}
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              {/* {useAccess(permissions.ACCESS_PERSONAL_KANBAN) && (
                <NavItem id="viewKanban">
                  <NavLink
                    to="/admin/kanban/personal"
                    tag={Link}
                    onClick={handleQuickStartTour}
                  >
                    <i
                      className={classnames(
                        'ni ni-chart-bar-32',
                        classes.rotate180
                      )}
                    />
                  </NavLink>
                </NavItem>
              )} */}
              <Dropdown
                isOpen={notificationDropdownOpen}
                toggle={toggleNotification}
              >
                <DropdownToggle
                  className="nav-link"
                  color=""
                  tag="a"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  <i className="ni ni-bell-55" />
                  <NotificationBadge count={notificationCount} />
                </DropdownToggle>
                <DropdownMenu
                  className={classnames(
                    'dropdown-menu-xl py-0 overflow-hidden',
                    classes.notificationDropdown
                  )}
                  right
                >
                  <NotificationList
                    notifications={notifications}
                    shouldMakeCall={shouldMakeCall}
                    count={notificationCount}
                    isNotificationLoading={isNotificationLoading}
                    toggleNotification={toggleNotification}
                    analyticsSendEvent={analyticsSendEvent}
                  />
                </DropdownMenu>
              </Dropdown>
            </Nav>
            <Nav className="align-items-center ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  className="nav-link pr-0"
                  color=""
                  tag="a"
                  href="#"
                >
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        className={classes.avatar}
                        alt="avatar"
                        src={get(auth, 'user.avatar')}
                      />
                    </span>
                    <img
                      src={polygonIcon}
                      alt="polygon icon"
                      className="ml-1"
                    />
                    {/* <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {get(auth, 'user.name')}
                      </span>
                    </Media> */}
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    href={`/admin/profile`}
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/admin/profile`);
                    }}
                  >
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  {useAccess(permissions.ACCESS_PERSONAL_KANBAN) && !isPartner && (
                    <DropdownItem>
                      <i
                        className={classnames(
                          'ni ni-chart-bar-32',
                          classes.rotate180
                        )}
                      />
                      <Link
                        to="/admin/kanban/personal"
                        className={classes['dropdown-link']}
                      >
                        Personal Kanban
                      </Link>
                    </DropdownItem>
                  )}
                  <DropdownItem
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      dispatch(showSupportForm(true));
                    }}
                  >
                    <i className="fa fa-question-circle" />
                    <span>Ask a Question</span>
                  </DropdownItem>
                  {shouldShowStartQuickStart() && (
                    <DropdownItem onClick={updateQuickStart}>
                      <i className="fas fa-map" />
                      <span>Quick Start</span>
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  <DropdownItem
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      dispatch(doLogout());
                    }}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
  isMockUp: false,
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  isMockUp: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default AdminNavbar;
