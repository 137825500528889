import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, UncontrolledTooltip } from 'reactstrap';
import NoViewedStories from 'assets/img/theme/no-estimated-delivery.svg';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from './EstimatedDeliveryDates.module.scss';
import get from 'lodash/get';
import history from 'helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import UserInfo from 'components/UserInfo';
import { getUpcomingStudyList } from 'store/actions/fulfillment';
import Loading from 'components/Loading';

const EstimatedDeliveryDates = ({ isMobile }) => {
  const dispatch = useDispatch();

  const studiesMeta = useSelector(({ fulfillment }) =>
    get(fulfillment, 'studyList.data.meta', {})
  );

  const current_page = get(studiesMeta, 'current_page', 0);
  const last_page = get(studiesMeta, 'last_page', 0);

  const studiesLoading = useSelector(({ fulfillment }) =>
    get(fulfillment, 'studyList.isInProgress', false)
  );

  const [studies, setStudies] = useState([]);
  const [filters, setFilters] = useState({});

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/admin/studies/${row.id}/dashboard`);
    },
  };

  useEffect(() => {
    fetchStudies(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStudies = async (page = 1, params = {}, fromSort = false) => {
    const { data } = await dispatch(
      getUpcomingStudyList({ page, limit: 5, ...params })
    );

    if (fromSort) {
      setStudies([...data]);
    } else {
      setStudies([...studies, ...data]);
    }
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  return (
    <>
      <Card className={classes.wrapper}>
        <CardHeader className={classes.cardHeader}>
          <div className="d-flex justify-content-between">
            <h3 className="mb-0">Upcoming Estimated Delivery Dates</h3>
          </div>
        </CardHeader>
        <CardBody
          id="recently-viewed-stories-card-body"
          className={cx('p-0', classes.cardBody)}
        >
          <InfiniteScroll
            useWindow={false}
            hasMore={current_page < last_page}
            initialLoad={false}
            pageStart={1}
            loadMore={() => {
              if (studies?.length && !studiesLoading) {
                fetchStudies(current_page + 1, filters);
              }
            }}
          >
            {studies?.length === 0 && studiesLoading ? (
              <Loading />
            ) : (
              <BaseTable
                keyField="id"
                defaultSorted={null}
                noDataIndication={() => (
                  <div
                    className={cx(
                      'd-flex flex-column justify-content-centre align-items-centre',
                      classes.tableWrapper
                    )}
                  >
                    <img
                      src={NoViewedStories}
                      className="mx-auto my-3"
                      alt="No Upcoming Estimated Deliveries"
                    />
                  </div>
                )}
                remote
                search={false}
                bordered={false}
                loading={studiesLoading}
                paginationOptions={false}
                data={studies}
                classes="mb-0"
                wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
                onTableChange={(type, { sortOrder, sortField }) => {
                  if (type === 'sort') {
                    const sort =
                      sortOrder === 'desc' ? `-${sortField}` : sortField;
                    setFilters({ sort });
                    fetchStudies(1, { sort }, true);
                  }
                }}
                rowEvents={rowEvents}
                rowClasses={classes.row}
                columns={[
                  {
                    dataField: 'name',
                    text: 'STUDY',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => {
                      return (
                        <>
                          <h5
                            className={cx(
                              'm-0',
                              classes.ellipsis,
                              classes.storyName
                            )}
                          >
                            {cell || ''}
                          </h5>
                        </>
                      );
                    },
                  },
                  {
                    dataField: 'study_years',
                    text: 'YEARS',
                    sort: false,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => {
                      return (
                        <>
                          <div
                            className={cx(
                              'd-flex align-items-center',
                              classes.ellipsis,
                              classes.storyName,
                              classes.projectName
                            )}
                          >
                            <span id={`project-name-${row.id}`}>{cell}</span>
                          </div>
                          <UncontrolledTooltip
                            delay={0}
                            target={`project-name-${row.id}`}
                            placement="bottom"
                            innerClassName={classes['tooltip']}
                            boundariesElement="viewport"
                          >
                            {cell}
                          </UncontrolledTooltip>
                        </>
                      );
                    },
                  },
                  {
                    dataField: 'owner',
                    text: 'PROJECT LEAD',
                    sort: false,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => {
                      return (
                        <div className="d-flex justify-content-center">
                          {cell ? (
                            <UserInfo
                              info={{
                                name: cell.name,
                                id: cell.id,
                                avatar: cell.avatar,
                              }}
                              showName={false}
                              showToolTip={true}
                            />
                          ) : (
                            '-'
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    dataField: 'estimated_delivery_date',
                    text: 'DEADLINE',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: cell => {
                      return <span>{cell || '-'}</span>;
                    },
                  },
                ]}
              />
            )}
          </InfiniteScroll>
        </CardBody>
      </Card>
    </>
  );
};

export default EstimatedDeliveryDates;
