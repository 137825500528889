import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentCard from './DocumentCard';
import classes from './DocumentGathering.module.scss';
import { fetchDocumentStudyTypes } from 'store/actions/documents';
import { clearCommentsData } from 'store/actions/Story/comments';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import Loading from 'components/Loading';
import DocumentCommentModal from 'components/Fulfillment/Modals/DocumentCommentModal';
import history from 'helpers/history';
import StudyDocumentDetail from './StudyDocumentDetail/StudyDocumentDetail';

const DocumentGathering = ({
  studyId,
  onAddDocument,
  allDocuments,
  handleCloseCommentModal,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [activeYear, setActiveYear] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalParams, setModalParams] = useState({
    parent_type: '',
    parent_id: '',
  });
  const isDocsLoading = useSelector(({ documents }) =>
    get(documents, `documentStudyTypes.isInProgress`, false)
  );

  const projectCompanyId = useSelector(({ project }) =>
    get(project, 'getProject.data.data.company_id', null)
  );
  const isClient = useSelector(({ auth }) => auth.user.is_client, false);
  const roles = useSelector(({ auth }) => auth.user.roles, []);

  const handleCloseModal = () => {
    handleCloseCommentModal();
    dispatch(fetchDocumentStudyTypes(studyId));
    dispatch(clearCommentsData());
    setIsDocumentModalOpen(false);
    history.push(`?`);
  };

  const handleOpenPreviewModal = id => {
    history.push(`${pathname}?modal_type=document&parent_id=${id}`);
    setModalData(allDocuments.find(_ => _.id === id));
  };

  useEffect(() => {
    const { modal_type = '', parent_id = '' } = queryProps; //document|study

    if (modal_type === 'document') {
      const params = {
        parent_type: 'FinancialYearDocumentType',
        parent_id: parent_id,
      };
      setModalParams(params);
      setIsDocumentModalOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps?.parent_id, queryParams?.modal_type]);

  return (
    <>
      {isDocumentModalOpen && (
        <DocumentCommentModal
          params={modalParams}
          documentTypeId={modalData?.id}
          parentId={studyId}
          activeYear={activeYear}
          title={
            <div className="d-flex">
              <i className="fas fa-folder-open" />
              <div className="mr-2 ml-2">{activeYear?.year}</div>
              <div>{modalData?.short_name}</div>
            </div>
          }
          isOpen={isDocumentModalOpen}
          closeModal={handleCloseModal}
          // currentTab={get(queryProps, 'tab')}
          companyId={projectCompanyId}
          initiativeId={studyId}
        />
      )}
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.title}>
            <p>Source Documents</p>
          </div>
        </div>
        {isDocsLoading && allDocuments?.length === 0 ? (
          <Loading />
        ) : (
          <div className={classes.cards}>
            {allDocuments.map((data, index) => {
              return (
                <div key={index} className={classes.documentCard}>
                  <DocumentCard
                    key={index}
                    data={data}
                    onAddDocument={onAddDocument}
                    handleOpenPreviewModal={() =>
                      handleOpenPreviewModal(data?.financial_year_document_id)
                    }
                    canUpload={isClient && !roles.includes('Technical_SME')}
                  />
                  <StudyDocumentDetail data={data} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentGathering;
