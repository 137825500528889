import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import PersonalDashboardQuickActions from 'views/pages/QuickActions/PersonalDashboardQuickActions';
import useBreakPoint from 'helpers/useBreakPoint';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import classes from './AccountOverview.module.scss';

import ClientTeams from 'views/pages/ClientProfile/ClientTeams';
import Helmet from 'components/Helmet';
import RecentlyViewedStudies from 'views/pages/dashboards/FulfillmentDashboard/RecentlyViewedStudies';

const AccountOverview = ({
  clientId,
  handleClientTeamAction,
  onStoryClick,
  clientName,
  companyId,
}) => {
  const dispatch = useDispatch();
  const isMobile = useBreakPoint('sm', 'down');

  const useGetDetails = (field, defaultValue) =>
    useGetFieldFromObject('clientProfile', field, defaultValue);

  return (
    <Container fluid>
      <Helmet title="Strike Portal - Clients - Account Overview" />

      <Row>
        <Col md={4}>
          <RecentlyViewedStudies
            listParams={{ type: '', limit: 10, client: clientId }}
            title="Studies"
            cardClassName={classes.card}
          />
        </Col>
        <Col md={8}>
          <PersonalDashboardQuickActions
            clientId={clientId}
            clientName={clientName}
            companyId={companyId}
          />
          <ClientTeams
            companyId={companyId}
            clientId={clientId}
            isMobile={isMobile}
            getDetails={useGetDetails}
            handleClientTeamAction={handleClientTeamAction}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AccountOverview;
