import React, { useEffect, useState } from 'react';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';

import Modal, { ModalBody } from 'components/FormFields/Modal';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import classes from './DocumentPreviewModal.module.scss';
import classnames from 'classnames';
import {
  assignStoryOwner as assignStoryOwnerAction,
  updateSelectedStoryStatus,
  updateStoryPriority,
} from 'store/actions/Story/details';
import { clearCommentsData } from 'store/actions/Story/comments';
import moment from 'moment';
import useBreakPoint from 'helpers/useBreakPoint';
import { permissions, useAccess } from 'helpers/permission';
import find from 'lodash/find';
import DocumentDetails from './DocumentDetails';
import PropTypes from 'prop-types';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import socket from 'helpers/socket';
import toNumber from 'lodash/toNumber';
import analytics, { analyticsConstants } from 'helpers/analytics';
import {
  didAllOnboardingFlowCompleted,
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import Loading from 'components/Loading';
import BaseTable from 'components/Table';
import { deleteAttachedFile } from 'store/actions/clientProfile';
import {
  downloadDocument,
  fetchDocumentTypesForFY,
  clearDocumentUrl,
} from 'store/actions/documents';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import FileViewer from 'components/FileViewer';
import ActivityModal from './DocumentDetails/Tabs/Activity/ActivityModal';

const DocumentPreviewModal = ({
  isOpen,
  closeModal,
  projectId: recievedProjectId,
  storyId: recievedStoryId,
  onChange,
  currentTab: parentCurrentTab = 'overview',
  onTabChange,
  comment,
  title,
  documentData,
  activeYear,
  companyId,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState({});
  const [isFileViewerOpen, setFileViewerOpen] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [fileViewerData, setFileViewerData] = useState(null);
  const isMobile = useBreakPoint('xs', 'down');

  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };

  useEffect(() => {
    setFiles(documentData);
  }, [documentData]);

  useEffect(() => {
    if (recievedStoryId) {
      socket.joinAndListenComments('DocumentType', recievedStoryId);
      return () => {
        socket.leaveComments();
        dispatch(clearCommentsData());
      };
    }
    // used when notification is sent to user
    const leftColumn = document.getElementById('storyModal-leftColumn');
    if (leftColumn) leftColumn.scrollTop = 0;
    setFiles(documentData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recievedProjectId, recievedStoryId]);

  const isStoryFetchInProgress = useSelector(({ story }) =>
    get(story, 'details.isInProgress', false)
  );

  const projectMemberDetails = useSelector(({ project }) =>
    get(project, 'getProject.data.data.team_members', [])
  );
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));

  const isUserMemberOfTeam = find(
    projectMemberDetails,
    member => member.id === userId
  );

  const isUserAllowedEdit = useAccess([
    permissions.EDIT_ALL_STORIES,
    { permission: permissions.EDIT_PROJECT_STORIES, value: isUserMemberOfTeam },
  ]);
  const [editField, setEditField] = useState();
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const useGetFieldValue = (fieldName, emptyValue = null) =>
    useGetFieldFromObject('story', `details.data.${fieldName}`, emptyValue);

  const isStoryFetchError = useGetFieldFromObject(
    'story',
    'details.isError',
    false
  );
  const storyFetchErrorMessage = useGetFieldFromObject(
    'story',
    'details.message',
    'Something went wrong. Please try again'
  );
  const errorStoryId = useGetFieldFromObject('story', 'details.id');
  const downloadLoading = useGetFieldFromObject(
    'documents',
    'downloadDoc.isInProgress',
    false
  );

  const isStoryError =
    isStoryFetchError && toNumber(errorStoryId) === recievedStoryId;

  const storyId = useGetFieldValue('id');
  const owner = {
    name: useGetFieldValue('owner.name'),
    avatar: useGetFieldValue('owner.avatar'),
  };
  const projectId = useGetFieldValue('initiative.id');
  const projectName = useGetFieldValue('initiative.name');
  const score = useGetFieldValue('score');
  const status = {
    color: useGetFieldValue('status.color'),
    status: useGetFieldValue('status.status'),
  };
  const createdBy = {
    name: useGetFieldValue('created_by.name'),
    avatar: useGetFieldValue('created_by.avatar'),
  };

  const priority = useGetFieldValue('priority', null);
  const complexity = useGetFieldValue('complexity', null);
  const priorities = useGetFieldFromObject('story', 'priorityOptions.data', []);
  const complexities = useGetFieldFromObject(
    'story',
    'complexityOptions.data',
    []
  );
  const scoreMatrix = useGetFieldFromObject('story', 'scoreMatrix.data', []);
  const useGetTime = field =>
    moment(useGetFieldValue(field, moment()))
      .tz(userTimeZone)
      .format('MMMM DD, YYYY');
  const createdOn = useGetTime('created_at');
  const updatedOn = useGetTime('updated_at');
  if (
    !isStoryFetchInProgress &&
    projectId !== recievedProjectId &&
    recievedStoryId === storyId
  ) {
    closeModal();
  }

  const assignStoryOwner = async ({ id }) => {
    analyticsSendEvent({
      action: analyticsConstants.action.update_story_owner,
      updated_from: 'Story Modal',
    });
    await dispatch(assignStoryOwnerAction(storyId, id));
    setEditField();
    onChange();
  };

  const changeStatus = async item => {
    analyticsSendEvent({
      action: analyticsConstants.action.update_story_status,
      updated_from: 'Story Modal',
    });
    await dispatch(updateSelectedStoryStatus(storyId, item));
    onChange();
  };

  const changePriority = async data => {
    analyticsSendEvent({
      action: analyticsConstants.action.update_story_priority,
      updated_from: 'Story Modal',
    });
    await dispatch(updateStoryPriority(storyId, data));
    onChange();
  };

  useEffect(() => {
    if (isStoryError) {
      NotificationHandler.open({
        message: storyFetchErrorMessage,
        operation: 'failure',
      });
      closeModal();
    }
  }, [closeModal, isStoryError, storyFetchErrorMessage]);

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const handleModalClose = async () => {
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'story_creation'
    ) {
      const isWithCreateProject = quickStartTour.withCreateProject;
      await dispatch(
        updateActiveTourStatus({
          step: null,
          activeTour: null,
          withCreateProject: false,
        })
      );
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action:
          analyticsConstants.action.complete_create_a_story_onboarding_flow,
      });
      if (isWithCreateProject) {
        analytics.sendEvent({
          category: analyticsConstants.category.onboarding,
          action:
            analyticsConstants.action.complete_create_a_project_onboarding_flow,
        });
      }
      dispatch(
        setUserPreference({
          ...onBoardingTour,
          value: {
            ...get(onBoardingTour, 'value', {}),
            status: 'Active',
            steps: {
              ...get(onBoardingTour, 'value.steps', {}),
              story_creation: {
                status: 'Completed',
              },
              ...(isWithCreateProject
                ? {
                    project_creation: {
                      status: 'Completed',
                    },
                  }
                : {}),
            },
          },
        })
      );
      didAllOnboardingFlowCompleted();
    }
    closeModal();
  };

  const handleDocumentDelete = async id => {
    await deleteAttachedFile(id);
    const index = files.documents.findIndex(d => d.id === id);
    const updatedFiles = {
      ...files,
      documents: [
        ...files.documents?.slice(0, index),
        ...files.documents?.slice(index + 1),
      ],
    };
    setFiles(updatedFiles);
    dispatch(fetchDocumentTypesForFY(activeYear, companyId));
  };

  const handleDeleteModal = id => {
    AlertPopupHandler.open({
      onConfirm: () => handleDocumentDelete(id),
      confirmBtnText: `Delete`,
      text: (
        <div className="d-flex flex-column">
          <div>Do you want to delete this file?</div>
          <div>
            This file will be unrecoverable and version history will be lost.{' '}
          </div>
        </div>
      ),
    });
  };

  const handleDownload = index => {
    dispatch(downloadDocument(files.documents[index]));
  };

  const NoDataIndication = () => (
    <div className="d-flex align-items-center p-4 justify-content-center">
      No Files Uploaded
    </div>
  );

  const handleFileViewer = () => {
    setFileViewerOpen(false);
    setFileViewerData(null);
    dispatch(clearDocumentUrl());
  };

  const handleViewHistory = () => {
    setOpenActivityModal(true);
  };

  return (
    <Modal
      size="xl"
      scrollable
      fade={false}
      title="Preview File"
      toggle={handleModalClose}
      isOpen={isOpen && !isStoryError}
      className={classes.documentModalWrapper}
    >
      <ModalBody
        className={classnames(
          'p-0 d-flex flex-row flex-wrap',
          classes['document-modal'],
          { [classes['document-modal-mobile']]: isMobile }
        )}
        id="storyModal-body"
      >
        {isStoryFetchInProgress ? (
          <Loading wrapperClass={classes.loading} />
        ) : (
          <>
            <FileViewer
              isOpen={isFileViewerOpen}
              toggle={handleFileViewer}
              fileData={fileViewerData}
              title={title}
            />
            <ActivityModal
              storyId={storyId}
              isOpen={openActivityModal}
              toggle={() => setOpenActivityModal(false)}
              activeYear={activeYear}
              documentData={documentData}
            />
            <div className={classnames('w-100 d-flex', classes.modalWrapper)}>
              <div className={classes.fileTable}>
                <div className={classes.modalHeader}>
                  <div className={classes.folderTitle}>
                    <div className="d-flex align-items-center">
                      <i className="fas fa-folder-open" />
                      <div className="mr-2 ml-2">{activeYear?.year}</div>
                      <div>{documentData?.short_name}</div>
                    </div>
                  </div>
                  <span
                    className={classes.viewHistory}
                    onClick={handleViewHistory}
                  >
                    View History
                  </span>
                </div>
                <BaseTable
                  keyField="id"
                  bootstrap4
                  remote
                  noDataIndication={NoDataIndication}
                  search={false}
                  bordered={false}
                  paginationOptions={false}
                  wrapperClasses={classes.tableHeader}
                  data={files.documents}
                  columns={[
                    {
                      dataField: 'name',
                      text: 'FILES',
                      formatter: (cell, row) => (
                        <>
                          <div
                            className={classes.fileName}
                            onClick={() => {
                              setFileViewerData(row);
                              setFileViewerOpen(true);
                            }}
                          >
                            <span id={`file-name-${row.id}`}>{cell}</span>
                          </div>
                          <UncontrolledTooltip
                            delay={0}
                            target={`file-name-${row.id}`}
                            placement="top-start"
                            innerClassName={classes['tooltip']}
                            boundariesElement="viewport"
                            hideArrow={true}
                          >
                            {cell}
                          </UncontrolledTooltip>
                        </>
                      ),
                    },
                    {
                      dataField: 'size',
                      text: 'SIZE',
                      formatter: (cell, row, index) => {
                        const size = Math.round(+cell / 1024);
                        return (
                          <div className="d-flex justify-content-between w-100 align-items-center">
                            <span
                              onClick={() => {
                                setFileViewerData(row);
                                setFileViewerOpen(true);
                              }}
                              className={classes.cursor}
                            >
                              {size} KB
                            </span>
                            <span>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    className={classes.downloadText}
                                    onClick={() => handleDownload(index)}
                                    disabled={downloadLoading}
                                  >
                                    Download File
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => handleDeleteModal(row.id)}
                                    className={classes.deleteText}
                                  >
                                    Delete File
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </span>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              </div>
              <div className={classes.comment}>
                <DocumentDetails
                  projectId={projectId}
                  storyId={recievedStoryId}
                  currentTab={parentCurrentTab}
                  comment={comment}
                  assignStoryOwner={assignStoryOwner}
                  isUserAllowedEdit={isUserAllowedEdit}
                  owner={owner}
                  projectName={projectName}
                  priority={priority}
                  complexity={complexity}
                  complexities={complexities}
                  scoreMatrix={scoreMatrix}
                  score={score}
                  priorities={priorities}
                  createdBy={createdBy}
                  updatedOn={updatedOn}
                  createdOn={createdOn}
                  status={status}
                  changeStatus={changeStatus}
                  changePriority={changePriority}
                  onTabChange={onTabChange}
                  analyticsSendEvent={analyticsSendEvent}
                />
              </div>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

DocumentPreviewModal.propTypes = {
  projectId: PropTypes.number,
  storyId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  onChange: PropTypes.func,
  onTabChange: PropTypes.func,
  currentTab: PropTypes.oneOf(['comments', 'activity', 'info']),
  comment: PropTypes.number,
};

DocumentPreviewModal.defaultProps = {
  isOpen: false,
  onChange: () => {},
  currentTab: 'comments',
};
export default DocumentPreviewModal;
