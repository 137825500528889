import React from 'react';
import { Badge } from 'reactstrap';
import classes from './Kanban.module.scss';
import get from 'lodash/get';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import AssignmentCard from 'components/AssignmentCard';
import cx from 'classnames';

const Column = props => {
  const { name, id, data, filter, expand, user } = props;
  let isDragDisabled = true;
  const acceptableRoles = [
    'Company_Administrator',
    'Fulfilment_Onboarding_Manager',
    'Fulfilment_Director',
  ];

  const roles = get(user, 'roles', []);

  acceptableRoles.forEach(role => {
    const isRolePresent = roles.includes(role);
    if (isRolePresent) {
      isDragDisabled = false;
    }
  });

  const studies = data || [];

  return (
    <div className={classes.column}>
      <div className="mt-1 ml-2 mb-0 flex-shrink-0">
        <div className={classes.columnHeader}>
          <h4 className="m-0">{name}</h4>
          <Badge
            color="info"
            className={cx(
              classes.badge,
              classes[name.toLowerCase().replaceAll(' ', '_')]
            )}
          >
            {studies.length > 99 ? '99+' : studies.length}
          </Badge>
          {filter && <div className="ml-auto">{filter}</div>}
        </div>
      </div>

      <div className={cx(classes.list, { [classes.withFilter]: filter })}>
        <Droppable droppableId={`${id}`}>
          {provided => (
            <div ref={provided.innerRef}>
              {studies.map((item, index) => (
                <Draggable
                  draggableId={`${item.id}`}
                  index={index}
                  key={item.id}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided, snapshot) => {
                    const attrs = {
                      studyId: get(item, 'id'),
                      ownerAvatar: get(item, 'owner.avatar'),
                      progressColorFrom: `#${get(item, 'health.color.0')}`,
                      progressColorTo: `#${get(item, 'health.color.1')}`,
                      companyName: get(item, 'name', null),
                      deadline: get(item, 'deadline', null),
                      studyYears: get(item, 'study_years', null),
                      tasks: get(item, 'tasks', {}),
                      studyPhase: name,
                      expand: expand,
                    };

                    const draggableStyles = snapshot.isDragging
                      ? {
                          ...provided.draggableProps.style,
                          zIndex: 1,
                        }
                      : {
                          ...provided.draggableProps.style,
                          marginBottom: '10px',
                        };

                    const dragHandle = { ...provided.dragHandleProps };

                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...dragHandle}
                        style={draggableStyles}
                        key={item.id}
                      >
                        <AssignmentCard {...attrs} />
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default Column;
