import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClientTasks from '../dashboards/Fields/ClientTasks';
import { getStudyTasksForTasksScreen } from 'store/actions/projectDashboard';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import useBreakPoint from 'helpers/useBreakPoint';
import { Container } from 'reactstrap';
import TaskModal from 'views/pages/dashboards/TaskModal';
import { toNumber, get, map } from 'lodash';
import history from 'helpers/history';
import Helmet from 'components/Helmet';

const Tasks = ({ projectId, isCompanyAdmin, userId }) => {
  const isMobile = useBreakPoint('xs', 'down');
  const dispatch = useDispatch();
  const useGetDetails = (field, defaulValue) => {
    return useGetFieldFromObjects('projectDashboard', field, defaulValue);
  };
  const useGetTasksDetails = (field, defaulValue) => {
    return useGetDetails(
      `tasksScreenClientTasks.${field.replace('data.', '')}`,
      defaulValue
    );
  };
  const [storyId, setStoryId] = useState('');
  const [isTaskModalOpen, setIsTaskModal] = useState(false);
  const [comment, setComment] = useState(null);
  const tab = useRef('comment');
  const isClient = useGetFieldFromObjects('auth', 'user.is_client', false);
  const commonProps = {
    getDetails: useGetTasksDetails,
    isMobile,
    studyDashboard: true,
    tasksDashboard: true,
    showSearch: !isCompanyAdmin,
    dropdownPlaceholder: 'All Teammates',
    defaultSort: { dataField: 'owner_id', order: 'asc' },
  };
  const openTaskProps = {
    ...commonProps,
    reducerKey: 'open',
    actionToPerform: obj => {
      dispatch(
        getStudyTasksForTasksScreen(projectId, {
          ...obj,
          status: 'open',
          ...(isCompanyAdmin ? { owner_id: userId } : {}),
        })
      );
    },
    cardHeader: 'Open Tasks',
  };
  const completedTaskProps = {
    ...commonProps,
    reducerKey: 'completed',
    cardHeader: 'Completed Tasks',
    actionToPerform: obj => {
      dispatch(
        getStudyTasksForTasksScreen(projectId, {
          ...obj,
          status: 'completed',
          ...(isCompanyAdmin ? { owner_id: userId } : {}),
        })
      );
    },
  };
  return (
    <Container fluid>
      <Helmet
        title={`${isClient ? 'Client' : 'Strike'} Portal - Studies - Tasks`}
      />
      <ClientTasks
        {...openTaskProps}
        onRowClick={id => {
          setStoryId(id);
          setIsTaskModal(true);
        }}
      />
      {isTaskModalOpen && (
        <TaskModal
          storyId={toNumber(storyId)}
          isOpen={isTaskModalOpen}
          closeModal={() => {
            setIsTaskModal(false);
            tab.current = 'comment';
            history.push(`?`);
          }}
          currentTab={tab.current}
          comment={comment}
        />
      )}
      <ClientTasks
        {...completedTaskProps}
        onRowClick={id => {
          setStoryId(id);
          setIsTaskModal(true);
        }}
      />
    </Container>
  );
};

export default Tasks;
