import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import classes from './VoidModal.module.scss';
import cs from 'classnames';
import Dropdowns from 'components/Dropdowns';
import { useDispatch, useSelector } from 'react-redux';
import { callPostUpdateStatusDealDeskById } from 'store/actions/dealDeskActions';
import get from 'lodash/get';
import Button from 'components/Button';

const VoidModal = forwardRef(({ id, openSuccessModal, type }, ref) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState({
    value: '',
    text: 'Select',
  });

  const [text, setText] = useState('');

  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );

  const data = dealDesk?.data;

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const toggleModal = () => {
    setOpen(!isOpen);
  };

  const options = [
    {
      value: '',
      text: 'Select',
      onClick: async () => {
        setSelected({
          value: '',
          text: 'Select',
        });
      },
    },
    {
      value: 'change_requested',
      text: 'Changes Requested',
      onClick: async () => {
        setSelected({
          value: 'change_requested',
          text: 'Changes Requested',
        });
      },
    },
    {
      value: 'not_qualified',
      text: 'Denied - Not Qualified',
      onClick: async () => {
        setSelected({
          value: 'not_qualified',
          text: 'Denied - Not Qualified',
        });
      },
    },
    {
      value: 'no_utilization',
      text: 'Denied - No Utilization',
      onClick: async () => {
        setSelected({
          value: 'no_utilization',
          text: 'Denied - No Utilization',
        });
      },
    },
    {
      value: 'premature',
      text: 'Denied - Premature',
      onClick: async () => {
        setSelected({
          value: 'premature',
          text: 'Denied - Premature',
        });
      },
    },
    {
      value: 'other',
      text: 'Other',
      onClick: async () => {
        setSelected({
          value: 'other',
          text: 'Other',
        });
      },
    },
  ];

  const handleSubmit = async () => {
    if (!selected.value) return;
    setLoading(true);
    if (selected.value !== 'other') {
      await dispatch(
        callPostUpdateStatusDealDeskById(
          id,
          {
            status: selected.value,
            reason: '',
          },
          type == 'deals' ? 'deal-desk' : 'partner-deal-desk'
        )
      );
    } else {
      if (!text) return;

      await dispatch(
        callPostUpdateStatusDealDeskById(
          id,
          {
            status_id: selected.value,
            reason: text,
          },
          type == 'deals' ? 'deal-desk' : 'partner-deal-desk'
        )
      );
    }
    setLoading(false);
    openSuccessModal();
    setOpen(false);
  };

  const renderContent = () => {
    return (
      <>
        <ModalBody>
          <div className={classes.title}>Void “{data?.client?.name}”?</div>
          <div className={classes.subTitle}>
            Please let us know why this deal didn't qualify. We'll notify the
            originator of this deal.
          </div>

          <div>
            <Dropdowns
              dropdownClasses={cs(classes.customDropdown)}
              className="m-0 p-1 ml-1"
              text={
                <div className={classes.caret}>
                  <span>{selected.text}</span>{' '}
                  <i className="fas fa-caret-down" />
                </div>
              }
              active={selected.value}
              size="sm"
              role="button"
              caret={false}
              color=""
              options={options}
            />

            {selected.value === 'other' && (
              <textarea
                placeholder="Type here"
                value={text}
                onChange={e => setText(e.target.value)}
                className={classes.textOther}
              />
            )}
          </div>

          <div className={classes.buttons}>
            <button
              type="button"
              className={cs('btn btn-secondary', classes.deny)}
              onClick={() => toggleModal()}
            >
              Cancel
            </button>
            <Button
              loading={loading}
              type="button"
              className="btn btn-danger"
              onClick={() => handleSubmit()}
            >
              Save and Void
            </Button>
          </div>
        </ModalBody>
      </>
    );
  };

  return (
    <Modal
      size="md"
      centered={true}
      scrollable
      fade={false}
      isOpen={isOpen}
      toggle={toggleModal}
      className={classes.modalVoid}
    >
      {renderContent()}
    </Modal>
  );
});

export default VoidModal;
