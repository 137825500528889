import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import {
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardBody,
} from 'reactstrap';
import CardsHeader from 'components/Headers/CardsHeader.js';
import BaseTable from 'components/Table';
import {
  fetchClientsList,
  createClient,
  deleteClient,
} from 'store/actions/clients';
import NoClientsImg from 'assets/img/theme/No_Clients_List.png';
import ClientForm from './Client.Form';

import classes from './clients.module.scss';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import {
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import {
  useAccess,
  permissions,
  showUnAuhtorizedError,
} from 'helpers/permission';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { fetchYears } from 'store/actions/metadata';

const Clients = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isModal, setModal] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.clients,
      ...rest,
    });
  };
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const clientState = useSelector(({ client }) => client.clientState);
  const createReducer = useSelector(({ client }) => client.createClient);
  const deleteReducer = useSelector(({ client }) => client.deleteClient);
  const editReducer = useSelector(({ client }) => client.editClientProfile);

  useEffect(() => {
    dispatch(fetchYears());
    analyticsSendEvent({ action: analyticsConstants.action.view_clients_list });
  }, [dispatch]);

  const clients = get(clientState, 'data.data', []);
  const clientMetadata = get(clientState, 'data.meta', {});
  const listLoading = get(clientState, 'isInProgress', false);
  const createLoading = get(createReducer, 'isInProgress', false);
  const editLoading = get(editReducer, 'isInProgress', false);
  const deleteLoading = get(deleteReducer, 'isInProgress', false);

  if (!useAccess([permissions.LIST_CLIENTS])) {
    showUnAuhtorizedError();
  }

  const NoDataIndication = () => {
    return (
      <div
        className={classNames(
          'd-flex align-items-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column w-100">
          <img
            className={classNames('m-auto w-100', classes.image)}
            src={NoClientsImg}
            alt="No Accounts"
          />
          <h4 className="display-4 mb-0 text-center px-2">
            Go ahead and create your first account!
          </h4>
          <p className="text-center">
            To add a account, click on the{' '}
            <span className={classes.addClient} onClick={openModal}>
              Add Account
            </span>{' '}
            button in the upper right corner
          </p>
        </div>
      </div>
    );
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const openModal = () => {
    setModal(true);
  };

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );
  const closeModal = () => {
    setModal(false);
    setEditValues(null);
  };

  const submitValues = async values => {
    setLoading(true);
    const studyYears = [];
    const { start_year, end_year } = values;
    let start = start_year;
    while (start <= end_year) {
      studyYears.push(start);
      start++;
    }
    const payload = {
      name: values.name,
      assigned_user_id: values.assignedTo.id,
      study_years: studyYears,
    };
    const { status } = await dispatch(createClient(payload));
    if (status) {
      const sort =
        sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
      await dispatch(fetchClientsList(page, sort, ''));
    }
    setModal(false);
    setLoading(false);
  };

  const confirmDelete = async data => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    analyticsSendEvent({ action: analyticsConstants.action.delete_client });

    await dispatch(deleteClient(data.id, { page, sort, q: '' }));
  };

  const deleteAction = row => e => {
    e.preventDefault();
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Delete Account',
      text: `You are about to delete "${row.name}". Do you want to continue?`,
      data: row,
    });
  };

  const handleTableChange = async (
    type,
    { page, sortOrder, sortField, searchText }
  ) => {
    if (type === 'pagination') {
      setPage(page);
    } else if (type === 'sort') {
      setPage(1);
      setSortBy({
        dataField: sortField,
        order: sortOrder,
      });
    } else if (type === 'search') {
      analyticsSendEvent({
        action: analyticsConstants.action.search_clients_list,
      });
      setPage(1);
    }
    const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
    await dispatch(fetchClientsList(page, sort, searchText));
  };

  const handleClearTour = () => {
    dispatch(
      setUserPreference({
        ...onBoardingTour,
        value: {
          ...get(onBoardingTour, 'value', {}),
          status: 'Active',
          ...(!quickStartTour.isRestarted
            ? {
                steps: {
                  ...get(onBoardingTour, 'value.steps', {}),
                  [`${quickStartTour.activeTour}`]: {
                    status: 'Completed',
                  },
                },
              }
            : {}),
        },
      })
    );
    dispatch(
      updateActiveTourStatus({
        activeTour: null,
        step: null,
        isRestarted: false,
      })
    );
  };

  return (
    <>
      {isModal ? (
        <ClientForm
          isModalOpen={isModal}
          submitValues={submitValues}
          closeModal={closeModal}
          editValues={editValues}
          quickStartTour={quickStartTour}
          loading={loading}
        />
      ) : null}
      <div className={classes.clients}>
        <CardsHeader name="Clients" isRoot={true} />
        <div className="px-4">
          <Card>
            <CardBody className="p-0">
              <BaseTable
                keyField="id"
                defaultSorted={[sortBy]}
                noDataIndication={NoDataIndication}
                bootstrap4
                remote
                search={true}
                headerButtonText="Create New Client"
                headerButtonClassName={classes.headerButton}
                headerButtonAction={() => setModal(true)}
                bordered={false}
                loading={
                  listLoading || createLoading || editLoading || deleteLoading
                }
                paginationOptions={{
                  page: page,
                  totalSize: clientMetadata.total,
                  sizePerPage: parseInt(clientMetadata.per_page),
                }}
                data={clients}
                columns={[
                  {
                    dataField: 'name',
                    text: 'NAME',
                    sort: true,
                    classes: classes.clientName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row, index) => (
                      <div className="d-flex justify-content-between w-100 align-items-center font-weight-bold">
                        <Link
                          to={`/admin/accounts/${row.id}/overview`}
                          {...(quickStartTour.activeTour ===
                            'client_creation' && index === 0
                            ? {
                                id: 'firstClientRow',
                                onClick: handleClearTour,
                              }
                            : {})}
                        >
                          {cell}
                        </Link>
                      </div>
                    ),
                  },
                  {
                    dataField: 'billing_state',
                    text: 'STATE',
                    sort: true,
                    classes: classes.clientName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {cell ? cell : '-'}
                      </div>
                    ),
                  },
                  {
                    dataField: 'assigned_user',
                    text: 'ASSIGNED TO',
                    sort: true,
                    classes: classes.clientName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {cell ? cell : '-'}
                      </div>
                    ),
                  },
                  {
                    dataField: 'business_development_rep',
                    text: 'BD REP',
                    sort: true,
                    classes: classes.clientName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {cell ? cell : '-'}
                      </div>
                    ),
                  },
                  {
                    dataField: 'created_at',
                    text: 'DATE CREATED',
                    sort: true,
                    classes: classes.clientName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <span>
                          {moment(cell)
                            .tz(userTimezone)
                            .format('MM/DD/YYYY')}
                        </span>
                        <span>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={e => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                to={`/admin/accounts/${row.id}/overview`}
                                tag={Link}
                              >
                                View Account
                              </DropdownItem>
                              <DropdownItem
                                href="#"
                                onClick={deleteAction(row)}
                              >
                                Delete Account
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </span>
                      </div>
                    ),
                  },
                ]}
                onTableChange={handleTableChange}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Clients;
