import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { ReactComponent as DocumentIcon } from 'assets/img/icons/document-comment-icon-v2.svg';
import { ReactComponent as StudyIcon } from 'assets/img/icons/study-message-v2.svg';
import classes from './Interactions.module.scss';

import { useDispatch } from 'react-redux';
import ContentEditor from 'components/ContentEditor';
import Avatar from 'components/Avatar';

const InteractionItem = props => {
  const dispatch = useDispatch();
  const { interaction, handleMessageClick, user, userTimezone } = props;

  const getMessageIcon = () => {
    switch (interaction?.type) {
      case 'FileAdded':
        return <DocumentIcon />;
      case 'DocumentCommentAdded':
      case 'CommentAdded':
      default:
        return <StudyIcon />;
    }
  };

  const getMessage = interaction => {
    switch (interaction?.type) {
      case 'FileAdded':
        return (
          <ContentEditor
            className={classes.fileAdded}
            content={interaction?.data?.document?.name}
          />
        );
      case 'DocumentCommentAdded':
      default:
        return (
          <ContentEditor
            className={classes.documentCommentAdded}
            content={interaction?.data?.comment?.message || ''}
          />
        );
    }
  };

  const userName = (
    <b style={{ color: '#000' }}>
      {user?.id === interaction.user?.id ? 'You' : interaction?.user?.name}
    </b>
  );

  const getStatusColor = label => {
    switch (label) {
      case 'Not Started':
        return '#32325D';
      case 'Ready For Review':
        return '#1DA1F2';
      case 'Needs Attention':
        return '#EC0C38';
      case 'In Review':
        return '#E9B411';
      case 'Completed':
        return '#24A46D';
      default:
        return '#32325D';
    }
  };

  const getActivityText = () => {
    switch (interaction?.type) {
      case 'FileAdded':
        return (
          <span>
            {userName} uploaded a file to{' '}
            <b
              onClick={() => handleMessageClick(interaction)}
              style={{ color: '#35B0F0', cursor: 'pointer' }}
            >
              {interaction?.data?.documentType?.name}
            </b>{' '}
            <span className={classes.date}>
              {moment(interaction?.updated_at)
                .tz(userTimezone)
                .format('MM/DD/YYYY h:mm A')}
            </span>
          </span>
        );
      case 'CommentAdded':
        return (
          <span>
            {userName} messaged in{' '}
            <b
              onClick={() => handleMessageClick(interaction)}
              style={{ color: '#35B0F0', cursor: 'pointer' }}
            >
              {interaction?.data?.project?.name}
            </b>{' '}
            <span className={classes.date}>
              {moment(interaction?.updated_at)
                .tz(userTimezone)
                .format('MM/DD/YYYY h:mm A')}
            </span>
          </span>
        );
      case 'DocumentStatusChanged':
        return (
          <span>
            {userName} changed status of{' '}
            <b
              onClick={() => handleMessageClick(interaction)}
              style={{ color: '#35B0F0', cursor: 'pointer' }}
            >
              {interaction?.data?.documentType?.name}
            </b>{' '}
            to{' '}
            <b
              onClick={() => handleMessageClick(interaction)}
              style={{
                color: getStatusColor(interaction?.data?.newStatus?.label),
                cursor: 'pointer',
              }}
            >
              {interaction?.data?.newStatus?.label}
            </b>{' '}
            <span className={classes.date}>
              {moment(interaction?.updated_at)
                .tz(userTimezone)
                .format('MM/DD/YYYY h:mm A')}
            </span>
          </span>
        );
      case 'DocumentCommentAdded':
      default:
        return (
          <span>
            {userName} messaged in{' '}
            <b
              onClick={() => handleMessageClick(interaction)}
              style={{ color: '#35B0F0', cursor: 'pointer' }}
            >
              {interaction?.data?.documentType?.name}
            </b>{' '}
            <span className={classes.date}>
              {moment(interaction?.updated_at)
                .tz(userTimezone)
                .format('MM/DD/YYYY h:mm A')}
            </span>
          </span>
        );
    }
  };

  const Activity = ({ interaction }) => {
    return (
      <div
        onClick={() => handleMessageClick(interaction)}
        className={classes.activityCard}
      >
        <div className={classes.cardText}>
          {getMessageIcon()}
          {getMessage(interaction)}
        </div>
        <div className={classes.messageOpenAction}>
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </div>
      </div>
    );
  };

  const renderActivity = () => {
    if (interaction?.grouped_activities?.length) {
      return (
        <>
          {interaction?.grouped_activities?.map((interaction, index) => {
            return <Activity key={index} interaction={interaction} />;
          })}
        </>
      );
    } else {
      return <Activity interaction={interaction} />;
    }
  };

  return (
    <>
      {interaction?.type === 'DocumentStatusChanged' ? (
        <div className={classes.statusChange}>
          <div className={classes.activityText}>
            <div className={classes.divider}></div>
            {getActivityText()}
            <div className={classes.divider}></div>
          </div>
        </div>
      ) : (
        <div className={classes.activityWrapper}>
          <div className={classes.activity}>
            <div className={classes.avatarWrapper}>
              <div
                className={cx(classes.unread, {
                  [classes.unresolved]: !interaction?.resolved_at,
                })}
              >
                <i className="fas fa-circle fa-xs"></i>
              </div>
              <Avatar
                className={classes.avatar}
                url={interaction?.user?.avatar}
              />
            </div>
            <div className={classes.contentWrapper}>
              <div className={classes.activityText}>{getActivityText()}</div>
            </div>
          </div>
          <div className={classes.activityAction}>
            <div></div>
            <div className={classes.messageCardWrapper}>
              {' '}
              {renderActivity()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InteractionItem;
