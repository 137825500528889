import React, { useEffect, useRef } from 'react';
import classes from './TabComments.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import classNames from 'classnames';
import CommentIcon from 'assets/img/icons/deal-desk/comment-icon.svg';
import { callPostMarkReadCommentDealDesk } from 'store/actions/dealDeskActions';

export const TabComments = props => {
  const dispatch = useDispatch();
  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );
  const comments = useSelector(({ dealDesk }) => get(dealDesk, 'listComments'));
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const data = comments?.data || [];
  const unread = dealDesk?.data?.unread_comments || 0;

  const el = useRef(null);
  useEffect(() => {
    el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

    if (unread > 0 && data.length > 0) {
      const ids = data?.map(d => d.id);
      dispatch(callPostMarkReadCommentDealDesk(ids));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChangeTab = comment => {
    let tab = comment?.target;

    if (tab) {
      tab = tab.split('.')[0];
    }

    switch (tab) {
      case 'client':
        props.setTab(1);
        break;

      case 'discovery_call':
        props.setTab(2);
        break;

      case 'el_review':
        props.setTab(3);
        break;

      default:
        break;
    }
  };

  const NoDataIndication = () => {
    return (
      <div
        className={classNames(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className={classes.imageWrapper}>
            <img
              className={classNames('m-auto w-100', classes.defaultImage)}
              src={CommentIcon}
              alt="Discuss this deal with
              the Strike team"
            />
          </div>
          <h4
            className={classNames(
              'display-4',
              'mb-0',
              'text-center',
              'px-2',
              classes.defaultText
            )}
          >
            Discuss this deal with the Strike team
          </h4>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.tabComments}>
      {data.map(d => {
        return (
          <div key={d.id} className={classes.commentItem}>
            <div className={classes.avatar}>
              <div className={classes.picture}>
                <img src={d.created_by?.avatar} />
              </div>
              <div className={classes.name}>{d.created_by?.name}</div>
              <div className={classes.date}>
                {moment(d?.updated_at)
                  .tz(userTimezone)
                  .format('MM/DD/YYYY')}
              </div>
            </div>

            {d?.subject && (
              <div className={classes.quote}>
                <div className={classes.content}>
                  <div
                    className={classes.message}
                    dangerouslySetInnerHTML={{
                      __html: '“ ' + d?.subject + ' ”',
                    }}
                  />
                </div>
                <div>
                  <button onClick={() => handleChangeTab(d)}>View</button>
                </div>
              </div>
            )}

            <div
              className={classes.message}
              dangerouslySetInnerHTML={{ __html: d?.message }}
            />
          </div>
        );
      })}

      {data.length === 0 && NoDataIndication()}

      <div id={'el'} ref={el}></div>
    </div>
  );
};
