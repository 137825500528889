import Request from './request';
import { store } from 'store/index';
import get from 'lodash/get';
import axios from 'axios';

export const getInteractions = async params => {
  return Request.call({
    url: `/interactions`,
    method: 'GET',
    params: {
      limit: 20,
      ...params,
    },
  });
};

export const getStudyInteractions = async (id, params = {}) => {
  return Request.call({
    url: `/initiatives/${id}/interactions`,
    method: 'GET',
    params: {
      limit: 20,
      ...params,
    },
  });
};

export const getUpcomingStudyList = async params => {
  return Request.call({
    url: `/initiatives?type=upcoming`,
    method: 'GET',
    params: {
      limit: 5,
      ...params,
    },
  });
};

export const getRecentlyViewedStudiesList = async (params = {}) => {
  return Request.call({
    url: `/initiatives`,
    method: 'GET',
    params: {
      limit: 5,
      type: 'recently_viewed',
      ...params,
    },
  });
};

export const getDocumentBucketStatusList = async params => {
  // params: limit
  return Request.call({
    url: `/document-status-list`,
    method: 'GET',
    params: { ...params },
  });
};
export const getFinancialYearStatusList = async params => {
  // params: limit
  return Request.call({
    url: `/year-status-list`,
    method: 'GET',
    params: { ...params },
  });
};

export const updateFinancialYearStatus = async (data, yearId) => {
  return Request.call({
    url: `/financial-document/year/${yearId}/status`,
    method: 'POST',
    data,
  });
};

export const updateDocumentBucketStatus = async (data, documentId) => {
  return Request.call({
    url: `financial-document/document/${documentId}/status`,
    method: 'POST',
    data,
  });
};
export const postInteractionUnresolved = async data => {
  return Request.call({
    url: `/initiatives/interactions/unresolved`,
    method: 'POST',
    data,
  });
};

export const downloadAllDocuments = async (id, params) => {
  const storeData = store.getState();
  const token = get(storeData, 'auth.user.auth_token', null);

  // Using axios here, as using Request.call allows to download the file but content is broken/corrupted
  // If we use Request.call content of file gets undefined in text file, images are broken
  return axios({
    url: `${process.env.REACT_APP_API_URL}/initiatives/${id}/download-all`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
};

export const getAllDocumentList = async (id, params) => {
  return Request.call({
    url: `/initiatives/${id}/list-all-docs`,
    method: 'GET',
    params,
  });
};

export const getAllDocumentFolder = async (id, params) => {
  return Request.call({
    url: `/initiatives/${id}/docs-folders`,
    method: 'GET',
    params,
  });
};
