import React from 'react';
import { connect } from 'react-redux';
// react library for routing
import { Link, NavLink as NavLinkRRD } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from 'react-perfect-scrollbar';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import DollarIcon from 'assets/img/icons/dollar-circle-icon.svg';

// reactstrap components
import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import { permissions } from 'helpers/permission';
import { showSupportForm } from 'store/actions/app';
import classes from './Sidebar.module.scss';
import {
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import InviteUserFromSidebar from 'components/Sidebar/InviteUserFromSidebar';
import { fetchInviteClientRoles, fetchRoles } from 'store/actions/users';
import queryString from 'query-string';
import Can from 'components/Can';
import history from 'helpers/history';
import Button from 'components/Button';
import SubmitReferralForm from 'components/Sidebar/SubmitReferralForm';
import ScheduleMeetingForm from 'components/Sidebar/ScheduleMeetingForm';
import { fetchCalendlyDetails } from 'store/actions/partners';
import CalendlyModal from 'components/Sidebar/CalendlyModal';
import { isEmpty } from 'lodash';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      adminOpen: false,
      customizationsOpen: false,
      isInviteModal: false,
      invitingClient: false,
      isSubmitReferralModal: false,
      isScheduleMeetingModal: false,
      calendlyLink: '',
      ...this.getCollapseStates(props.routes),
    };
  }

  componentDidMount() {
    this.props.fetchRoles();
    this.props.fetchClientRoles();
    const queryProps = queryString.parse(this.props.location.search);
    if (this.props.isPartner) {
      switch (queryProps.action) {
        case 'submit_referral':
          this.setState({
            isSubmitReferralModal: true,
          });
          break;
        case 'book_meeting':
          var calendlyActionButton = document.getElementById(
            'calendly-action-button'
          );
          calendlyActionButton.click();
          break;
        default:
          break;
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      this.openModalThroughQuery();
    }
  }

  openModalThroughQuery = () => {
    const queryProps = queryString.parse(this.props.location.search);
    if (this.props.isPartner) {
      switch (queryProps.action) {
        case 'submit_referral':
          this.setState({
            isSubmitReferralModal: true,
          });
          break;
        case 'book_meeting':
          var calendlyActionButton = document.getElementById(
            'calendly-action-button'
          );
          calendlyActionButton.click();
          break;
        default:
          break;
      }
    }
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  handleInviteModal = value => {
    this.setState({
      isInviteModal: value,
    });
    if (!value) {
      this.setState({ inviteClient: false });
    }
  };

  /*// toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };*/

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {
      referralCollapse: true,
    };
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      }
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
    const step = get(this.props, 'quickStartTour.step');
    const activeTour = get(this.props, 'quickStartTour.activeTour');
    if (activeTour) {
      setTimeout(() => {
        if (
          (activeTour === 'client_creation' ||
            activeTour === 'project_creation' ||
            activeTour === 'story_creation' ||
            activeTour === 'invite_team' ||
            activeTour === 'view_projects') &&
          step === 0
        ) {
          setTimeout(() => {
            this.props.updateActiveTourStatus({
              step:
                activeTour === 'invite_team' || activeTour === 'story_creation'
                  ? this.props.assignedProjects.length
                    ? 1
                    : 2
                  : 1,
            });
          }, 1500);
        }
      });
    }
  };

  checkForPermission = permission => {
    if (isArray(permission)) {
      let atLeastOnePermission = false;
      for (let i = 0; i < permission.length; i++) {
        if (includes(this.props.permissions, permission[i])) {
          atLeastOnePermission = true;
          break;
        }
      }
      return atLeastOnePermission;
    }
    return includes(this.props.permissions, permission);
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { showEarning, currentRole, isPartner } = this.props;
    const isPartnerAdmin = currentRole === 'Partner_Administrator';

    return routes.map((prop, key) => {
      if (
        // (prop.permission && !this.checkForPermission(prop.permission)) ||
        prop.redirect ||
        prop.hide ||
        (prop.hideForAdmin && !this.props.isClient) ||
        (prop.forAdminOnly && this.props.isClient) ||
        (prop.path === '/earnings' && !showEarning) ||
        (prop.showForPartnerAdmin && !(isPartnerAdmin && isPartner))
      ) {
        return null;
      }

      const { badge: Badge } = prop;
      if (prop.collapse) {
        const st = {};
        st[prop.state] = !this.state[prop.state];

        return (
          <NavItem key={key}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              className={classnames({
                active: this.getCollapseInitialState(prop.views),
              })}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">
                    {prop.name} {prop.badge && <Badge />}
                  </span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal">
                    {prop.name} {prop.badge && <Badge />}
                  </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={this.state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {this.createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem
          className={classnames(
            this.activeRoute(prop.layout + prop.path),
            classes.navLink
          )}
          key={key}
        >
          <NavLink
            to={prop.layout + prop.path}
            activeClassName={classes.active}
            onClick={this.closeSidenav}
            disabled={prop.disabled}
            tag={NavLinkRRD}
            id={`sidebarItem-${prop.path.slice(1)}`}
          >
            {prop.icon !== undefined ? (
              prop.path === '/earnings' ? (
                <>
                  <img
                    src={DollarIcon}
                    width={18}
                    height={16}
                    className="mr-3"
                    alt="Not found icon"
                  />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              )
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal">
                  {prop.name} {prop.badge && <Badge />}
                </span>
              </>
            ) : (
              <>
                {prop.name} {prop.badge && <Badge />}
              </>
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  getDisabledClass = () => {
    const step = get(this.props, 'quickStartTour.step');
    const activeTour = get(this.props, 'quickStartTour.activeTour');
    if (activeTour) {
      if (activeTour === 'user_invitation' && step === 1) {
        return '';
      } else if (!document.body.classList.contains('g-sidenav-pinned')) {
        return `${classes.disabled}`;
      }
    }
  };

  handleClickForCustomizations = async e => {
    e.preventDefault();
    this.setState({
      customizationsOpen: !this.state.customizationsOpen,
    });
  };

  handleClickForAdmin = async e => {
    e.preventDefault();
    const activeTour = get(this.props, 'quickStartTour.activeTour');
    const step = get(this.props, 'quickStartTour.step');
    this.setState({
      adminOpen:
        activeTour === 'user_invitation' ? true : !this.state.adminOpen,
    });
    if (activeTour === 'user_invitation' && step === 0) {
      if (!document.body.classList.contains('g-sidenav-pinned')) {
        await this.props.toggleSidenav();
      }
      setTimeout(async () => {
        this.props.updateActiveTourStatus({
          step: 1,
        });
      }, 250);
    }
  };

  handleSidebarClick = async () => {
    const currentStep = get(this.props, 'quickStartTour.step');
    const currentTour = get(this.props, 'quickStartTour.activeTour');
    // if tour is active and sidebar is toggled then updating tour state
    if (
      currentStep === 0 ||
      ((currentTour === 'user_invitation' || currentTour === 'time_logging') &&
        currentStep === 1)
    ) {
      await this.props.updateActiveTourStatus({
        activeTour: null,
        step: null,
      });
      await this.props.toggleSidenav();
      setTimeout(() => {
        this.props.updateActiveTourStatus({
          activeTour: currentTour,
          step:
            currentTour === 'user_invitation' && currentStep === 1
              ? 0
              : currentStep,
        });
      }, 2000);
    } else {
      this.props.toggleSidenav();
    }
    // updating admin state on sidebar click
    this.setState({
      adminOpen: false,
    });
  };

  render() {
    const {
      routes,
      logo,
      currentUser,
      isClient,
      isPartner,
      currentRole,
    } = this.props;
    let showAdminAction = !isClient;
    const isSlaFlow =
      get(this.props, 'location.pathname') === '/admin/client/sla-agreement';

    const isPartnerManger = currentRole === 'Partner_Manager';
    if (isClient) {
      showAdminAction = currentUser.roles.some(
        role => role === 'Client_Master_Collaborator'
      );
    }
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank',
      };
    }
    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          <div className="ml-auto">
            <div
              className={classnames('sidenav-toggler d-xl-block', {
                active: false,
              })}
              onClick={this.handleSidebarClick}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen>
            <Nav navbar>
              {this.createLinks(routes)}
              {!isPartner && !this.props.isClient && (
                <Can permissions={permissions.INVITE_USERS}>
                  {showAdminAction && (
                    <NavItem>
                      <NavLink
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          this.handleInviteModal(true);
                        }}
                      >
                        <i className="fas fa-user-plus icon-gradient icon-invite-user" />
                        <span className="nav-link-text">Invite Team</span>
                      </NavLink>
                    </NavItem>
                  )}
                </Can>
              )}
            </Nav>
            {!isPartner && !isSlaFlow && !isClient && (
              <div
                className={classnames(this.props.isClient && classes.footerNav)}
              >
                <hr className="my-3" />
                <h6
                  className={classnames('navbar-heading p-0 text-muted', {
                    ['infoTitle']: this.props.sidenavOpen,
                  })}
                >
                  <span className="docs-normal">Information</span>
                  <span className="docs-mini"></span>
                </h6>
                <Nav className="mb-md-3" navbar>
                  {/* <NavItem>
                <NavLink
                  href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/overview?ref=adpr-sidebar"
                  target="_blank"
                >
                  <i className="ni ni-spaceship" />
                  <span className="nav-link-text">Getting started</span>
                </NavLink>
              </NavItem> */}
                  {!this.props.isClient && (
                    <NavItem
                      className={this.activeRoute('/admin/knowledge-base')}
                    >
                      <Link to="/admin/knowledge-base" className="nav-link">
                        <i className="ni ni-spaceship" />
                        <span className="nav-link-text">Knowledge Base</span>
                      </Link>
                    </NavItem>
                  )}
                  {/*{!this.props.isClient && (*/}
                  {/*  <NavItem>*/}
                  {/*    <NavLink*/}
                  {/*      href="#pablo"*/}
                  {/*      data-toggle="collapse"*/}
                  {/*      aria-expanded={this.state.customizationsOpen}*/}
                  {/*      onClick={this.handleClickForCustomizations}*/}
                  {/*    >*/}
                  {/*      <i className="fas fa-tools"></i>*/}
                  {/*      <span className="nav-link-text">Customizations</span>*/}
                  {/*    </NavLink>*/}
                  {/*    <Collapse isOpen={this.state.customizationsOpen}>*/}
                  {/*      <Nav className="nav-sm flex-column">*/}
                  {/*        <NavItem>*/}
                  {/*          <NavLink*/}
                  {/*            to="/admin/assignment-types"*/}
                  {/*            className={this.activeRoute(*/}
                  {/*              '/admin/assignment-types'*/}
                  {/*            )}*/}
                  {/*            tag={NavLinkRRD}*/}
                  {/*          >*/}
                  {/*            <span className="sidenav-mini-icon" />*/}
                  {/*            <span className="sidenav-normal">*/}
                  {/*              Assignment Types*/}
                  {/*            </span>*/}
                  {/*          </NavLink>*/}
                  {/*        </NavItem>*/}
                  {/*      </Nav>*/}
                  {/*    </Collapse>*/}
                  {/*  </NavItem>*/}
                  {/*)}*/}
                  {showAdminAction && !isPartnerManger && !this.props.isClient && (
                    <NavItem>
                      <NavLink
                        href="#pablo"
                        data-toggle="collapse"
                        aria-expanded={this.state.adminOpen}
                        id="sidebarItem-Admin"
                        onClick={this.handleClickForAdmin}
                      >
                        <i className="ni ni-settings-gear-65" />
                        <span className="nav-link-text">Admin</span>
                      </NavLink>
                      <Collapse isOpen={this.state.adminOpen}>
                        <Nav className="nav-sm flex-column">
                          <NavItem>
                            <NavLink
                              to="/admin/users"
                              className={this.activeRoute('/admin/users')}
                              tag={NavLinkRRD}
                              id="sidebarItem-Users"
                              onClick={async () => {
                                const step = get(
                                  this.props,
                                  'quickStartTour.step'
                                );
                                const activeTour = get(
                                  this.props,
                                  'quickStartTour.activeTour'
                                );
                                if (
                                  activeTour === 'user_invitation' &&
                                  step === 1
                                ) {
                                  await this.props.toggleSidenav();
                                  this.props.updateActiveTourStatus({
                                    step: 2,
                                  });
                                }
                              }}
                            >
                              <span className="sidenav-mini-icon" />
                              <span className="sidenav-normal">
                                User Management
                              </span>
                            </NavLink>
                          </NavItem>
                          <Can permissions={permissions.VIEW_COMPANY_SETTINGS}>
                            <NavItem>
                              <NavLink
                                to="/admin/company"
                                className={this.activeRoute('/admin/company')}
                                tag={NavLinkRRD}
                              >
                                <span className="sidenav-mini-icon" />
                                <span className="sidenav-normal">
                                  Company Settings
                                </span>
                              </NavLink>
                            </NavItem>
                          </Can>
                          {!this.props.isClient && (
                            <NavItem>
                              <NavLink
                                to="/admin/contracts"
                                className={this.activeRoute('/admin/contracts')}
                                tag={NavLinkRRD}
                              >
                                <span className="sidenav-mini-icon" />
                                <span className="sidenav-normal">
                                  Contracts
                                </span>
                              </NavLink>
                            </NavItem>
                          )}
                        </Nav>
                      </Collapse>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        this.props.showSupportForm(true);
                      }}
                    >
                      <i className="fa fa-question-circle" />
                      <span className="nav-link-text">
                        {this.props.isClient ? 'Support' : 'Ask a Question'}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            )}
            {isPartner && (
              <>
                {/*<ScheduleMeetingForm*/}
                {/*  isOpen={this.state.isScheduleMeetingModal}*/}
                {/*  closeModal={() => {*/}
                {/*    this.setState({*/}
                {/*      isScheduleMeetingModal: false,*/}
                {/*    });*/}
                {/*  }}*/}
                {/*/>*/}
                <CalendlyModal
                  link={this.state.calendlyLink}
                  isOpen={this.state.isScheduleMeetingModal}
                  isLoading={this.props.isCalendlyLoading}
                  modalClose={() => {
                    this.setState({
                      isScheduleMeetingModal: false,
                    });
                    history.push('?');
                  }}
                />
                <SubmitReferralForm
                  openMeetingModal={link => {
                    this.setState({
                      isScheduleMeetingModal: true,
                      calendlyLink: link,
                    });
                  }}
                  isOpen={this.state.isSubmitReferralModal}
                  openModal={() => {
                    this.setState({
                      isSubmitReferralModal: true,
                    });
                  }}
                  closeModal={() => {
                    this.setState({
                      isSubmitReferralModal: false,
                    });
                    history.push('?');
                  }}
                />

                <div
                  className={classnames(
                    classes.partnerActions,
                    'nav-link-text'
                  )}
                >
                  <div className={classes.actionButtons}>
                    <Button
                      color="info"
                      onClick={() => {
                        this.setState({
                          isSubmitReferralModal: true,
                        });
                      }}
                    >
                      Submit a Referral
                    </Button>
                    <Button
                      id="calendly-action-button"
                      onClick={async () => {
                        const resp = await this.props.fetchCalendly(
                          currentUser.id
                        );
                        if (resp?.status === 1) {
                          this.setState({
                            isScheduleMeetingModal: true,
                          });
                          this.setState({
                            calendlyLink: resp?.link || '',
                          });
                        }
                      }}
                      outline
                      color="info"
                      loading={this.props.isCalendlyLoading}
                    >
                      Book a Meeting
                    </Button>
                  </div>

                  <p>
                    Talk to a Strike Tax advisor about how to improve your
                    referral campaign
                  </p>
                </div>
              </>
            )}
            {this.props.isClient && (
              <>
                <CalendlyModal
                  link={this.state.calendlyLink}
                  isOpen={this.state.isScheduleMeetingModal}
                  isLoading={this.props.isCalendlyLoading}
                  modalClose={() => {
                    this.setState({
                      isScheduleMeetingModal: false,
                    });
                  }}
                />
                <div
                  className={classnames(classes.clientAction, 'nav-link-text')}
                >
                  <div className={classnames(classes.actionButtons)}>
                    {!currentUser?.roles?.includes('Technical_SME') ? (
                      <Button
                        outline
                        leftIcon={
                          <i className="fas fa-user-plus icon-gradient icon-invite-user" />
                        }
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ invitingClient: true });
                          this.handleInviteModal(true);
                        }}
                        className={classes.inviteClientButton}
                      >
                        Invite Collaborators
                      </Button>
                    ) : (
                      <div></div>
                    )}
                    <div className={classes.bookMeetingButtonWrapper}>
                      <Button
                        className={classes.clientBookMeeting}
                        onClick={async () => {
                          this.setState({
                            isScheduleMeetingModal: true,
                          });
                          this.setState({
                            calendlyLink: 'https://calendly.com/rick-patel',
                          });
                        }}
                        outline
                        loading={this.props.isCalendlyLoading}
                      >
                        Book a Meeting
                      </Button>
                      <p>Set a meeting with your Strike team.</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Collapse>
        </div>
      </div>
    );
    return (
      <>
        <Navbar
          style={{ overflowX: 'hidden' }}
          className={`sidenav navbar-vertical left-side-menu navbar-expand-xs navbar-light bg-white ${
            this.props.rtlActive ? '' : 'fixed-left'
          } ${classes.sidebar} ${this.getDisabledClass()}`}
        >
          {/* {navigator.platform.indexOf('Win') > -1 ? (
            <PerfectScrollbar>
              {scrollBarInner}
              </PerfectScrollbar>
          ) : ( */}
          {scrollBarInner}
          {/* )} */}
        </Navbar>
        {this.state.isInviteModal ? (
          <InviteUserFromSidebar
            closeModal={this.handleInviteModal}
            invitingClient={this.state.invitingClient}
          />
        ) : null}
      </>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
  fetchRoles: PropTypes.func,
  fetchCalendly: PropTypes.func,
};

function mapStateToProps(state) {
  const planId = get(state, 'auth.user.company.subscription.plan_id', '');
  const allPlans = get(state, 'billing.plans.data') || [];
  const proPlan = allPlans.find(p => p.name === 'Pro');
  const isPro = planId === get(proPlan, 'plan_id');
  return {
    permissions: get(state, 'auth.user.permissions', []) || [],
    isClient: get(state, 'auth.user.is_client', false) || false,
    isDocumentsApproved: get(
      state,
      'auth.user.company.documents_approved',
      false
    ),
    quickStartTour: state.profile.quickStartTour,
    preferences: state.profile.preference.data,
    isProPlan: isPro,
    currentUser: state.auth.user,
    showEarning: get(state, 'auth.user.see_earning', false),
    assignedProjects: get(state, 'auth.user.assigned_initiatives', []),
    isPartner:
      get(state, 'auth.user.is_partner', false) ||
      !isEmpty(get(state, 'user.impersonateState.guestPartner', {})),
    isCalendlyLoading:
      get(state, 'partner.calendlyDetails.isInProgress', false) || false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showSupportForm: isOpen => {
      dispatch(showSupportForm(isOpen));
    },
    setUserPreference: data => {
      dispatch(setUserPreference(data));
    },
    updateActiveTourStatus: data => {
      dispatch(updateActiveTourStatus(data));
    },
    fetchRoles: data => {
      dispatch(fetchRoles(data));
    },
    fetchClientRoles: () => {
      dispatch(fetchInviteClientRoles());
    },
    fetchCalendly: async data => {
      const resp = await dispatch(fetchCalendlyDetails(data));
      return resp;
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
