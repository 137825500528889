import React, { useRef, useState, useEffect } from 'react';
import classes from './ContractCreate.module.scss';
import {
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { get, isEmpty } from 'lodash';
import { Form, Formik } from 'formik';
import cx from 'classnames';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import ToggleCheckBox from 'components/FormFields/ToggleCheckBox';
import { createContract, clearContractData } from 'store/actions/contract';
import Button from 'components/Button';
import PreviewModal from './PreviewModal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Loading from 'components/Loading';
import CustomEditor from 'components/FormFields/CustomEditor';

const VARIABLES = [
  '{{ Entity Name }}',
  '{{ Who is the El being address to? }}',
  '{{ Signatory Email }}',
  '{{ Signatory Name }}',
  '{{ccName1}}',
  '{{ccEmail}}',
  '{{ Address line 1 }}',
  '{{ Address line 2 }}',
  '{{ City }}',
  '{{ State }}',
  '{{ Zipcode}}',
  '{{ Open Year1 of EL }}',
  '{{ Current Year of EL }}',
  '{{ First year of last Tax Return }}',
  '{{ Last year of last Tax Return }}',
  '{{ Option 1 }}',
  '{{ Option 2 }}',
  '{{ Date }}',
  '{{ RA Main Partner Contact }}',
  '{{ RA Legal Entity Name }}',
  '{{ RA Signatory Email }}',
  '{{ Referral Agreement % }}',
  '{{ Effective Date }}',
  '{{ Related Entities }}',
];

const ContractCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [previewDetails, setPreviewDetails] = useState({});
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [contractType, setContractType] = useState('ertc');
  const summaryRef = useRef();
  const contentRef = useRef();

  const createReducer = useSelector(({ contract }) => contract.createContract);
  const editReducer = useSelector(({ contract }) => contract.editContract);
  const getInitialContract = useSelector(
    ({ contract }) => contract.getContract
  );

  const createLoading = get(createReducer, 'isInProgress', false);
  const editLoading = get(editReducer, 'isInProgress', false);
  const initialContractLoading = get(getInitialContract, 'isInProgress', false);
  const initialContract = get(getInitialContract, 'data', {});
  const contractId = get(initialContract, 'id', null);
  const status = get(initialContract, 'status', 'Draft');
  const type = get(initialContract, 'type', 'ERTC');

  let initialValues = {
    title: get(initialContract, 'title', ''),
    status: status === 'Draft' ? false : true,
    summary: get(initialContract, 'summary', ''),
    content: get(initialContract, 'content', ''),
  };

  useEffect(() => {
    setContractType(type);
  }, [type]);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required('Title is required'),
    status: Yup.boolean().required(),
    summary: Yup.string()
      .ensure()
      .required('Required')
      .test(
        'is-empty',
        'Content can not be empty',
        value =>
          !isEmpty(value.replace(/(<\/?(?:img)[^>]*>)|<[^>]+>/gi, '$1').trim())
      ),
    content: Yup.string()
      .ensure()
      .required('Required')
      .test(
        'is-empty',
        'Content can not be empty',
        value =>
          !isEmpty(value.replace(/(<\/?(?:img)[^>]*>)|<[^>]+>/gi, '$1').trim())
      ),
  });

  const renderForm = ({ handleSubmit, setFieldValue, values, isValid }) => {
    return (
      <Form role="form" onSubmit={handleSubmit} className={classes.root}>
        <Card className={classes.card}>
          <CardBody className={classes.cardBody}>
            <Row className={classes.row}>
              <Col>
                <h5 className={classes.required}>First Name</h5>
                <div className={classes.inputWrapper}>
                  <FormikInput
                    groupClassName="w-100"
                    name="title"
                    placeholder="Title*"
                    type="text"
                  />
                  <div
                    className={cx(
                      classes.checkBoxWrapper,
                      'd-flex align-items-center'
                    )}
                  >
                    <p>Publish</p>
                    <ToggleCheckBox
                      className="d-flex"
                      checkedLabel={'Yes'}
                      unCheckedLabel={'No'}
                      checked={values.status}
                      onChange={event => {
                        setFieldValue(
                          'status',
                          event.target.checked ? event.target.checked : false
                        );
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className={classes.row}>
              <Col md={12}>
                <h5>
                  Contract Summary Text -{' '}
                  <span className={classes.light}>
                    Displayed above contract on client signing screen
                  </span>
                </h5>
                <CustomEditor
                  variables={VARIABLES}
                  assignRef={summaryRef}
                  value={values.summary}
                  onChange={content => setFieldValue('summary', content)}
                  className={cx('mb-4', classes.summaryEditor)}
                  placeholder="Enter contract summary content (optional)"
                />
              </Col>
            </Row>
            <Row className={classes.row}>
              <Col md={12}>
                <h5>Full Contract Language</h5>
                <CustomEditor
                  variables={VARIABLES}
                  assignRef={contentRef}
                  value={values.content}
                  onChange={content => setFieldValue('content', content)}
                  className={cx('mb-4', classes.fullContractEditor)}
                  placeholder="Enter full contract language"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button
              onClick={() => {
                history.push('/admin/contracts');
              }}
              color="link"
            >
              Cancel
            </Button>
            <div className={classes.rightBtn}>
              <Button
                className={cx('btn-outline-default', classes.previewButton)}
                onClick={() => {
                  setIsPreviewModalOpen(true);
                  setPreviewDetails(values);
                }}
                disabled={!isValid}
                // loading={isLoading}
              >
                Preview
              </Button>
              <Button
                type="submit"
                color={'primary'}
                disabled={!isValid}
                loading={createLoading}
              >
                Save
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Form>
    );
  };

  if (initialContractLoading) {
    return <Loading />;
  }

  return (
    <>
      {isPreviewModalOpen && (
        <PreviewModal
          isOpen={isPreviewModalOpen}
          previewDetails={previewDetails}
          toggle={() => setIsPreviewModalOpen(!isPreviewModalOpen)}
        />
      )}
      <Container className={classes.container} fluid>
        <div className={classes.title}>
          <p>Create New Contract</p>
          <div className={classes.contractTypeSwitch}>
            <span>Template Type:</span>
            <ButtonGroup
              aria-label="Template Type"
              role="group"
              className={classes.buttonGroup}
            >
              <Button
                className={cx({ [classes.active]: contractType === 'ERTC' })}
                onClick={() => setContractType('ERTC')}
              >
                ERTC
              </Button>
              <Button
                className={cx({ [classes.active]: contractType === 'R&D' })}
                onClick={() => setContractType('R&D')}
              >
                RD
              </Button>
              <Button
                className={cx({ [classes.active]: contractType === 'RA' })}
                onClick={() => setContractType('RA')}
              >
                RA
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className={classes.formWrapper}>
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async values => {
              const formValues = {
                ...values,
                status: values.status ? 1 : 0,
                type: contractType,
                ...(contractId ? { id: +contractId } : {}),
              };
              const { status } = await dispatch(createContract(formValues));
              if (status) {
                history.push('/admin/contracts');
                dispatch(clearContractData());
              }
            }}
          >
            {renderForm}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default ContractCreate;
