import React from 'react';
import PropTypes from 'prop-types';
import classes from './CardsHeader.module.scss';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import cs from 'classnames';

const CardsHeader = props => {
  const {
    currentPage = '',
    name = '',
    title = '',
    activeContent = '',
    parentName = '',
    parentLink = '',
    childName = '',
    childLink = '',
    dark,
    isRoot,
    showActionMenu = false,
    actionMenu = false,
    studyName = '',
  } = props;

  const getName = () => {
    if (studyName) {
      return `${parentName} - ${studyName}`;
    }
    return currentPage
      ? currentPage
      : name.length > 25
      ? `${name.slice(0, 24)}...`
      : name;
  };

  return (
    <>
      <div className="header">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col className="d-flex align-items-center">
                <h2
                  className={cs('d-inline-block mb-0 ', {
                    'text-white': dark === true,
                  })}
                >
                  {getName()}
                </h2>
                <h2 className={cs('d-inline-block mb-0', classes.name)}>
                  {title?.length > 25 ? `${title.slice(0, 24)}...` : title}
                </h2>
                {!isRoot && (
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName={cs('breadcrumb-links', {
                      'breadcrumb-dark ': dark === true,
                    })}
                  >
                    <BreadcrumbItem>
                      <Link to="/admin/dashboard">
                        <i className="fas fa-home" />
                      </Link>
                    </BreadcrumbItem>
                    {parentName && (
                      <BreadcrumbItem>
                        <Link to={parentLink}>
                          {parentName.length > 25
                            ? `${parentName.slice(0, 24)}...`
                            : parentName}
                        </Link>
                      </BreadcrumbItem>
                    )}
                    {childName ? (
                      <BreadcrumbItem>
                        <Link to={childLink}>
                          {childName.length > 25
                            ? `${childName.slice(0, 24)}...`
                            : childName}
                        </Link>
                      </BreadcrumbItem>
                    ) : null}
                    {name ? (
                      <BreadcrumbItem aria-current="page" className="active">
                        {name.length > 25 ? `${name.slice(0, 24)}...` : name}
                      </BreadcrumbItem>
                    ) : null}
                    {activeContent ? (
                      <BreadcrumbItem aria-current="page" className="active">
                        {activeContent.length > 25
                          ? `${activeContent.slice(0, 24)}...`
                          : activeContent}
                      </BreadcrumbItem>
                    ) : null}
                  </Breadcrumb>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  currentPage: PropTypes.string,
  dark: PropTypes.bool,
  isRoot: PropTypes.bool,
  parentLink: PropTypes.string,
};

CardsHeader.defaultProps = {
  dark: false,
};
export default CardsHeader;
