import React, { useEffect, useState } from 'react';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Row,
  Col,
  Badge,
} from 'reactstrap';
import cx from 'classnames';
import get from 'lodash/get';
import ProgressBar from 'components/Fulfillment/ProgressBar';
import ExpenseCard from './ExpenseCard';
import Overview from './Overview';
import classes from './FulfillmentStudyDashboard.module.scss';
import { getDashboardMessages } from 'store/actions/userDashboard';
import { fetchDocumentStudyTypes } from 'store/actions/documents';
import { useParams, useHistory, useLocation } from 'react-router';
import { ReactComponent as BackIcon } from 'assets/img/icons/chevron-left-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getProject as getProjectAction } from 'store/actions/projects';
import Interactions from './Interactions';
import SourceDocuments from './SourceDocuments';
import Loading from 'components/Loading';
import { formatDate } from 'helpers/times';
import Button from 'components/Button';
import KickoffModal from './KickoffModal';
import queryString from 'query-string';
import Tabs from 'components/Tabs';

const FulfillmentStudyDashboard = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);
  const [openKickoffModal, setOpenKickoffModal] = useState(false);

  const toggleKickoffModal = () => setOpenKickoffModal(!openKickoffModal);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const dispatch = useDispatch();

  const params = useParams();
  const id = get(params, 'id');
  const query = queryString.parse(search);

  const tab = query?.tab || 'activity_feed'; //study_documents

  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', null)
  );
  const currentStudyLoading = useSelector(({ project }) =>
    get(project, 'getProject.isInProgress', false)
  );
  const studyInteractionsCount = useSelector(({ fulfillment }) =>
    get(fulfillment, 'studyInteractions.data.unresolved_count', 0)
  );

  const phases = get(currentStudy, 'phases', null);

  const projectPhase = get(currentStudy, 'project_phase', null);
  const name = get(currentStudy, 'name', '');

  const client = get(currentStudy, 'client');

  const lead = get(currentStudy, 'lead');
  const taxDeadline = get(currentStudy, 'tax_deadline');
  const documentGatherBy = get(currentStudy, 'documents_gather_by');
  const creditEstimate = get(currentStudy, 'value');
  const dateFormat = 'MMM DD, YYYY';
  const handleDocAndYearStatusUpdate = () => {
    setIsRefresh(true);
    dispatch(getProjectAction(id));
  };
  useEffect(() => {
    dispatch(getProjectAction(id));
    dispatch(getDashboardMessages('list'));
    dispatch(fetchDocumentStudyTypes(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const tabs = [
    {
      id: 'activity_feed',
      label: (
        <span className="d-flex align-items-center">
          Activity Feed{' '}
          {studyInteractionsCount > 0 && (
            <Badge className={classes.headerBadge}>
              {studyInteractionsCount > 99 ? '99+' : studyInteractionsCount}
            </Badge>
          )}
        </span>
      ),
      onClick: () => history.push(`${pathname}?tab=activity_feed`),
    },
    {
      id: 'study_documents',
      label: <span>Study Documents</span>,
      onClick: () => history.push(`${pathname}?tab=study_documents`),
    },
  ];

  if (currentStudyLoading && !isRefresh) {
    return <Loading />;
  }

  return (
    <>
      {openKickoffModal ? (
        <KickoffModal
          size="lg"
          backdrop={['static']}
          scrollable
          fade={false}
          title={'ssss'}
          toggle={toggleKickoffModal}
          isOpen={openKickoffModal}
          className={classes.modalWrapper}
          headerClassName={classes.modalHeader}
        >
          hi
        </KickoffModal>
      ) : null}
      <Container className={classes.container} fluid>
        <div className={classes.headerWrapper}>
          <div className={classes.header}>
            <BackIcon
              className={classes.leftIcon}
              onClick={() => history.push('/admin/dashboard')}
            />
            <div className={classes.studyName}>{name ? name : '-'}</div>{' '}
            <Breadcrumb
              className={cx(
                'd-none d-md-inline-block ml-md-4',
                classes.breadcrumb
              )}
              listClassName="breadcrumb-links"
            >
              <BreadcrumbItem>
                <div style={{ display: 'flex' }}>
                  <a href="/admin/dashboard">
                    <i className="fas fa-home" />
                  </a>
                  &nbsp; - &nbsp;
                  <div className={classes.rightStudyName}>
                    {name ? name : '-'}
                  </div>
                </div>
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
          <Button
            onClick={() => {
              history.push(`/admin/accounts/${client?.id}/overview`);
            }}
            className={classes.goToClient}
            color="secondary"
          >
            Go to Client
          </Button>
        </div>

        <ProgressBar phases={phases} projectPhase={projectPhase?.label} />
        <Card className={classes.card}>
          <CardBody className={classes.cardBody}>
            <Row className={classes.row}>
              <Col className={cx('col-12 col-lg-9 ', classes.content)}>
                <div className={classes.expenseCardWrapper}>
                  <ExpenseCard
                    title="Tax Deadline"
                    value={formatDate(taxDeadline, dateFormat)}
                    color="#172B4D"
                  />
                  <ExpenseCard
                    title="Credit Estimate"
                    value={<span>{creditEstimate}</span>}
                    color="#172B4D"
                  />
                  <ExpenseCard
                    title="Gather Docs By"
                    value={formatDate(documentGatherBy, dateFormat)}
                    //"MAR 10, 2023"
                    color="#172B4D"
                  />
                </div>
                <div className={classes.contentWrapper}>
                  <Tabs
                    navClassName={classes.tabs}
                    tabs={tabs}
                    activeTab={tab}
                  />
                  <TabContent className={classes.content} activeTab={tab}>
                    <TabPane
                      tabId={'activity_feed'}
                      key={0}
                      tag={Container}
                      fluid
                      className={classes.tabWrapper}
                    >
                      <Interactions activeTab={tab} />
                    </TabPane>
                    <TabPane
                      tabId={'study_documents'}
                      key={1}
                      tag={Container}
                      fluid
                      className={classes.tabWrapper}
                    >
                      <SourceDocuments
                        handleDocAndYearStatusUpdate={
                          handleDocAndYearStatusUpdate
                        }
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </Col>

              <Col className={cx('col-12 col-lg-3', classes.tab)}>
                <div className={classes.kickoffAction}>
                  <h3>Next Step</h3>
                  <Button
                    onClick={() => setOpenKickoffModal(true)}
                    color="primary"
                    className={classes.button}
                  >
                    Ready for Kickoff Call
                  </Button>
                </div>
                <Overview />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default FulfillmentStudyDashboard;
