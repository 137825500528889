import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from './RecentlyViewedStudies.module.scss';
import history from 'helpers/history';

import NoStudy from 'assets/img/theme/NoStudy.png';
import { getRecentlyViewedStudiesList } from 'store/actions/fulfillment';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from 'components/Loading';
import { useLocation } from 'react-router';
import queryString from 'query-string';

const RecentlyViewedStudies = ({
  title = 'Recently Viewed Studies',
  cardClassName = '',
  listParams = {
    type: 'recently_viewed',
  },
  isDocumentPage = false,
  rowAction,
  fetchFirstStudy,
  showActive = false,
}) => {
  const dispatch = useDispatch();
  const { search: queryParams } = useLocation();
  const { initiative_id = null } = queryString.parse(queryParams);
  const [studyId, setStudyId] = useState(initiative_id);

  const studiesMeta = useSelector(({ fulfillment }) =>
    get(fulfillment, 'recentlyViewedStudiesList.data.meta', {})
  );
  const studiesLoading = useSelector(({ fulfillment }) =>
    get(fulfillment, 'recentlyViewedStudiesList.isInProgress', false)
  );

  const current_page = get(studiesMeta, 'current_page', 0);
  const last_page = get(studiesMeta, 'last_page', 0);

  const [studies, setStudies] = useState([]);
  const [filters, setFilters] = useState({});

  const rowEvents = {
    onClick: (e, row) => {
      if (rowAction) {
        rowAction(row);
      } else {
        history.push(`/admin/studies/${row.id}/dashboard`);
      }
    },
  };

  useEffect(() => {
    initiative_id && setStudyId(initiative_id);
  }, [initiative_id]);

  useEffect(() => {
    fetchStudies(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchFirstStudy && studies.length > 0 && current_page === 1) {
      fetchFirstStudy(studies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies, current_page]);

  const fetchStudies = async (page = 1, params = {}, fromSort = false) => {
    const { data } = await dispatch(
      getRecentlyViewedStudiesList({ page, ...params, ...listParams })
    );

    if (fromSort) {
      setStudies([...data]);
    } else {
      setStudies([...studies, ...data]);
    }
  };

  return (
    <Card className={classes.wrapper}>
      <CardHeader className={classes.cardHeader}>
        <h3 className="mb-0">{title}</h3>
        {studies.length > 0 && listParams?.type && (
          <>
            <span className={classes.tableSubHeader}>Viewed last 48 hours</span>
          </>
        )}
      </CardHeader>
      <CardBody className={cx(classes.cardBody, cardClassName)}>
        <InfiniteScroll
          useWindow={false}
          hasMore={current_page < last_page}
          initialLoad={false}
          pageStart={1}
          loadMore={() => {
            if (studies?.length && !studiesLoading) {
              fetchStudies(current_page + 1, filters);
            }
          }}
        >
          {studies?.length === 0 && studiesLoading ? (
            <Loading />
          ) : (
            <BaseTable
              keyField="id"
              defaultSorted={null}
              noDataIndication={() => (
                <div className="text-center mt-5 mb-5">
                  <img src={NoStudy} />

                  <div className="mt-1">
                    {' '}
                    <h3>
                      <b>No Recently Viewed Studies</b>
                    </h3>
                  </div>
                </div>
              )}
              search={false}
              bordered={false}
              loading={studiesLoading}
              paginationOptions={false}
              data={studies}
              classes="mb-0"
              rowClasses={classes.row}
              wrapperClasses={classes.tableWrapper}
              rowEvents={rowEvents}
              onTableChange={(type, { sortOrder, sortField }) => {
                if (type === 'sort') {
                  const sort =
                    sortOrder === 'desc' ? `-${sortField}` : sortField;
                  setFilters({ sort });
                  fetchStudies(1, { sort }, true);
                }
              }}
              columns={[
                {
                  dataField: 'name',
                  text: 'Study',
                  sort: false,
                  classes: (d, row) => {
                    return showActive && studyId == row.id
                      ? classes.activeName
                      : '';
                  },
                  formatter: (cell, row) => (
                    <>
                      <div
                        className={cx(
                          'd-flex align-items-center',
                          classes.name
                        )}
                        id={`project-name-${row.id}`}
                      >
                        <span>{cell || '-'}</span>
                      </div>
                    </>
                  ),
                },
                {
                  dataField: 'study_years',
                  text: 'YEARS',
                  sort: false,
                  hidden: isDocumentPage,
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div
                          className={cx(
                            'd-flex align-items-center',
                            classes['project-name']
                          )}
                          id={`project-phase-${row.id}`}
                        >
                          <span>{cell?.label || '2017 - 2019'}</span>
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: 'phase',
                  text: 'Phase',
                  sort: false,
                  hidden: isDocumentPage,
                  formatter: (cell = {}, row) => {
                    return (
                      <>
                        <div className="m-0 d-flex align-items-center">
                          {cell?.name && (
                            <i
                              style={{
                                color: `${cell?.color}`,
                                fontSize: '6px',
                              }}
                              className="fas fa-circle mr-1"
                            />
                          )}
                          <span> {cell?.name || '-'}</span>
                        </div>
                      </>
                    );
                  },
                },
              ]}
            />
          )}
        </InfiniteScroll>
      </CardBody>
    </Card>
  );
};

export default RecentlyViewedStudies;
