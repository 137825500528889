import CalendlyModal from 'components/Sidebar/CalendlyModal';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './PartnerManager.module.scss';

const PartnerManager = () => {
  const partnerManager = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.partner_manager', {})
  );
  const calendlyLink = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.calendly_link', '')
  );

  const [openMeetingModal, setOpenMeetingModal] = useState(false);
  return (
    <>
      {calendlyLink && (
        <CalendlyModal
          link={calendlyLink}
          isOpen={openMeetingModal}
          isLoading={false}
          modalClose={() => {
            setOpenMeetingModal(false);
          }}
        />
      )}
      <div className={classes.profile}>
        <div className={classes.image}>
          <img src={partnerManager?.avatar} alt="img" />
        </div>
        <div className={classes.details}>
          <h3>
            {partnerManager?.first_name} {partnerManager?.last_name}
          </h3>
          <a href={`mailto:${partnerManager?.email}`}>
            <img
              src={require('assets/img/icons/envelope-icon.svg')}
              alt="envelope"
            />
            Send Email
          </a>
          <a>
            <img src={require('assets/img/icons/phone-icon.svg')} alt="phone" />
            Make a Call
          </a>
          <a onClick={() => setOpenMeetingModal(true)}>
            <img
              src={require('assets/img/icons/calender-icon.svg')}
              alt="calender"
            />
            Book a Meeting
          </a>
        </div>
      </div>
    </>
  );
};

export default PartnerManager;
