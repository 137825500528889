import React, { useEffect, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import Modal, { ModalBody } from 'components/FormFields/Modal';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import classes from './DocumentCommentModal.module.scss';
import classnames from 'classnames';

import { ReactComponent as PreviewIcon } from 'assets/img/icons/eye-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/download-icon.svg';
import { clearCommentsData } from 'store/actions/Story/comments';
import moment from 'moment';
import useBreakPoint from 'helpers/useBreakPoint';
import DocumentDetails from './DocumentDetails';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import socket from 'helpers/socket';
import analytics, { analyticsConstants } from 'helpers/analytics';
import {
  didAllOnboardingFlowCompleted,
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import Loading from 'components/Loading';
import BaseTable from 'components/Table';
import { deleteAttachedFile } from 'store/actions/clientProfile';
import {
  downloadDocument,
  clearDocumentUrl,
  fetchDocumentStudyTypes,
  uploadStudyDocument,
} from 'store/actions/documents';
import { useParams, useLocation } from 'react-router';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import Button from 'components/Button';
import DocumentViewer from './DocumentViewer';
import DocumentYearStatus from './DocumentYearStatus';
import { downloadAllDocuments } from 'store/actions/fulfillment';
import history from 'helpers/history';
import queryString from 'query-string';
import DocumentUpload from 'views/pages/ClientStudyDashboard/DocumentUploadModal/DocumentUpload';
import cloneDeep from 'lodash/cloneDeep';

const DocumentCommentModal = ({
  isOpen,
  closeModal,
  params = {},
  userType = 'client',
  handleDocAndYearStatusUpdate = () => {},
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const parameters = useParams();
  const initiativeId = get(parameters, 'id', queryProps?.initiative_id);
  const [files, setFiles] = useState({});
  const [isFileViewerOpen, setFileViewerOpen] = useState(false);
  const [fileViewerData, setFileViewerData] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(false);
  const parentId = get(params, 'parent_id');

  const isMobile = useBreakPoint('xs', 'down');
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );

  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };

  const handleGetDocumentData = documents => {
    const getDocData = documents?.find(
      doc => doc?.financial_year_document_id == parentId
    );

    return getDocData || [];
  };
  const getDocs = async () => {
    setDocumentLoading(true);
    const { data = [] } = await dispatch(fetchDocumentStudyTypes(initiativeId));
    const getDocData = handleGetDocumentData(data);

    setFiles(getDocData);
    setDocumentLoading(false);
  };
  useEffect(() => {
    getDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiativeId]);

  useEffect(() => {
    if (parentId) {
      socket.joinAndListenComments('DocumentType', parentId, params);
      return () => {
        socket.leaveComments();
        dispatch(clearCommentsData());
      };
    }
    // used when notification is sent to user
    const leftColumn = document.getElementById('storyModal-leftColumn');
    if (leftColumn) leftColumn.scrollTop = 0;
    setFiles(handleGetDocumentData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  const isStoryFetchInProgress = useSelector(({ story }) =>
    get(story, 'details.isInProgress', false)
  );

  const useGetFieldValue = (fieldName, emptyValue = null) =>
    useGetFieldFromObject('story', `details.data.${fieldName}`, emptyValue);

  const downloadLoading = useGetFieldFromObject(
    'documents',
    'downloadDoc.isInProgress',
    false
  );

  const storyId = useGetFieldValue('id');

  if (!isStoryFetchInProgress && parentId === storyId) {
    closeModal(successMessage);
  }

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );
  const createSuccessMessage = updatedDocument => {
    if (updatedDocument.status || updatedDocument.years) {
      let message = '';
      if (updatedDocument.status)
        message += `${currentStudy?.name}-${files?.name} has been marked "${updatedDocument?.status}"`;
      if (updatedDocument.years) {
        updatedDocument.years.forEach(year => {
          if (message != '')
            message += ` and Year ${year.year} in ${currentStudy?.name} - ${files?.name} set to "${year.status}"`;
          else
            message += `Year ${year.year} in ${currentStudy?.name} - ${files?.name} set to "${year.status}"`;
        });
      }
      setSuccessMessage(message);
      handleDocAndYearStatusUpdate();
    }
  };
  const handleModalClose = async () => {
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'story_creation'
    ) {
      const isWithCreateProject = quickStartTour.withCreateProject;
      await dispatch(
        updateActiveTourStatus({
          step: null,
          activeTour: null,
          withCreateProject: false,
        })
      );
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action:
          analyticsConstants.action.complete_create_a_story_onboarding_flow,
      });
      if (isWithCreateProject) {
        analytics.sendEvent({
          category: analyticsConstants.category.onboarding,
          action:
            analyticsConstants.action.complete_create_a_project_onboarding_flow,
        });
      }
      dispatch(
        setUserPreference({
          ...onBoardingTour,
          value: {
            ...get(onBoardingTour, 'value', {}),
            status: 'Active',
            steps: {
              ...get(onBoardingTour, 'value.steps', {}),
              story_creation: {
                status: 'Completed',
              },
              ...(isWithCreateProject
                ? {
                    project_creation: {
                      status: 'Completed',
                    },
                  }
                : {}),
            },
          },
        })
      );
      didAllOnboardingFlowCompleted();
    }
    closeModal(successMessage);
  };

  const handleDocumentDelete = async id => {
    await deleteAttachedFile(id);
    const index = files.documents.findIndex(d => d.id === id);
    const updatedFiles = {
      ...files,
      documents: [
        ...files.documents?.slice(0, index),
        ...files.documents?.slice(index + 1),
      ],
    };
    setFiles(updatedFiles);
  };

  const handleDeleteModal = id => {
    AlertPopupHandler.open({
      onConfirm: () => handleDocumentDelete(id),
      confirmBtnText: `Delete`,
      text: (
        <div className="d-flex flex-column">
          <div>Do you want to delete this file?</div>
          <div>
            This file will be unrecoverable and version history will be lost.{' '}
          </div>
        </div>
      ),
    });
  };

  const handleDownload = (index, row) => {
    dispatch(downloadDocument(files.documents[index]));
  };

  const NoDataIndication = () => {
    return (
      <div className={classes.noDataIndication}>
        {!documentLoading && (
          <>
            <p>There are no documents uploaded. </p>
            {userType === 'admin' && (
              <Button
                outline
                rightIcon={<i className="fas fa-file"></i>}
                className={classes.uploadButton}
                onClick={() => setShowUploadModal(true)}
              >
                Upload Document
              </Button>
            )}
          </>
        )}
      </div>
    );
  };

  const handleFileViewer = () => {
    setFileViewerOpen(false);
    setFileViewerData(null);

    dispatch(clearDocumentUrl());
  };

  const handlePreviewAll = () => {
    if (files?.documents?.length > 0) {
      setFileViewerOpen(true);
      setFileViewerData(files?.documents);
    }
  };

  const handleDownloadAll = async () => {
    await dispatch(
      downloadAllDocuments(initiativeId, {
        doc_type_ids: [files.id],
      })
    );
  };

  const onDrop = acceptedFiles => {
    setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
  };

  const handleRemoveFile = index => {
    const fileClone = cloneDeep(files);
    fileClone.splice(index, 1);
    setUploadedFiles(fileClone);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((attachment, index) => {
        formData.append(`attachments[${index}]`, attachment);
      });
    }
    formData.append('initiative_id', initiativeId);
    formData.append('document_type_id', files.id);
    const response = await dispatch(uploadStudyDocument(formData));

    if (response?.status) {
      getDocs();
      setShowUploadModal(false);
    }
  };

  return (
    <>
      {showUploadModal ? (
        <Modal
          size="lg"
          backdrop={['static']}
          scrollable
          fade={false}
          title={
            <p>
              Add your <strong>{files.name}</strong>
            </p>
          }
          toggle={() => setShowUploadModal(false)}
          isOpen={showUploadModal}
          className={classes.modalWrapperUpload}
          headerClassName={classes.modalHeaderUpload}
        >
          <DocumentUpload
            files={uploadedFiles}
            next={handleUpload}
            prev={() => setShowUploadModal(false)}
            onDrop={onDrop}
            removeFile={handleRemoveFile}
            userType={userType}
          />
        </Modal>
      ) : null}
      {isFileViewerOpen ? (
        <DocumentViewer
          handleDownloadAll={handleDownloadAll}
          isOpen={isFileViewerOpen}
          toggle={handleFileViewer}
          title={currentStudy?.name}
          files={fileViewerData}
        />
      ) : null}
      <Modal
        size="xl"
        closeButtonVariant="version_2"
        scrollable
        fade={false}
        title={`${currentStudy?.name} ${
          files?.short_name ? ' - ' + files?.short_name : ''
        } `}
        toggle={handleModalClose}
        isOpen={isOpen}
        className={classes.documentModalWrapper}
      >
        <ModalBody
          className={classnames(
            'p-0 d-flex flex-row flex-wrap',
            classes['document-modal'],
            { [classes['document-modal-mobile']]: isMobile }
          )}
          id="storyModal-body"
        >
          {isStoryFetchInProgress ? (
            <Loading wrapperClass={classes.loading} />
          ) : (
            <>
              <div className="d-flex flex-column flex-fill ">
                {userType === 'admin' && files?.financial_year_document_id && (
                  <DocumentYearStatus
                    documentData={files}
                    initiativeId={currentStudy?.id}
                    handleDocAndYearStatusUpdate={createSuccessMessage}
                  />
                )}
                <div
                  className={classnames('w-100 d-flex', classes.modalWrapper)}
                >
                  <div
                    className={classnames(classes.fileTable, {
                      [classes.height]: userType === 'admin',
                    })}
                  >
                    <div className={classes.modalHeader}>
                      <div className={classes.folderTitle}>
                        <div className="d-flex align-items-center">
                          <i className="fas fa-folder-open" />
                          <div>{'Uploaded Docs '}</div>
                        </div>
                      </div>
                      {userType === 'admin' && (
                        <div className={classes.adminAction}>
                          <Button
                            outline
                            rightIcon={<i className="fas fa-file"></i>}
                            color="default"
                            className={classes.uploadButton}
                            onClick={() => setShowUploadModal(true)}
                          >
                            Upload Document
                          </Button>

                          <span>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className={classnames(classes.dropdownMenu)}
                              >
                                <DropdownItem
                                  onClick={handleDownloadAll}
                                  className={classes.downloadAllDocuments}
                                  disabled={downloadLoading}
                                >
                                  <span>Download All</span>
                                  <DownloadIcon />
                                </DropdownItem>
                                <DropdownItem
                                  className={classes.downloadAllDocuments}
                                  onClick={handlePreviewAll}
                                >
                                  <span>Preview All</span>
                                  <PreviewIcon />
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </span>
                        </div>
                      )}
                      {userType === 'client' &&
                        !pathname.includes('/admin/studies') && (
                          <div>
                            <Button
                              outline
                              leftIcon={
                                <i
                                  className="fa fa-envelope"
                                  aria-hidden="true"
                                ></i>
                              }
                              color="primary"
                              className={classes.redirectButton}
                              onClick={() => {
                                history.push(`/admin/studies/${initiativeId}`);
                                closeModal();
                              }}
                            >
                              Go to Study
                            </Button>
                          </div>
                        )}
                    </div>
                    <BaseTable
                      keyField="id"
                      bootstrap4
                      remote
                      loading={documentLoading}
                      noDataIndication={NoDataIndication}
                      showHeaderWithNoData
                      search={false}
                      bordered={false}
                      paginationOptions={false}
                      wrapperClasses={classes.tableHeader}
                      data={files?.documents || []}
                      columns={[
                        {
                          dataField: 'name',
                          text: 'Documents',
                          formatter: (cell, row, index) => (
                            <div
                              className={classnames(
                                classes.fileName,
                                'table-data  d-flex justify-content-between align-items-center'
                              )}
                              onClick={() => {
                                if (row?.mime_type === 'application/zip') {
                                  handleDownload(index, row);
                                } else {
                                  setFileViewerData([row]);
                                  setFileViewerOpen(true);
                                }
                              }}
                            >
                              <span id={`file-name-${row.id}`} className="p-0">
                                {cell}
                              </span>
                            </div>
                          ),
                        },
                        {
                          dataField: 'size',
                          text: 'Size',
                          formatter: (cell, row, index) => {
                            const size = Math.round(+cell / 1024);
                            return (
                              <div
                                className={classnames(
                                  classes.tData,
                                  'table-data d-flex justify-content-between align-items-center'
                                )}
                              >
                                <span
                                  onClick={() => {
                                    setFileViewerData([row]);
                                    setFileViewerOpen(true);
                                  }}
                                  className={classnames(classes.cursor)}
                                >
                                  {size} KB
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          dataField: 'created_at',
                          text: 'Date',
                          formatter: (cell, row, index) => {
                            return (
                              <div
                                className={classnames(
                                  classes.tData,
                                  'table-data d-flex justify-content-between align-items-center'
                                )}
                              >
                                <span className={classes.cursor}>
                                  {moment(cell).format('MM/DD/YYYY, h:mm A')}
                                </span>
                                <span className="p-0">
                                  <UncontrolledDropdown className="p-0 m-0">
                                    <DropdownToggle
                                      className="btn-icon-only text-light p-0 m-0"
                                      href="#pablo"
                                      role="button"
                                      size="sm"
                                      color=""
                                      onClick={e => e.preventDefault()}
                                    >
                                      <i className="fas fa-ellipsis-v" />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className={classnames(
                                        classes.dropdownMenu
                                      )}
                                    >
                                      <DropdownItem
                                        className={classes.downloadText}
                                        onClick={() =>
                                          handleDownload(index, row)
                                        }
                                        disabled={downloadLoading}
                                      >
                                        <DownloadIcon />
                                        <span>Download</span>
                                      </DropdownItem>
                                      {userType === 'client' && (
                                        <DropdownItem
                                          onClick={() =>
                                            handleDeleteModal(row.id)
                                          }
                                          className={classes.deleteText}
                                        >
                                          <i
                                            className="fas fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                          <span>Delete File</span>
                                        </DropdownItem>
                                      )}
                                      <DropdownItem
                                        className={classes.downloadText}
                                        onClick={() => {
                                          setFileViewerData([row]);
                                          setFileViewerOpen(true);
                                        }}
                                      >
                                        <PreviewIcon /> <span>Preview</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </span>
                              </div>
                            );
                          },
                        },
                      ]}
                    />
                  </div>
                  <div className={classes.comment}>
                    <DocumentDetails
                      initiativeId={initiativeId}
                      userType={userType}
                      params={params}
                      parentId={parentId}
                      currentTab={'comments'}
                      comment={''}
                      onTabChange={() => {}}
                      analyticsSendEvent={analyticsSendEvent}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

DocumentCommentModal.defaultProps = {
  isOpen: false,
  onChange: () => {},
  currentTab: 'comments',
};
export default DocumentCommentModal;
