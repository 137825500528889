import React, { useEffect, useState } from 'react';
import classes from './AdvanceFilterModal.module.scss';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import cx from 'classnames';
import Input from 'components/FormFields/Input';
import Button from 'components/Button';
import DateRangePicker from 'components/FormFields/DateRangePicker';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { get, map, isEqual } from 'lodash';
import RSelect from 'components/FormFields/RSelect';
import {
  getDeadlineDates,
  getProjectDirectors,
  getProjectStaff,
} from 'store/actions/kanbanActions';

const AdvanceFilterModal = ({ isOpen, toggle, handleFilter, filter }) => {
  const dispatch = useDispatch();
  const timezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const projectDeadLines = useSelector(({ kanban }) => {
    const data = get(kanban, 'deadlineDates.data', []);
    return map(data, date => ({
      id: date,
      text: moment(date).format('YYYY-MM-DD'),
    }));
  });

  const projectDeadLinesLoading = useSelector(({ kanban }) =>
    get(kanban, 'deadlineDates.loading', false)
  );

  const projectStaffData = useSelector(({ kanban }) => {
    const data = get(kanban, 'projectStaff.data', []);
    return map(data, d => ({
      value: d.id,
      label: d.name,
    }));
  });

  const projectDirectorData = useSelector(({ kanban }) => {
    const data = get(kanban, 'projectDirector.data', []);
    return map(data, d => ({
      value: d.id,
      label: d.name,
    }));
  });

  const initialDates = {
    start_date: moment().tz(timezone),
    end_date: moment().tz(timezone),
    date_range: '',
  };

  const alreadySelectedDirectors = projectDirectorData.filter(d =>
    filter?.assignees?.includes(d?.value)
  );
  const alreadySelectedStaff = projectStaffData.filter(d =>
    filter?.assignees?.includes(d?.value)
  );

  const alreadySelectedDeadline = projectDeadLines?.filter(
    d => filter?.deadline_date === d?.text
  );

  const [projectDirectors, setProjectDirectors] = useState(
    alreadySelectedDirectors
  );

  const [deadline, setDeadline] = useState(alreadySelectedDeadline);

  const [projectStaff, setProjectStaff] = useState(alreadySelectedStaff);

  const [signedElDate, setSignedElDate] = useState({
    ...initialDates,
    start_date:
      moment(filter?.signed_from_date).tz(timezone) || initialDates.start_date,
    end_date:
      moment(filter?.signed_to_date).tz(timezone) || initialDates.end_date,
  });

  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState({
    ...initialDates,
    start_date:
      moment(filter?.delivery_from_date).tz(timezone) ||
      initialDates.start_date,
    end_date:
      moment(filter?.delivery_to_date).tz(timezone) || initialDates.end_date,
  });

  const [localFilter, setLocalFilter] = useState(filter);

  useEffect(() => {
    dispatch(getDeadlineDates());
    dispatch(getProjectStaff());
    dispatch(getProjectDirectors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const assignees = [
      ...projectDirectors?.map(d => d.value),
      ...projectStaff?.map(d => d.value),
    ];
    const estimatedDeliveryDateParams = estimatedDeliveryDate.date_range
      ? {
          delivery_from_date: estimatedDeliveryDate.start_date.format(
            'YYYY-MM-DD'
          ),
          delivery_to_date: estimatedDeliveryDate.end_date.format('YYYY-MM-DD'),
        }
      : {};
    const signedElDateParams = signedElDate.date_range
      ? {
          signed_from_date: signedElDate.start_date.format('YYYY-MM-DD'),
          signed_to_date: signedElDate.end_date.format('YYYY-MM-DD'),
        }
      : {};

    const filterToSet = {
      ...estimatedDeliveryDateParams,
      ...signedElDateParams,
      assignees,
      deadline_date: deadline.text,
    };
    setLocalFilter({ ...filter, ...filterToSet });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    signedElDate,
    estimatedDeliveryDate,
    projectStaff,
    projectDirectors,
    deadline,
  ]);

  const handleProjectDirectors = option => {
    const findSelectOptionIndex = projectDirectors?.findIndex(
      opt => opt?.value === option.value
    );
    const selectedOptionClone = [...projectDirectors];

    if (findSelectOptionIndex === -1) {
      selectedOptionClone.push(option);
    } else {
      selectedOptionClone.splice(findSelectOptionIndex, 1);
    }
    setProjectDirectors(selectedOptionClone);
  };

  const handleProjectStaff = option => {
    const findSelectOptionIndex = projectStaff?.findIndex(
      opt => opt?.value === option.value
    );
    const selectedOptionsClone = [...projectStaff];

    if (findSelectOptionIndex === -1) {
      selectedOptionsClone.push(option);
    } else {
      selectedOptionsClone.splice(findSelectOptionIndex, 1);
    }
    setProjectStaff(selectedOptionsClone);
  };

  return (
    <Modal
      toggle={toggle}
      //   backdrop="static"
      centered
      isOpen={isOpen}
      title={'Advanced Filters'}
      className={classes.modal}
      size={'xl'}
    >
      <ModalBody className={classes.modalBody}>
        <div className={classes.filterWrapper}>
          <div className={classes.checkboxFilters}>
            <div
              className={cx(
                classes.projectDirectors,
                classes.filterContentWrapper
              )}
            >
              <div className={classes.title}>
                <h3>Project Director</h3>
                <div className={classes.divider}></div>
              </div>
              <div className={classes.options}>
                {projectDirectorData?.map((option, index) => {
                  return (
                    <div key={index} className={classes.option}>
                      <Input
                        id={`checkbox=${option.value}`}
                        type="checkbox"
                        inputClassName="h-100"
                        checked={
                          projectDirectors?.findIndex(
                            opt => opt?.value === option?.value
                          ) !== -1
                        }
                        onChange={() => {
                          handleProjectDirectors(option);
                        }}
                        label={<span>{option.label}</span>}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={cx(classes.projectStaff, classes.filterContentWrapper)}
            >
              <div className={classes.title}>
                <h3>Project Staff</h3>
                <div className={classes.divider}></div>
              </div>
              <div className={classes.options}>
                {projectStaffData?.map((option, index) => {
                  return (
                    <div key={index} className={classes.option}>
                      <Input
                        id={`checkbox=${option.value}-${index}`}
                        type="checkbox"
                        inputClassName="h-100"
                        checked={
                          projectStaff?.findIndex(
                            opt => opt?.value === option?.value
                          ) !== -1
                        }
                        onChange={() => {
                          handleProjectStaff(option);
                        }}
                        label={<span>{option.label}</span>}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={classes.dateFilters}>
            <div
              className={cx(
                classes.signedElDate,
                classes.datePicker,
                classes.filterContentWrapper
              )}
            >
              <div className={classes.title}>
                <h3>Signed EL Date</h3>
                <div className={classes.divider}></div>
              </div>
              <div>
                <DateRangePicker
                  rootClassName={classes.rootStyle}
                  calendarClassName={classes.calendarStyle}
                  leftPanelClassName={classes.leftPanelStyle}
                  buttonClassName={classes.buttonStyle}
                  showIcon={false}
                  defaultOpen
                  removePanelActions={[1, 3, 4, 5]}
                  activeButton={2}
                  dayPickerRangeControllerProps={{ numberOfMonths: 1 }}
                  startDate={signedElDate.start_date}
                  datePickerClass={classes.datePicker}
                  endDate={signedElDate.end_date}
                  value={signedElDate.date_range}
                  onChange={dates => {
                    if (dates.startDate && dates.endDate) {
                      setSignedElDate(prev => ({
                        ...prev,
                        start_date: dates.startDate,
                      }));
                      setSignedElDate(prev => ({
                        ...prev,
                        end_date: dates.endDate,
                      }));
                      const rangeDate =
                        dates.startDate.format('YYYY-MM-DD') +
                        ' - ' +
                        dates.endDate.format('YYYY-MM-DD');
                      setSignedElDate(prev => ({
                        ...prev,
                        date_range: rangeDate,
                      }));
                    } else {
                      setSignedElDate(prev => ({
                        ...prev,
                        date_range: signedElDate?.date_range,
                      }));
                    }
                  }}
                />
              </div>
              <div className={classes.resetButton}>
                <Button
                  color="link"
                  onClick={() => {
                    setSignedElDate(initialDates);
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
            <div className={classes.dateSideWrapper}>
              <div
                className={cx(
                  classes.estimatedDeliveryDate,
                  classes.datePicker,
                  classes.filterContentWrapper
                )}
              >
                <div className={classes.title}>
                  <h3>Estimated Delivery Date</h3>
                  <div className={classes.divider}></div>
                </div>
                <div>
                  <div>
                    <DateRangePicker
                      rootClassName={classes.rootStyle}
                      calendarClassName={classes.calendarStyle}
                      leftPanelClassName={classes.leftPanelStyle}
                      buttonClassName={classes.buttonStyle}
                      showIcon={false}
                      defaultOpen
                      removePanelActions={[1, 3, 4, 5]}
                      activeButton={2}
                      dayPickerRangeControllerProps={{ numberOfMonths: 1 }}
                      startDate={estimatedDeliveryDate.start_date}
                      datePickerClass={classes.datePicker}
                      endDate={estimatedDeliveryDate.end_date}
                      value={estimatedDeliveryDate.date_range}
                      onChange={dates => {
                        if (dates.startDate && dates.endDate) {
                          setEstimatedDeliveryDate(prev => ({
                            ...prev,
                            start_date: dates.startDate,
                          }));
                          setEstimatedDeliveryDate(prev => ({
                            ...prev,
                            end_date: dates.endDate,
                          }));
                          const rangeDate =
                            dates.startDate.format('YYYY-MM-DD') +
                            ' - ' +
                            dates.endDate.format('YYYY-MM-DD');
                          setEstimatedDeliveryDate(prev => ({
                            ...prev,
                            date_range: rangeDate,
                          }));
                        } else {
                          setEstimatedDeliveryDate(prev => ({
                            ...prev,
                            date_range: estimatedDeliveryDate?.date_range,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={classes.resetButton}>
                  <Button
                    color="link"
                    onClick={() => {
                      setEstimatedDeliveryDate(initialDates);
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
              <div
                className={cx(
                  classes.clientDeadlineDate,
                  classes.datePicker,
                  classes.filterContentWrapper
                )}
              >
                <div className={classes.title}>
                  <h3>Filing Deadline Date</h3>
                  <div className={classes.divider}></div>
                </div>
                <div>
                  <RSelect
                    placeholder="Select Date"
                    isClearable={false}
                    menuPosition="fixed"
                    isLoading={projectDeadLinesLoading}
                    options={projectDeadLines}
                    value={deadline}
                    leftIconClass={classes.icon}
                    onBlur={() => {
                      // setFieldTouched('industry_id', true);
                    }}
                    onChange={selectedOption => {
                      setDeadline(selectedOption);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <div className={classes.buttons}>
          <Button
            className={cx('btn-outline-primary', classes.previewButton)}
            onClick={() => {
              handleFilter({});
              setLocalFilter({});
              setEstimatedDeliveryDate(initialDates);
              setSignedElDate(initialDates);
              setProjectDirectors([]);
              setProjectStaff([]);
              toggle();
            }}
            // disabled={!isValid}
            // loading={isLoading}
          >
            Clear Filters
          </Button>
          <Button
            type="submit"
            color={'primary'}
            onClick={() => {
              handleFilter(localFilter);
              toggle();
            }}
            disabled={isEqual(filter, localFilter)}
            // loading={createLoading}
          >
            Apply
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AdvanceFilterModal;
