import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import BaseTable from 'components/Table';
import classNames from 'classnames';
import { Card, CardBody } from 'reactstrap';
import numeral from 'numeral';
import classes from './deliverables.module.scss';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import analytics, { analyticsConstants } from 'helpers/analytics';
import moment from 'moment';
import { clearDocumentUrl, downloadDocument } from 'store/actions/documents';
import Dropdowns from 'components/Dropdowns';
import {
  doResetActiveDeliverables,
  getDeliverable,
} from 'store/actions/clientProfile';
import FileViewer from 'components/FileViewer';
import { AlertPopupHandler } from 'components/AlertPopup';
import {
  addDeliverableToStudy,
  getDeliverables,
} from 'store/actions/projectDashboard';
import DeliverablesModal from 'components/DeliverablesModal';
import Helmet from 'components/Helmet';

const Deliverables = ({ projectId, clientId }) => {
  const [file, setFile] = useState();
  const [isFileViewerOpen, setFileViewerOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const [showDeliverablesModal, setShowDeliverablesModal] = useState(false);

  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const dispatch = useDispatch();

  useEffect(() => {
    analyticsSendEvent({
      action: analyticsConstants.action.view_projects_list,
    });
  }, []);

  const deliverables = useGetFieldFromObjects(
    'projectDashboard',
    'deliverables.data',
    []
  );

  const loading = useGetFieldFromObjects(
    'projectDashboard',
    'deliverables.isInProgress',
    false
  );

  const downloadLoading = useGetFieldFromObjects(
    'documents',
    'downloadDoc.isInProgress',
    false
  );

  const isClient = useGetFieldFromObjects('auth', 'user.is_client', false);

  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.projects,
      ...rest,
    });
  };

  const NoDataIndication = () => (
    <div
      className={classNames('d-flex align-items-center', classes.noDataWrapper)}
    >
      <div className="d-flex justify-content-between align-items-center flex-column w-100">
        <div className={classes.listBackground}>
          <i className={`fas fa-list-ul ${classes.listitem}`}></i>
        </div>
        <p className={classes.listText}>No Deliverables</p>
      </div>
    </div>
  );

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort" />;
    else if (order === 'asc') return <i className="fas fa-sort-up" />;
    else if (order === 'desc') return <i className="fas fa-sort-down" />;
    return null;
  };

  const handleFileViewer = () => {
    setFileViewerOpen(false);
    dispatch(clearDocumentUrl());
  };

  const handleDownload = async row => {
    const fileData = await dispatch(getDeliverable(row.id));
    dispatch(downloadDocument(fileData.attachment));
  };

  const viewFileHandle = async row => {
    const fileData = await dispatch(getDeliverable(row.id));
    setFile(fileData);
    setFileViewerOpen(true);
  };

  const handleDeliverableModalClose = () => {
    setShowDeliverablesModal(false);
    dispatch(doResetActiveDeliverables());
  };

  const rowEvents = {
    onClick: (e, row) => {
      viewFileHandle(row);
    },
  };

  const handleCreateDeliverable = async (values, { resetForm }) => {
    const formData = new FormData();
    const { name, description, attachment } = values;
    if (attachment.size > 51200000) {
      return AlertPopupHandler.open({
        onConfirm: () => {},
        confirmBtnText: `Ok`,
        showCancel: false,
        title: 'Upload Unsuccessful',
        text: (
          <div className="d-flex flex-column">
            <div>Maximum allowed file size is 50 MB</div>
          </div>
        ),
      });
    }
    formData.append('name', name);
    formData.append('description', description);
    formData.append('client_id', clientId);
    formData.append('study_id', projectId);
    formData.append(`attachment`, attachment);
    await dispatch(addDeliverableToStudy(formData));
    dispatch(getDeliverables(projectId));
    resetForm();
    setShowDeliverablesModal(false);
  };

  return (
    <>
      <Helmet
        title={`${
          isClient ? 'Client' : 'Strike'
        } Portal - Studies - Deliverables`}
      />
      {isFileViewerOpen && (
        <FileViewer
          isOpen={isFileViewerOpen}
          toggle={handleFileViewer}
          fileData={file?.attachment}
          title="Preview"
        />
      )}
      <div className={classes.projects}>
        <div className="px-4" id="projectsList">
          <Card>
            <CardBody className="p-0">
              <BaseTable
                id="projectsTable"
                keyField="id"
                defaultSorted={[sortBy]}
                noDataIndication={NoDataIndication}
                bootstrap4
                remote
                bordered={false}
                loading={loading}
                search={false}
                showHeaderWithNoData={true}
                headerButtonAction={
                  !isClient ? () => setShowDeliverablesModal(true) : () => {}
                }
                headerButtonText={!isClient && 'Upload Deliverable'}
                headerButtonClassName={
                  !isClient && classes.deliverablesUploadButton
                }
                paginationOptions={{
                  page,
                  totalSize: get(deliverables, 'meta.total', 10),
                  sizePerPage: numeral(
                    get(deliverables, 'meta.per_page', 10)
                  ).value(),
                }}
                data={get(deliverables, 'data', [])}
                columns={[
                  {
                    dataField: 'name',
                    text: 'NAME',
                    sort: true,
                    classes: classes['project-name-column'],
                    sortCaret: renderSortCaret,
                    formatter: cell => (
                      <div
                        className={classNames(
                          'd-flex justify-content-between w-100 align-items-center',
                          classes['project-name']
                        )}
                        title={cell}
                      >
                        {cell}
                      </div>
                    ),
                  },
                  {
                    dataField: 'file_type',
                    text: 'FILE TYPE',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: cell => (
                      <div
                        className={classNames(
                          'd-flex justify-content-between w-100 align-items-center',
                          classes['project-name']
                        )}
                        title={cell}
                      >
                        {cell}
                      </div>
                    ),
                  },
                  {
                    dataField: 'uploaded_by',
                    text: 'UPLOADED BY',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: cell => (
                      <div
                        className={classNames(
                          'd-flex justify-content-between w-100 align-items-center',
                          classes['project-name']
                        )}
                        title={cell}
                      >
                        {cell}
                      </div>
                    ),
                  },
                  {
                    dataField: 'updated_at',
                    text: 'DATE RECEIVED',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => {
                      return (
                        <div
                          className={classNames(
                            'd-flex justify-content-between w-100 align-items-center',
                            classes['project-name']
                          )}
                        >
                          <span>
                            {moment(cell)
                              .tz(userTimezone)
                              .format('M/DD/YYYY')}
                          </span>
                          <span onClick={e => e.stopPropagation()}>
                            <Dropdowns
                              className=" text-light ml-1"
                              text={<i className="fas fa-ellipsis-v" />}
                              size="sm"
                              caret={false}
                              role="button"
                              color=""
                              options={[
                                {
                                  text: 'View File',
                                  onClick: () => viewFileHandle(row),
                                },
                                {
                                  text: 'Download File',
                                  onClick: () => handleDownload(row),
                                  disabled: downloadLoading,
                                },
                              ]}
                            />
                          </span>
                        </div>
                      );
                    },
                  },
                ]}
                onTableChange={(
                  type,
                  { page, sortOrder, sortField, searchText, ...rest }
                ) => {
                  if (type === 'pagination') {
                    setPage(page);
                  } else if (type === 'sort') {
                    setSortBy({
                      dataField: sortField,
                      order: sortOrder,
                    });
                  } else if (type === 'search') {
                    analyticsSendEvent({
                      action: analyticsConstants.action.search_projects_list,
                    });
                  }
                  const sort =
                    sortOrder === 'desc' ? `-${sortField}` : sortField;
                  dispatch(getDeliverables(projectId, { page, sort }));
                }}
                rowEvents={rowEvents}
              />
            </CardBody>
          </Card>
        </div>
      </div>
      <DeliverablesModal
        handleClose={handleDeliverableModalClose}
        isOpen={showDeliverablesModal}
        handleSubmit={handleCreateDeliverable}
      />
    </>
  );
};

export default Deliverables;
