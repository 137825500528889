import Types from 'store/types/kanbanTypes';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { reassignOrder } from 'helpers/dragDropHelpers';
import {
  getDataKanban,
  postReOrderStories,
  getDataPersonalKanban,
  patchUpdateStatusAssignments,
  postReOrderAssignment,
  removeColumn,
  addColumn,
  editColumn,
  postReOrderColumns,
  getGlobalKanban,
  getProjectStaff as getProjectStaffApi,
  getProjectDirectors as getProjectDirectorsApi,
  getDeadlineDates as getDeadlineDatesApi,
} from 'api/kanbanApi';
import { updateStory as updateStoryApi } from 'api/story';
import get from 'lodash/get';
import map from 'lodash/map';
import toString from 'lodash/toString';
import cloneDeep from 'lodash/cloneDeep';

export const reOrderColumns = (projectId, reqData) => {
  return async dispatch => {
    dispatch({
      type: Types.REORDER_COLUMN_LOADING,
      data: reqData,
    });
    try {
      const resp = await postReOrderColumns(projectId, map(reqData, 'id'));
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.REORDER_COLUMN_SUCCESS,
          status,
          data,
        });
      } else {
        dispatch({
          type: Types.REORDER_COLUMN_ERROR,
          status,
          message,
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.REORDER_COLUMN_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const modifyColumn = (projectId, columnId, reqData) => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_COLUMN_LOADING,
    });

    try {
      const resp = await editColumn(projectId, columnId, reqData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.EDIT_COLUMN_SUCCESS,
          status,
          data,
        });
      } else {
        dispatch({
          type: Types.EDIT_COLUMN_ERROR,
          status,
          message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.EDIT_COLUMN_ERROR,
        message: 'Something went wrong',
      });
      NotificationHandler.open({
        message: 'Something went wrong',
        operation: 'failure',
      });
    }
  };
};

export const createColumn = (projectId, reqData) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_COLUMN_LOADING,
    });

    try {
      const resp = await addColumn(projectId, reqData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.CREATE_COLUMN_SUCCESS,
          status,
          data,
        });
      } else {
        dispatch({
          type: Types.CREATE_COLUMN_ERROR,
          status,
          message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.CREATE_COLUMN_ERROR,
        message: 'Something went wrong',
      });
      NotificationHandler.open({
        message: 'Something went wrong',
        operation: 'failure',
      });
    }
  };
};

export const deleteColumn = (projectId, columnId) => {
  return async dispatch => {
    dispatch({
      type: Types.REMOVE_COLUMN_LOADING,
    });

    try {
      const resp = await removeColumn(projectId, columnId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.REMOVE_COLUMN_SUCCESS,
          status,
          data,
        });
        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.REMOVE_COLUMN_ERROR,
          status,
          message,
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.REMOVE_COLUMN_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const doGetDataKanban = (projectId, keyword) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_DATA_KANBAN_LOADING,
    });

    const resp = await getDataKanban(projectId, keyword);

    if (resp.status === 1) {
      if (resp.data) {
        dispatch({
          type: Types.GET_DATA_KANBAN_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      }
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: Types.GET_DATA_KANBAN_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doPostReOrderStories = (projectId, sourceId, updatedItems) => {
  return async (dispatch, getState) => {
    if (sourceId) {
      const storeData = getState();
      const kanban = get(storeData, 'kanban.getKanban.data');
      const index = kanban.columns.findIndex(item => {
        return toString(item.id) === sourceId;
      });
      let columns = [...kanban.columns];
      columns[index]['stories'] = updatedItems;

      dispatch({
        type: Types.GET_DATA_KANBAN_SUCCESS,
        status: 1,
        data: {
          ...kanban,
          columns,
        },
      });
    }

    const resp = await postReOrderStories(
      projectId,
      reassignOrder(updatedItems, 'board_order')
    );

    if (resp.status !== 1) {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostChangeStatusStory = (storyId, statusId) => {
  return async () => {
    const resp = await updateStoryApi(storyId, { status_id: statusId });

    if (resp.status !== 1) {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp;
  };
};

export const doMoveStoryCard = (
  projectId,
  storyId,
  sourceId,
  destinationId,
  sourceItems,
  destinationItems
) => {
  return async (dispatch, getState) => {
    const storeData = getState();
    const kanban = get(storeData, 'kanban.getKanban.data');
    const newKanbanData = cloneDeep(kanban);
    const srcItemIndex = newKanbanData.columns.findIndex(
      item => item.id === sourceId
    );
    newKanbanData.columns[srcItemIndex].stories = reassignOrder(
      sourceItems,
      'board_order'
    );
    const destItemIndex = newKanbanData.columns.findIndex(
      item => item.id === destinationId
    );
    newKanbanData.columns[destItemIndex].stories = reassignOrder(
      destinationItems,
      'board_order'
    );
    dispatch({
      type: Types.REORDER_KANBAN_STORIES_LOADING,
      data: newKanbanData,
    });

    const resp = await updateStoryApi(storyId, {
      initiative_board_column_id: destinationId,
    });
    if (resp.status === 1) {
      const story = resp.data;
      let stories = newKanbanData.columns[destItemIndex].stories.map(item =>
        item.id === story.id ? story : item
      );
      newKanbanData.columns[destItemIndex].stories = stories;
      dispatch({
        type: Types.REORDER_KANBAN_STORIES_SUCCESS,
        status: resp.status,
        data: newKanbanData,
      });

      dispatch(
        doPostReOrderStories(
          projectId,
          '',
          reassignOrder(destinationItems, 'board_order')
        )
      );
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: Types.REORDER_KANBAN_STORIES_ERROR,
        status: resp.status,
        message: resp.message,
        data: kanban,
      });
    }

    return resp;
  };
};

export const doGetDataPersonalKanban = (userId, params) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_DATA_PERSONAL_KANBAN_LOADING,
    });

    const resp = await getDataPersonalKanban(userId, params);

    if (resp.status === 1) {
      if (resp.data) {
        dispatch({
          type: Types.GET_DATA_PERSONAL_KANBAN_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      }
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: Types.GET_DATA_PERSONAL_KANBAN_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doPostReOrderAssigments = (
  userId,
  sourceId,
  updatedItems,
  isGlobal = true
) => {
  return async (dispatch, getState) => {
    if (sourceId) {
      const storeData = getState();
      const path = isGlobal
        ? 'kanban.globalKanban.data'
        : 'kanban.getPersonalKanban.data';
      const kanban = get(storeData, path);
      const index = kanban.columns.findIndex(item => item.id === sourceId);

      let columns = [...kanban.columns];
      columns[index]['studies'] = updatedItems;

      dispatch({
        type: Types.GET_DATA_PERSONAL_KANBAN_SUCCESS,
        status: 1,
        data: {
          ...kanban,
          columns,
        },
      });
    }

    const resp = await postReOrderAssignment(
      userId,
      reassignOrder(updatedItems, 'board_order')
    );

    if (resp.status !== 1) {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPatctUpdateStatusAssignment = (userId, taskId, statusId) => {
  return async () => {
    const resp = await patchUpdateStatusAssignments(userId, taskId, {
      status_id: statusId,
    });

    if (resp.status !== 1) {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp;
  };
};

export const doMoveAssignmentCard = (
  userId,
  taskId,
  sourceId,
  destinationId,
  sourceItems,
  destinationItems,
  isGlobal = true,
  companyId
) => {
  return async (dispatch, getState) => {
    await dispatch({
      type: Types.GET_DATA_PERSONAL_KANBAN_LOADING,
    });

    const storeData = getState();
    const path = isGlobal
      ? 'kanban.globalKanban.data'
      : 'kanban.getPersonalKanban.data';
    const kanban = get(storeData, path);

    let data = { ...kanban };
    // source column
    let index = data.columns.findIndex(item => item.id === sourceId);
    data.columns[index].studies = reassignOrder(sourceItems, 'board_order');

    // destination column
    index = data.columns.findIndex(item => item.id === destinationId);
    data.columns[index].studies = reassignOrder(
      destinationItems,
      'board_order'
    );

    await dispatch({
      type: Types.GET_DATA_PERSONAL_KANBAN_SUCCESS,
      status: 1,
      data: data,
    });

    const payload = {
      phase_id: destinationId,
    };

    const resp = await patchUpdateStatusAssignments({
      companyId,
      taskId,
      data: payload,
      isGlobal,
      userId,
    });

    if (resp.status === 1) {
      const assignment = resp.data;

      let studies = data.columns[index].studies.map(item =>
        item.id === assignment.id ? assignment : item
      );
      data.columns[index].studies = studies;

      dispatch({
        type: Types.GET_DATA_PERSONAL_KANBAN_SUCCESS,
        status: resp.status,
        data: data,
      });

      dispatch(
        doPostReOrderAssigments(
          userId,
          '',
          reassignOrder(destinationItems, 'board_order')
        )
      );
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp;
  };
};

export const getGlobalKanbanData = params => {
  return async dispatch => {
    dispatch({
      type: Types.GET_DATA_GLOBAL_KANBAN_LOADING,
    });

    const resp = await getGlobalKanban(params);

    if (resp.status === 1) {
      if (resp.data) {
        dispatch({
          type: Types.GET_DATA_GLOBAL_KANBAN_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      }
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: Types.GET_DATA_GLOBAL_KANBAN_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const getProjectStaff = () => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PROJECT_STAFF_LOADING,
    });

    const resp = await getProjectStaffApi();

    if (resp.status === 1) {
      if (resp.data) {
        dispatch({
          type: Types.GET_PROJECT_STAFF_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      }
    } else {
      dispatch({
        type: Types.GET_PROJECT_STAFF_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const getProjectDirectors = () => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PROJECT_DIRECTORS_LOADING,
    });

    const resp = await getProjectDirectorsApi();

    if (resp.status === 1) {
      if (resp.data) {
        dispatch({
          type: Types.GET_PROJECT_DIRECTORS_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      }
    } else {
      dispatch({
        type: Types.GET_PROJECT_DIRECTORS_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const getDeadlineDates = () => {
  return async dispatch => {
    dispatch({
      type: Types.GET_DEADLINE_DATES_LOADING,
    });

    const resp = await getDeadlineDatesApi();

    if (resp.status === 1) {
      if (resp.data) {
        dispatch({
          type: Types.GET_DEADLINE_DATES_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      }
    } else {
      dispatch({
        type: Types.GET_DEADLINE_DATES_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};
