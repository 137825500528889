import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import FulfillmentStudyDashboard from 'views/pages/FulfillmentStudyDashboard';
import StrikeStudyDashboard from 'views/pages/Project/StrikeStudyDashboard';

const StudyDashboard = () => {
  const currentUser = useSelector(({ auth }) => get(auth, 'user'));

  return (
    <>
      {currentUser?.is_fulfilment ||
      currentUser?.email === 'support@striketax.com' ? ( //TODO: remove this condition
        <FulfillmentStudyDashboard />
      ) : (
        <StrikeStudyDashboard />
      )}
    </>
  );
};

export default StudyDashboard;
