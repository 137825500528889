import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import classes from './SignatureModal.module.scss';
import cx from 'classnames';
import Button from 'components/Button';

import ModalFooterInput from '../ModalFooterInput';
import useBreakpoint from 'helpers/useBreakPoint';
import { useWindowSize } from 'react-use';
import { updateContractStatus } from 'store/actions/contract';

const SignatureModal = ({
  isOpen,
  persistBackdrop,
  handleClose,
  handleOpenDeclineModal,
  handleRemoveBackdrop,
  handleOpenDeclineExitModal,
  handleGetEmail,
  handleHeightChange,
}) => {
  const dispatch = useDispatch();
  const companyName = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.company', '')
  );
  const initiativeId = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.initiative.id', null)
  );

  const type = useSelector(({ auth }) =>
    get(auth, 'clientContract.type', null)
  );

  const isMobile = useBreakpoint('xs', 'down');
  const [showFooter, setShowFooter] = useState(false);
  const signModalRef = useRef(null);
  const { width } = useWindowSize();

  const handleLink = () => {
    setShowFooter(true);
  };

  useEffect(() => {
    if (signModalRef.current) {
      const addHeight = showFooter ? 180 : 0;
      handleHeightChange(signModalRef.current.clientHeight + addHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signModalRef, isMobile, showFooter, width]);

  return (
    <div
      className={cx(classes.customModal, {
        [classes.openSignatureModal]: isOpen || persistBackdrop,
      })}
    >
      <Card
        innerRef={signModalRef}
        className={cx(classes.card, { [classes.showCard]: isOpen })}
      >
        <CardBody className={classes.cardBody}>
          <p className={classes.title}>
            Agree to the use of electronic signatures to proceed
          </p>
          <div className={classes.checkbox}>
            <p className={classes.text}>
              I agree to use electronic signatures and understand that my
              electronic signature will be treated the same as a handwritten
              signature for the purposes of validity, enforceability, and
              admissibility.
            </p>
          </div>
          <div className={classes.buttons}>
            <Button
              className={classes.decline}
              onClick={handleOpenDeclineModal}
              color="secondary"
            >
              Decline
            </Button>
            <Button
              className={classes.agree}
              onClick={() => {
                dispatch(
                  updateContractStatus(
                    initiativeId,
                    { status: 'viewed' },
                    type == 'deals' ? 'deal-desk' : 'partner-deal-desk'
                  )
                );
                handleClose();
                handleRemoveBackdrop();
              }}
              color="secondary"
            >
              I agree
            </Button>
          </div>
          <div className={classes.noAuthority}>
            <p onClick={handleLink}>
              Don’t have signing authority for {companyName}?
            </p>
          </div>
        </CardBody>
        <CardFooter
          className={cx(classes.cardFooter, {
            [classes.showCardFooter]: showFooter,
          })}
        >
          <p>Forward this agreement to the appropriate signing party.</p>
          <ModalFooterInput
            handleClose={handleClose}
            handleOpenDeclineExitModal={handleOpenDeclineExitModal}
            handleGetEmail={handleGetEmail}
          />
        </CardFooter>
      </Card>
    </div>
  );
};

export default SignatureModal;
