import React from 'react';
import DocumentIcon from 'assets/img/icons/document-comment-icon.svg';
import cx from 'classnames';

import StudyMessageIcon from 'assets/img/icons/study-message.svg';
import style from './NewInteractionCard.module.scss';
import { get } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

const NewInteractionCard = ({ data }) => {
  const {
    document_activities_count,
    comment_activities_count,
    status = null,
    short_name = '',
    updated_at = '2023-09-06 12:51:37',
  } = data;
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const isReadyForReview = get(status, 'label', '') === 'Ready For Review';

  return (
    <div className={style.newInteractionsWrapper}>
      <div className={cx(style.documentTitle, 'mb-4')}>
        <span className={style.dot}></span>
        <span className={style.title}>New Interactions</span>
      </div>
      <div className="d-flex flex-row" style={{ gap: 24 }}>
        <div></div>
        <div className="d-flex flex-column details">
          {document_activities_count > 0 && (
            <div
              className="d-flex flex-row align-items-center doc mb-3"
              style={{ gap: 12 }}
            >
              <span className={style.imageWrapper} style={{ width: '24px' }}>
                <img src={DocumentIcon} alt="document-img" />
              </span>
              <span className={style.documentTitle}>
                ({document_activities_count} New){' '}
                {document_activities_count > 1 ? 'Documents' : 'Document'}{' '}
                Uploaded
              </span>
            </div>
          )}
          {comment_activities_count > 0 && (
            <div
              className="d-flex flex-row align-items-center msg mb-3"
              style={{ gap: 12 }}
            >
              <span className={style.imageWrapper} style={{ width: '24px' }}>
                <img src={StudyMessageIcon} alt="study-img" />
              </span>
              <div className={style.documentTitle}>
                ({comment_activities_count} New){' '}
                {comment_activities_count > 1 ? 'Messages' : 'Message'}
              </div>
            </div>
          )}
          {isReadyForReview && (
            <div
              className={cx(
                'd-flex flex-row align-items-center msg mb-3',
                style.readyForReview
              )}
              style={{ gap: 12 }}
            >
              <span className={style.imageWrapper} style={{ width: '24px' }}>
                <i className="fas fa-solid fa-check"></i>
              </span>
              <div className={style.documentTitle}>
                Client has set {short_name} bucket as Ready for Review -{' '}
                <span className={style.date}>
                  {moment(updated_at)
                    .tz(userTimezone)
                    .format('MM/DD/YYYY h:mm A')}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewInteractionCard;
