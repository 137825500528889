import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import cx from 'classnames';
import classes from './ClientStudyDashboard.module.scss';
import DocumentGathering from './DocumentGathering';
import Button from 'components/Button';
import ProgressBar from 'components/Fulfillment/ProgressBar';
import { getProject as getProjectAction } from 'store/actions/projects';
import history from 'helpers/history';
import DocumentUploadModal from './DocumentUploadModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDocumentStatus,
  fetchDocumentStudyTypes,
} from 'store/actions/documents';
import { clearCommentsData } from 'store/actions/Story/comments';
import socket from 'helpers/socket';
import DocumentDetails from 'components/Fulfillment/Modals/DocumentCommentModal/DocumentDetails';
import { fetchSupportStaffList as fetchSupportStaff } from 'store/actions/supportStaff';
import get from 'lodash/get';

const DOC_TYPES = ['main', 'supporting'];

const ClientStudyDashboard = props => {
  const dispatch = useDispatch();
  const studyId = get(props, 'match.params.id');
  const documents = useSelector(({ documents }) =>
    get(documents, `documentStudyTypes.data.data`, [])
  );
  const [modalParams, setModalParams] = useState({
    parent_type: 'Initiative',
    parent_id: studyId,
  });

  const [currentTab, setCurrentTab] = useState('comment');
  const [openChat, setOpenChat] = useState(false);
  const [activeComment, setActiveComment] = useState(null);
  const [documentUploadData, setDocumentUploadData] = useState(null);

  const [openDocumentUploadModal, setOpenDocumentUploadModal] = useState(false);
  const [documentType, setDocumentType] = useState(DOC_TYPES[0]);

  const handleOpenDocumentUploadModal = () => setOpenDocumentUploadModal(true);
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );
  const currentUser = useSelector(({ auth }) => get(auth, 'user'));

  const thread_id = get(currentStudy, 'thread_id');

  const projectPhase = get(currentStudy, 'project_phase', '');
  const phases = get(currentStudy, 'phases', []);

  const handleCloseDocumentUploadModal = () =>
    setOpenDocumentUploadModal(false);

  const handleMainDocumentUpload = id => {
    const docData = documents.find(d => d.id === id);
    if (docData.is_supporting) {
      setDocumentUploadData(documents.find(d => d.is_supporting));
      setDocumentType(DOC_TYPES[1]);
      handleOpenDocumentUploadModal();
    } else {
      setDocumentUploadData(docData);
      setDocumentType(DOC_TYPES[0]);
      handleOpenDocumentUploadModal();
    }
  };

  const handleCloseCommentModal = () => {
    setModalParams({
      parent_type: 'Initiative',
      parent_id: 0,
    });
    setTimeout(() => {
      setModalParams({
        parent_type: 'Initiative',
        parent_id: studyId,
      });
    });
  };
  useEffect(() => {
    if (modalParams.parent_id) {
      socket.joinAndListenComments(
        'Initiative',
        modalParams.parent_id,
        modalParams
      );
      return () => {
        socket.leaveComments();
        dispatch(clearCommentsData());
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalParams.parent_id]);

  useEffect(() => {
    dispatch(getProjectAction(studyId));
    dispatch(fetchDocumentStatus());
    dispatch(fetchDocumentStudyTypes(studyId));
    dispatch(clearCommentsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId]);

  useEffect(() => {
    const clientId = get(currentStudy, 'client.id');
    if (!clientId) return;
    dispatch(fetchSupportStaff(clientId));
  }, [currentStudy, dispatch]);

  return (
    <>
      {openDocumentUploadModal ? (
        <DocumentUploadModal
          isOpen={openDocumentUploadModal}
          onClose={handleCloseDocumentUploadModal}
          type={documentType}
          data={documentUploadData}
        />
      ) : null}
      <Container fluid className={classes.documentProgress}>
        <div className={classes.back}>
          <Button
            onClick={() => {
              history.push('/admin/dashboard');
            }}
            className={classes.backButton}
            color="link"
            leftIcon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
          >
            Back to Dashboard
          </Button>
        </div>
        <ProgressBar phases={phases} projectPhase={projectPhase?.label} />
      </Container>
      <Container fluid className={cx(classes.dashboardWrapper, 'mt-4')}>
        <div className={classes.study}>
          <div className={classes.header}>
            <div className={classes.topHeader}>
              {currentUser && <h3>Welcome Back, {currentUser.name}!</h3>}
              <h3 className={classes.chat}>
                Study Chat{' '}
                <i
                  className="fas fa-comments"
                  onClick={() => setOpenChat(true)}
                ></i>
              </h3>
            </div>
            <p>
              Below you’ll find a few tasks that are required before we can
              formally kick off your tax study.
            </p>
          </div>

          <div className={classes.documentGathering}>
            <DocumentGathering
              threadId={thread_id}
              studyId={studyId}
              allDocuments={documents}
              onAddDocument={handleMainDocumentUpload}
              handleCloseCommentModal={handleCloseCommentModal}
            />
          </div>
        </div>

        <div className={classes.comment}>
          <h3>Study Chat</h3>
          <div className={classes.container}>
            <DocumentDetails
              initiativeId={studyId}
              params={modalParams}
              parentId={modalParams.parent_id}
              userType={'client'}
              currentTab={currentTab}
              comment={activeComment}
              onTabChange={() => {}}
              analyticsSendEvent={() => {}}
            />
          </div>
        </div>
      </Container>
      {openChat && (
        <div className={classes.responsiveComments}>
          <div className={classes.comment}>
            <div className={classes.container}>
              <DocumentDetails
                initiativeId={studyId}
                params={modalParams}
                parentId={modalParams.parent_id}
                userType={'client'}
                currentTab={currentTab}
                comment={activeComment}
                onTabChange={() => {}}
                analyticsSendEvent={() => {}}
                leftTitle={'Study Chat'}
                showCloseButton={true}
                onClose={() => {
                  setOpenChat(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientStudyDashboard;
