import React, { useEffect, useState } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from './PartnerTracker.module.scss';
import history from 'helpers/history';
import { clearReferralCommentsData } from 'store/actions/partners';
import { useDispatch, useSelector } from 'react-redux';
import InfoCircle from 'assets/img/icons/info-circle-dark-icon.svg';
import Button from 'components/Button';
import moment from 'moment';
import PartnerInfoModal from './PartnerInfoModal';
import Input from 'components/FormFields/Input';
import NoDataPlaceholder from './NoDataPlaceholder';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { get } from 'lodash';
import { formatCurrency } from 'views/pages/Earnings/constants';

const PartnerTrackerTable = ({ data = [], maxHeight = true }) => {
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState(null);
  const [leadId, setLeadId] = useState('');
  const [activeComment, setActiveComment] = useState(null);
  const [currentTab, setCurrentTab] = useState('comment');

  const [tableData, setTableData] = useState(data);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const [searchText, setSearchText] = useState('');
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);

  const parentId = useSelector(({ auth }) => auth.user.company.id, null);

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  useEffect(() => {
    const { lead = '', tab = '', comment } = queryProps;
    if (lead && data) {
      const leadData = data.find(item => item.id === lead);
      if (leadData) {
        setPartnerInfo(leadData.popup);
        setLeadId(lead);
        setCurrentTab(tab);
        setIsModal(true);
        setActiveComment(comment);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps.lead, data]);

  const rowEvents = {
    onClick: (e, row) => {
      setLeadId(row.id);
      setPartnerInfo(row.popup);
      setIsModal(true);
    },
  };

  const handleSearch = () => {
    const searchName = searchText.toLowerCase();
    const filteredPartners = data.filter(person =>
      person.name.toLowerCase().includes(searchName)
    );
    setTableData(filteredPartners);
  };

  const handleChange = e => {
    const searchName = e.target.value.toLowerCase();
    const filteredPartners = data.filter(person =>
      person.name.toLowerCase().includes(searchName)
    );
    setTableData(filteredPartners);
    setSearchText(e.target.value);
  };

  const getSortByValue = (obj, field) => {
    switch (field) {
      case 'referred_on':
        return new Date(obj['referred_on']);
      case 'status':
        return obj?.custom['Partner Status'];
      default:
        return obj[field];
    }
  };

  useEffect(() => {
    const clone = [...tableData];
    clone.sort((a, b) => {
      if (sortBy.order === 'asc') {
        if (
          getSortByValue(a, sortBy.dataField) <
          getSortByValue(b, sortBy.dataField)
        ) {
          return -1;
        }
        if (
          getSortByValue(a, sortBy.dataField) >
          getSortByValue(b, sortBy.dataField)
        ) {
          return 1;
        }
        return 0;
      } else {
        if (
          getSortByValue(a, sortBy.dataField) <
          getSortByValue(b, sortBy.dataField)
        ) {
          return 1;
        }
        if (
          getSortByValue(a, sortBy.dataField) >
          getSortByValue(b, sortBy.dataField)
        ) {
          return -1;
        }
        return 0;
      }
    });
    setTableData(clone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const handleTableChange = async (type, { sortOrder, sortField }) => {
    setSortBy({
      dataField: sortField,
      order: sortOrder,
    });
  };

  return (
    <>
      {isModal && partnerInfo ? (
        <PartnerInfoModal
          parentId={parentId}
          leadId={leadId}
          partnerInfo={partnerInfo}
          isOpen={isModal}
          modalType="partner"
          closeModal={() => {
            setIsModal(false);
            dispatch(clearReferralCommentsData());
            history.push('?');
          }}
        />
      ) : null}
      <div className={classes.referredTablePartner}>
        <div className={classes.tableHeader}>
          <div className={classes.title}>Referred Partners</div>
          <div className={classes.search}>
            <Input
              rightIcon={
                searchText ? (
                  <i
                    onClick={() => {
                      setTableData(data);
                      setSearchText('');
                    }}
                    className={cx('fa fa-times-circle', classes.icon)}
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i style={{ width: 14, height: 14 }}></i>
                )
              }
              value={searchText}
              className={cx(
                classes.input,
                'form-control-alternative edit-event--description textarea-autosize'
              )}
              placeholder="Search by partner name"
              onChange={handleChange}
            />
            <Button
              type="submit"
              id="inviteUsersBtn"
              color={'primary'}
              className={'float-left'}
              onClick={handleSearch}
              disabled={searchText === ''}
            >
              Search
            </Button>
          </div>
        </div>
        <Card className={cx(classes.card)}>
          <CardBody className={cx('p-0', classes.scroll)}>
            <BaseTable
              keyField="id"
              remote
              noDataIndication={NoDataPlaceholder}
              search={false}
              bordered={false}
              paginationOptions={false}
              data={tableData}
              onTableChange={handleTableChange}
              classes="mb-0"
              wrapperClasses={cx(
                classes.tableWrapper,
                'table-responsive',
                maxHeight && classes.maxHeight
              )}
              columns={[
                {
                  dataField: 'name',
                  text: (
                    <span className=" d-inline-flex truncate-ellipsis">
                      <span title="Partner">Partner</span>
                    </span>
                  ),
                  sort: true,
                  // hidden: studyDashboard || tasksDashboard || clientDashboard,
                  sortCaret: renderSortCaret,
                  headerStyle: {
                    width: '20%',
                  },
                  style: {
                    width: '20%',
                  },
                  footerStyle: {
                    width: '20%',
                  },
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div className="truncate-ellipsis">
                          <span
                            className={cx('m-0', classes.partnerName)}
                            title={cell ? cell : '-'}
                          >
                            {cell ? cell : '-'}
                          </span>
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: 'date_created',
                  text: (
                    <span className="d-inline-flex truncate-ellipsis">
                      <span title="Referred On">Referred On</span>
                    </span>
                  ),
                  sort: true,
                  headerStyle: {
                    width: '12%',
                  },
                  style: {
                    width: '12%',
                  },
                  footerStyle: {
                    width: '12%',
                  },
                  // hidden: studyDashboard || tasksDashboard || clientDashboard,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div className="truncate-ellipsis">
                          <span
                            className={cx('m-0')}
                            title={
                              cell ? moment(cell).format('MMM DD, YYYY') : '-'
                            }
                          >
                            {cell ? moment(cell).format('MMM DD, YYYY') : '-'}
                          </span>
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: 'status',
                  sort: true,
                  text: (
                    <span className="d-inline-flex truncate-ellipsis">
                      <span title="status">status</span>
                    </span>
                  ),
                  // hidden: studyDashboard || tasksDashboard || clientDashboard,
                  sortCaret: renderSortCaret,
                  headerStyle: {
                    width: '12%',
                  },
                  style: {
                    width: '12%',
                  },
                  footerStyle: {
                    width: '12%',
                  },
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div className="truncate-ellipsis">
                          <span
                            className={cx('m-0 text-break')}
                            title={
                              row?.custom['Partner Status']
                                ? row?.custom['Partner Status']
                                : '-'
                            }
                          >
                            {row?.custom['Partner Status']
                              ? row?.custom['Partner Status']
                              : '-'}
                          </span>
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: 'number_of_referrals',
                  text: (
                    <span className="d-inline-flex truncate-ellipsis">
                      <span title="# of Referrals"># of Referrals</span>
                    </span>
                  ),
                  sort: true,
                  headerStyle: {
                    width: '13%',
                  },
                  style: {
                    width: '13%',
                    textAlign: 'center',
                  },
                  footerStyle: {
                    width: '13%',
                  },
                  // hidden: studyDashboard || tasksDashboard || clientDashboard,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div className="truncate-ellipsis">
                          <span className={cx('m-0')} title={cell ? cell : '-'}>
                            {cell ? cell : '-'}
                          </span>
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: 'number_of_client',
                  text: (
                    <span className="d-inline-flex truncate-ellipsis">
                      <span title="# of Clients"># of Clients</span>
                    </span>
                  ),
                  sort: true,
                  headerStyle: {
                    width: '11.5%',
                  },
                  style: {
                    width: '11.5%',
                    textAlign: 'center',
                  },
                  footerStyle: {
                    width: '11.5%',
                  },
                  // hidden: studyDashboard || tasksDashboard || clientDashboard,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div className="truncate-ellipsis">
                          <span
                            className={cx('m-0 ')}
                            title={cell ? cell : '-'}
                          >
                            {cell ? cell : '-'}
                          </span>
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: 'potential_earning',
                  headerStyle: {
                    width: '18%',
                  },
                  style: {
                    width: '18%',
                    textAlign: 'center',
                  },
                  footerStyle: {
                    width: '18%',
                  },
                  text: (
                    <span className="d-inline-flex truncate-ellipsis">
                      <span title="Potential Earnings">Potential Earnings</span>
                      <img
                        id="potential-earnings-info"
                        src={InfoCircle}
                        className="ml-1"
                        alt="InfoCircle"
                      />
                      <UncontrolledTooltip
                        target={`potential-earnings-info`}
                        placement="bottom-end"
                        innerClassName={classes.earningsInfoTooltip}
                        boundariesElement="viewport"
                        hideArrow
                        autohide={false}
                      >
                        <p>
                          <b>Potential Earnings</b> - The amount Strike
                          estimates you will earn if the contracted credits are
                          utilized and paid for by the client.
                        </p>
                      </UncontrolledTooltip>
                    </span>
                  ),
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <div className="truncate-ellipsis">
                        <span
                          id={`recent-story-name-${get(row, 'id')}`}
                          // className={cx('text-break')}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginLeft: '2rem',
                          }}
                          title={
                            cell ? formatCurrency(cell, true, 2, true) : '-'
                          }
                        >
                          {cell ? formatCurrency(cell, true, 2, true) : '-'}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  dataField: 'potential_earning',
                  text: '',
                  sort: false,
                  headerStyle: {
                    width: '4%',
                  },
                  style: {
                    width: '4%',
                  },
                  footerStyle: {
                    width: '4%',
                  },
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <div className="d-flex align-content-center justify-content-between text-nowrap">
                        <div className={cx('mx-2', classes.addIcon)}>
                          <i
                            className="fa fa-plus-circle partner-more-info"
                            aria-hidden="true"
                            leadname={row.name}
                            lead_id={row.id}
                          ></i>
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              rowEvents={rowEvents}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default PartnerTrackerTable;
