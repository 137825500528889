import React, { useState, useEffect } from 'react';
import cs from 'classnames';
import classes from './DocumentDetails.module.scss';
import {
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Badge,
} from 'reactstrap';
import useBreakPoint from 'helpers/useBreakPoint';
import findIndex from 'lodash/findIndex';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { analyticsConstants } from 'helpers/analytics';
import CommentSection from 'components/Fulfillment/CommentSection';

const DocumentDetails = ({
  parentId,
  currentTab = 'comments',
  onTabChange,
  analyticsSendEvent,
  params,
  userType = 'client',
  initiativeId,
  showCloseButton = false,
  leftTitle = '',
  onClose = null,
}) => {
  const comments = useSelector(({ story }) =>
    get(story, 'comments.storyComments.data.data', [])
  );

  const unreadFrom = useSelector(({ story }) =>
    get(story, 'comments.storyComments.unreadFrom', null)
  );

  const getCommentsCount = () => {
    if (unreadFrom) {
      const foundIndex = comments.findIndex(c => c.id === unreadFrom);
      if (foundIndex > -1) {
        return comments.length - foundIndex;
      } else {
        return 0;
      }
    }
    return 0;
  };

  const contents = [
    {
      name: 'Messages',
      iconClassName: 'fas fa-comments',
      gradientColor: 'orange',
      pageLink: 'comments',
      showBadge: true,
      count: getCommentsCount(),
      gaEventTag: analyticsConstants.action.view_story_comments,
    },
  ];

  useEffect(() => {
    const tabIndex = findIndex(contents, obj => obj.pageLink === currentTab);
    analyticsSendEvent({
      action:
        analyticsConstants.action[
          contents[tabIndex > -1 ? tabIndex : 0].gaEventTag
        ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentTab = () => {
    const tabIndex = findIndex(contents, obj => obj.pageLink === currentTab);
    return tabIndex > -1 ? contents[tabIndex].pageLink : contents[0].pageLink;
  };

  const isMobile = useBreakPoint('xs', 'down');
  const [tabs, setTabs] = useState(getCurrentTab());

  const toggleTabs = tab => {
    setTabs(tab);
    onTabChange(tab);
  };
  return (
    <div
      className={cs(
        classes['right-column'],
        'pb-3 d-flex flex-column',
        isMobile ? 'border-top' : '',
        { 'w-100': isMobile }
      )}
    >
      <div className={classes.tabWrapper}>
        <Nav className="nav-fill flex-row" id="tabs-icons-text">
          {contents.map(content => (
            <NavItem key={content.pageLink} className={classes.tabItem}>
              <NavLink
                className={
                  tabs === content.pageLink ? 'text-primary' : 'text-muted'
                }
                onClick={() => {
                  analyticsSendEvent({
                    action: analyticsConstants.action[content.gaEventTag],
                  });
                  toggleTabs(content.pageLink);
                }}
                href="#"
                role="tab"
              >
                <div
                  className={classes.tabTitleWrapper}
                  style={{ display: showCloseButton ? 'flex' : 'block' }}
                >
                  <span className={classes.leftTitle}>{leftTitle}</span>
                  <span className="d-flex flex-column">
                    <i className={content.iconClassName} />
                    {content.name}
                    {content.showBadge && content.count ? (
                      <Badge
                        color="danger"
                        className={cs(
                          'badge-sm badge-circle badge-floating border-white',
                          classes.badgeCount
                        )}
                      >
                        {content.count > 99 ? '99+' : content.count}
                      </Badge>
                    ) : null}
                  </span>
                  {showCloseButton && (
                    <a title="close" onClick={onClose}>
                      <h2> X</h2>
                    </a>
                  )}
                </div>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <TabContent activeTab={tabs} className="h-100 pl-3 pr-3">
        <TabPane tabId="comments" className="h-100">
          <CommentSection
            initiativeId={initiativeId}
            userType={userType}
            parentId={parentId}
            storyId={parentId}
            params={params}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default DocumentDetails;
