import Types from 'store/types/AuthTypes';
import {
  postLogin,
  postLogout,
  postRegister,
  postEmailVerify,
  postEmailResetPassword,
  postResetPassword,
  getProfileUser,
  acceptAndSetup,
  accept,
  checkEmailVerify,
  getInviteStatus,
  createCompany,
  postResendVerifyEmail,
  getSlaContract,
  accountSetup,
} from 'api/authApi';
import { getUserPreference } from './profile';
import history from 'helpers/history';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import get from 'lodash/get';
import analytics, { analyticsConstants } from 'helpers/analytics';
import UpgradeAlert from 'components/UpgradeAlert';
import { AlertPopupHandler } from 'components/AlertPopup';
import moment from 'moment-timezone';
import { updatePartnerTimeZone } from 'store/actions/partners';

const analyticsSendEvent = ({ ...rest }) => {
  analytics.sendEvent({
    category: analyticsConstants.category.authentication,
    ...rest,
  });
};

export const doLogin = (email, password, shouldRedirect = true) => {
  return async dispatch => {
    dispatch({
      type: Types.LOGIN_INPROGRESS,
    });

    const resp = await postLogin(email, password);

    if (resp.errorStatus === 423) {
      dispatch({
        type: Types.LOGIN_DEACTIVATED,
        user: resp.data,
      });
      NotificationHandler.open({
        title: ' ',
        icon: ' ',
        message: resp.message,
        operation: 'failure',
      });
      return history.push('/auth/invitations/deactivated');
    }
    if (resp.status === 1) {
      analyticsSendEvent({ action: analyticsConstants.action.user_sign_in });
      dispatch({
        type: Types.LOGIN_SUCCESS,
        status: resp.status,
        user: resp.data,
      });
      const isClient = resp.data.is_client;
      const isPartner = resp.data.is_partner;
      const [role] = resp.data.roles;
      const isPartnerManger = role === 'Partner_Manager';

      if (isPartner) {
        const userTimeZone = moment.tz.guess(true);
        await dispatch(updatePartnerTimeZone(userTimeZone));
      }

      const profileRes = await dispatch(showProfileUser());
      if (profileRes.errorStatus === 402) {
        // subscription outdated
        analyticsSendEvent({
          category: analyticsConstants.category.billing,
          action: analyticsConstants.action.locked_company_sign_in,
        });
      }

      if (shouldRedirect && profileRes.status === 1) {
        dispatch(getUserPreference());
        let defaultRoute;
        if (isClient) {
          defaultRoute = '/admin/dashboard';
        } else if (isPartner) {
          defaultRoute = '/admin/referral/clients';
        } else if (isPartnerManger) {
          defaultRoute = '/admin/partners';
        } else {
          defaultRoute = '/admin/dashboard';
        }
        const redirectL =
          sessionStorage.getItem('redirect_URL') || defaultRoute;
        if (history) history.push(redirectL);
        sessionStorage.removeItem('redirect_URL');
      }
    } else {
      dispatch({
        type: Types.LOGIN_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp;
  };
};

export const doLoginSocial = resp => {
  return async dispatch => {
    analyticsSendEvent({ action: analyticsConstants.action.user_sign_in });
    dispatch({
      type: Types.LOGIN_SUCCESS,
      status: resp.status,
      user: resp.data,
    });
    dispatch(getUserPreference());
  };
};

export const doLoginWithoutRedirect = (email, password) => {
  return async dispatch => {
    dispatch({
      type: Types.LOGIN_INPROGRESS,
    });

    const resp = await postLogin(email, password);

    if (resp.status === 1) {
      analyticsSendEvent({ action: analyticsConstants.action.user_unlock });
      dispatch({
        type: Types.LOGIN_SUCCESS,
        status: resp.status,
        user: resp.data,
      });
      const profileRes = await dispatch(showProfileUser());
      if (profileRes.errorStatus === 402) {
        // subscription outdated
        analyticsSendEvent({
          category: analyticsConstants.category.billing,
          action: analyticsConstants.action.locked_company_sign_in,
        });
      }
      if (profileRes.status === 1) {
        dispatch(getUserPreference());
      }
    } else {
      dispatch({
        type: Types.LOGIN_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

const redirectToOnboarding = async (clientOnboarding = false) => {
  const userInfo = await getProfileUser();
  const isClient = get(userInfo.data, 'is_client', false);
  if (isClient && clientOnboarding && history) {
    history.push('/admin/dashboard?showOnboarding=true');
    sessionStorage.removeItem('redirect_URL');
    return;
  }
  const show_macro_onboarding =
    !!get(userInfo.data, 'show_macro_onboarding', 0) &&
    get(userInfo.data, 'roles', []).includes('Client_Master_Collaborator');
  const redirectL = show_macro_onboarding
    ? '/admin/onboarding/setup'
    : sessionStorage.getItem('redirect_URL') || '/admin/dashboard';

  if (history) history.push(redirectL);
  sessionStorage.removeItem('redirect_URL');
};

export const doLogout = (
  isLogoutClick = true,
  shouldCallApi = true,
  stopRedirect = false
) => {
  return async dispatch => {
    // close alert if any
    UpgradeAlert.close();
    AlertPopupHandler.close();
    if (shouldCallApi) {
      await postLogout();
    }
    if (isLogoutClick) {
      sessionStorage.removeItem('redirect_URL');
    }
    analyticsSendEvent({ action: analyticsConstants.action.user_log_out });
    dispatch({
      type: Types.LOGOUT_SUCCESS,
    });
    if (!stopRedirect) {
      history.push({
        pathname: '/auth/login',
        state: {
          isLogOut: isLogoutClick,
        },
      });
    }
  };
};

export const doRegister = user => {
  return async dispatch => {
    dispatch({
      type: Types.REGISTER_INPROGRESS,
    });
    const resp = await postRegister(user);
    analyticsSendEvent({
      action: analyticsConstants.action.user_registration_set_email,
    });

    if (resp.status === 1) {
      dispatch({
        type: Types.REGISTER_SUCCESS,
        status: resp.status,
      });
      return 1;
    } else {
      dispatch({
        type: Types.REGISTER_FAILURE,
        status: resp.status,
        message: resp.message,
      });
      return 0;
    }
  };
};

export const setPassword = data => {
  return async dispatch => {
    dispatch({
      type: Types.VERIFICATION_INPROGRESS,
    });

    const resp = await postEmailVerify(data);

    if (resp.status === 1) {
      dispatch({
        type: Types.VERIFICATION_SUCCESS,
        status: resp.status,
      });
      analyticsSendEvent({
        action: analyticsConstants.action.user_registration_set_password,
      });
      await dispatch(doLogin(data.email, data.password, false));
      return resp;
    } else {
      dispatch({
        type: Types.VERIFICATION_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
  };
};

export const verifyEmailVerificationValidity = data => {
  return async dispatch => {
    dispatch({
      type: Types.VERIFICATION_VALIDITY_INPROGRESS,
    });
    const resp = await checkEmailVerify(data);
    if (resp.status === 1) {
      dispatch({
        type: Types.VERIFICATION_VALIDITY_SUCCESS,
        status: resp.status,
      });
    } else {
      dispatch({
        type: Types.VERIFICATION_VALIDITY_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp;
  };
};

export const resendInvite = data => {
  return async dispatch => {
    dispatch({
      type: Types.RESEND_VERIFY_EMAIL_INPROGRESS,
    });

    const resp = await postResendVerifyEmail(data);

    if (resp.status === 1) {
      dispatch({
        type: Types.RESEND_VERIFY_EMAIL_SUCCESS,
        status: resp.status,
      });
    } else {
      dispatch({
        type: Types.RESEND_VERIFY_EMAIL_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
  };
};

export const sendEmailResetPassword = email => {
  return async dispatch => {
    dispatch({
      type: Types.PASSWORD_RESET_INPROGRESS,
    });

    const resp = await postEmailResetPassword(email);

    if (resp.status === 1) {
      dispatch({
        type: Types.PASSWORD_RESET_COMPLETE,
        status: resp.status,
        data: resp.data,
        message: resp.message,
      });
    } else {
      dispatch({
        type: Types.PASSWORD_RESET_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
  };
};

export const resendEmailResetPassword = email => {
  return async dispatch => {
    dispatch({
      type: Types.RESEND_PASSWORD_LINK_INPROGRESS,
    });

    const resp = await postEmailResetPassword(email);

    if (resp.status === 1) {
      dispatch({
        type: Types.PASSWORD_RESET_COMPLETE,
        status: resp.status,
        data: resp.data,
        message: resp.message,
      });
    } else {
      dispatch({
        type: Types.PASSWORD_RESET_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
  };
};

export const doResetPassword = data => {
  return async dispatch => {
    dispatch({
      type: Types.PASSWORD_RESET_INPROGRESS,
    });

    const resp = await postResetPassword(data);

    if (resp.status === 1) {
      dispatch({
        type: Types.PASSWORD_RESET_COMPLETE,
        status: resp.status,
        data: resp.data,
        message: resp.message,
      });
    } else {
      dispatch({
        type: Types.PASSWORD_RESET_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
  };
};

export const showLockScreen = () => {
  return async dispatch => {
    // close alert if any
    UpgradeAlert.close();
    AlertPopupHandler.close();
    analyticsSendEvent({ action: analyticsConstants.action.user_timeout });
    dispatch({
      type: Types.DEACTIVATE_USER,
    });
  };
};

export const changeToken = token => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_TOKEN,
      data: token,
    });
  };
};

export const showProfileUser = () => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PROFILE_INPROGRESS,
    });

    const resp = await getProfileUser();
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_PROFILE_SUCCESS,
        status: resp.status,
        user: resp.data,
      });
      analytics.setUserData(resp.data);
    } else {
      dispatch({
        type: Types.GET_PROFILE_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp;
  };
};

export const getClientSlaContract = (
  token,
  signature,
  contract_id,
  salesops
) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_SLA_CONTRACT_INPROGRESS,
    });
    const resp = await getSlaContract(token, signature, contract_id, salesops);
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_SLA_CONTRACT_SUCCESS,
        status: resp.status,
        data: resp,
        salesops,
      });
    } else {
      dispatch({
        type: Types.GET_SLA_CONTRACT_FAILURE,
        status: resp.status,
        message: resp.message,
      });

      window.location.href = '/auth/login?token_expired=true';
    }
    return resp;
  };
};

export const acceptAndSetupInvite = (companyId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.ACCEPT_INVITE_INPROGRESS,
    });

    const resp = await acceptAndSetup(companyId, data);

    if (resp.status === 1) {
      dispatch({
        type: Types.ACCEPT_INVITE_SUCCESS,
        status: resp.status,
        user: resp.data,
      });
      await redirectToOnboarding(true);
    } else {
      dispatch({
        type: Types.ACCEPT_INVITE_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp.status;
  };
};

export const userAccountSetup = encrypted_user_id => {
  return async dispatch => {
    dispatch({
      type: Types.GET_ACCOUNT_SETUP_DETAILS_INPROGRESS,
    });

    const resp = await accountSetup(encrypted_user_id);

    if (resp.status === 1) {
      dispatch({
        type: Types.GET_ACCOUNT_SETUP_DETAILS_SUCCESS,
        status: resp.status,
        user: resp.data,
      });
    } else {
      dispatch({
        type: Types.GET_ACCOUNT_SETUP_DETAILS_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp;
  };
};

export const acceptInvite = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.ACCEPT_INVITE_INPROGRESS,
    });

    const resp = await accept(id, data);

    if (resp.status === 1) {
      dispatch({
        type: Types.ACCEPT_INVITE_SUCCESS,
        status: resp.status,
        user: resp.data,
      });
      await redirectToOnboarding();
    } else {
      dispatch({
        type: Types.ACCEPT_INVITE_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp.status;
  };
};

export const checkInviteStatus = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.CHECK_INVITE_STATUS_INPROGRESS,
    });

    const resp = await getInviteStatus(id, data);
    if (resp.status === 1) {
      dispatch({
        type: Types.CHECK_INVITE_STATUS_SUCCESS,
        status: resp.status,
        user: resp.data,
      });
    } else {
      dispatch({
        type: Types.CHECK_INVITE_STATUS_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
    return { ...resp };
  };
};

export const createOwnCompany = id => {
  return async dispatch => {
    dispatch({
      type: Types.REGISTER_INPROGRESS,
    });

    const resp = await createCompany(id);
    const status = get(resp, 'status');
    const message = get(resp, 'message');
    const user = get(resp, 'data');

    if (status === 1) {
      // here calling register and login both actions because once user register we directly login them
      analyticsSendEvent({
        action: analyticsConstants.action.user_registration,
      });
      dispatch({
        type: Types.REGISTER_SUCCESS,
        status: status,
      });
      dispatch({
        type: Types.LOGIN_SUCCESS,
        status: status,
        user,
      });
      history.push('/admin/dashboard');
      NotificationHandler.open({
        message: message,
        operation: 'success',
      });
    } else {
      dispatch({
        type: Types.REGISTER_FAILURE,
        status: resp.status,
        message: resp.message,
      });
      NotificationHandler.open({
        message: message,
        operation: 'failure',
      });
    }
  };
};

export const logoutDeactivatedUser = data => {
  return async dispatch => {
    const companyName = get(data, 'company.name');
    await dispatch(doLogout());
    NotificationHandler.open({
      message: `Your access to ${companyName} has been revoked. Please contact your system administrator`,
      operation: 'failure',
    });
  };
};

export const socialLoginInprogess = () => {
  return async dispatch => {
    dispatch({
      type: Types.SOCIAL_LOGIN_INPROGESS,
    });
  };
};

export const socialLogin = status => {
  return async dispatch => {
    if (status === 1) {
      dispatch({
        type: Types.SOCIAL_LOGIN_SUCCESS,
      });
    } else {
      dispatch({
        type: Types.SOCIAL_LOGIN_FAILURE,
      });
    }
  };
};

export const toggleAuthError = () => {
  return async dispatch => {
    dispatch({
      type: Types.TOGGLE_AUTH_ERROR,
    });
  };
};

export const toggleResetPasswordError = () => {
  return async dispatch => {
    dispatch({
      type: Types.TOGGLE_RESET_PASSWORD_ERROR,
    });
  };
};

export const toggleResetPasswordSuccess = () => {
  return async dispatch => {
    dispatch({
      type: Types.TOGGLE_RESET_PASSWORD_SUCCESS,
    });
  };
};

export const toggleEmailVerifyError = () => {
  return async dispatch => {
    dispatch({
      type: Types.TOGGLE_EMAIL_VERIFY_ERROR,
    });
  };
};

export const toggleRegisterError = () => {
  return async dispatch => {
    dispatch({
      type: Types.TOGGLE_REGISTER_ERROR,
    });
  };
};
