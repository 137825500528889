import Types from 'store/types/AuthTypes';
import AppReducer from './app';
import AuthReducer from './AuthReducer';
import backlogsReducer from './backlogs';
import clientReducer from './clients';
import clientProfileReducer from './clientProfile';
import { combineReducers } from 'redux';
import projectReducers from './projects';
import notificationReducers from './notifications';
import epicReducers from './epics';
import knowledageBaseReducer from './knowledageBaseReducer';
import timer from './timerReducer';
import profileReducers from './profile';
import metadataReducers from './metadata';
import assignedProjects from './assignedProjects';
import Utility from './utility';
import timeSpent from './timeSpentReducer';
import timelogReport from './timelogReportReducer';
import userReducer from './users';
import teamMembersReducer from './teamMembers';
import supportStaffsReducers from './supportStaffs';
import kanban from './kanbanReducer';
import components from './components';
import story from './Story';
import projectDashboard from './projectDashboard';
import partnerDashboard from './partnerDashboard';
import company from './company';
import userDashboard from './userDashboard';
import fulfillment from './fulfillment';
import billing from './billing';
import assignmentTypeCustomizations from './assignmentTypeCustomizations';
import quickActions from './quickActions';
import documents from './documents';
import onboarding from './onboarding';
import dealDeskReducer from './dealDeskReducer';
import partnerReducer from './partners';
import Contract from './contracts';
const reducers = {
  app: AppReducer,
  auth: AuthReducer,
  backlog: backlogsReducer,
  client: clientReducer,
  clientProfile: clientProfileReducer,
  components,
  documents,
  onboarding,
  epic: epicReducers,
  project: projectReducers,
  utility: Utility,
  notification: notificationReducers,
  knowledgeBase: knowledageBaseReducer,
  timer,
  profile: profileReducers,
  metadata: metadataReducers,
  assignedProjects,
  timeSpent,
  timelogReport,
  user: userReducer,
  teamMembers: teamMembersReducer,
  partner: partnerReducer,
  contract: Contract,
  kanban,
  supportStaffs: supportStaffsReducers,
  story,
  projectDashboard,
  partnerDashboard,
  company,
  userDashboard,
  fulfillment,
  billing,
  assignmentTypeCustomizations,
  quickActions,
  dealDesk: dealDeskReducer,
};

const combinedReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (
    action.type === Types.LOGOUT_SUCCESS ||
    action.type === Types.LOGIN_INPROGRESS ||
    action.type === Types.SOCIAL_LOGIN_INPROGESS
  ) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
