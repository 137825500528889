import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import get from 'lodash/get';
import {
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import Tours from './Tours';
import useBreakPoint from 'helpers/useBreakPoint';
import { permissions, useAccess } from 'helpers/permission';
import ToursRenderer from './ToursRenderer';
import { getAvailableTours, getCurrentStepForTour } from './TourHelpers';
import analytics, { analyticsConstants } from 'helpers/analytics';

const allowedRoles = ['client'];
const QuickStartTour = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useBreakPoint('xs', 'down');
  const [isVisible, setVisible] = useState(false);
  const [isClosed, setClosed] = useState(true);
  const canSubmitDocuments = useAccess(permissions.SUBMIT_DOCUMENTS);

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const runningTime = useSelector(({ timer }) => get(timer, 'runningTime'));
  const assignedProjects = useSelector(({ auth }) =>
    get(auth, 'user.assigned_initiatives', [])
  );

  useEffect(() => {
    if (!canSubmitDocuments) return;
    const status = get(onBoardingTour, 'value.status', '');
    if (status) {
      if (status === 'Active') {
        setVisible(true);
        setClosed(false);
      } else if (status === 'Collapsed') {
        setVisible(false);
        setClosed(false);
      } else if (status === 'Closed') {
        setVisible(false);
        setClosed(true);
      }
    }
  }, [canSubmitDocuments, onBoardingTour]);

  const checkTourAndResetToInitialStep = location => {
    if (quickStartTour && quickStartTour.activeTour) {
      const isProjectTour =
        (quickStartTour.activeTour === 'project_creation' &&
          !location.pathname.startsWith('/admin/projects')) ||
        (!location.pathname.startsWith('/admin/projects') &&
          (!location.pathname.endsWith('/backlog') ||
            !location.pathname.endsWith('/dashboard')));
      const isStoryCreationTour =
        quickStartTour.activeTour === 'story_creation' &&
        (!location.pathname.startsWith('/admin/projects') ||
          (location.pathname.startsWith('/admin/projects') &&
            (!location.pathname.endsWith('/backlog') ||
              !location.pathname.endsWith('/dashboard')) &&
            location.search &&
            !location.search.startsWith('?story')));
      const isInviteTeamTour =
        quickStartTour.activeTour === 'invite_team' &&
        (!location.pathname.startsWith('/admin/projects') ||
          (location.pathname.startsWith('/admin/projects') &&
            (!location.pathname.endsWith('/team') ||
              !location.pathname.endsWith('/dashboard'))));
      const isClientTour =
        quickStartTour.activeTour === 'client_creation' &&
        location.pathname !== '/admin/accounts';
      const isUserInviteTour =
        quickStartTour.activeTour === 'user_invitation' &&
        location.pathname !== '/admin/users';
      if (
        isProjectTour ||
        isClientTour ||
        isUserInviteTour ||
        isStoryCreationTour ||
        isInviteTeamTour
      ) {
        setTimeout(() => {
          const step = getCurrentStepForTour(quickStartTour.activeTour, {
            assignedProjects,
            runningTime,
          });
          dispatch(
            updateActiveTourStatus({
              ...quickStartTour,
              step: step || 0,
            })
          );
        }, 20);
      }
    }
  };

  /*
    This effect will handle the path change for tours, if user changes the route in between
    of the active tour they will be taken back to the initial step

    Regarding dependency:
    -  We need to update the binding on history change and quickstartTour change
   */
  useEffect(() => {
    const unlisten = history.listen(location => {
      checkTourAndResetToInitialStep(location);
    });
    return unlisten;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, quickStartTour]);

  if (isClosed || isMobile) {
    return null;
  }

  const updateOnBoardingStatus = status => () => {
    if (status === 'Closed') {
      setVisible(false);
      setClosed(true);
    } else {
      setVisible(!isVisible);
    }
    if (status === 'Collapsed') {
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action: analyticsConstants.action.hide_onboarding_drawer,
      });
    } else if (status === 'Active') {
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action: analyticsConstants.action.show_onboarding_drawer,
      });
    } else if (status === 'Closed') {
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action: analyticsConstants.action.close_onboarding_drawer,
      });
    }
    const updatedOnboardingSteps = get(onBoardingTour, 'value.steps', {});
    Object.keys(updatedOnboardingSteps).forEach(k => {
      updatedOnboardingSteps[k].status =
        updatedOnboardingSteps[k].status === 'Active'
          ? 'Pending'
          : updatedOnboardingSteps[k].status;
    });
    if (status === 'Closed') {
      dispatch(
        updateActiveTourStatus({
          activeTour: null,
          step: null,
          isRestarted: false,
          withCreateProject: false,
        })
      );
    }
    dispatch(
      setUserPreference({
        ...onBoardingTour,
        value: {
          ...get(onBoardingTour, 'value', {}),
          status: status,
          ...(status === 'Closed'
            ? {
                steps: {
                  ...updatedOnboardingSteps,
                },
              }
            : {}),
        },
      })
    );
  };

  const analyticsStartEventMapping = {
    user_invitation:
      analyticsConstants.action.select_invite_your_team_onboarding_flow,
    project_creation:
      analyticsConstants.action.select_create_a_project_onboarding_flow,
    client_creation:
      analyticsConstants.action.select_create_a_client_onboarding_flow,
    time_logging:
      analyticsConstants.action.select_make_a_time_entry_onboarding_flow,
    story_creation:
      analyticsConstants.action.select_create_a_story_onboarding_flow,
    invite_team:
      analyticsConstants.action.select_invite_project_team_onboarding_flow,
    view_projects:
      analyticsConstants.action.select_view_projects_onboarding_flow,
    view_personal_kanban:
      analyticsConstants.action.select_view_personal_kanban_onboarding_flow,
  };

  const handleTourClick = (tourKey, isRestarted) => async () => {
    const updatedOnboardingSteps = get(onBoardingTour, 'value.steps', {});
    const html = document.getElementsByTagName('html')[0];
    if (html && !html.classList.contains('hide-scrollbar')) {
      html.classList.add('hide-scrollbar');
    }
    const { [tourKey]: currentKey, ...restData } = updatedOnboardingSteps;
    const currentActiveTour = quickStartTour.activeTour;
    const newRestData = { ...restData };
    Object.keys(restData).forEach(k => {
      newRestData[k].status =
        newRestData[k].status === 'Active' ? 'Pending' : newRestData[k].status;
    });
    if (quickStartTour && quickStartTour.activeTour) {
      // this is to clear the tour so that new tour gets tooltip rendered
      await dispatch(
        updateActiveTourStatus({
          activeTour: null,
          step: null,
          isRestarted: isRestarted,
          withCreateProject: false,
        })
      );
    }
    if (currentActiveTour !== tourKey) {
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action: analyticsStartEventMapping[tourKey],
      });

      const step = getCurrentStepForTour(tourKey, {
        assignedProjects,
        runningTime,
      });
      const doesContainProjectTour =
        tourKey === 'story_creation' || tourKey === 'invite_team';
      const extra = {
        ...(doesContainProjectTour && step > 1 && step < 5
          ? {
              withCreateProject: true,
            }
          : {}),
      };
      dispatch(
        setUserPreference({
          ...onBoardingTour,
          value: {
            ...get(onBoardingTour, 'value', {}),
            status: 'Collapsed',
            ...(!isRestarted
              ? {
                  steps: {
                    ...get(onBoardingTour, 'value.steps', {}),
                    [tourKey]: {
                      ...currentKey,
                      status:
                        currentActiveTour === tourKey ? 'Pending' : 'Active',
                    },
                    ...newRestData,
                  },
                }
              : {}),
          },
        })
      );
      dispatch(
        updateActiveTourStatus({
          activeTour: tourKey,
          step,
          ...extra,
        })
      );
    }
  };

  const renderTourBasedOnKey = () => {
    const availableTours = getAvailableTours(onBoardingTour.key);
    return (
      <ToursRenderer
        availableTours={availableTours}
        quickStartTour={quickStartTour}
        onBoardingTour={onBoardingTour}
        handleTourClick={handleTourClick}
      />
    );
  };
  // if role is not admin and not manager then we are hiding quick start
  if (
    onBoardingTour.key &&
    allowedRoles.findIndex(k => k === onBoardingTour.key) === -1
  ) {
    return null;
  }

  const handleOnboardingModal = () => {
    setVisible(!isVisible);
    const status = get(onBoardingTour, 'value.status', '');
    if (status && status === 'Active') {
      dispatch(
        setUserPreference({
          ...onBoardingTour,
          value: {
            ...get(onBoardingTour, 'value', {}),
            status: 'Collapsed',
          },
        })
      );
    }
  };

  return (
    <>
      {/* {isVisible ? <div className={classes.overlay} /> : null}
      <div
        className={cs(
          'position-fixed left-0 bottom-0 bg-white w-100',
          classes.quickStart,
          { [classes.hide]: !isVisible }
        )}
      >
        <div
          className={classes.minimize}
          onClick={() => setVisible(!isVisible)}
        >
          <span
            className="text-white"
            onClick={updateOnBoardingStatus(isVisible ? 'Collapsed' : 'Active')}
          >
            <i
              className={cs('fas mr-3 fa-chevron-down', {
                [classes.rotate]: !isVisible,
              })}
            />
            Quick Start
          </span>
        </div>
        <div className="w-100 d-flex justify-content-between">
          <div className={classes.title}>Start your journey the Lyght way!</div>
          <div
            className={cs('text-primary', classes.close)}
            onClick={updateOnBoardingStatus('Closed')}
          >
            <i className="fas fa-times mr-2" />
            Close Quick Start
          </div>
        </div>
        <div className="w-100 d-flex mt-4 justify-content-between">
          {renderTourBasedOnKey()}
        </div>
      </div> */}

      <Tours />
    </>
  );
};

export default QuickStartTour;
