import {
  getInteractions as getInteractionsApi,
  getUpcomingStudyList as getUpcomingStudyListApi,
  getRecentlyViewedStudiesList as getRecentlyViewedStudiesListApi,
  getDocumentBucketStatusList as getDocumentBucketStatusListApi,
  getFinancialYearStatusList as getFinancialYearStatusListApi,
  updateFinancialYearStatus as updateFinancialYearStatusApi,
  updateDocumentBucketStatus as updateDocumentBucketStatusApi,
  getStudyInteractions as getStudyInteractionsApi,
  postInteractionUnresolved as postInteractionUnresolvedApi,
  downloadAllDocuments as downloadAllDocumentsApi,
  getAllDocumentList as getAllDocumentListApi,
  getAllDocumentFolder as getAllDocumentFolderApi,
} from 'api/fulfillment';
import Types from 'store/types/fulfillment';
import get from 'lodash/get';
import { NotificationHandler } from 'components/Notifications';
import { fileDownload } from 'helpers/constants';

export const getInteractions = (params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_INTERACTIONS_LIST_INPROGRESS });
    try {
      const resp = await getInteractionsApi(params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const total = get(resp, 'total', 0);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_INTERACTIONS_LIST_SUCCESS,
          data: { data, meta, total },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_INTERACTIONS_LIST_FAILURE,
          message,
        });
      }
      return { data, total, meta };
    } catch (error) {
      dispatch({
        type: Types.FETCH_INTERACTIONS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const getStudyInteractions = (id, params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_STUDY_INTERACTIONS_LIST_INPROGRESS });
    try {
      const resp = await getStudyInteractionsApi(id, params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const total = get(resp, 'total', 0);
      const unresolved_count = get(resp, 'unresolved_count', 0);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_STUDY_INTERACTIONS_LIST_SUCCESS,
          data: { data, meta, total, unresolved_count },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_STUDY_INTERACTIONS_LIST_FAILURE,
          message,
        });
      }
      return { data, total, unresolved_count };
    } catch (error) {
      dispatch({
        type: Types.FETCH_STUDY_INTERACTIONS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const getUpcomingStudyList = params => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_STUDY_LIST_INPROGRESS });
    try {
      const resp = await getUpcomingStudyListApi(params);

      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_STUDY_LIST_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_STUDY_LIST_FAILURE,
          message,
        });
      }
      return { data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_INTERACTIONS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const getRecentlyViewedStudiesList = (params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_INPROGRESS });
    try {
      const resp = await getRecentlyViewedStudiesListApi(params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_FAILURE,
          message,
        });
      }
      return { data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_FAILURE,
        message: error,
      });
    }
  };
};
export const getDocumentBucketStatusList = params => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_INPROGRESS });
    try {
      const resp = await getDocumentBucketStatusListApi(params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const getFinancialYearStatusList = params => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_INPROGRESS });
    try {
      const resp = await getFinancialYearStatusListApi(params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const postInteractionUnresolved = data => {
  return async dispatch => {
    dispatch({ type: Types.POST_INTERACTION_AS_UNRESOLVED_INPROGRESS });
    try {
      const resp = await postInteractionUnresolvedApi(data);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.POST_INTERACTION_AS_UNRESOLVED_SUCCESS,
          data: resp.data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.POST_INTERACTION_AS_UNRESOLVED_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.POST_INTERACTION_AS_UNRESOLVED_FAILURE,
        message: error,
      });
    }
  };
};
export const updateDocumentBucketStatus = (params, documentId) => {
  return async dispatch => {
    dispatch({ type: Types.UPDATE_DOCUMENT_BUCKET_STATUS_INPROGRESS });
    try {
      const resp = await updateDocumentBucketStatusApi(params, documentId);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.UPDATE_DOCUMENT_BUCKET_STATUS_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.UPDATE_DOCUMENT_BUCKET_STATUS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_DOCUMENT_BUCKET_STATUS_FAILURE,
        message: error,
      });
    }
  };
};

export const downloadAllDocuments = (id, params) => {
  return async dispatch => {
    NotificationHandler.open({
      title: ' ',
      icon: ' ',
      operation: 'create',
      message: 'File Downloading Started',
    });
    dispatch({
      type: Types.FETCH_DOWNLOAD_ALL_DOCUMENTS_INPROGRESS,
    });
    try {
      const response = await downloadAllDocumentsApi(id, params);
      // using await so that we show the notification once file has been downloaded
      await fileDownload(response, 'docs', { type: 'application/zip' });
      dispatch({
        type: Types.FETCH_DOWNLOAD_ALL_DOCUMENTS_SUCCESS,
      });
      NotificationHandler.open({
        title: 'Success',
        operation: 'create',
        message: 'File Downloaded Successfully',
      });
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOWNLOAD_ALL_DOCUMENTS_FAILURE,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: 'Something Went Wrong',
      });
    }
  };
};

export const getAllDocumentList = (id, params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_ALL_DOCUMENTS_LIST_INPROGRESS });
    try {
      const resp = await getAllDocumentListApi(id, params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const current_page = get(resp, 'current_page', 0);
      const last_page = get(resp, 'last_page', 0);
      const meta = { current_page, last_page };

      if (status) {
        dispatch({
          type: Types.FETCH_ALL_DOCUMENTS_LIST_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_ALL_DOCUMENTS_LIST_FAILURE,
          message,
        });
      }
      return { data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_ALL_DOCUMENTS_LIST_FAILURE,
        message: error,
      });
    }
  };
};
export const updateFinancialYearStatus = (params, yearId) => {
  return async dispatch => {
    dispatch({ type: Types.UPDATE_FINANCIAL_YEAR_STATUS_INPROGRESS });
    try {
      const resp = await updateFinancialYearStatusApi(params, yearId);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.UPDATE_FINANCIAL_YEAR_STATUS_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.UPDATE_FINANCIAL_YEAR_STATUS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_FINANCIAL_YEAR_STATUS_FAILURE,
        message: error,
      });
    }
  };
};

export const getAllDocumentFolder = (id, params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_ALL_DOCUMENTS_FOLDER_INPROGRESS });
    try {
      const resp = await getAllDocumentFolderApi(id, params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const current_page = get(resp, 'current_page', 0);
      const last_page = get(resp, 'last_page', 0);
      const meta = { current_page, last_page };

      if (status) {
        dispatch({
          type: Types.FETCH_ALL_DOCUMENTS_FOLDER_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_ALL_DOCUMENTS_FOLDER_FAILURE,
          message,
        });
      }
      return { data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_ALL_DOCUMENTS_FOLDER_FAILURE,
        message: error,
      });
    }
  };
};
