import React from 'react';
import style from './ExpenseCard.module.scss';

const ExpenseCard = props => {
  const { title, value, color } = props;
  return (
    <div className={style.container}>
      <div className={style.title} style={{ color: color }}>
        {title}
      </div>
      <div className={style.value}>{value}</div>
    </div>
  );
};

export default ExpenseCard;
