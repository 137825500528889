import { get } from 'lodash';
import {
  fetchContractsList as fetchContractsListApi,
  createContract as createContractApi,
  editContract as editContractApi,
  deleteContract as deleteContractApi,
  getContract as getContractApi,
  postContractAgreed as postContractAgreedApi,
  postContractDeclined as postContractDeclinedApi,
  updateContractStatus as updateContractStatusApi,
} from 'api/contracts';
import Types from '../types/contracts';
import NotificationHandler from 'components/Notifications/NotificationHandler';

export const fetchContractsList = (page, limit, sort, q) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CONTRACTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchContractsListApi(page, limit, sort, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CONTRACTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_CONTRACTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CONTRACTS_FAILURE,
        message: error,
      });
    }
  };
};

export const getContract = id => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_SINGLE_CONTRACT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getContractApi(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_SINGLE_CONTRACT_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_SINGLE_CONTRACT_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.FETCH_SINGLE_CONTRACT_FAILURE,
        message: error,
      });
    }
  };
};

export const createContract = (data, isDuplicate = false) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_CONTRACT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await createContractApi(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.CREATE_CONTRACT_SUCCESS,
          data: resp || {},
        });

        NotificationHandler.open({
          message: data?.id
            ? 'Contract was updated successfully!'
            : isDuplicate
            ? 'Contract was duplicated successfully!'
            : 'New contract was created successfully!',
          title: ' ',
          icon: ' ',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.CREATE_CONTRACT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.CREATE_CONTRACT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const editContract = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_CONTRACT_INPROGRESS,
    });
    try {
      const resp = await editContractApi(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.EDIT_CONTRACT_SUCCESS,
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'success',
          icon: ' ',
          title: ' ',
        });
      } else {
        dispatch({
          type: Types.EDIT_CONTRACT_FAILURE,
          message,
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'failure',
          icon: ' ',
          title: ' ',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_CONTRACT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
        icon: ' ',
        title: ' ',
      });
    }
  };
};

export const deleteContract = (id, filters) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_CONTRACT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteContractApi(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_CONTRACT_SUCCESS,
          data: resp || {},
        });
        const { page, limit, sort, q } = filters;
        await dispatch(fetchContractsList(page, limit, sort, q));
        NotificationHandler.open({
          message: 'Contract deleted successfully',
          operation: 'update',
          icon: ' ',
          title: ' ',
        });
      } else {
        dispatch({
          type: Types.DELETE_CONTRACT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_CONTRACT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const postContractAgreed = id => {
  return async dispatch => {
    dispatch({
      type: Types.POST_CONTRACT_AGREED_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await postContractAgreedApi(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.POST_CONTRACT_AGREED_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.POST_CONTRACT_AGREED_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.POST_CONTRACT_AGREED_FAILURE,
        message: error,
      });
    }
  };
};

export const postContractDeclined = (id, email, reason) => {
  return async dispatch => {
    dispatch({
      type: Types.POST_CONTRACT_DECLINED_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await postContractDeclinedApi(id, email, reason);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.POST_CONTRACT_DECLINED_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.POST_CONTRACT_DECLINED_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.POST_CONTRACT_DECLINED_FAILURE,
        message: error,
      });
    }
  };
};

export const updateContractStatus = (id, data, type = 'deal-desk') => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.UPDATE_CONTRACT_STATUS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const storeData = getState();
      const salesops = get(storeData, 'auth.salesops');
      const resp = await updateContractStatusApi(
        id,
        {
          ...data,
          ...(salesops ? { salesops } : {}),
        },
        type
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_CONTRACT_STATUS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.UPDATE_CONTRACT_STATUS_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.UPDATE_CONTRACT_STATUS_FAILURE,
        message: error,
      });
    }
  };
};

export const clearContractData = () => {
  return async dispatch => {
    dispatch({
      type: Types.CLEAR_CONTRACT_DATA,
      data: {},
    });
  };
};
