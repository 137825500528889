import Request from './request';

export const getDataKanban = async (projectId, q) => {
  return Request.call({
    url: `/initiatives/${projectId}/board?q=${q}`,
    method: 'GET',
  });
};

export const addColumn = async (projectId, data) => {
  return Request.call({
    url: `/initiatives/${projectId}/board/add-column`,
    method: 'POST',
    data,
  });
};

export const editColumn = async (projectId, sectionId, data) => {
  return Request.call({
    url: `/initiatives/${projectId}/board/update-column/${sectionId}`,
    method: 'PUT',
    data,
  });
};

export const removeColumn = async (projectId, sectionId) => {
  return Request.call({
    url: `/initiatives/${projectId}/board/delete-column/${sectionId}`,
    method: 'DELETE',
  });
};

export const postReOrderStories = async (projectId, data) => {
  return Request.call({
    url: `/initiatives/${projectId}/board/prioritize-stories`,
    method: 'POST',
    data,
  });
};

export const postReOrderColumns = async (projectId, data) => {
  return Request.call({
    url: `/initiatives/${projectId}/board/re-order-columns`,
    method: 'POST',
    data,
  });
};

export const getDataPersonalKanban = async (userId, params) => {
  return Request.call({
    url: `/users/${userId}/board`,
    method: 'GET',
    params,
  });
};

export const postReOrderAssignment = async (userId, data) => {
  return Request.call({
    url: `/users/${userId}/board/prioritize-studies`,
    method: 'POST',
    data,
  });
};

export const getGlobalKanban = async params => {
  return Request.call({
    url: `/companies/kanban`,
    method: 'GET',
    params,
  });
};

export const patchUpdateStatusAssignments = async ({
  userId,
  taskId,
  data,
  isGlobal = true,
}) => {
  let url;
  if (isGlobal) {
    url = `/companies/board/${taskId}/update-status`;
  } else {
    url = `/users/${userId}/board/${taskId}/update-status`;
  }
  return Request.call({
    url,
    method: 'PATCH',
    data,
  });
};

export const getProjectStaff = async () => {
  return Request.call({
    url: `/users/list/dropdown?role[]=FulfilmentStaff`,
    method: 'GET',
  });
};

export const getProjectDirectors = async () => {
  return Request.call({
    url: `/users/list/dropdown?role[]=FulfilmentDirector`,
    method: 'GET',
  });
};

export const getDeadlineDates = async () => {
  return Request.call({
    url: `/companies/filing-deadlines`,
    method: 'GET',
  });
};
