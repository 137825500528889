import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import CommentIcon from 'assets/img/icons/deal-desk/comment-icon.svg';
import classes from './CommentFeature.module.scss';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';

export const ShowComments = ({ field }) => {
  const [show, setShow] = useState(false);

  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );

  const comment = dealDesk?.data?.comments?.[field];

  const renderCommment = () => {
    return (
      <div className={classes.commentBox}>
        <div className={classes.title}>
          <div>{comment?.created_by?.name}</div>
          <div className={classes.date}>
            {moment(comment?.updated_at)
              .tz(userTimezone)
              .format('MM/DD/YYYY')}
          </div>
        </div>

        <div
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: comment?.message }}
        />
      </div>
    );
  };

  if (!comment) return '';

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShow(false);
      }}
    >
      <div className={classes.commentFeature}>
        <img
          className={classes.messageIcon}
          src={CommentIcon}
          onClick={() => setShow(true)}
        />

        {show && renderCommment()}
      </div>
    </OutsideClickHandler>
  );
};
