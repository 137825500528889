import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import get from 'lodash/get';

import classes from './DocumentYearStatus.module.scss';
import {
  updateDocumentBucketStatus,
  updateFinancialYearStatus,
} from 'store/actions/fulfillment';
import { formatDate } from 'helpers/times';

const DocumentYearStatus = ({ documentData, handleDocAndYearStatusUpdate }) => {
  const dispatch = useDispatch();
  const {
    status: statusData = '',
    documents = [],
    dueDate = '',
    years: yearsData = [],
    financial_year_document_id = null,
  } = documentData;
  const [updatedDocument, setUpdatedDocument] = useState({});
  const mapStatus = status => {
    return status?.toLowerCase()?.replace(/ /g, '_');
  };
  const [status, setStatus] = useState(statusData);
  const [years, setYears] = useState(yearsData);
  const yearStatuses = useSelector(({ fulfillment }) =>
    get(fulfillment, 'financialYearStatusList.data.data', [])
  );
  const statuses = useSelector(({ fulfillment }) =>
    get(fulfillment, 'documentBucketStatusList.data.data', [])
  );

  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', null)
  );
  const documentGatherBy = get(currentStudy, 'documents_gather_by');
  const dateFormat = 'MM/DD/YYYY';
  const getDocStatusStyle = status => {
    const docStatus = mapStatus(status?.label);
    switch (docStatus) {
      case 'docs_submited':
        return '#037fbe';
      case 'needs_attention':
        return '#ec0c38';
      case 'in_review':
        return '#E9B411';
      case 'completed':
        return '#24a46d';
      default:
        return '#32325d';
    }
  };

  const getDocStatusOptionStyle = status => {
    const commonStyle = {
      borderRadius: '4px',
      padding: '4px',
      background: '#FFF',
      textAlign: 'center',
      marginBottom: '8px',
    };
    switch (status) {
      case 'docs_submited':
        return {
          ...commonStyle,
          border: '1px solid #24A46D',
        };
      case 'Needs Attention':
        return {
          ...commonStyle,
          border: '1px solid #EC0C38',
          color: '#EC0C38',
        };
      case 'In Review':
        return {
          ...commonStyle,
          border: '1px solid #E9B411',
          color: '#E9B411',
        };
      case 'Completed':
        return {
          ...commonStyle,
          border: '1px solid #24A46D',
          color: '#24A46D',
        };
      case 'Ready For Review':
        return {
          ...commonStyle,
          border: '1px solid #04A0F0',
          color: '#04A0F0',
        };
      default:
        return {};
    }
  };

  const getYearStatusStyle = year => {
    const yearStatus = mapStatus(year?.label ?? year?.status?.label);

    switch (yearStatus) {
      case 'partial':
        return {
          background: '#FC7C5F',
          height: '32px',
          borderColor: '#FC7C5F',
          width: '95px',
          color: '#101010',
          fontWeight: 600,
        };
      case 'complete':
        return {
          background: '#6FCF97',
          height: '32px',
          borderColor: '#6FCF97',
          width: '95px',
          color: '#101010',
          fontWeight: 600,
        };
      case 'none':
        return {
          background: '#B8C6D6',
          height: '32px',
          borderColor: '#B8C6D6',
          width: '95px',
          color: '#101010',
          fontWeight: 600,
        };
      case 'not_available':
      default:
        return {
          background: '#FFF',
          height: '32px',
          borderColor: '#000',
          width: '95px',
          color: '#101010',
          fontWeight: 600,
        };
    }
  };

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <div style={{ ...getDocStatusOptionStyle(props.label) }}>
            {props.label}
          </div>
        </components.Option>
      </div>
    );
  };

  const updateDocBucketStatus = async statusId => {
    await dispatch(
      updateDocumentBucketStatus(
        {
          status_id: statusId,
        },
        financial_year_document_id
      )
    );
    updatedDocument.status = statuses.find(x => x.id == statusId).label;
    setUpdatedDocument(updatedDocument);
    handleDocAndYearStatusUpdate(updatedDocument);
  };
  const updateYearStatus = async (yearId, statusId) => {
    await dispatch(
      updateFinancialYearStatus(
        {
          status_id: statusId,
        },
        yearId
      )
    );
    const year = yearsData.find(y => y.id == yearId)?.year;
    const status = yearStatuses.find(s => s.id == statusId)?.label;
    if (!updatedDocument.years) updatedDocument.years = [];
    updatedDocument.years.push({ year: year, status: status });
    setUpdatedDocument(updatedDocument);
    handleDocAndYearStatusUpdate(updatedDocument);
  };
  return (
    <form noValidate>
      <div className={classes.formWrapper}>
        <div className={classes.formContainer}>
          <div>
            <label htmlFor="docBucketStatus" className={classes.label}>
              Doc Bucket Status
            </label>
            <div className={classes.select1}>
              <Select
                options={statuses.filter(s => s.label !== 'Ready For Review')}
                id="docBucketStatus"
                value={status}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                onChange={value => {
                  setStatus(value);
                  updateDocBucketStatus(value.id);
                }}
                placeholder="Select Status"
                styles={{
                  control: base => ({
                    ...base,
                    background: '#fff',
                    height: '32px',
                    borderColor: getDocStatusStyle(status),
                    fontColor: getDocStatusStyle(status),
                    color: getDocStatusStyle(status),
                  }),
                  placeholder: styles => ({
                    ...styles,
                    color: getDocStatusStyle(status),
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: getDocStatusStyle(status),
                  }),
                  dropdownIndicator: styles => ({
                    ...styles,
                    color: getDocStatusStyle(status),
                  }),
                  indicatorSeparator: styles => ({
                    ...styles,
                    display: 'none',
                  }),
                  option: styles => ({
                    ...styles,
                    background: 'transparent',
                    padding: 0,
                    fontSize: '14px',
                    cursor: 'pointer',
                  }),
                  menu: base => ({
                    ...base,
                    padding: '4px 6px 0px 6px',
                  }),
                }}
                components={{
                  Option: Option,
                }}
                isClearable={false}
              />
            </div>
          </div>
          <div className="w-100">
            {yearsData?.length > 0 && (
              <label
                htmlFor="years"
                className={cx(classes.label, classes.bucketStatusLabel)}
              >
                Select Year(s) Received
              </label>
            )}
            <div style={{ display: 'flex', gap: '12px', flex: '1' }}>
              {years?.map((year, index) => (
                <div className={classes.select} key={year.year}>
                  <Select
                    options={yearStatuses}
                    placeholder={year.year}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.id}
                    styles={{
                      control: base => ({
                        ...base,
                        ...getYearStatusStyle(year),
                      }),
                      placeholder: styles => ({
                        ...styles,
                        color: getYearStatusStyle(year)?.color,
                      }),
                      singleValue: provided => ({
                        ...provided,
                        color: getYearStatusStyle(year)?.color,
                      }),
                      dropdownIndicator: styles => ({
                        ...styles,
                        color: getYearStatusStyle(year)?.color,
                      }),
                      indicatorSeparator: styles => ({
                        ...styles,
                        display: 'none',
                      }),
                      option: styles => ({
                        ...styles,
                        fontSize: '12px',
                        padding: '8px 10px',
                        cursor: 'pointer',
                      }),
                    }}
                    isClearable={false}
                    value={year.id}
                    onChange={value => {
                      const allyearsData = [...years];
                      let yearStatusData = allyearsData[index];
                      yearStatusData = {
                        ...yearStatusData,
                        status: value,
                      };
                      allyearsData[index] = yearStatusData;
                      setYears(allyearsData);
                      updateYearStatus(year.id, value.id);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.docDeatailWrapper}>
          <div className=" d-flex flex-column justify-content-center align-items-center gap-11px">
            <label htmlFor="noOfDocs" className={classes.label}>
              Number of docs
            </label>
            <h4>{documents?.length || '-'}</h4>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <label htmlFor="noOfDocs" className={classes.label}>
              Need All Docs By:
            </label>
            <h4>
              {documentGatherBy
                ? formatDate(documentGatherBy, dateFormat)
                : '-'}
            </h4>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DocumentYearStatus;
