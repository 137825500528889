import React from 'react';
import cs from 'classnames';
import classes from './AssignmentCard.module.scss';
import moment from 'moment';
import { useHistory } from 'react-router';
import Avatar from 'components/Avatar';

const AssignmentCard = ({
  studyId,
  ownerAvatar,
  expand,
  companyName,
  deadline,
  studyPhase,
  studyYears,
  tasks: { completed, total },
}) => {
  const history = useHistory();
  const moveToStudyDashboard = () => {
    return history.push({
      pathname: `/admin/studies/${studyId}/dashboard`,
      state: { name: 'Kanban', link: '/admin/kanban/company' },
    });
  };
  return (
    <div className={classes.root} onClick={moveToStudyDashboard}>
      <div
        className={cs(
          classes.progressBar,
          classes[studyPhase.toLowerCase().replaceAll(' ', '_')]
        )}
      >
        <span className={classes.companyName}>{companyName}</span>
      </div>
      {expand && (
        <div className={classes.toggleAnimation}>
          <div className={classes.body}>
            <div className={classes.studyRow}>
              <span className={classes.studyYear}>{studyYears}</span>
              <span className={classes.studyCompletion}>
                {completed}/{total}
              </span>
            </div>
            <div className={classes.studyRow}>
              <span className={classes.studyDeadline}>
                {deadline && moment(deadline).format('MM/DD/YYYY')}
              </span>
              <Avatar url={ownerAvatar} className={classes.avatar} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignmentCard;
