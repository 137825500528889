import React from 'react';
import Types from 'store/types/documents';
import { get } from 'lodash';
import {
  fetchDocTypesByYear,
  downloadDoc,
  fetchYears,
  uploadDocument,
  previewDocument,
  submitYear as submitYearApi,
  verifyYear as verifyYearApi,
  undoVerifyYear as undoVerifyYearApi,
  syncDocument as syncDocumentApi,
  getDocumentActivities,
  submitMissingYearInfoDocument as submitMissingYearInfoDocumentApi,
  fetchDocumentStatus as fetchDocumentStatusApi,
  fetchDocumentStudyTypes as fetchDocumentStudyTypesApi,
  uploadStudyDocument as uploadStudyDocumentApi,
} from 'api/documents';
import { fileDownload } from 'helpers/constants';
import { NotificationHandler } from 'components/Notifications';
import { AlertPopupHandler } from 'components/AlertPopup';

export const fetchFinancialYears = companyId => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_YEARS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchYears(companyId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_YEARS_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_YEARS_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.FETCH_YEARS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchDocumentTypesForFY = (
  year,
  companyId,
  clearRefetch = false
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DOCUMENT_FOR_YEAR_INPROGRESS,
      data: {
        year: year?.year,
      },
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchDocTypesByYear(year?.id, companyId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DOCUMENT_FOR_YEAR_SUCCESS,
          data: {
            data: resp.data || {},
            year: year?.year,
            clearRefetch,
          },
        });
      } else {
        dispatch({
          type: Types.FETCH_DOCUMENT_FOR_YEAR_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOCUMENT_FOR_YEAR_FAILURE,
        data: {
          year: year?.year,
        },
        message: error,
      });
    }
  };
};

export const fetchDocumentActivity = (id, page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DOCUMENT_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getDocumentActivities(id, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DOCUMENT_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DOCUMENT_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOCUMENT_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreDocumentActivity = (id, page) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_MORE_DOCUMENT_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getDocumentActivities(id, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_DOCUMENT_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_MORE_DOCUMENT_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_MORE_DOCUMENT_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};

export const uploadDocumentsForYear = (docType, data) => {
  return async dispatch => {
    dispatch({
      type: Types.DOCUMENT_UPLOAD_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await uploadDocument(docType, data);
      const status = get(resp, 'status');
      const responseData = get(resp, 'data');
      const message = get(resp, 'message');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.DOCUMENT_UPLOAD_SUCCESS,
          data: responseData,
        });
        NotificationHandler.open({
          title: 'File(s) uploaded successfully!',
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.DOCUMENT_UPLOAD_FAILURE,
          message: message,
        });
        AlertPopupHandler.open({
          onConfirm: () => {},
          confirmBtnText: `Ok`,
          showCancel: false,
          title: 'Upload Unsuccessful',
          text: (
            <div className="d-flex flex-column">
              <div>{message}</div>
            </div>
          ),
        });
      }
      return { status, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.DOCUMENT_UPLOAD_FAILURE,
        message: error,
      });
      AlertPopupHandler.open({
        onConfirm: () => {},
        confirmBtnText: `Ok`,
        showCancel: false,
        title: 'Upload Unsuccessful',
        text: (
          <div className="d-flex flex-column">
            <div>{error}</div>
          </div>
        ),
      });
    }
  };
};

export const downloadDocument = doc => {
  return async dispatch => {
    NotificationHandler.open({
      title: ' ',
      icon: ' ',
      operation: 'create',
      message: 'File Downloading Started',
    });
    dispatch({
      type: Types.DOWNLOAD_DOCUMENT_INPROGRESS,
    });
    try {
      const response = await downloadDoc(doc.id);
      // using await so that we show the notification once file has been downloaded
      await fileDownload(response, doc.name);
      dispatch({
        type: Types.DOWNLOAD_DOCUMENT_SUCCESS,
      });
      NotificationHandler.open({
        title: 'Success',
        operation: 'create',
        message: 'File Downloaded Successfully',
      });
    } catch (error) {
      dispatch({
        type: Types.DOWNLOAD_DOCUMENT_FAILURE,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const previewDocumentUrl = documentId => {
  return async dispatch => {
    dispatch({
      type: Types.PREVIEW_DOCUMENT_INPROGRESS,
    });
    try {
      const resp = await previewDocument(documentId);
      const { status, url } = resp;
      if (status) {
        dispatch({
          type: Types.PREVIEW_DOCUMENT_SUCCESS,
          url,
        });
      } else {
        dispatch({
          type: Types.PREVIEW_DOCUMENT_FAILURE,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.PREVIEW_DOCUMENT_FAILURE,
      });
    }
  };
};

export const clearDocumentUrl = () => {
  return async dispatch => {
    dispatch({
      type: Types.CLEAR_PREVIEW_DOCUMENT,
    });
  };
};

export const submitYear = (year, data) => {
  return async dispatch => {
    dispatch({
      type: Types.SUBMIT_YEAR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await submitYearApi(year, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.SUBMIT_YEAR_SUCCESS,
          year,
        });
        NotificationHandler.open({
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.SUBMIT_YEAR_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
      return { status, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.SUBMIT_YEAR_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
      });
    }
  };
};

export const verifyYear = (year, data) => {
  return async dispatch => {
    dispatch({
      type: Types.VERIFY_YEAR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await verifyYearApi(year, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.VERIFY_YEAR_SUCCESS,
          year,
        });
        NotificationHandler.open({
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.VERIFY_YEAR_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
      return { status, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.VERIFY_YEAR_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
      });
    }
  };
};

export const undoVerifyYear = (year, data) => {
  return async dispatch => {
    dispatch({
      type: Types.UNDO_VERIFY_YEAR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await undoVerifyYearApi(year, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.UNDO_VERIFY_YEAR_SUCCESS,
          year,
        });
        NotificationHandler.open({
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.UNDO_VERIFY_YEAR_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
      return { status, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.UNDO_VERIFY_YEAR_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
      });
    }
  };
};

export const syncDocument = (id, type, skipApi = false) => {
  return async dispatch => {
    if (skipApi) {
      dispatch({
        type: Types.SYNC_DOCUMENT_SUCCESS,
        id,
        integrationType: type,
      });
      return true;
    }
    dispatch({
      type: Types.SYNC_DOCUMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await syncDocumentApi(id, type);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.SYNC_DOCUMENT_SUCCESS,
          id,
          integrationType: type,
        });
      } else {
        dispatch({
          type: Types.SYNC_DOCUMENT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
      return { status, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.SYNC_DOCUMENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
      });
    }
  };
};

export const syncDocumentCompleted = data => {
  const {
    sync_info: { error },
  } = data;
  if (error) {
    NotificationHandler.open({
      message: error,
      operation: 'failure',
    });
  }
  return async dispatch => {
    dispatch({
      type: Types.SYNC_DOCUMENT_COMPLETED,
      data,
    });
  };
};

export const submitMissingYearInfoDocument = (data, customMessage = {}) => {
  return async dispatch => {
    dispatch({
      type: Types.SUBMIT_MISSING_YEAR_INFO_DOCUMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await submitMissingYearInfoDocumentApi(data);
      const status = get(resp, 'status');
      const responseData = get(resp, 'data');
      const message = get(resp, 'message');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.SUBMIT_MISSING_YEAR_INFO_DOCUMENT_SUCCESS,
          data: responseData,
        });
        NotificationHandler.open({
          operation: 'update',
          message: message,
          icon: ' ',
          title: ' ',
          ...customMessage,
        });
      } else {
        dispatch({
          type: Types.SUBMIT_MISSING_YEAR_INFO_DOCUMENT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
      return { status, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.SUBMIT_MISSING_YEAR_INFO_DOCUMENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
      });
    }
  };
};

export const fetchDocumentStatus = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DOCUMENT_STATUS_LIST_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchDocumentStatusApi();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DOCUMENT_STATUS_LIST_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DOCUMENT_STATUS_LIST_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOCUMENT_STATUS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchDocumentStudyTypes = initiative_id => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DOCUMENT_STUDY_TYPES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchDocumentStudyTypesApi(initiative_id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DOCUMENT_STUDY_TYPES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DOCUMENT_STUDY_TYPES_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOCUMENT_STUDY_TYPES_FAILURE,
        message: error,
      });
    }
  };
};

export const uploadStudyDocument = data => {
  return async dispatch => {
    dispatch({
      type: Types.STUDY_DOCUMENT_UPLOAD_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await uploadStudyDocumentApi(data);
      const status = get(resp, 'status');
      const responseData = get(resp, 'data');
      const message = get(resp, 'message');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.STUDY_DOCUMENT_UPLOAD_SUCCESS,
          data: responseData,
        });
      } else {
        dispatch({
          type: Types.STUDY_DOCUMENT_UPLOAD_FAILURE,
          message: message,
        });
        AlertPopupHandler.open({
          onConfirm: () => {},
          confirmBtnText: `Ok`,
          showCancel: false,
          title: 'Upload Unsuccessful',
          text: (
            <div className="d-flex flex-column">
              <div>{message}</div>
            </div>
          ),
        });
      }
      return { status, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.STUDY_DOCUMENT_UPLOAD_FAILURE,
        message: error,
      });
      AlertPopupHandler.open({
        onConfirm: () => {},
        confirmBtnText: `Ok`,
        showCancel: false,
        title: 'Upload Unsuccessful',
        text: (
          <div className="d-flex flex-column">
            <div>{error}</div>
          </div>
        ),
      });
    }
  };
};
