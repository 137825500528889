import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import { QuilIcons } from 'assets/icons';
import classes from './CustomToolbar.module.scss';

export const CustomToolbar = ({ toolbarRef, variables, icons, assignRef }) => {
  const options = QuilIcons.map((val, index) => ({
    value: val,
    label: <img src={val} alt={`Icon ${index}`} />,
  }));
  const variableOptions = variables.map((val, index) => ({
    value: val,
    label: val,
  }));

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (value, type) => {
    setSelectedOption(value);
    const quill = assignRef?.current?.getEditor();
    if (quill) {
      const range = quill.getSelection(true);
      const cursorPosition = range ? range.index : 0;
      if (type === 'image') {
        quill.insertEmbed(cursorPosition, 'image', value?.value);
        quill.setSelection(cursorPosition + 5);
      } else if (type === 'var') {
        quill.insertText(cursorPosition, value?.value);
        quill.setSelection(cursorPosition + value?.value.length);
      }
    }
  };

  return (
    <div ref={toolbarRef}>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-link" />
        <button className="ql-blockquote" />
        <button className="ql-code-block" />
        <button className="ql-image" />
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <div className={classes.btnWrapper}>
          <Select
            options={variableOptions}
            onChange={value => handleSelect(value, 'var')}
            placeholder="Insert Variables"
            className={classes.selectVariable}
          />
          <Select
            options={options}
            onChange={value => handleSelect(value, 'image')}
            placeholder="Insert Icons"
            className={classes.selectIcon}
          />
        </div>
      </span>
    </div>
  );
};

export default CustomToolbar;
