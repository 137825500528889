import Types from 'store/types/kanbanTypes';
import differenceWith from 'lodash/differenceWith';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

const initialState = {
  getKanban: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  getPersonalKanban: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  deleteColumn: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  addColumn: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  editColumn: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  reorderColumns: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  reorderStories: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  globalKanban: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  projectStaff: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  projectDirector: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  deadlineDates: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
};

export default function KanbanReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_DATA_KANBAN_LOADING:
      return {
        ...state,
        getKanban: {
          ...state.getKanban,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.GET_DATA_KANBAN_SUCCESS:
      return {
        ...state,
        getKanban: {
          ...state.getKanban,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.GET_DATA_KANBAN_ERROR:
      return {
        ...state,
        getKanban: {
          ...state.getKanban,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    case Types.GET_DATA_PERSONAL_KANBAN_LOADING:
      return {
        ...state,
        getPersonalKanban: {
          ...state.getPersonalKanban,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.GET_DATA_PERSONAL_KANBAN_SUCCESS:
      return {
        ...state,
        getPersonalKanban: {
          ...state.getPersonalKanban,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.GET_DATA_PERSONAL_KANBAN_ERROR:
      return {
        ...state,
        getPersonalKanban: {
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    case Types.REMOVE_COLUMN_LOADING:
      return {
        ...state,
        deleteColumn: {
          ...state.deleteColumn,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.REMOVE_COLUMN_SUCCESS:
      return {
        ...state,
        getKanban: {
          ...state.getKanban,
          data: {
            ...get(state, 'getKanban.data', {}),
            columns: differenceWith(
              get(state, 'getKanban.data.columns', []),
              [action.data],
              isEqual
            ),
          },
        },
        deleteColumn: {
          ...state.deleteColumn,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.REMOVE_COLUMN_ERROR:
      return {
        ...state,
        deleteColumn: {
          ...state.deleteColumn,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    case Types.CREATE_COLUMN_LOADING:
      return {
        ...state,
        addColumn: {
          ...state.addColumn,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.CREATE_COLUMN_SUCCESS:
      return {
        ...state,
        getKanban: {
          ...state.getKanban,
          data: {
            ...get(state, 'getKanban.data', {}),
            columns: [...get(state, 'getKanban.data.columns', []), action.data],
          },
        },
        addColumn: {
          ...state.addColumn,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.CREATE_COLUMN_ERROR:
      return {
        ...state,
        addColumn: {
          ...state.addColumn,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    case Types.EDIT_COLUMN_LOADING:
      return {
        ...state,
        editColumn: {
          ...state.editColumn,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.EDIT_COLUMN_SUCCESS:
      return {
        ...state,
        getKanban: {
          ...state.getKanban,
          data: {
            ...get(state, 'getKanban.data', {}),
            columns: map(get(state, 'getKanban.data.columns', []), column => {
              return column.id === action.data.id ? action.data : column;
            }),
          },
        },
        editColumn: {
          ...state.editColumn,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.EDIT_COLUMN_ERROR:
      return {
        ...state,
        editColumn: {
          ...state.editColumn,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    case Types.REORDER_COLUMN_LOADING:
      return {
        ...state,
        getKanban: {
          ...state.getKanban,
          data: {
            ...get(state, 'getKanban.data', {}),
            columns: action.data,
          },
        },
        reorderColumns: {
          ...state.reorderColumns,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.REORDER_COLUMN_SUCCESS:
      return {
        ...state,
        reorderColumns: {
          ...state.reorderColumns,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.REORDER_COLUMN_ERROR:
      return {
        ...state,
        reorderColumns: {
          ...state.reorderColumns,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    case Types.REORDER_KANBAN_STORIES_LOADING:
      return {
        ...state,
        getKanban: {
          ...state.getKanban,
          data: action.data,
        },
        reorderStories: {
          ...state.reorderStories,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.REORDER_KANBAN_STORIES_SUCCESS:
      return {
        ...state,
        reorderStories: {
          ...state.reorderStories,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.REORDER_KANBAN_STORIES_ERROR:
      return {
        ...state,
        getKanban: {
          ...state.getKanban,
          data: action.data,
        },
        reorderStories: {
          ...state.reorderStories,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    case Types.GET_DATA_GLOBAL_KANBAN_LOADING:
      return {
        ...state,
        globalKanban: {
          ...state.globalKanban,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.GET_DATA_GLOBAL_KANBAN_SUCCESS:
      return {
        ...state,
        globalKanban: {
          ...state.globalKanban,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.GET_DATA_GLOBAL_KANBAN_ERROR:
      return {
        ...state,
        globalKanban: {
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    case Types.GET_PROJECT_STAFF_LOADING:
      return {
        ...state,
        projectStaff: {
          ...state.projectStaff,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.GET_PROJECT_STAFF_SUCCESS:
      return {
        ...state,
        projectStaff: {
          ...state.projectStaff,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.GET_PROJECT_STAFF_ERROR:
      return {
        ...state,
        projectStaff: {
          ...state.projectStaff,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    case Types.GET_PROJECT_DIRECTORS_LOADING:
      return {
        ...state,
        projectDirector: {
          ...state.projectDirector,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.GET_PROJECT_DIRECTORS_SUCCESS:
      return {
        ...state,
        projectDirector: {
          ...state.projectDirector,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.GET_PROJECT_DIRECTORS_ERROR:
      return {
        ...state,
        projectDirector: {
          ...state.projectDirector,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    case Types.GET_DEADLINE_DATES_LOADING:
      return {
        ...state,
        deadlineDates: {
          ...state.deadlineDates,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };
    case Types.GET_DEADLINE_DATES_SUCCESS:
      return {
        ...state,
        deadlineDates: {
          ...state.deadlineDates,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };
    case Types.GET_DEADLINE_DATES_ERROR:
      return {
        ...state,
        deadlineDates: {
          ...state.deadlineDates,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    default:
      return state;
  }
}
