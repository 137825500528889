import React, { useState, useEffect } from 'react';
import classes from './DocumentVerify.module.scss';
import Button from 'components/Button';
import cx from 'classnames';
import { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { deleteAttachedFile } from 'store/actions/clientProfile';
import {
  fetchDocumentStudyTypes,
  fetchDocumentTypesForFY,
} from 'store/actions/documents';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';

const DocumentVerify = ({
  prev,
  files,
  onVerify,
  isAllYearsCovered,
  data,
  removeFile,
}) => {
  const dispatch = useDispatch();

  const uploadLoading = useGetFieldFromObjects(
    'documents',
    'studyDocumentUpload.isInProgress',
    false
  );

  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );

  useEffect(() => {
    if (files.length === 0) {
      prev();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleDeleteModal = id => {
    AlertPopupHandler.open({
      onConfirm: () => removeFile(id),
      confirmBtnText: `Delete`,
      text: (
        <div className="d-flex flex-column">
          <div>Do you want to delete this file?</div>
          <div>
            This file will be unrecoverable and version history will be lost.{' '}
          </div>
        </div>
      ),
    });
  };

  return (
    <div className={classes.docVerify}>
      <ModalBody className={cx('d-flex flex-row flex-wrap', classes.modalBody)}>
        <div className={classes.title}>
          <h3>
            Do these documents include all {data.short_name} records for{' '}
            {currentStudy?.current_study}?
          </h3>
        </div>
      </ModalBody>
      {files.length !== 0 && (
        <ModalBody
          className={cx('d-flex flex-row flex-wrap', classes.uploadNumber)}
        >
          <p>
            {files.length} file{files.length > 1 ? 's' : ''} have been uploaded
          </p>
        </ModalBody>
      )}
      <ModalBody
        className={cx('d-flex flex-row flex-wrap', classes.uploadedFileSection)}
      >
        <div className={classes.fileList}>
          {files.map(({ id, name, size }, i) => {
            return (
              <div key={i} className={classes.file}>
                <div className={classes.fileDetails}>
                  <img src={require('assets/img/extensions/Icn-file.svg')} />
                  <div className={classes.title}>
                    <h3>{name}</h3>
                    <p>{Math.round(size / 1024)} KB</p>
                  </div>
                </div>
                <div
                  onClick={() => handleDeleteModal(i)}
                  className={classes.removeFile}
                >
                  <i className="fas fa-trash" aria-hidden="true"></i>
                </div>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <Button
          className={classes.back}
          onClick={() => {
            prev();
          }}
          leftIcon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
        >
          Back
        </Button>
        <div className={classes.actions}>
          <Button
            loading={uploadLoading && !isAllYearsCovered}
            className={cx(classes.next, 'btn-outline-primary')}
            onClick={() => {
              onVerify(false);
            }}
          >
            No
          </Button>
          <Button
            loading={uploadLoading && isAllYearsCovered}
            className={cx(classes.next, 'btn-outline-primary')}
            onClick={() => {
              onVerify(true);
            }}
          >
            Yes
          </Button>
        </div>
      </ModalFooter>
    </div>
  );
};

export default DocumentVerify;
