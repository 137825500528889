import React from 'react';
import classes from './InteractionCard.module.scss';
import { ReactComponent as DocumentIcon } from 'assets/img/icons/document-comment-icon.svg';
import { ReactComponent as StudyIcon } from 'assets/img/icons/study-message.svg';
import moment from 'moment';
import history from 'helpers/history';

const InteractionCard = ({ data, userTimezone }) => {
  return (
    <div
      onClick={() => history.push(`/admin/studies/${data.id}/dashboard`)}
      className={classes.interactionWrapper}
    >
      <div className={classes.name}>
        <p>{data?.name || '-'}</p>
      </div>
      <div className={classes.content}>
        <div className={classes.counts}>
          <div className={classes.doc}>
            {!!data?.documents_uploads && (
              <>
                <span>{data?.documents_uploads}</span>
                <DocumentIcon />
              </>
            )}
          </div>
          <div className={classes.msg}>
            {!!data?.comments_added && (
              <>
                <span>{data?.comments_added}</span>
                <StudyIcon />
              </>
            )}
          </div>
        </div>
        <div className={classes.date}>
          <p>
            {data?.recent_activity
              ? moment(data?.recent_activity)
                  .tz(userTimezone)
                  .format('MM/DD/YYYY h:mm A')
              : '-'}
          </p>
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
};

export default InteractionCard;
