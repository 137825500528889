/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getClientSlaContract } from 'store/actions/authActions';
import { useDispatch } from 'react-redux';
import Loading from 'components/Loading';
import { updateContractStatus } from 'store/actions/contract';

const SLACallback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = queryString.parse(location.search || '');
  const { token, signature, sla_contract, salesops } = queryParams;
  const history = useHistory();

  useEffect(() => {
    const fetchClient = async () => {
      if (token || signature || sla_contract || salesops) {
        const { status, initiative, contract } = await dispatch(
          getClientSlaContract(token, signature, sla_contract, salesops)
        );

        if (status) {
          const type =
            contract.parent_type == 'App\\PartnerReferral'
              ? 'referrals'
              : 'deals';
          dispatch(
            updateContractStatus(
              initiative?.id,
              { status: 'opened' },
              type == 'deals' ? 'deal-desk' : 'partner-deal-desk'
            )
          );
          history.push('/admin/client/sla-agreement');
        }
      }
    };
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, signature, sla_contract]);

  return (
    <div className="main-root-loading min-vh-100">
      <Loading />
    </div>
  );
};

export default SLACallback;
