import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_DATA_KANBAN_LOADING',
  'GET_DATA_KANBAN_SUCCESS',
  'GET_DATA_KANBAN_ERROR',
  'GET_DATA_PERSONAL_KANBAN_LOADING',
  'GET_DATA_PERSONAL_KANBAN_SUCCESS',
  'GET_DATA_PERSONAL_KANBAN_ERROR',
  'REMOVE_COLUMN_LOADING',
  'REMOVE_COLUMN_SUCCESS',
  'REMOVE_COLUMN_ERROR',
  'CREATE_COLUMN_LOADING',
  'CREATE_COLUMN_SUCCESS',
  'CREATE_COLUMN_ERROR',
  'EDIT_COLUMN_LOADING',
  'EDIT_COLUMN_SUCCESS',
  'EDIT_COLUMN_ERROR',
  'REORDER_COLUMN_LOADING',
  'REORDER_COLUMN_SUCCESS',
  'REORDER_COLUMN_ERROR',
  'REORDER_KANBAN_STORIES_LOADING',
  'REORDER_KANBAN_STORIES_SUCCESS',
  'REORDER_KANBAN_STORIES_ERROR',
  'GET_DATA_GLOBAL_KANBAN_LOADING',
  'GET_DATA_GLOBAL_KANBAN_SUCCESS',
  'GET_DATA_GLOBAL_KANBAN_ERROR',
  'GET_PROJECT_STAFF_LOADING',
  'GET_PROJECT_STAFF_SUCCESS',
  'GET_PROJECT_STAFF_ERROR',
  'GET_PROJECT_DIRECTORS_LOADING',
  'GET_PROJECT_DIRECTORS_SUCCESS',
  'GET_PROJECT_DIRECTORS_ERROR',
  'GET_DEADLINE_DATES_LOADING',
  'GET_DEADLINE_DATES_SUCCESS',
  'GET_DEADLINE_DATES_ERROR'
);
