import Request from './request';

export const getDealDesks = async params => {
  return Request.call({
    url: '/deal-desk',
    method: 'GET',
    params,
  });
};

export const getDealDeskReferral = async params => {
  return Request.call({
    url: '/partner-deal-desk',
    method: 'GET',
    params,
  });
};

export const getDealDeskById = async (path, id) => {
  return Request.call({
    url: `/${path}/${id}`,
    method: 'GET',
  });
};

export const getDealDeskActivitiesById = async (id, path, params) => {
  return Request.call({
    url: `/${path}/${id}/activities`,
    method: 'GET',
    params,
  });
};

export const getDealDeskStatuses = async () => {
  return Request.call({
    url: `/deal-desk/statuses`,
    method: 'GET',
  });
};

export const postUpdateDealDeskById = async (id, data, path) => {
  return Request.call({
    url: `/${path}/${id}`,
    method: 'POST',
    data,
  });
};

export const postUpdateStatusDealDeskById = async (id, data, path) => {
  return Request.call({
    url: `/${path}/${id}/status`,
    method: 'PUT',
    data,
  });
};

export const getDealDeskUsers = async () => {
  return Request.call({
    url: `/deal-desk/users`,
    method: 'GET',
  });
};

export const getContractDeatails = async (id, path) => {
  return Request.call({
    url: `/${path}/${id}/preview`,
    method: 'GET',
  });
};

export const previewSignedContract = async token => {
  return Request.call({
    url: `/deal-desk/preview-contract?token=${token}`,
    method: 'GET',
  });
};

export const postAddCommentDealDesk = (
  id,
  message,
  subject,
  target,
  parent_type
) => {
  return Request.call({
    url: `/comments`,
    method: 'POST',
    data: {
      parent_id: id,
      parent_type: parent_type,
      type: 'deal-desk',
      message,
      subject,
      target,
    },
  });
};

export const getCommentsDealDesk = (id, parent_type = 'Initiative') => {
  return Request.call({
    url: `/comments`,
    method: 'GET',
    params: {
      parent_id: id,
      parent_type: parent_type,
      limit: -1,
    },
  });
};

export const postReadCommentsDealDesk = async data => {
  return Request.call({
    url: `/comments/update/read`,
    method: 'POST',
    data: data,
  });
};

export const getOptions = async () => {
  return Request.call({
    url: `/deal-desk/plan-options`,
    method: 'GET',
  });
};

export const addRelatedEntityDealDesk = async (id, data) => {
  return Request.call({
    url: `deal-desk/${id}/related-entity`,
    method: 'POST',
    data: data,
  });
};

export const deleteRelatedEntityDealDesk = async id => {
  return Request.call({
    url: `deal-desk/related-entity/${id}/`,
    method: 'DELETE',
  });
};
