import React, { useEffect, useState } from 'react';
import classes from './Interactions.module.scss';

import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Badge,
  Popover,
  PopoverBody,
} from 'reactstrap';

import cx from 'classnames';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import InfiniteScroll from 'react-infinite-scroller';
import Input from 'components/FormFields/Input';
import { debounce } from 'lodash';
import { ReactComponent as MessageIcon } from 'assets/img/icons/study-message.svg';

import { ReactComponent as SwitchIcon } from 'assets/img/icons/switch-vertical-icon.svg';
import InteractionCard from './InteractionCard/InteractionCard';
import Loading from 'components/Loading';
import { getInteractions } from 'store/actions/fulfillment';
import useBreakpoint from 'helpers/useBreakPoint';

const SORT_OPTIONS = [
  { label: 'New Interactions', value: '-recent_activity' },
  { label: 'Old Interactions', value: 'recent_activity' },
  { label: 'Nearest Deadline', value: '-deadline' },
  { label: 'Furthest Deadline', value: 'deadline' },
  { label: 'Most Document Messages', value: '-document_comments' },
  { label: 'Most Document Uploads', value: '-documents_uploads' },
  { label: 'Study Name - A to Z', value: 'name' },
  { label: 'Study Name - Z to A', value: '-name' },
];

const Interactions = () => {
  const dispatch = useDispatch();
  const isTablet = useBreakpoint('lg', 'down');
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const interactionsMeta = useSelector(({ fulfillment }) =>
    get(fulfillment, 'interactions.data.meta', {})
  );
  const interactionLoading = useSelector(({ fulfillment }) =>
    get(fulfillment, 'interactions.isInProgress', false)
  );
  const [interactions, setInteractions] = useState([]);
  const [total, setTotal] = useState();

  const current_page = interactionsMeta?.current_page || 0;
  const last_page = interactionsMeta?.last_page || 0;

  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState('-recent_activity');
  const [openFilterDropDown, setOpenFilterDropDown] = useState(false);

  useEffect(() => {
    fetchInteractions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = e => {
    e.persist();

    delayedSearch(e);
  };

  const searchInputChanged = e => {
    const searchText = e.target.value;
    fetchInteractions(1, searchText, true);
    setSearchText(e.target.value);
  };

  const fetchInteractions = async (
    page = 1,
    q = '',
    fromSearch = false,
    params = {}
  ) => {
    const { data, meta } = await dispatch(
      getInteractions({ page, keyword: q, sort: sortOrder, ...params })
    );
    setTotal(meta.total);
    if (fromSearch) {
      setInteractions([...data]);
    } else {
      setInteractions([...interactions, ...data]);
    }
  };

  const handleInteractionSort = (sort = '-recent_activity') => {
    setSortOrder(sort);
    fetchInteractions(1, searchText, true, { sort });
  };

  const delayedSearch = debounce(searchInputChanged, 500);
  return (
    <div className={classes.wrapper}>
      <Card className={cx(classes.card)}>
        <CardHeader>
          <div
            className={cx(
              'd-flex justify-content-between align-items-center',
              classes.headerContent
            )}
          >
            <h3 className="mb-0 d-flex" onClick={() => {}}>
              {'Interactions'}
              {total !== 0 && (
                <Badge className={classes.headerBadge}>
                  {total > 99 ? '99+' : total}
                </Badge>
              )}
            </h3>
            <div className={cx(classes.search)}>
              <span>Search:</span>
              <Input
                className={cx(
                  classes.input,
                  'form-control-alternative edit-event--description textarea-autosize'
                )}
                placeholder=""
                onChange={handleChange}
              />
              <SwitchIcon
                id="interaction-sort"
                className={cx({ [classes.sortIcon]: openFilterDropDown })}
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenFilterDropDown(true)}
              />
              <Popover
                isOpen={openFilterDropDown}
                toggle={() => {
                  setOpenFilterDropDown(currentState => !currentState);
                }}
                trigger="legacy"
                placement={'bottom-end'}
                target={'interaction-sort'}
                hideArrow={true}
                className={classes.popover}
              >
                <PopoverBody className={classes.popoverBody}>
                  <h3>Sort By:</h3>
                  {SORT_OPTIONS.map((option, index) => {
                    return (
                      <div
                        onClick={() => {
                          handleInteractionSort(option.value);
                          setOpenFilterDropDown(false);
                        }}
                        className={cx(classes.option, {
                          [classes.active]: option.value === sortOrder,
                        })}
                        key={index}
                      >
                        {option.label}
                      </div>
                    );
                  })}
                </PopoverBody>
              </Popover>
            </div>
          </div>
        </CardHeader>
        <Collapse isOpen={true}>
          <CardBody className={cx(classes.cardBody)}>
            <InfiniteScroll
              useWindow={isTablet ? false : true}
              hasMore={interactions.length && current_page < last_page}
              initialLoad={false}
              pageStart={0}
              loader={
                <div>
                  <Loading key="loader" size={50} />
                </div>
              }
              loadMore={() => {
                if (interactions.length !== 0 && !interactionLoading) {
                  fetchInteractions(current_page + 1, searchText);
                }
              }}
            >
              {interactions.length === 0 && interactionLoading ? (
                <Loading />
              ) : interactions.length === 0 ? (
                <div className={classes.noMessages}>
                  <div className={classes.content}>
                    <MessageIcon width={88} height={88} />
                    <p>There are currently no interactions to show.</p>
                  </div>
                </div>
              ) : (
                interactions.map((data, i) => {
                  return (
                    <InteractionCard
                      data={data}
                      userTimezone={userTimezone}
                      key={i}
                    />
                  );
                })
              )}
            </InfiniteScroll>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

export default Interactions;
