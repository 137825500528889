import React from 'react';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import moment from 'moment';
import Avatar from '../Avatar';
import ContentEditor from '../ContentEditor';
import classes from './Activity.module.scss';
import history from 'helpers/history';
import LyghtCircle from 'assets/img/brand/Lyght_circle.svg';
import { includes } from 'lodash';
import { useLocation } from 'react-router';

const Activity = ({
  item,
  isLargeAvatar,
  onClick,
  showComment = true,
  showFullDate = false,
}) => {
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const dateFormat = showFullDate ? 'MMMM DD, YYYY, hh:mm A' : 'MMM DD, YYYY';
  const location = useLocation();
  const handleOnClick = url => () => {
    if (url) {
      if (includes(url, location.pathname) && onClick) {
        onClick(url);
      } else {
        history.push(url.slice(url.indexOf('/admin')));
      }
    }
  };

  const getUserInitials = username => {
    if (!username) return false;
    if (username.split(' ').length === 1) {
      return username
        ?.split('')
        ?.join(' ')
        ?.slice(0, 3);
    }
    return username
      ?.split(' ')
      ?.map(n => n?.slice(0, 1))
      ?.join(' ')
      ?.slice(0, 3);
  };

  const handleAvatarClick = () => {
    if (item.profile_url) {
      window.open(item.profile_url);
    }
  };

  return (
    <div className={classes.item}>
      <div
        className={cs(classes.userAvatar, {
          [classes.userAvatarLarge]: isLargeAvatar,
          [classes.pointer]: item.profile_url,
        })}
        onClick={handleAvatarClick}
      >
        {item.owner_avatar ? (
          <Avatar
            url={
              item.owner_avatar === 'lyght-logo'
                ? LyghtCircle
                : item.owner_avatar
            }
            className={cs(classes.activityUser, {
              [classes.activityUserLarge]: isLargeAvatar,
            })}
          />
        ) : (
          <div className={classes.userNameAvatar}>
            {getUserInitials(item?.user_name) || 'U K'}
          </div>
        )}
      </div>
      <div
        className={cs(classes.time, {
          [classes.timeLarge]: isLargeAvatar,
        })}
      >
        <div className={classes.fromTo}>
          {moment(item.date_created)
            .tz(userTimeZone)
            .format(dateFormat)}
        </div>
      </div>
      <div className={classes.title}>
        <h5>{item.type}</h5>
      </div>
      {showComment && item.comment ? (
        <div className={classes.comment}>
          <ContentEditor content={item.comment} />
        </div>
      ) : null}
    </div>
  );
};

Activity.defaultProps = {
  item: {},
  isLargeAvatar: false,
  analyticsObj: {},
};

export default Activity;
