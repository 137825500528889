import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classes from './DealDesk.module.scss';
import cs from 'classnames';
import get from 'lodash/get';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

import DealDeskModal from './DealDeskModal';

import {
  callGetListStatuses,
  callGetListUsers,
} from 'store/actions/dealDeskActions';

import Loading from 'components/Loading';
import Deals from './Tabs/Deals';
import Referrals from './Tabs/Referrals';

const DealDesk = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const params = queryString.parse(location.search);

  const tab = params?.tab || 'deals'; //referrals

  useEffect(() => {
    dispatch(callGetListStatuses());
    dispatch(callGetListUsers());
  }, [dispatch]);

  const dealId = params?.deal_id || null;
  useEffect(() => {
    if (dealId) modalRef.current.open(dealId);
  }, [dealId]);

  const dealDesks = useSelector(({ dealDesk }) =>
    get(dealDesk, 'listDealDesks')
  );

  const dealDeskReferral = useSelector(({ dealDesk }) =>
    get(dealDesk, 'listDealDesksReferral')
  );

  const modalRef = useRef(null);

  return (
    <>
      <Container fluid className={classes.wrapper}>
        {dealDesks?.loading ||
          (dealDeskReferral?.loading && (
            <Loading wrapperClass={classes.loading} />
          ))}

        <p className={classes.title}>Deal Desk</p>

        <div className={classes.contentWrapper}>
          <Nav className={classes.nav} tabs>
            <NavItem className={classes.navItem}>
              <NavLink
                className={cs(classes.navLink, {
                  [classes.active]: tab === 'deals',
                })}
                onClick={() => history.push(`${location.pathname}?tab=deals`)}
              >
                Deals
              </NavLink>
            </NavItem>
            <NavItem className={classes.navItem}>
              <NavLink
                className={cs(classes.navLink, {
                  [classes.active]: tab === 'referrals',
                })}
                onClick={() =>
                  history.push(`${location.pathname}?tab=referrals`)
                }
              >
                Referrals
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tab}>
            <TabPane tabId="deals">
              <Deals meta={dealDesks?.meta} data={dealDesks?.data || []} />
            </TabPane>
            <TabPane tabId="referrals">
              <Referrals
                meta={dealDeskReferral?.meta}
                data={dealDeskReferral?.data || []}
              />
            </TabPane>
          </TabContent>
        </div>
      </Container>

      <DealDeskModal ref={modalRef} type={tab} />
    </>
  );
};

export default DealDesk;
