import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import classes from './Kanban.module.scss';

import { ButtonGroup, Container } from 'reactstrap';
import Input from 'components/FormFields/Input';
import cx from 'classnames';

import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useDebounce, useEffectOnce } from 'react-use';
import DragContainer from './DragContainer';
import { ReactComponent as FilterIcon } from 'assets/img/icons/advance-filter-icon.svg';
import { ReactComponent as RightIcon } from 'assets/img/icons/arrow-right-icon.svg';
import { ReactComponent as LeftIcon } from 'assets/img/icons/arrow-left-icon.svg';

import analytics, { analyticsConstants } from 'helpers/analytics';
import isEmpty from 'lodash/isEmpty';
import Loading from 'components/Loading';
import DateRangePicker from 'components/FormFields/DateRangePicker';
import Button from 'components/Button';
import AdvanceFilterModal from './AdvanceFilterModal';

const Kanban = props => {
  const loading = useSelector(({ kanban }) =>
    get(kanban, 'globalKanban.loading', false)
  );
  const [keyword, setKeyword] = useState('');
  const [count, setCount] = useState(0);
  const [period, setPeriod] = useState('last_month');
  const [expand, setExpand] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange, setDateRange] = useState();
  const [openAdvanceFilter, setOpenAdvanceFilter] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState({});
  const [studyType, setStudyType] = useState('all'); //r&d, ertc
  const scrollableRef = useRef(null);
  const customScrollbarRef = useRef(null);
  const [scrollDivWidth, setScrollDivWidth] = useState(0);

  const [dataRetrievalCount, setDataRetrievalCount] = useState(0);

  const handleFilter = filter => {
    setAdvanceFilter(filter);
  };

  useEffectOnce(() => {
    analyticsSendEvent({
      action: props.isGlobal
        ? analyticsConstants.action.view_global_kanban
        : analyticsConstants.action.view_personal_kanban,
    });
    const footerElement = document.getElementById('admin-footer');
    const parent = document.getElementById('admin-footer').parentElement;
    parent.removeChild(footerElement);
    return () => {
      parent.appendChild(footerElement);
    };
  });

  const loggedInUserId = useSelector(({ auth }) => get(auth, 'user.id'));
  const user = useSelector(({ auth }) => get(auth, 'user', {}));

  const handleExpandCollapse = () => {
    setExpand(!expand);
  };
  const analyticsSendEvent = ({ action, label, value, ...rest }) => {
    analytics.sendEvent({
      category: props.isGlobal
        ? analyticsConstants.category.global_kanban
        : analyticsConstants.category.personal_kanban,
      // is_logged_in_user: isLoggedInUser,
      action,
      label,
      value,
      ...rest,
    });
  };

  useDebounce(
    () => {
      if (!isEmpty(keyword))
        analyticsSendEvent({
          action: props.isGlobal
            ? analyticsConstants.action.search_global_kanban
            : analyticsConstants.action.search_personal_kanban,
          label: analyticsConstants.label.kanban_search,
        });
    },
    1000,
    [keyword]
  );

  const getData = async (period, keyword) => {
    let params = {
      q: keyword,
      ...advanceFilter,
      ...(studyType !== 'all' ? { study_type: studyType } : {}),
    };
    if (startDate && endDate) {
      params = {
        ...params,
        from_date: moment(startDate).format('YYYY/MM/DD'),
        to_date: moment(endDate).format('YYYY/MM/DD'),
      };
    }
    await props.fetchKanbanData(params);
    setDataRetrievalCount(currentCount => currentCount + 1);
  };

  useDebounce(
    () => {
      getData(period, keyword);
    },
    500,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, keyword, count, advanceFilter, studyType]
  );

  const reload = () => setCount(count + 1);

  const assignmentSummary = get(props.data, 'studies_summary', 0);

  const scrollLeft = () => {
    if (scrollableRef.current) {
      const scrollableContainer = scrollableRef.current;
      // Adjust this value to scroll left by a specific amount
      const scrollAmount = 300; // Change this as needed
      scrollableContainer.scrollBy({
        top: 0,
        left: -scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (scrollableRef.current) {
      const scrollableContainer = scrollableRef.current;
      // Adjust this value to scroll right by a specific amount
      const scrollAmount = 300; // Change this as needed
      scrollableContainer.scrollBy({
        top: 0,
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    setScrollDivWidth(scrollableRef?.current?.scrollWidth || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableRef, dataRetrievalCount]);

  return (
    <>
      {openAdvanceFilter ? (
        <AdvanceFilterModal
          filter={advanceFilter}
          isOpen={openAdvanceFilter}
          toggle={() => setOpenAdvanceFilter(false)}
          handleFilter={handleFilter}
        />
      ) : null}
      <div className={classes.root}>
        <div className={classes.headerWrapper}>
          <div className="d-flex flex-column">
            <h2 className={classes.title}>
              {props.title}{' '}
              <span className={classes.desc}>
                {assignmentSummary.open} Open Studies
              </span>
            </h2>

            <div className={classes.studyTypeSwitch}>
              <ButtonGroup
                aria-label="Template Type"
                role="group"
                className={classes.buttonGroup}
              >
                <Button
                  className={cx({ [classes.active]: studyType === 'all' })}
                  onClick={() => setStudyType('all')}
                >
                  All
                </Button>
                <Button
                  className={cx({ [classes.active]: studyType === 'r&d' })}
                  onClick={() => setStudyType('r&d')}
                >
                  R&D
                </Button>
                <Button
                  className={cx({ [classes.active]: studyType === 'ertc' })}
                  onClick={() => setStudyType('ertc')}
                >
                  ERTC
                </Button>
              </ButtonGroup>
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <div className="input-group-prepend align-items-center">
                <span id="basic-addon1">Search: </span>
              </div>
              <Input
                className={classes.searchBox}
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
              />
              <DateRangePicker
                showIcon
                startDate={startDate}
                endDate={endDate}
                value={dateRange}
                onChange={dates => {
                  setStartDate(dates.startDate);
                  setEndDate(dates.endDate);
                  if (dates.startDate && dates.endDate) {
                    const rangeDate =
                      dates.startDate.format('MMM DD, YYYY') +
                      ' - ' +
                      dates.endDate.format('MMM DD, YYYY');
                    setDateRange(rangeDate);
                  } else {
                    setDateRange(dateRange);
                  }
                }}
              />
            </div>

            <div className="d-flex justify-content-end mt-3">
              {/*<div className={classes.switchIcon}>*/}
              {/*  <ToggleCheckBox />*/}
              {/*  <span className={classes.switchText}>Switch to List View</span>*/}
              {/*</div>*/}
              <div className={classes.expandCollapse}>
                <Button
                  onClick={() => handleExpandCollapse()}
                  className={classes.expandButton}
                >
                  {expand ? (
                    <i className="fas fa-expand-arrows-alt"></i>
                  ) : (
                    <i className="fas fa-compress-arrows-alt"></i>
                  )}
                </Button>
              </div>
              <div className={classes.filterButtonWrapper}>
                <Button
                  onClick={() => setOpenAdvanceFilter(true)}
                  className={classes.filterButton}
                  rightIcon={<FilterIcon />}
                >
                  Advanced Filters
                </Button>
              </div>
            </div>
          </div>
        </div>
        {dateRange && (
          <div className={classes.filters}>
            <span>
              {dateRange}
              <a
                onClick={e => {
                  e.preventDefault();
                  setStartDate(null);
                  setEndDate(null);
                  setDateRange(null);
                }}
                href="#"
              >
                X
              </a>
            </span>
          </div>
        )}
        <div id="kanban-page">
          {dataRetrievalCount === 0 || loading ? (
            <Loading wrapperClass={classes.loading} />
          ) : (
            <Container fluid>
              <div className={classes.scrollAction}>
                <LeftIcon onClick={scrollLeft} />
                <RightIcon onClick={scrollRight} />
              </div>
              <div
                className={classes.customScrollWrapper}
                ref={customScrollbarRef}
              >
                <div
                  style={{ width: `${scrollDivWidth}px`, height: '10px' }}
                ></div>
              </div>

              <div
                ref={scrollableRef}
                onScroll={e => {
                  if (customScrollbarRef?.current && e?.currentTarget) {
                    customScrollbarRef.current.scrollLeft =
                      e.currentTarget.scrollLeft;
                  }
                }}
                className={classes.contentContainer}
              >
                <div className={classes.content}>
                  <DragContainer
                    isGlobal={props.isGlobal}
                    userId={loggedInUserId}
                    user={user}
                    setPeriod={setPeriod}
                    refresh={reload}
                    data={props.data}
                    analyticsSendEvent={analyticsSendEvent}
                    expand={!expand}
                  />
                </div>
              </div>
            </Container>
          )}
        </div>
      </div>
    </>
  );
};

export default Kanban;
