import React, { useState, useCallback, useEffect } from 'react';
import { Col } from 'reactstrap';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import classes from './PartnerInfoModal.module.scss';
import classnames from 'classnames';
import moment from 'moment';
import useBreakPoint from 'helpers/useBreakPoint';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Badge from 'reactstrap/lib/Badge';
import orderBy from 'lodash/orderBy';
import {
  fetchReferralComments,
  clearReferralCommentsData,
} from 'store/actions/partners';
import socket from 'helpers/socket';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import PartnerInfoDetails from './PartnerInfoDetails';

const PartnerInfoModal = ({
  isOpen,
  closeModal,
  currentTab: parentCurrentTab,
  onTabChange,
  comment = '',
  parentId,
  leadId,
  modalType,
  partnerInfo,
}) => {
  const dispatch = useDispatch();
  const [createdOn, setCreatedOn] = useState(undefined);
  const [updatedOn, setUpdatedOn] = useState(undefined);
  const isMobile = useBreakPoint('xs', 'down');
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);

  //TODO change all useSelectors fetching source
  const isDataFetchInProgress = useSelector(({ partner }) =>
    get(partner, 'activities.isInProgress', false)
  );

  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const user = useSelector(({ auth }) => auth.user);
  const formattedActivity = partnerInfo.activities.map(d => ({
    ...d,
    date_created: d.time,
    type: d.type.match(/[A-Z][a-z]+/g).join(' '),
    user_name: d.lead,
  }));

  useEffect(() => {
    if (partnerInfo.activities.length) {
      const orderedData = orderBy(formattedActivity, ['date_created'], 'desc');
      const lastUpdateDate = moment(orderedData[0]?.date_created)
        .tz(userTimezone)
        .format('MMMM DD, YYYY');
      const createdDate = moment(
        orderedData[orderedData.length - 1]?.date_created
      )
        .tz(userTimezone)
        .format('MMMM DD, YYYY');
      setUpdatedOn(lastUpdateDate);
      setCreatedOn(createdDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedActivity]);

  useEffect(() => {
    dispatch(
      fetchReferralComments(parentId, '', 'Company', modalType, leadId, '')
    );

    socket.joinAndListenComments('Company', parentId);

    return () => {
      socket.leaveComments();
      dispatch(clearReferralCommentsData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId]);

  const ConditionallyRenderScrollBar = useCallback(
    ({ children }) => {
      return isMobile ? (
        children
      ) : (
        <div className={classes.scrollBar} id="storyModal-leftColumn">
          {children}
        </div>
      );
    },
    [isMobile]
  );

  const handleModalClose = () => {
    closeModal();
  };

  return (
    <Modal
      size="lg"
      scrollable
      fade={false}
      title={
        <div className="d-flex align-items-center">
          {!isDataFetchInProgress && <span>Partner Information</span>}
        </div>
      }
      toggle={handleModalClose}
      isOpen={isOpen}
      className={classes.modalWrapper}
    >
      <ModalBody
        className={classnames(
          'p-0 d-flex flex-row flex-wrap',
          classes['story-modal'],
          { [classes['story-modal-mobile']]: isMobile }
        )}
        id="storyModal-body"
      >
        {isDataFetchInProgress ? (
          <Loading wrapperClass={classes.loading} />
        ) : (
          <>
            <ConditionallyRenderScrollBar>
              <Col className={classnames(classes['left-column'], 'py-3')}>
                <div
                  className={classnames(classes.details, classes.badgeWrapper)}
                >
                  <div>
                    <h5>Partner Name</h5>
                    <p>{partnerInfo?.name}</p>
                  </div>
                  <Badge>{partnerInfo?.status}</Badge>
                </div>
                <div className={classes.details}>
                  <h5>Partner Contacts</h5>
                  <p>
                    {partnerInfo?.contacts?.length
                      ? partnerInfo?.contacts.join(', ')
                      : '-'}
                  </p>
                </div>
                <div className={classes.details}>
                  <h5>Referred On</h5>
                  <p>
                    {partnerInfo?.referred_on
                      ? moment(partnerInfo?.referred_on).format('MMM DD, YYYY')
                      : '-'}
                  </p>
                </div>
                <div className={classes.details}>
                  <h5>Referral Agreement Signed On</h5>
                  <p>
                    {partnerInfo?.ra_signed_on
                      ? moment(partnerInfo?.ra_signed_on).format('MMM DD, YYYY')
                      : '-'}
                  </p>
                </div>
                <div className={classes.details}>
                  <h5>Your Override Fee</h5>
                  <p>{partnerInfo?.override_fee || '-'}</p>
                </div>
                <div className={classes.details}>
                  <h5>Total Referrals</h5>
                  <p>{partnerInfo?.total_referrals || '0'}</p>
                </div>
                <div className={classes.details}>
                  <h5>Total Clients Referred</h5>
                  <p>{partnerInfo?.total_clients_referred || '0'}</p>
                </div>
                <div className={classes.details}>
                  <h5>Active Clients</h5>
                  <p>{partnerInfo?.total_active_clients || '0'}</p>
                </div>
                <div className={classes.details}>
                  <h5>No Longer Clients</h5>
                  <p>{partnerInfo?.total_no_longer_client || '0'}</p>
                </div>
              </Col>
            </ConditionallyRenderScrollBar>
            <PartnerInfoDetails
              activities={formattedActivity}
              type={modalType}
              parentType={'Company'}
              currentTab={parentCurrentTab}
              comment={comment}
              updatedOn={updatedOn}
              createdOn={createdOn}
              onTabChange={onTabChange}
              leadId={leadId}
              parentId={queryProps?.parent || parentId}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

PartnerInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  onChange: PropTypes.func,
  onTabChange: PropTypes.func,
  currentTab: PropTypes.oneOf(['comments', 'activity', 'info']),
  comment: PropTypes.number,
};

PartnerInfoModal.defaultProps = {
  isOpen: false,
  onChange: () => {},
  onTabChange: () => {},
  currentTab: 'comments',
};
export default PartnerInfoModal;
