import React from 'react';
import { Badge } from 'reactstrap';
import cx from 'classnames';
import style from './StudyDocumentDetail.module.scss';

const StudyDocumentDetail = ({ key, data, onClickDocument }) => {
  const { years, documents, is_yearly } = data;
  return (
    <div className={style.container} onClick={onClickDocument}>
      <div className={style.cardData}>
        <div className={style.dataWrapper}>
          <div className={style.rightWrapper}>
            <div className={style.statusWrapper}>
              <div className={style.statusContainer}>
                <div className={style.statusText}># of docs</div>
                <div>
                  <span className={style.docsNo}>{documents?.length}</span>
                </div>
              </div>
              {is_yearly && (
                <div className={style.statusContainer}>
                  <div>
                    <div className={style.statusText}>year status</div>
                    <div className={style.yearWrapper}>
                      {years &&
                        years.map((year, index) => {
                          return (
                            <div key={index}>
                              <Badge
                                pill
                                className={cx(
                                  style.year,
                                  style[year?.status?.label?.toLowerCase()]
                                )}
                              >
                                {year?.year}
                              </Badge>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyDocumentDetail;
