import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_INTERACTIONS_LIST_INPROGRESS',
  'FETCH_INTERACTIONS_LIST_SUCCESS',
  'FETCH_INTERACTIONS_LIST_FAILURE',
  'FETCH_STUDY_LIST_INPROGRESS',
  'FETCH_STUDY_LIST_SUCCESS',
  'FETCH_STUDY_LIST_FAILURE',
  'FETCH_RECENTLY_VIEWED_STUDY_LIST_INPROGRESS',
  'FETCH_RECENTLY_VIEWED_STUDY_LIST_SUCCESS',
  'FETCH_RECENTLY_VIEWED_STUDY_LIST_FAILURE',
  'FETCH_FINANCIAL_YEAR_STATUS_LIST_INPROGRESS',
  'FETCH_FINANCIAL_YEAR_STATUS_LIST_SUCCESS',
  'FETCH_FINANCIAL_YEAR_STATUS_LIST_FAILURE',
  'FETCH_DOCUMENT_BUCKET_STATUS_LIST_INPROGRESS',
  'FETCH_DOCUMENT_BUCKET_STATUS_LIST_SUCCESS',
  'FETCH_DOCUMENT_BUCKET_STATUS_LIST_FAILURE',
  'UPDATE_FINANCIAL_YEAR_STATUS_INPROGRESS',
  'UPDATE_FINANCIAL_YEAR_STATUS_SUCCESS',
  'UPDATE_FINANCIAL_YEAR_STATUS_FAILURE',
  'UPDATE_DOCUMENT_BUCKET_STATUS_INPROGRESS',
  'UPDATE_DOCUMENT_BUCKET_STATUS_SUCCESS',
  'UPDATE_DOCUMENT_BUCKET_STATUS_FAILURE',
  'FETCH_STUDY_INTERACTIONS_LIST_INPROGRESS',
  'FETCH_STUDY_INTERACTIONS_LIST_SUCCESS',
  'FETCH_STUDY_INTERACTIONS_LIST_FAILURE',
  'POST_INTERACTION_AS_RESOLVED_INPROGRESS',
  'POST_INTERACTION_AS_RESOLVED_SUCCESS',
  'POST_INTERACTION_AS_RESOLVED_FAILURE',
  'POST_INTERACTION_AS_UNRESOLVED_INPROGRESS',
  'POST_INTERACTION_AS_UNRESOLVED_SUCCESS',
  'POST_INTERACTION_AS_UNRESOLVED_FAILURE',
  'FETCH_DOWNLOAD_ALL_DOCUMENTS_INPROGRESS',
  'FETCH_DOWNLOAD_ALL_DOCUMENTS_SUCCESS',
  'FETCH_DOWNLOAD_ALL_DOCUMENTS_FAILURE',
  'FETCH_ALL_DOCUMENTS_LIST_INPROGRESS',
  'FETCH_ALL_DOCUMENTS_LIST_SUCCESS',
  'FETCH_ALL_DOCUMENTS_LIST_FAILURE',
  'FETCH_ALL_DOCUMENTS_FOLDER_INPROGRESS',
  'FETCH_ALL_DOCUMENTS_FOLDER_SUCCESS',
  'FETCH_ALL_DOCUMENTS_FOLDER_FAILURE'
);
