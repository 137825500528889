import React, { useEffect, useState } from 'react';
import classes from './DocumentUploadModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/FormFields/Modal';
import InitialInfo from './InitialInfo';
import DocumentUpload from './DocumentUpload';
import DocumentVerify from './DocumentVerify';
import YearsVerification from './YearsVerification';
import { cloneDeep, get } from 'lodash';
import DocumentSubmit from './DocumentSubmit';
import {
  fetchDocumentStudyTypes,
  submitMissingYearInfoDocument,
  uploadStudyDocument,
} from 'store/actions/documents';

const customMessage = {
  message:
    'Your documents were successfully uploaded and submitted for review. The team will get back to you shortly.',
};

const DocumentUploadModal = ({ data, isOpen, onClose, type }) => {
  const dispatch = useDispatch();
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );

  const documentStatusList = useSelector(({ documents }) =>
    get(documents, `documentStatus.data.data`, [])
  );

  const [uploadingStep, setUploadingStep] = useState(1);
  const [isAllYearsCovered, setIsAllYearsCovered] = useState(null);
  const [files, setFiles] = useState([]);
  const [startYear, endYear = null] = currentStudy?.engagement_years
    ?.split('-')
    .map(year => Number(year));

  const next = () => setUploadingStep(prev => prev + 1);
  const prev = () => {
    if (uploadingStep === 1) {
      onClose();
    } else if (uploadingStep === 2 && type !== 'main') {
      setFiles([]);
      setUploadingStep(prev => prev - 1);
    } else if (uploadingStep === 3) {
      setFiles([]);
      setUploadingStep(prev => prev - 1);
    } else {
      setUploadingStep(prev => prev - 1);
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    if (files.length > 0) {
      files.forEach((attachment, index) => {
        formData.append(`attachments[${index}]`, attachment);
      });
    }
    formData.append('initiative_id', currentStudy.id);
    formData.append('document_type_id', data.id);
    const { status } = await dispatch(uploadStudyDocument(formData));
    if (status) {
      await dispatch(fetchDocumentStudyTypes(currentStudy.id));
      next();
    }
  };

  const handleVerifyAction = async action => {
    setIsAllYearsCovered(action);
    next();
  };

  const onDrop = acceptedFiles => {
    setFiles([...files, ...acceptedFiles]);
  };

  const handleRemoveFile = index => {
    const fileClone = cloneDeep(files);
    fileClone.splice(index, 1);
    setFiles(fileClone);
  };

  const handleSubmitReviewAllYearsCovered = async () => {
    const status_id = documentStatusList?.find(
      doc => doc?.label === 'Ready For Review'
    )?.id;
    await dispatch(
      submitMissingYearInfoDocument(
        {
          initiative_id: currentStudy.id,
          document_type_id: data.id,
          status_id,
        },
        customMessage
      )
    );
    await dispatch(fetchDocumentStudyTypes(currentStudy.id));
    onClose();
  };

  const handleSubmitReviewMissingYear = async formData => {
    const status_id = documentStatusList?.find(
      doc => doc?.label?.toLowerCase() === 'ready for review'
    )?.id;

    await dispatch(
      submitMissingYearInfoDocument(
        {
          extra_info: formData,
          status_id,
          initiative_id: currentStudy.id,
          document_type_id: data.id,
        },
        customMessage
      )
    );
    await dispatch(fetchDocumentStudyTypes(currentStudy.id));
    onClose();
  };

  useEffect(() => {
    if (uploadingStep === 0) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingStep]);

  const getYearsRange = () => {
    if (!endYear) {
      return startYear;
    }
    return `${startYear} - ${endYear}`;
  };

  const getSupportingDocUploadModules = () => {
    switch (uploadingStep) {
      case 1:
        return (
          <InitialInfo
            data={data}
            prev={prev}
            next={next}
            isSupportingDoc={true}
          />
        );
      case 2:
        return (
          <DocumentUpload
            prev={prev}
            next={handleUpload}
            files={files}
            onDrop={onDrop}
            removeFile={handleRemoveFile}
          />
        );
      case 3:
        return (
          <DocumentSubmit
            prev={prev}
            handleSubmitReview={handleSubmitReviewAllYearsCovered}
          />
        );
      default:
        return <div></div>;
    }
  };

  const getUploadingModules = () => {
    switch (uploadingStep) {
      case 1:
        return <InitialInfo data={data} prev={prev} next={next} />;
      case 2:
        return (
          <DocumentUpload
            prev={prev}
            next={handleUpload}
            files={files}
            onDrop={onDrop}
            removeFile={handleRemoveFile}
          />
        );
      case 3:
        return (
          <DocumentVerify
            data={data}
            onVerify={handleVerifyAction}
            isAllYearsCovered={isAllYearsCovered}
            prev={prev}
            files={files}
            removeFile={handleRemoveFile}
          />
        );
      case 4:
        return isAllYearsCovered ? (
          <DocumentSubmit
            prev={prev}
            handleSubmitReview={handleSubmitReviewAllYearsCovered}
          />
        ) : (
          <YearsVerification
            prev={prev}
            close={onClose}
            years={data.years}
            handleSubmitReview={handleSubmitReviewMissingYear}
          />
        );
      default:
        return <InitialInfo prev={prev} next={next} />;
    }
  };
  return (
    <Modal
      size="lg"
      backdrop={['static']}
      scrollable
      fade={false}
      title={
        <div className="d-flex align-items-center">
          <p>
            Add your{' '}
            <strong>
              {data.is_supporting ? 'Supporting Docs' : data.name} for years{' '}
              {getYearsRange()}
            </strong>
          </p>
        </div>
      }
      toggle={onClose}
      isOpen={isOpen}
      className={classes.modalWrapper}
      headerClassName={classes.modalHeader}
    >
      {type === 'main'
        ? getUploadingModules()
        : getSupportingDocUploadModules()}
    </Modal>
  );
};

export default DocumentUploadModal;
