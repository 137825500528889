import React, { useEffect } from 'react';

import { Container } from 'reactstrap';
import classes from './PartnerTracker.module.scss';
import PartnerTrackerTable from './PartnerTrackerTable';
import PartnerStatus from './PartnerStatus';
import PartnerActivity from './PartnerActivity';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { getReferralTrackerDashboard } from 'store/actions/partnerDashboard';
import Loading from 'components/Loading';
import { formatCurrency } from '../Earnings/constants';

const PartnerTracker = () => {
  const dispatch = useDispatch();
  const referralActivities = useSelector(({ partnerDashboard }) =>
    get(
      partnerDashboard,
      'referralTrackerDashboard.data.partner_activities',
      []
    )
  );
  const dashboardData = useSelector(({ partnerDashboard }) =>
    get(partnerDashboard, 'referralTrackerDashboard.data', {})
  );
  const pieChartData = useSelector(({ partnerDashboard }) =>
    get(
      partnerDashboard,
      'referralTrackerDashboard.data.partner_status_chart',
      {}
    )
  );
  const pieChartPercentageData = useSelector(({ partnerDashboard }) =>
    get(
      partnerDashboard,
      'referralTrackerDashboard.data.partner_status_chart_percent',
      {}
    )
  );
  const referredPartners = useSelector(({ partnerDashboard }) =>
    get(partnerDashboard, 'referralTrackerDashboard.data.list_partners', [])
  );
  const loading = useSelector(({ partnerDashboard }) =>
    get(partnerDashboard, 'referralTrackerDashboard.isInProgress', {})
  );

  useEffect(() => {
    dispatch(getReferralTrackerDashboard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container fluid className={classes.partnerTrackerDashboard}>
        <div className={classes.header}>
          <span className={classes.title}>Partner Referral Tracker</span>
        </div>
        <div className={classes.infoWrapper}>
          <div>
            <p>
              Partners <br /> You’ve Referred
            </p>
            <p>{dashboardData?.total_partners || 0}</p>
          </div>
          <div>
            <p>
              Active Partners <br />
              (made a recent referral)
            </p>
            <p>{dashboardData?.total_active_partners || 0}</p>
          </div>
          <div>
            <p>
              Referrals <br /> from Partners
            </p>
            <p>{dashboardData?.total_referral_partners || 0}</p>
          </div>
          <div>
            <p>
              Active Clients <br /> from Partners
            </p>
            <p>{dashboardData?.total_active_client_from_partners || 0}</p>
          </div>
          <div>
            <p>
              Potential <br /> Earnings
            </p>
            <p>{formatCurrency(dashboardData?.potential_earning_sum, true)}</p>
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.baseTableWrapper}>
            <div className={classes.table}>
              <PartnerTrackerTable data={referredPartners} />
            </div>
          </div>
          <div className={classes.statusAndActivity}>
            <div className={classes.partnerStatus}>
              <PartnerStatus
                data={pieChartData}
                percentageData={pieChartPercentageData}
              />
            </div>
            {referralActivities?.length > 0 && (
              <div className={classes.partnerActivity}>
                <PartnerActivity referralActivities={referralActivities} />
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default PartnerTracker;
