import React, { useEffect, useState } from 'react';
import classes from './Filters.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Dropdowns from 'components/Dropdowns';
import { debounce, get } from 'lodash';
import cs from 'classnames';
import Input from 'components/FormFields/Input';
import { ReactComponent as SearchIcon } from 'assets/img/icons/search-icon.svg';
import DateRangePicker from 'components/FormFields/DateRangePicker';

//filterType: deals | referrals
const Filters = ({ setFilter, filterType = 'deals' }) => {
  const dispatch = useDispatch();
  const statuses = useSelector(
    ({ dealDesk }) => get(dealDesk, 'listStatuses.data') || []
  );

  const users = useSelector(
    ({ dealDesk }) => get(dealDesk, 'listUsers.data') || []
  );

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange, setDateRange] = useState();

  const [period, setPeriod] = useState({
    value: '',
    text: 'All Time',
  });
  const [status, setStatus] = useState({
    value: '',
    text: 'All Statuses',
  });

  const [types, setTypes] = useState({
    value: '',
    text: 'All Types',
  });

  const [submitBy, setSubmitBy] = useState({
    value: '',
    text: 'All Reps',
  });

  const [size, setSize] = useState({
    value: '',
    text: 'All Sizes',
  });

  const [referrals, setReferrals] = useState({
    value: '',
    text: 'All %',
  });

  const [searchText, setSearchText] = useState('');

  const handleChange = e => {
    e.persist();

    delayedSearch(e);
  };

  const searchInputChanged = e => {
    setSearchText(e.target.value);
  };

  const delayedSearch = debounce(searchInputChanged, 500);

  const periodOptions = [
    {
      value: '',
      text: 'All time',
      onClick: async () => {
        setPeriod({
          value: '',
          text: 'All time',
        });
      },
      from_date: '',
      to_date: '',
    },
    {
      value: 'last_year',
      text: 'Last 365 days',
      onClick: async () => {
        setPeriod({
          value: 'last_year',
          text: 'Last 365 days',
        });
      },
      from_date: moment()
        .subtract(365, 'days')
        .format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD'),
    },
    {
      value: 'last_six_months',
      text: 'Last 6 months',
      onClick: async () => {
        setPeriod({
          value: 'last_six_months',
          text: 'Last 6 months',
        });
      },
      from_date: moment()
        .subtract(182, 'days')
        .format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD'),
    },
    {
      value: 'last_month',
      text: 'Last 30 Days',
      onClick: async () => {
        setPeriod({
          value: 'last_month',
          text: 'Last 30 days',
        });
      },
      from_date: moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD'),
    },
    {
      value: 'last_week',
      text: 'Last 7 Days',
      onClick: async () => {
        setPeriod({
          value: 'last_week',
          text: 'Last 7 Days',
        });
      },
      from_date: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      to_date: moment().format('YYYY-MM-DD'),
    },
  ];

  let statusOptions = [
    {
      value: '',
      text: 'All statuses',
      onClick: async () => {
        setStatus({
          value: '',
          text: 'All statuses',
        });
      },
    },
  ];

  const dataStatuses = statuses || [];
  dataStatuses.forEach(d => {
    const existStatus = statusOptions.find(s => s.text === d.status);
    const existStatusIndex = statusOptions.findIndex(s => s.text === d.status);

    if (!existStatus) {
      statusOptions.push({
        value: [d.id],
        text: d.status,
        onClick: async () => {
          setStatus({
            value: [d.id],
            text: d.status,
          });
        },
      });
    } else {
      let listValues = existStatus.value;
      listValues.push(d.id);
      statusOptions[existStatusIndex] = {
        value: listValues,
        text: d.status,
        onClick: async () => {
          setStatus({
            value: listValues,
            text: d.status,
          });
        },
      };
    }
  });

  const typesOptions = [
    {
      value: '',
      text: 'All types',
      onClick: () => {
        setTypes({
          value: '',
          text: 'All types',
        });
      },
    },
    {
      value: 'ertc',
      text: 'ERTC',
      onClick: async () => {
        setTypes({
          value: 'ertc',
          text: 'ERTC',
        });
      },
    },
    {
      value: 'r&d',
      text: 'R&D',
      onClick: async () => {
        setTypes({
          value: 'r&d',
          text: 'R&D',
        });
      },
    },
  ];

  const submitByOptions = [
    {
      value: '',
      text: 'All Reps',
      onClick: async () => {
        setSubmitBy({
          value: '',
          text: 'All Reps',
        });
      },
    },
  ];
  users.map(u => {
    submitByOptions.push({
      value: u.id,
      text: u.name,
      onClick: async () => {
        setSubmitBy({
          value: u.id,
          text: u.name,
        });
      },
    });
  });

  const dealSizeOptions = [
    {
      value: '',
      text: 'All Sizes',
      onClick: async () => {
        setSize({
          value: '',
          text: 'All Sizes',
        });
      },
    },
    {
      value: '$0-$10k',
      text: '$0 - $10k',
      onClick: async () => {
        setSize({
          value: '$0-$10k',
          text: '$0 - $10k',
        });
      },
    },
    {
      value: '$10k-$20k',
      text: '$10k - $20k',
      onClick: async () => {
        setSize({
          value: '$10k-$20k',
          text: '$10k - $20k',
        });
      },
    },
    {
      value: '$20k-$50k',
      text: '$20k - $50k',
      onClick: async () => {
        setSize({
          value: '$20k-$50k',
          text: '$20k - $50k',
        });
      },
    },
    {
      value: '$50k-$100k',
      text: '$50k - $100k',
      onClick: async () => {
        setSize({
          value: '$50k - $100k',
          text: '$50k - $100k',
        });
      },
    },
    {
      value: '$100k+',
      text: '$100k +',
      onClick: async () => {
        setSize({
          value: '$100k+',
          text: '$100k +',
        });
      },
    },
  ];

  const referralsOptions = [
    {
      value: '',
      text: 'All %',
      onClick: async () => {
        setReferrals({
          value: '',
          text: 'All %',
        });
      },
    },
    {
      value: '10%',
      text: '10%',
      onClick: async () => {
        setReferrals({
          value: '10%',
          text: '10%',
        });
      },
    },
    {
      value: '15%',
      text: '15%',
      onClick: async () => {
        setReferrals({
          value: '15%',
          text: '15%',
        });
      },
    },
  ];

  useEffect(() => {
    setFilter(prev => {
      let data = {
        ...prev,
        type: types?.value ?? '',
        deal_size: size?.value ?? '',
        status_id: status?.value ?? '',
        submitted_by: submitBy?.value ?? '',
        referrals: referrals.value ?? '',
        q: searchText,
      };

      if (startDate && endDate && dateRange) {
        const periodSelected = periodOptions.find(
          p => p.value === period?.value
        );

        data = {
          ...data,
          from_date: startDate.format('YYYY-MM-DD'),
          to_date: endDate.format('YYYY-MM-DD'),
        };
      }

      return data;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types, status, size, searchText, dateRange, submitBy, referrals]);

  //   useEffect(() => {
  //     dispatch(callGetListDealDesks(filter));
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [filter]);

  return (
    <div className={classes.headerDropdowns}>
      <div className={classes.dateRangePicker}>
        <p>DATE RANGE</p>
        <DateRangePicker
          label=""
          placeholder="All time"
          showIcon={false}
          startDate={startDate}
          datePickerClass={classes.datePicker}
          endDate={endDate}
          value={dateRange}
          onChange={dates => {
            if (dates.startDate && dates.endDate) {
              setStartDate(dates.startDate);
              setEndDate(dates.endDate);
              const rangeDate =
                dates.startDate.format('YYYY-MM-DD') +
                ' - ' +
                dates.endDate.format('YYYY-MM-DD');
              setDateRange(rangeDate);
            } else {
              setDateRange(dateRange);
            }
          }}
        />
      </div>
      {filterType === 'deals' && (
        <div>
          <p>Type </p>
          <Dropdowns
            dropdownClasses={cs(classes.customDropdown)}
            className="m-0 p-1 ml-1"
            text={
              <div className={classes.caret}>
                <span className={classes.statusText}>{types.text}</span>{' '}
                <i className="fas fa-caret-down" />{' '}
              </div>
            }
            active={types.value}
            size="sm"
            role="button"
            caret={false}
            color=""
            options={typesOptions}
          />
        </div>
      )}
      <div>
        <p>Submitted By </p>
        <Dropdowns
          dropdownClasses={cs(classes.customDropdown)}
          className="m-0 p-1 ml-1"
          text={
            <div className={classes.caret}>
              <span className={classes.statusText}>{submitBy.text}</span>{' '}
              <i className="fas fa-caret-down" />{' '}
            </div>
          }
          active={submitBy.value}
          size="sm"
          role="button"
          caret={false}
          color=""
          options={submitByOptions}
        />
      </div>
      {filterType === 'deals' ? (
        <div>
          <p>Deal size Range </p>
          <Dropdowns
            dropdownClasses={cs(classes.customDropdown)}
            className="m-0 p-1 ml-1"
            text={
              <div className={classes.caret}>
                <span className={classes.statusText}>{size.text}</span>{' '}
                <i className="fas fa-caret-down" />{' '}
              </div>
            }
            active={size.value}
            size="sm"
            role="button"
            caret={false}
            color=""
            options={dealSizeOptions}
          />
        </div>
      ) : (
        <div>
          <p>REFERRAL % </p>
          <Dropdowns
            dropdownClasses={cs(classes.customDropdown)}
            className="m-0 p-1 ml-1"
            text={
              <div className={classes.caret}>
                <span className={classes.statusText}>{referrals.text}</span>{' '}
                <i className="fas fa-caret-down" />{' '}
              </div>
            }
            active={referrals.value}
            size="sm"
            role="button"
            caret={false}
            color=""
            options={referralsOptions}
          />
        </div>
      )}
      <div>
        <p>STATUS </p>
        <Dropdowns
          dropdownClasses={cs(classes.customDropdown)}
          className="m-0 p-1 ml-1"
          text={
            <div className={classes.caret}>
              <span className={classes.statusText}>{status.text}</span>{' '}
              <i className="fas fa-caret-down" />{' '}
            </div>
          }
          active={status.value}
          size="sm"
          role="button"
          caret={false}
          color=""
          options={statusOptions}
        />
      </div>
      <div>
        <p className={classes.searchLabel}>SEARCH</p>
        <div className={classes.searchButtonWrapper}>
          <Input
            leftIcon={<SearchIcon />}
            className={cs(
              classes.input,
              'form-control-alternative edit-event--description textarea-autosize'
            )}
            placeholder="Search"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
