import React, { useState, useEffect } from 'react';
import MessagesItem from './MessagesItem';
import MessagesNotAvailable from './MessagesNotAvailable';

import { useDispatch, useSelector } from 'react-redux';
import classes from './Messages.module.scss';
import get from 'lodash/get';
import { useLocation } from 'react-router';
import cx from 'classnames';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import Loading from 'components/Loading';
import Button from 'components/Button';
import history from 'helpers/history';
import MessageFilter from './MessageFilter';
import InfiniteScroll from 'react-infinite-scroller';
import { getDashboardMessages } from 'store/actions/userDashboard';
import {
  clearCommentsData,
  getUnreadMessagesCount,
} from 'store/actions/Story/comments';
import queryString from 'query-string';
import { fetchProjectsList } from 'store/actions/projects';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import MessageModal from 'components/Fulfillment/Modals/GeneralCommentModal';
import DocumentModal from 'components/Fulfillment/Modals/DocumentCommentModal';

const modalType = {
  DocumentCommentAdded: 'FinancialYearDocumentType',
  DocumentType: 'FinancialYearDocumentType',
  FinancialYearDocumentType: 'FinancialYearDocumentType',
  Company: 'Company',
  Initiative: 'Initiative',
};

const Messages = props => {
  const {
    notifications,
    count,
    isMessagesLoading,
    toggleNotification,
    shouldMakeCall,
    showFilter = false,
  } = props;

  const dispatch = useDispatch();
  const [openGeneralModal, setOpenGeneralModal] = useState(false);
  const [openStudyModal, setOpenStudyModal] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [filters, setFilters] = useState(null);
  const { pathname } = useLocation();
  const [modalParams, setModalParams] = useState({
    parent_type: '',
    parent_id: '',
  });
  const [selectedMessageData, setSelectedMessageData] = useState(null);

  const page = useSelector(({ userDashboard }) =>
    get(userDashboard, 'messages.meta.current_page', 0)
  );
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);

  const projects = useGetFieldFromObjects(
    'project',
    'projectState.data.data',
    []
  );

  const unreadComments = useSelector(({ story }) =>
    get(story, 'comments.unreadCount.count', null)
  );

  useEffect(() => {
    dispatch(getUnreadMessagesCount());
    dispatch(fetchProjectsList(1));
  }, [dispatch]);

  useEffect(() => {
    const { modal_type = '', parent_id = '' } = queryProps; //document|study
    if (openStudyModal || openDocumentModal) return;

    if (modal_type === 'document') {
      const params = {
        parent_type: 'FinancialYearDocumentType',
        parent_id: parent_id,
      };
      setModalParams(params);
      setOpenDocumentModal(true);
    }
    if (modal_type === 'study') {
      const params = {
        parent_type: 'Initiative',
        parent_id: parent_id,
      };
      setModalParams(params);
      setOpenStudyModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps?.parent_id, queryParams?.modal_type]);

  const handleViewAllMessages = () => {
    history.push('/admin/client/messages');
  };

  const handleMessageClick = data => {
    const params = {
      parent_type: modalType[data?.comment_type],
      parent_id: data?.parent_id,
    };

    setSelectedMessageData(data);
    switch (data.comment_type) {
      case 'Company':
        setOpenGeneralModal(true);
        setModalParams(prev => ({
          ...params,
          thread_id: data?.thread_id,
          type: 'general',
        }));
        break;
      case 'Initiative':
        history.push(
          `${pathname}?modal_type=study&parent_id=${data?.parent_id}`
        );
        break;
      case 'DocumentType':
      case 'FinancialYearDocumentType':
        history.push(
          `${pathname}?modal_type=document&parent_id=${data?.parent_id}&initiative_id=${data?.initiative_id}`
        );
        break;
      default:
    }
  };

  const handleSetFilters = filters => {
    setFilters(filters);
  };

  const handleMessageFetchAfterRead = async () => {
    if (showFilter) {
      await dispatch(
        getDashboardMessages('list', filters, {
          page: page,
        })
      );
    } else {
      await dispatch(
        getDashboardMessages('widgetList', filters, {
          page: page,
        })
      );
    }
    dispatch(getUnreadMessagesCount());
  };
  return (
    <>
      {openGeneralModal ? (
        <MessageModal
          parentId={modalParams.parent_id}
          parentType={'Company'}
          params={modalParams}
          data={selectedMessageData}
          initiativeId={selectedMessageData?.initiative_id}
          isOpen={openGeneralModal}
          closeModal={() => {
            dispatch(clearCommentsData());
            handleMessageFetchAfterRead();
            setOpenGeneralModal(false);
            history.push(`?`);
          }}
        />
      ) : null}
      {openStudyModal ? (
        <MessageModal
          params={modalParams}
          data={selectedMessageData}
          isOpen={openStudyModal}
          initiativeId={selectedMessageData?.initiative_id}
          closeModal={() => {
            dispatch(clearCommentsData());
            handleMessageFetchAfterRead();
            setOpenStudyModal(false);
            history.push(`?`);
          }}
        />
      ) : null}
      {openDocumentModal ? (
        <DocumentModal
          params={modalParams}
          isOpen={openDocumentModal}
          closeModal={() => {
            dispatch(clearCommentsData());
            handleMessageFetchAfterRead();
            setOpenDocumentModal(false);
            history.push(`?`);
          }}
          // currentTab={get(queryProps, 'tab')}
        />
      ) : null}
      <Card className={classes.card}>
        <CardHeader className={cx(classes.cardHeader, { ['p-3']: showFilter })}>
          {showFilter ? (
            <MessageFilter
              handleSetFilters={handleSetFilters}
              hasMoreThanOneStudy={projects.length > 1}
            />
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="mb-0">
                  Messages
                  {unreadComments > 0 && (
                    <span className={classes.headerBadge}>
                      {unreadComments}
                    </span>
                  )}
                </h3>
              </div>
              <Button
                onClick={handleViewAllMessages}
                className={classes.viewButton}
                color="link"
              >
                View all messages
              </Button>
            </>
          )}
        </CardHeader>
        <CardBody
          className={cx(classes.notificationList, {
            [classes.fullView]: showFilter,
          })}
          key="notifications"
        >
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={async () => {
              if (!isMessagesLoading && shouldMakeCall) {
                let apiToCall;
                if (showFilter) {
                  apiToCall = 'list';
                  // set internal loading true, so we can stop hasMore flag till api loading
                  // when fetching next set of notifications we are sending the last notifications created_at
                  await dispatch(
                    getDashboardMessages(apiToCall, filters, {
                      page: page + 1,
                    })
                  );
                }
              }
            }}
            hasMore={shouldMakeCall}
            loader={isMessagesLoading && <Loading key="loader" size={50} />}
            useWindow={false}
          >
            {notifications.length > 0 ? (
              <div
                className={cx(classes.messagesWrapper, { ['p-3']: showFilter })}
              >
                {notifications.map((notification, index) => (
                  <MessagesItem
                    key={`${notification.id}-${index}`}
                    notification={notification}
                    toggle={toggleNotification}
                    userTimezone={userTimezone}
                    limit={notifications.length}
                    handleMessageClick={handleMessageClick}
                  />
                ))}
              </div>
            ) : isMessagesLoading ? (
              <></>
            ) : (
              <MessagesNotAvailable showFilter={showFilter} />
            )}
          </InfiniteScroll>
        </CardBody>
        {showFilter && (
          <CardFooter className={classes.cardFooter}>
            <div className={classes.actions}>
              <Button className={classes.archiveButton} color="link">
                View Archived
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
    </>
  );
};

export default Messages;
