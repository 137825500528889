import React, { useEffect, useState } from 'react';
import classes from './Deals.module.scss';
import DealDeskItem from '../../DealDeskItem';
import Pagination from 'components/Pagination';
import { useDispatch } from 'react-redux';
import { callGetListDealDesks } from 'store/actions/dealDeskActions';
import Filters from '../../Filters';
import history from 'helpers/history';
import { useLocation } from 'react-use';

const Deals = ({ meta = {}, data = [] }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [filter, setFilter] = useState({
    statuses: '',
    search: '',
    type: '',
    size: '',
    from_date: '',
    to_date: '',
  });

  useEffect(() => {
    dispatch(callGetListDealDesks(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <div className={classes.wrapper}>
      <Filters filter={filter} setFilter={setFilter} />

      {data.map(item => (
        <DealDeskItem
          key={item.id}
          item={item}
          type={'deals'}
          openModal={id => {
            history.push(`${location.pathname}?tab=deals&deal_id=${id}`);
          }}
        />
      ))}
      <div className={classes.paginationWrapper}>
        {meta && meta.total > 0 && (
          <div className={classes.paging}>
            From {meta.from} to {meta.to} of {meta.total} Results
          </div>
        )}

        {meta?.total > 0 && (
          <Pagination
            totalPage={meta.last_page}
            currentPage={meta.current_page}
            onClick={page => {
              dispatch(callGetListDealDesks({ ...filter, page }));
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Deals;
