import React, { useEffect, useCallback, useState, useMemo } from 'react';
import useBreakPoint from 'helpers/useBreakPoint';
import {
  Navbar,
  Nav,
  Row,
  Container,
  Col,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
  Badge,
} from 'reactstrap';
import get from 'lodash/get';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import ViewProjectImg from 'assets/img/theme/View_Project.svg';
import classes from './projects.module.scss';
import classNames from 'classnames';
import { useParams, useHistory, Redirect } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getProject as getProjectAction } from 'store/actions/projects';
import { fetchStudyPhases } from 'store/actions/metadata';
import CardsHeader from 'components/Headers/CardsHeader';
import EpicsList from 'views/pages/Epics/EpicsList';
import Users from 'views/pages/Users';
import Tasks from '../Tasks';
import PropTypes from 'prop-types';
import {
  permissions,
  useAccess,
  showUnAuhtorizedError,
} from 'helpers/permission';
import NavigationDropdown, {
  DesktopNavigation,
} from 'components/NavigationDropdown';
import Kanban from 'views/pages/Project/Kanban';
import Dashboard from 'views/pages/Project/Dashboard';
import compact from 'lodash/compact';
import toNumber from 'lodash/toNumber';
import Button from 'components/Button';
import Types from 'store/types/backlogs';
import SelectAsync from 'components/FormFields/SelectAsync';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import { massDeleteStories, massUpdateStories } from 'store/actions/backlogs';
import flatMap from 'lodash/flatMap';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import pluralize from 'pluralize';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import intersection from 'lodash/intersection';
import each from 'lodash/each';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { updateActiveTourStatus } from 'store/actions/profile';
import Loading from 'components/Loading';
import replace from 'lodash/replace';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import Backlogs from 'views/pages/Backlogs';
import MyDocuments from 'views/pages/MyDocuments';
import Deliverables from 'views/pages/Deliverables';
import { getStudyName } from 'helpers/formatName';
import Helmet from 'components/Helmet';
import ClientStudyDocuments from '../ClientProfile/ClientStudyDocuments';

const StrikeStudyDashboard = ({
  isMockUp,
  mockUpPage,
  mockUpData,
  ...rest
}) => {
  const history = useHistory();
  const parentName = history.location?.state?.name;
  const parentLink = history.location?.state?.link;
  const analyticsSendEvent = ({ ...rest }) => {
    if (!isMockUp)
      analytics.sendEvent({
        category: analyticsConstants.category.backlog,
        ...rest,
      });
  };
  const params = useParams();
  const id = get(params, 'id');
  const page = isMockUp ? mockUpPage : get(params, 'page', '');
  const currentUser = useSelector(({ auth }) => get(auth, 'user'));

  const loggedInUserID = useSelector(({ auth }) => get(auth, 'user.id', null));
  const useGetSelectedProjectProperty = (property, defaultValue) =>
    useSelector(({ project }) => {
      return !isMockUp
        ? get(project, `getProject.${property}`, defaultValue)
        : get(mockUpData, replace(property, 'data.data.', ''));
    }, isEqual);
  const isGetProjectLoading = useGetSelectedProjectProperty(
    'isInProgress',
    true
  );
  const userRole = useGetFieldFromObjects('auth', 'user.roles', []);
  const isCompanyAdmin = userRole.includes('Company_Administrator');
  const projectMembers = useGetSelectedProjectProperty(
    'data.data.team_members',
    []
  );
  const isSelectedProjectError = useGetSelectedProjectProperty(
    'isError',
    false
  );
  const selectedProjectErrorMessage = useGetSelectedProjectProperty(
    'message',
    ''
  );

  const sectionState = useSelector(
    ({ backlog }) => get(backlog, 'sectionsState.data', []),
    isEqual
  );

  const backlogItemsState = useSelector(
    ({ backlog }) => get(backlog, 'itemsState.data', {}),
    isEqual
  );

  const reducerId = useGetSelectedProjectProperty('id');
  const isMemberOfProject =
    projectMembers?.findIndex(m => m.id === loggedInUserID) > -1;
  const isUserAssignedProject = useSelector(({ auth }) =>
    includes(get(auth, 'user.assigned_initiatives', []), toNumber(id))
  );
  const isUserAllowedViewProject = useAccess({
    permission: permissions.VIEW_ALL_PROJECTS,
    value: isUserAssignedProject,
    operation: 'or',
  });

  const statuses = useSelector(({ story }) =>
    get(story, 'statusOptions.data', [])
  );

  const selectedStories = useSelector(({ backlog }) =>
    get(backlog, 'selectedStories', {})
  );
  const getSelectedStoriesList = useCallback(() => flatMap(selectedStories), [
    selectedStories,
  ]);

  const totalSelectedStoriesCount = useMemo(
    () => getSelectedStoriesList().length,
    [getSelectedStoriesList]
  );

  const shouldShowTeam =
    !currentUser?.is_client || userRole.includes('Client_Master_Collaborator');

  const shouldShowBoard = useAccess([
    permissions.VIEW_ALL_PROJECTS_KANBAN,
    {
      permission: permissions.VIEW_MY_PROJECTS_KANBAN,
      value: isMemberOfProject,
    },
  ]);

  const shouldShowDashboard = useAccess([
    permissions.VIEW_ALL_PROJECTS_DASHBOARD,
    {
      permission: permissions.VIEW_MY_PROJECTS_DASHBOARD,
      value: isMemberOfProject,
    },
  ]);
  const isAllowedEdit = useAccess([
    permissions.EDIT_ALL_STORIES,
    { permission: permissions.EDIT_PROJECT_STORIES, value: isMemberOfProject },
  ]);

  const isSectionEditAllowed = useAccess([
    permissions.EDIT_ALL_BACKLOG_SECTIONS,
    {
      permission: permissions.EDIT_PROJECT_BACKLOG_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const isAddStoryAllowed = useAccess([
    permissions.ADD_STORIES_TO_ALL_BACKLOG_SECTIONS,
    {
      permission: permissions.ADD_STORIES_TO_PROJECT_BACKLOG_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const isStoryReorderAllowed = useAccess([
    permissions.PRIORITIZE_ALL_PROJECTS_BACKLOG,
    {
      permission: permissions.PRIORITIZE_MY_PROJECTS_BACKLOG,
      value: isMemberOfProject,
    },
  ]);

  const isStoryEditAllowed = useAccess([
    permissions.EDIT_ALL_STORIES,
    { permission: permissions.EDIT_PROJECT_STORIES, value: isMemberOfProject },
  ]);

  const isStoryDeleteAllowed = useAccess(permissions.DELETE_ALL_STORIES);

  const isStoryViewAllowed = useAccess([
    permissions.VIEW_ALL_STORIES,
    { permission: permissions.VIEW_PROJECT_STORIES, value: isMemberOfProject },
  ]);

  const isMassUpdateAllowed = useAccess([
    permissions.MASS_UPDATE_STORIES_OF_ALL_SECTIONS,
    {
      permission: permissions.MASS_UPDATE_STORIES_OF_MY_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  const isMassDeleteAllowed = useAccess([
    permissions.MASS_DELETE_STORIES_OF_ALL_SECTIONS,
    {
      permission: permissions.MASS_DELETE_STORIES_OF_MY_SECTIONS,
      value: isMemberOfProject,
    },
  ]);

  // creating contents option here as we need to check conditions from project data
  const contents = compact([
    (shouldShowDashboard || isMockUp) && {
      name: 'Dashboard',
      iconClassName: 'fas fa-th-large text-xs',
      gradientColor: 'green',
      pageLink: 'dashboard',
    },
    !currentUser?.is_client && {
      name: 'Backlog',
      iconClassName: 'ni ni-bullet-list-67',
      gradientColor: 'info',
      pageLink: 'backlog',
      id: 'backlogTab',
    },
    currentUser?.is_client && {
      name: 'Tasks',
      iconClassName: 'ni ni-bullet-list-67',
      gradientColor: 'info',
      pageLink: 'tasks',
      id: 'taskTab',
    },
    /*{
      name: 'Roadmap',
      iconClassName: 'fas fa-stream',
      gradientColor: 'green',
      pageLink: 'roadmap',
    },*/
    {
      name: 'Documents',
      iconClassName: 'fas fa-file',
      gradientColor: 'primary',
      pageLink: 'documents',
      id: 'documentsTab',
    },
    (shouldShowTeam || isMockUp) && {
      name: 'Team',
      iconClassName: 'fas fa-users',
      gradientColor: 'red',
      pageLink: 'team',
      id: 'teamsTab',
    },
    {
      name: 'Deliverables',
      iconClassName: 'fas fa-archive',
      gradientColor: 'red',
      pageLink: 'qa',
    },
    /*{
      name: 'QA',
      iconClassName: 'fas fa-bug',
      gradientColor: 'yellow',
      pageLink: 'qa',
    },
    {
      name: 'Documents',
      iconClassName: 'fas fa-book-open',
      gradientColor: 'info',
      pageLink: 'documents',
    },*/
  ]);
  const projectName = useGetSelectedProjectProperty('data.data.name', '');
  const projectId = useGetSelectedProjectProperty('data.data.id', '');
  const companyId = useGetSelectedProjectProperty('data.data.company_id', '');
  const currentStudy = useGetSelectedProjectProperty(
    'data.data.current_study',
    ''
  );
  const studyName = useGetSelectedProjectProperty('data.data.study_name', '');
  const clientId = useGetSelectedProjectProperty('data.data.client.id', '');

  const activeContent = find(contents, obj => obj.pageLink === page);
  const isMobile = useBreakPoint('sm', 'down');
  const dispatch = useDispatch();
  const runningTime = useSelector(({ timer }) => get(timer, 'runningTime'));
  const isClient = useGetFieldFromObjects('auth', 'user.is_client', false);
  useEffect(() => {
    if (!isMockUp) {
      dispatch(getProjectAction(id));
      dispatch(fetchStoryStatuses());
      dispatch(fetchStudyPhases());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const updateSelectedStories = (field, value) => {
    let storiesOrderedList = [];
    const sortedSectionList = orderBy(sectionState, ['sort_order'], ['asc']);
    each(sortedSectionList, section => {
      const sortedStoryIds = map(
        sortBy(get(backlogItemsState, [section.id, 'data'], []), [
          'sort_order',
        ]),
        'id'
      );
      storiesOrderedList = storiesOrderedList.concat(
        intersection(sortedStoryIds, getSelectedStoriesList())
      );
    });
    dispatch(massUpdateStories(projectId, field, value, storiesOrderedList));
  };

  const onOptionClick = async content => {
    if (
      (quickStartTour &&
        quickStartTour.activeTour &&
        quickStartTour.activeTour === 'story_creation') ||
      quickStartTour.activeTour === 'invite_team'
    ) {
      setTimeout(() => {
        dispatch(
          updateActiveTourStatus({
            step: null,
            nextStep: 6,
          })
        );
      }, 1000);
    }
    history.replace(`/admin/studies/${id}/${content.pageLink}`);
  };

  const deleteStories = () => {
    analyticsSendEvent({
      action: analyticsConstants.action.multiselect_delete,
    });
    dispatch(massDeleteStories(projectId, getSelectedStoriesList()));
  };
  const deleteAction = () => {
    AlertPopupHandler.open({
      onConfirm: deleteStories,
      confirmBtnText: `Delete ${pluralize('Story', totalSelectedStoriesCount)}`,
      text: `You are about to delete ${pluralize(
        'story',
        totalSelectedStoriesCount,
        true
      )}. Do you want to continue?`,
    });
  };

  const { data: preferences = [] } = useSelector(
    ({ profile }) => profile.preference
  );
  const [collapsePreference] = filter(
    preferences,
    element =>
      get(element, 'category') === 'epic' &&
      get(element, 'key') === 'toggle_epics_window'
  );
  const [collapse, setCollapse] = useState(
    !isEqual(get(collapsePreference, 'value', 'expand'), 'expand')
  );

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const renderContent = useCallback(() => {
    // if (isGetProjectLoading || (projectId !== toNumber(id) && !isMockUp)) {

    switch (get(activeContent, 'name', '')) {
      case 'Dashboard':
        return shouldShowDashboard ? (
          <Dashboard
            {...{ isCompanyAdmin, userId: loggedInUserID }}
            projectId={id}
          />
        ) : (
          <Redirect to={`/admin/studies/${id}/${contents[0].pageLink}`} />
        );
      case 'Tasks':
        return (
          <Tasks
            projectId={id}
            {...{ isCompanyAdmin, userId: loggedInUserID }}
          />
        );
      case 'Deliverables':
        return <Deliverables projectId={id} clientId={clientId} />;
      case 'Backlog': {
        return (
          <>
            <Helmet
              title={`${
                isClient ? 'Client' : 'Strike'
              } Portal - Studies - Backlog`}
            />
            {!isMobile && (
              <EpicsList
                collapse={collapse}
                setCollapse={setCollapse}
                id={projectId}
                collapsePreference={collapsePreference}
              />
            )}
            <Col
              md={!isMobile && 'auto'}
              className={classNames({
                [classes['content-body']]: !isMobile,
                [classes.fullWidth]: collapse,
              })}
            >
              <Backlogs
                projectId={id}
                isSectionEditAllowed={isSectionEditAllowed}
                isAddStoryAllowed={isAddStoryAllowed}
                isStoryReorderAllowed={isStoryReorderAllowed}
                isStoryEditAllowed={isStoryEditAllowed}
                isStoryDeleteAllowed={isStoryDeleteAllowed}
                isStoryViewAllowed={isStoryViewAllowed}
              />
            </Col>
          </>
        );
      }
      case 'Team': {
        return shouldShowTeam ? (
          <div className="w-100">
            <Helmet
              title={`${
                isClient ? 'Client' : 'Strike'
              } Portal - Studies - Team`}
            />
            <Users
              projectId={projectId}
              companyId={companyId}
              inviteClient={true}
            />
          </div>
        ) : (
          <Redirect to={`/admin/studies/${id}/${contents[0].pageLink}`} />
        );
      }
      case 'Documents':
        return <ClientStudyDocuments clientId={clientId} />;

      case 'Boards': {
        return shouldShowBoard || isMockUp ? (
          <Kanban
            projectId={projectId}
            isAllowedEdit={isAllowedEdit}
            isMockUp={isMockUp}
            {...rest}
          />
        ) : (
          <Redirect to={`/admin/studies/${id}/${contents[0].pageLink}`} />
        );
      }

      default: {
        return (
          <Col>
            <div className="d-flex justify-content-center align-items-center flex-column mw-100">
              <img
                className={classNames(
                  classes['image-max-width'],
                  'w-100 h-100'
                )}
                src={ViewProjectImg}
                alt="View Study"
              />
              <h4 className="display-4 text-center mb-0">
                Currently under collaboration!
              </h4>
              <p className="text-center pl-2 pr-2">
                We’re currently building a simpler way to create and
                collaborate.
              </p>
            </div>
          </Col>
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeContent,
    collapse,
    collapsePreference,
    contents,
    id,
    isAddStoryAllowed,
    isAllowedEdit,
    isGetProjectLoading,
    isMobile,
    isMockUp,
    isSectionEditAllowed,
    isStoryDeleteAllowed,
    isStoryEditAllowed,
    isStoryReorderAllowed,
    isStoryViewAllowed,
    projectId,
    rest,
    shouldShowBoard,
    shouldShowDashboard,
    shouldShowTeam,
  ]);
  if (!isUserAllowedViewProject && page && !isMockUp) {
    showUnAuhtorizedError();
  }

  // if (projectId === toNumber(id) && !isGetProjectLoading && !activeContent) {
  //   return <Redirect to={`/admin/studies/${id}/${contents[0].pageLink}`} />;
  // }

  if (
    isSelectedProjectError &&
    reducerId === id &&
    isUserAllowedViewProject &&
    !isMockUp
  ) {
    NotificationHandler.open({
      message:
        selectedProjectErrorMessage || 'You do not have access to that page',
      operation: 'failure',
    });
    return <Redirect to="/admin/dashboard" />;
  }
  if (!page) {
    return <Redirect to={`/admin/studies/${id}/dashboard`} />;
  }

  return (
    <div className={classNames(classes['project-profile'])}>
      <Navbar
        className={classNames(
          'navbar-top navbar-expand p-0',
          classes['project-navbar'],
          { [classes['project-navbar-timer-on']]: runningTime && !isMockUp }
        )}
      >
        {/* {isGetProjectLoading && <Loading wrapperClass={classes.loading} />} */}

        <Container
          fluid
          className={classNames(classes['action-bar'], 'flex-wrap')}
        >
          <>
            <CardsHeader
              name={currentStudy}
              parentName={
                getStudyName(projectName).length > 25
                  ? `${getStudyName(projectName).slice(0, 24)}...`
                  : getStudyName(projectName)
              }
              currentPage={
                projectName.length > 25
                  ? `${projectName.slice(0, 24)}...`
                  : projectName
              }
              parentLink={`/admin/accounts/${clientId}/overview`}
              studyName={studyName}
            />
            <Nav id="projectNavigationButtons">
              {isMobile ? (
                <NavigationDropdown
                  options={contents}
                  activeContent={activeContent}
                  handleOptionClick={onOptionClick}
                />
              ) : (
                <DesktopNavigation
                  options={contents}
                  handleOptionClick={onOptionClick}
                  activeContent={activeContent}
                />
              )}
            </Nav>
          </>
          {totalSelectedStoriesCount > 0 && activeContent.name === 'Backlog' && (
            <div
              className={classNames(
                'bg-primary shadow-lg text-white w-100 border-primary rounded-bottom p-3 ml-4',
                classes['stories-action-container']
              )}
            >
              <Button
                leftIcon={<i className="fas fa-check-square" />}
                className="shadow-none mr-4"
                color="primary"
                outline={false}
                size="sm"
                onClick={() => dispatch({ type: Types.DESELECT_ALL_STORIES })}
              >
                Deselect({totalSelectedStoriesCount})
              </Button>
              {isMassUpdateAllowed && (
                <>
                  <SelectAsync
                    url={`/initiatives/${projectId}/backlogSections/list/dropdown`}
                    id="mass-update-section-move"
                    className="d-inline-block"
                    placeholder="Search Sections"
                    popperClassName={classes['selector-dropdown']}
                    onChange={data => {
                      updateSelectedStories('backlog_section_id', data.id);
                      analyticsSendEvent({
                        action:
                          analyticsConstants.action.multiselect_move_section,
                      });
                    }}
                  >
                    <Button
                      leftIcon={<i className="fas fa-stream" />}
                      className="shadow-none mr-4"
                      color="primary"
                      outline={false}
                      size="sm"
                    >
                      Move Section
                    </Button>
                  </SelectAsync>
                  <SelectAsync
                    url={`/initiatives/${projectId}/epics/dropdown`}
                    id="mass-update-epic-assign"
                    className="d-inline-block"
                    placeholder="Search Phases"
                    popperClassName={classes['selector-dropdown']}
                    onChange={data => {
                      updateSelectedStories('epic_id', data.id);
                      analyticsSendEvent({
                        action:
                          analyticsConstants.action.multiselect_assign_epic,
                      });
                    }}
                  >
                    <Button
                      leftIcon={<i className="fas fa-book" />}
                      className="shadow-none mr-4"
                      color="primary"
                      outline={false}
                      size="sm"
                    >
                      + Assign Epic
                    </Button>
                  </SelectAsync>
                  <UncontrolledDropdown direction="down" size="sm">
                    <DropdownToggle
                      color="primary"
                      caret={false}
                      className={classNames('shadow-none mr-4')}
                    >
                      <i className="fas fa-sync-alt mr-2" />
                      Update Status
                    </DropdownToggle>
                    <DropdownMenu className={classes['status-dropdown']}>
                      {orderBy(statuses, ['id'], 'asc').map((item, index) => (
                        <DropdownItem
                          key={index}
                          className="d-flex w-100 align-items-center"
                          onClick={() => {
                            updateSelectedStories('status_id', item.id);
                            analyticsSendEvent({
                              action:
                                analyticsConstants.action
                                  .multiselect_update_status,
                            });
                          }}
                        >
                          <Badge className="badge-dot m-0" color="">
                            <i
                              style={{
                                // TODO: this will be used from API so do not worry about static color
                                backgroundColor: item.color,
                              }}
                            />
                          </Badge>
                          <h5 className="font-weight-normal text-muted mb-0">
                            {item.status}
                          </h5>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              )}
              {isMassDeleteAllowed && (
                <Button
                  leftIcon={<i className="fas fa-trash" />}
                  className="shadow-none mr-4"
                  color="primary"
                  outline={false}
                  size="sm"
                  onClick={deleteAction}
                >
                  Delete
                </Button>
              )}
            </div>
          )}
        </Container>
      </Navbar>
      <Row noGutters className={classes.mainWrapper}>
        {renderContent()}
      </Row>
    </div>
  );
};

StrikeStudyDashboard.propTypes = {
  isMockUp: PropTypes.bool,
};
StrikeStudyDashboard.defaultProps = {
  isMockUp: false,
  mockUpPage: 'boards',
};

export default StrikeStudyDashboard;
