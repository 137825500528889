import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment';

import classes from './PartnerTracker.module.scss';
import ReferralSubmitted from 'assets/img/icons/status/referral-submitted.svg';
import StatusChange from 'assets/img/icons/status/status-change.svg';
import LeadBecameClient from 'assets/img/icons/status/lead-became-a-client.svg';
import ClientBecamePartner from 'assets/img/icons/status/client-became-a-partner.svg';
import Disqualified from 'assets/img/icons/status/disqualified.svg';
import ClientRequestedReferralAgreement from 'assets/img/icons/status/client-requested-a-referral-agreement.svg';
import NoDataPlaceholder from 'views/pages/PartnerTracker/NoDataPlaceholder';
import { daysFromNow } from 'helpers/times';

const statusIcons = {
  ReferralSubmitted: ReferralSubmitted,
  LeadStatusChange: StatusChange,
  lead_became_a_client: LeadBecameClient,
  LeadDisqualified: Disqualified,
  client_requested_a_referral_agreement: ClientRequestedReferralAgreement,
  client_became_a_partner: ClientBecamePartner,
};

const PartnerActivity = ({ referralActivities = [] }) => {
  return (
    <Card className={classes.activityCard}>
      <CardHeader>
        <h3 className="mb-0">Recent Activity</h3>
      </CardHeader>
      <CardBody className={classes.recentActivities}>
        {referralActivities.length > 0 ? (
          referralActivities
            .sort((a, b) => {
              return new Date(b.moment) - new Date(a.moment);
            })
            .map(({ type, message, time }, index) => {
              return (
                <div key={index} className={classes.activity}>
                  <div>
                    <img src={statusIcons[type]} alt="status-img" />
                  </div>
                  <div className={classes.content}>
                    <p className={classes.title}>{message}</p>
                    <p className={classes.timestamp}>
                      {daysFromNow(moment(time))}
                    </p>
                  </div>
                </div>
              );
            })
        ) : (
          <NoDataPlaceholder showEmptyStateHeader={false} />
        )}
      </CardBody>
    </Card>
  );
};

export default PartnerActivity;
