import Request from './request';
import { store } from 'store/index';
import get from 'lodash/get';
import axios from 'axios';

export const fetchYears = async companyId => {
  const configs = {
    url: 'financial-years',
    method: 'GET',
  };
  if (companyId) {
    configs.params = {
      company_id: companyId,
    };
  }
  return Request.call(configs);
};

export const fetchDocTypesByYear = async (year, companyId) => {
  const configs = {
    url: `financial-years/${year}/document-types`,
    method: 'GET',
  };
  if (companyId) {
    configs.params = {
      company_id: companyId,
    };
  }
  return Request.call(configs);
};

export const getDocumentActivities = async (id, page) => {
  return Request.call({
    url: `/document-types/${id}/activities`,
    method: 'GET',
    params: {
      page,
    },
  });
};

export const uploadDocument = async (documentType, data) => {
  return Request.call({
    url: `document-types/${documentType}/upload`,
    method: 'POST',
    data,
  });
};

export const downloadDoc = async id => {
  const storeData = store.getState();
  const token = get(storeData, 'auth.user.auth_token', null);

  // Using axios here, as using Request.call allows to download the file but content is broken/corrupted
  // If we use Request.call content of file gets undefined in text file, images are broken
  return axios({
    url: `${process.env.REACT_APP_API_URL}/media/${id}/download`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const previewDocument = async documentId => {
  return Request.call({
    url: `/media/${documentId}/preview`,
    method: 'GET',
  });
};

export const submitYear = async (year, data) => {
  return Request.call({
    url: `/financial-years/${year}/document-types/submit`,
    method: 'POST',
    data,
  });
};

export const verifyYear = async (year, data) => {
  return Request.call({
    url: `/financial-years/${year}/document-types/verify`,
    method: 'POST',
    data,
  });
};

export const undoVerifyYear = async (year, data) => {
  return Request.call({
    url: `/financial-years/${year}/document-types/undo-verify`,
    method: 'POST',
    data,
  });
};

export const syncDocument = async (id, type) => {
  const url = type === 'codat' ? `/codat/sync/${id}` : `/finch/sync/${id}`;
  return Request.call({
    url,
    method: 'POST',
  });
};

export const submitMissingYearInfoDocument = async data => {
  return Request.call({
    url: `document-types/submit-info`,
    method: 'POST',
    data,
  });
};

export const fetchDocumentStatus = async () => {
  return Request.call({
    url: `/document-status-list`,
    method: 'GET',
  });
};

export const fetchDocumentStudyTypes = async initiative_id => {
  return Request.call({
    url: `/study-document-types?initiative_id=${initiative_id}`,
    method: 'GET',
  });
};

export const uploadStudyDocument = async data => {
  return Request.call({
    url: `study-document/upload`,
    method: 'POST',
    data,
  });
};
