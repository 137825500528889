import Types from 'store/types/dealDeskTypes';
import {
  getDealDeskById,
  getDealDesks,
  getDealDeskStatuses,
  postUpdateDealDeskById,
  getDealDeskActivitiesById,
  postUpdateStatusDealDeskById,
  getDealDeskUsers,
  getContractDeatails,
  getCommentsDealDesk,
  postAddCommentDealDesk,
  postReadCommentsDealDesk,
  getOptions as getOptionsApi,
  getDealDeskReferral,
  addRelatedEntityDealDesk,
  deleteRelatedEntityDealDesk,
} from 'api/dealDeskApi';
import { NotificationHandler } from 'components/Notifications';
import { get } from 'lodash';

export const callGetListDealDesks = filter => {
  return async dispatch => {
    dispatch({
      type: Types.DEALDESKS_LOADING,
    });

    try {
      const resp = await getDealDesks(filter);
      if (resp.status === 1) {
        dispatch({
          type: Types.DEALDESKS_SUCCESS,
          status: resp.status,
          data: resp.data,
          meta: resp.meta,
          links: resp.links,
        });
      } else {
        dispatch({
          type: Types.DEALDESKS_ERROR,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DEALDESKS_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const callGetListDealDeskRefferal = filter => {
  return async dispatch => {
    dispatch({
      type: Types.DEALDESKS_REFERRAL_LOADING,
    });

    try {
      const resp = await getDealDeskReferral(filter);
      if (resp.status === 1) {
        dispatch({
          type: Types.DEALDESKS_REFERRAL_SUCCESS,
          status: resp.status,
          data: resp.data,
          meta: resp.meta,
          links: resp.links,
        });
      } else {
        dispatch({
          type: Types.DEALDESKS_REFERRAL_ERROR,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DEALDESKS_REFERRAL_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const getOptions = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_OPTION_LISTS_INPROGRESS });
    try {
      const resp = await getOptionsApi();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_OPTION_LISTS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_OPTION_LISTS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_OPTION_LISTS_FAILURE,
        message: error,
      });
    }
  };
};

export const callGetDealDeskById = (id, path = 'deal-desk') => {
  return async dispatch => {
    dispatch({
      type: Types.DEALDESK_DETAIL_LOADING,
    });

    try {
      const resp = await getDealDeskById(path, id);
      if (resp.status === 1) {
        dispatch({
          type: Types.DEALDESK_DETAIL_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      } else {
        dispatch({
          type: Types.DEALDESK_DETAIL_ERROR,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DEALDESK_DETAIL_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const callGetListStatuses = () => {
  return async dispatch => {
    dispatch({
      type: Types.DEALDESK_STATUSES_LOADING,
    });

    try {
      const resp = await getDealDeskStatuses();
      if (resp.status === 1) {
        dispatch({
          type: Types.DEALDESK_STATUSES_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      } else {
        dispatch({
          type: Types.DEALDESK_STATUSES_ERROR,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DEALDESK_STATUSES_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const callPostUpdateDealDeskById = (id, data, path = 'deal-desk') => {
  return async dispatch => {
    const resp = await postUpdateDealDeskById(id, data, path);

    if (resp.status === 1) {
      await dispatch(callGetDealDeskById(id, path));
    }

    return resp;
  };
};

export const callGetDealDeskActivitiesById = (id, path, params) => {
  return async dispatch => {
    dispatch({
      type: Types.DEALDESK_DETAIL_ACTIVITIES_LOADING,
    });

    try {
      const resp = await getDealDeskActivitiesById(id, path, params);
      if (resp.status === 1) {
        dispatch({
          type: Types.DEALDESK_DETAIL_ACTIVITIES_SUCCESS,
          status: resp.status,
          data: resp.data,
          meta: resp.meta,
          links: resp.links,
          params,
        });
      } else {
        dispatch({
          type: Types.DEALDESK_DETAIL_ACTIVITIES_ERROR,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DEALDESK_DETAIL_ACTIVITIES_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const callPostUpdateStatusDealDeskById = (
  id,
  data,
  path = 'deal-desk'
) => {
  return async dispatch => {
    const resp = await postUpdateStatusDealDeskById(id, data, path);

    if (resp.status === 1) {
      await dispatch(callGetDealDeskById(id, path));
    } else {
      NotificationHandler.open({
        operation: 'failure',
        title: ' ',
        message: resp.message,
      });
    }

    return resp;
  };
};

export const callGetListUsers = () => {
  return async dispatch => {
    dispatch({
      type: Types.DEALDESK_USERS_LOADING,
    });

    try {
      const resp = await getDealDeskUsers();
      if (resp.status === 1) {
        dispatch({
          type: Types.DEALDESK_USERS_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      } else {
        dispatch({
          type: Types.DEALDESK_USERS_ERROR,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DEALDESK_USERS_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const callGetContractDeatails = (id, type = 'deal-desk') => {
  return async dispatch => {
    dispatch({
      type: Types.GET_CONTRACT_DETAILS_LOADING,
    });

    try {
      const resp = await getContractDeatails(id, type);

      if (resp.status !== 0) {
        dispatch({
          type: Types.GET_CONTRACT_DETAILS_SUCCESS,
          status: 1,
          data: resp,
        });
        return {
          status: 1,
          data: resp,
        };
      } else {
        dispatch({
          type: Types.GET_CONTRACT_DETAILS_ERROR,
          status: resp.status,
          message: resp.message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message: resp.message,
        });
        return {
          status: resp.status,
        };
      }
    } catch (error) {
      dispatch({
        type: Types.GET_CONTRACT_DETAILS_ERROR,
        message: 'Something went wrong',
        status: 0,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
      return {
        status: 0,
      };
    }
  };
};
export const callGetListComments = (id, parent_type = 'Initiative') => {
  return async dispatch => {
    dispatch({
      type: Types.DEALDESK_COMMENTS_LOADING,
    });

    try {
      const resp = await getCommentsDealDesk(id, parent_type);
      if (resp.status === 1) {
        dispatch({
          type: Types.DEALDESK_COMMENTS_SUCCESS,
          status: resp.status,
          data: resp.data.reverse(),
        });
      } else {
        dispatch({
          type: Types.DEALDESK_COMMENTS_ERROR,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DEALDESK_COMMENTS_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const callPostAddCommentDealDesk = (
  id,
  message,
  subject = null,
  target = null,
  parent_type = 'Initiative'
) => {
  return async dispatch => {
    const resp = await postAddCommentDealDesk(
      id,
      message,
      subject,
      target,
      parent_type
    );

    if (resp.status === 1) dispatch(addNewComment(resp.data));

    return resp;
  };
};

export const callPostMarkReadCommentDealDesk = ids => {
  return async dispatch => {
    await postReadCommentsDealDesk(ids);

    dispatch({
      type: Types.DEALDESK_READ_ALL_COMMENT,
    });
  };
};

export const addNewComment = comment => {
  return async dispatch => {
    dispatch({
      type: Types.DEALDESK_ADD_NEW_COMMENT,
      data: comment,
    });
  };
};
export const addRelatedEntity = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.DEALDESK_RELATED_ENTITY_LOADING,
    });

    try {
      const resp = await addRelatedEntityDealDesk(id, data);
      if (resp.status === 1) {
        if (resp.status === 1) {
          await dispatch(callGetDealDeskById(id));
        }
        dispatch({
          type: Types.DEALDESK_RELATED_ENTITY_SUCCESS,
          status: resp.status,
          data: resp.data.reverse(),
        });
      } else {
        dispatch({
          type: Types.DEALDESK_RELATED_ENTITY_ERROR,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DEALDESK_RELATED_ENTITY_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const deleteRelatedEntity = (id, relatedEntityId) => {
  return async dispatch => {
    dispatch({
      type: Types.DEALDESK_RELATED_ENTITY_LOADING,
    });

    try {
      const resp = await deleteRelatedEntityDealDesk(relatedEntityId);
      if (resp.status === 1) {
        if (resp.status === 1) {
          await dispatch(callGetDealDeskById(id));
        }
        dispatch({
          type: Types.DEALDESK_RELATED_ENTITY_SUCCESS,
          status: resp.status,
          data: resp.data.reverse(),
        });
      } else {
        dispatch({
          type: Types.DEALDESK_RELATED_ENTITY_ERROR,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DEALDESK_RELATED_ENTITY_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};
