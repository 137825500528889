import React, { useEffect, useState } from 'react';
import { useLocation, Redirect, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import CardsHeader from 'components/Headers/CardsHeader';
import classNames from 'classnames';
import compact from 'lodash/compact';
import find from 'lodash/find';
import history from 'helpers/history';
import ConnectorSvg from 'assets/img/brand/Connector.svg';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import classes from './ClientProfile.module.scss';
import {
  fetchDeliverablesList,
  getDeliverable,
  getClientProfile,
  createAgreement,
  deleteAttachedFile,
  updateAgreement,
  getTimeSpent,
  getViewedProjects,
  getRecentTimeEntries,
  updateClient,
  fetchClientActivities,
  fetchTeamMembers,
} from 'store/actions/clientProfile';
import { createProject } from 'store/actions/projects';
import AgreementForm from './Agreement.Form';
import ProjectForm from '../Project/Project.Form';
import Loading from 'components/Loading';
import AccountOverview from 'views/pages/ClientProfile/AccountOverview';
import AboutCompany from 'views/pages/ClientProfile/AboutCompany';
import CreditBank from 'views/pages/ClientProfile/CreditBank';
import KickOffCall from 'views/pages/ClientProfile/KickOffCall';
import useBreakPoint from 'helpers/useBreakPoint';
import {
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import TaskModal from 'views/pages/dashboards/TaskModal';
import { permissions, useAccess } from 'helpers/permission';
import isEqual from 'lodash/isEqual';
import NotificationHandler from 'components/Notifications/NotificationHandler';

import analytics, { analyticsConstants } from 'helpers/analytics';
import UpgradeAlert from 'components/UpgradeAlert';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import queryString from 'query-string';
import toNumber from 'lodash/toNumber';
import { fetchInviteClientRoles } from 'store/actions/users';
import ClientStudyDocuments from './ClientStudyDocuments';

const ClientProfile = props => {
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.clients,
      ...rest,
    });
  };
  const clientId = get(props, 'match.params.clientId');

  const useGetValue = (field, defaultValue) =>
    useSelector(
      ({ clientProfile }) => get(clientProfile, field, defaultValue),
      isEqual
    );

  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const [storyId, setStoryId] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(0);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [isModal, setModal] = useState(false);
  const [isCreateProjectModal, setCreateProjectModal] = useState(false);
  const [editValues, setEditValues] = useState(null);
  const [sowInitialValues, setSowInitialValues] = useState(null);
  const [viewMode, setViewMode] = useState(null);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const [isTaskModalOpen, setIsTaskModal] = useState(false);

  useEffect(() => {
    if (queryProps.story) {
      setStoryId(queryProps.story);
      setIsTaskModal(true);
    }
  }, [queryProps.story]);

  useEffect(() => {
    dispatch(fetchInviteClientRoles());
  }, [dispatch]);

  const isTablet = useBreakPoint('md', 'down');
  const isUserAllowedCreateProject = useAccess(permissions.CREATE_PROJECTS);
  const contents = compact([
    {
      name: 'Account Overview',
      iconClassName: classNames('ni ni-chart-bar-32', classes['invert-icon']),
      gradientColor: 'green',
      pageLink: 'overview',
    },
    {
      name: 'About Company',
      iconClassName: 'ni ni-badge',
      gradientColor: 'blue',
      pageLink: 'about',
      id: 'backlogTab',
    },
    {
      name: 'Credit Bank',
      iconClassName: 'ni ni-building',
      gradientColor: 'info',
      pageLink: 'credit-bank',
    },
    {
      name: 'Kickoff Call Questions',
      iconClassName: 'ni ni-bullet-list-67',
      gradientColor: 'red',
      pageLink: 'kickoff-call',
    },
  ]);
  const navPage = get(params, 'page', '');
  const activeContent = find(contents, obj => obj.pageLink === navPage);

  useEffect(() => {
    setIsDataLoading(7);
    dispatch(
      getClientProfile(clientId, () => {
        dispatch(getViewedProjects(clientId)).finally(() =>
          setIsDataLoading(loading => loading - 1)
        );
        dispatch(getRecentTimeEntries(clientId)).finally(() =>
          setIsDataLoading(loading => loading - 1)
        );
        dispatch(getTimeSpent(clientId)).finally(() =>
          setIsDataLoading(loading => loading - 1)
        );
        dispatch(fetchDeliverablesList(clientId)).finally(() =>
          setIsDataLoading(loading => loading - 1)
        );
        dispatch(fetchClientActivities(clientId)).finally(() =>
          setIsDataLoading(loading => loading - 1)
        );
        dispatch(fetchTeamMembers(clientId)).finally(() =>
          setIsDataLoading(loading => loading - 1)
        );
      })
    ).finally(() => setIsDataLoading(loading => loading - 1));
  }, [clientId, dispatch]);

  const handleGetDeliverable = id => {
    dispatch(getDeliverable(id));
  };

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  useEffect(() => {
    /*
     When component is mounted for first time we are making sure we have shouldOpenPopup flag set,
     then we are opening popup and after then pushing the same route with updated state,
     so if user refreshes the page it won't open the alert this time
    */

    if (get(props, 'location.state.shouldOpenPopup')) {
      setTimeout(() => {
        AlertPopupHandler.openCustom({
          onConfirm: openModal,
          onCancel: () => {
            handleTourClear();
          },
          confirmBtnText: 'Add Agreement',
          title: 'Success!',
          text:
            'Do you have a contract or scope of work you would like to attach to this client?',
          cancelBtnBsStyle: 'secondary',
          confirmBtnBsStyle: 'info border-0',
          cancelBtnText: 'No, Thank You',
          customIcon: (
            <div className={classes.customIconWrapper}>
              <img src={ConnectorSvg} alt="Connector" />
            </div>
          ),
        });

        // This is to update the flag once we have opened the popup when we created the client
        history.push(`/admin/accounts/${props.match.params.clientId}`, {
          shouldOpenPopup: false,
        });
      }, 1000);
    }
    analyticsSendEvent({
      action: analyticsConstants.action.view_client_profile,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientProfile = useGetValue('clientProfile');
  const reducerId = useGetValue('clientProfile.id');

  const clientProfileLoading = get(clientProfile, 'isInProgress', false);
  const client = get(clientProfile, 'data', {});

  const isClientError = get(clientProfile, 'isError', false);
  const clientProfileMessage = get(clientProfile, 'message');
  const openModal = () => {
    setModal(true);
  };

  const handleTourClear = () => {
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'client_creation' &&
      !quickStartTour.step
    ) {
      if (quickStartTour) {
        dispatch(
          setUserPreference({
            ...onBoardingTour,
            value: {
              ...get(onBoardingTour, 'value', {}),
              status: 'Active',
            },
          })
        );
      }
      dispatch(
        updateActiveTourStatus({
          activeTour: null,
          step: null,
          isRestarted: false,
        })
      );
    }
  };

  const closeModal = () => {
    setModal(false);
    setViewMode('');
    setEditValues(null);
  };

  const confirmCreateProject = () => {
    setCreateProjectModal(true);
  };

  const cancelCreateProject = () => {
    setCreateProjectModal(false);
    setSowInitialValues(null);
  };

  const submitValues = async values => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    let agreement = {};
    const data = new FormData();
    data.append('name', values.name);
    if (viewMode === 'edit') {
      // when editing if link is blank we can send it
      data.append('link', values.link || '');
      data.append(
        'agreement_type_id',
        values.agreement_type_id ? values.agreement_type_id.id : ''
      );

      if (values.attachment) {
        data.append('attachment', values.attachment);
      }
      if (
        (!values.attachment || (values.attachment && !values.attachment.id)) &&
        editValues.attachment &&
        editValues.attachment.id
      ) {
        await deleteAttachedFile(editValues.attachment.id);
      }
      data.append('_method', 'PUT');
      agreement = await dispatch(
        updateAgreement(editValues.id, data, { page, sort, q: '' }, clientId)
      );
      if (agreement.errorStatus === SHOW_UPGRADE_ALERT_CODE) {
        UpgradeAlert.showStorageLimit();
        return 0;
      }
    } else {
      // when creating we should not send parameters which are not present
      if (values.link) {
        data.append('link', values.link);
      }
      if (values.agreement_type_id && values.agreement_type_id.id) {
        data.append('agreement_type_id', values.agreement_type_id.id);
      }
      if (values.attachment) {
        data.append('attachment', values.attachment);
      }
      analyticsSendEvent({ action: analyticsConstants.action.add_agreement });
      agreement = await dispatch(
        createAgreement(data, { page, sort, q: '' }, clientId)
      );
      if (agreement.errorStatus === SHOW_UPGRADE_ALERT_CODE) {
        UpgradeAlert.showStorageLimit();
        return 0;
      }
    }
    setModal(false);
    handleTourClear();
    // When agreement type is SOW setting details for create Project flow
    if (
      isUserAllowedCreateProject &&
      agreement.status &&
      agreement.data.agreement_type &&
      agreement.data.agreement_type.type === 'Scope of Work'
    ) {
      setSowInitialValues({
        client,
        contract: { id: agreement.data.id, name: agreement.data.name },
      });
      AlertPopupHandler.openCustom({
        onConfirm: confirmCreateProject,
        onCancel: cancelCreateProject,
        confirmBtnText: 'Create a Study',
        title: 'Success!',
        text: 'Would you like to create a project for this Scope of Work?',
        cancelBtnBsStyle: 'secondary',
        confirmBtnBsStyle: 'info border-0',
        cancelBtnText: 'No, Thank You',
        customIcon: (
          <div className={classes.customIconWrapper}>
            <img src={ConnectorSvg} alt="Connector" />
          </div>
        ),
      });
    }
    return agreement.status;
  };

  const submitProjectValues = async values => {
    const { status, data: project, errorStatus } = await dispatch(
      createProject(values, {}, false)
    );
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showProjectsLimit();
      return;
    }
    if (status) {
      history.push(`/admin/studies/${project.id}`);
    }
  };

  if (clientProfileLoading) {
    return <Loading wrapperClass={classes.profileLoader} />;
  }

  if (isClientError && clientId === reducerId) {
    NotificationHandler.open({
      message: clientProfileMessage,
      operation: 'failure',
    });
    return <Redirect to="/admin/dashboard" />;
  }

  const autoSubmitForm = values => {
    dispatch(updateClient(clientId, values));
  };

  const handleClientTeamAction = params => {
    dispatch(fetchTeamMembers(clientId, params));
  };

  return (
    <>
      <AgreementForm
        isModalOpen={isModal}
        submitValues={submitValues}
        closeModal={closeModal}
        viewMode={viewMode}
        editValues={editValues}
      />
      {isCreateProjectModal && (
        <ProjectForm
          isModalOpen={isCreateProjectModal}
          submitValues={submitProjectValues}
          closeModal={cancelCreateProject}
          isFromSow={true}
          sowInitialValues={sowInitialValues}
        />
      )}
      {isDataLoading ? (
        <Loading wrapperClass={classes.loading} />
      ) : (
        <div className={classes.clientProfile}>
          <div className={classes.header}>
            <CardsHeader
              title={client.name}
              parentName="Clients"
              parentLink="/admin/accounts"
              childName={client.name}
              childLink={`/admin/accounts/${clientId}/overview`}
              activeContent={
                navPage !== 'documents' ? activeContent.name : 'All Documents'
              }
            />
          </div>
          {navPage === 'overview' && (
            <AccountOverview
              clientId={clientId}
              clientName={client.name}
              companyId={client.company_id}
              handleClientTeamAction={handleClientTeamAction}
              onStoryClick={url => {
                const params = queryString.parseUrl(url);
                setStoryId(get(params, 'query.story'));
                setIsTaskModal(true);
              }}
            />
          )}
          {navPage === 'about' && <AboutCompany submitForm={autoSubmitForm} />}
          {navPage === 'credit-bank' && (
            <CreditBank submitForm={autoSubmitForm} />
          )}
          {navPage === 'kickoff-call' && (
            <KickOffCall submitForm={autoSubmitForm} />
          )}
          {navPage === 'documents' && (
            <ClientStudyDocuments clientId={clientId} />
          )}
        </div>
      )}
      {isTaskModalOpen && (
        <TaskModal
          storyId={toNumber(storyId)}
          isOpen={isTaskModalOpen}
          closeModal={() => {
            setIsTaskModal(false);
            history.push(`?`);
          }}
          currentTab={'comment'}
        />
      )}
    </>
  );
};

export default ClientProfile;
