import React, { useState, useMemo, useEffect } from 'react';
import cs from 'classnames';
import { components } from 'react-select';
import { Form, Col, Row, Collapse } from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { FormikInput } from 'components/FormFields/Input';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import Button from 'components/Button';
import { roles as accessRoles } from 'helpers/permission';
import { updateActiveTourStatus } from 'store/actions/profile';
import classes from './users.module.scss';
import RSelect from 'components/FormFields/RSelect';
import { remove } from 'lodash';
import BlockedAbilities from '../MacroOnBoarding/Invitation/BlockedAbilities';
import AllowedAbilites from '../MacroOnBoarding/Invitation/AllowedAbilites';
import ToggleCheckBox from 'components/FormFields/ToggleCheckBox';
import moment from 'moment';
import Avatar from 'components/Avatar';
import { getDefaultPhotoThumbnail } from 'helpers/userThumbnail';

const OptionComponent = ({
  innerProps = {},
  additionalClassName = '',
  data,
}) => {
  return (
    <div
      {...innerProps}
      className={cs(
        additionalClassName,
        'd-flex align-items-center',
        classes.option,
        classes[data.styleClass]
      )}
    >
      <div
        className={cs(
          'mr-3 p-3 d-flex align-items-center justify-content-center',
          classes.icon
        )}
      >
        {data.Icon}
      </div>
      <div>
        <h4 className={cs('h4', classes.title)}>{data.title}</h4>
        <p className={`text-xs font-weight-400 m-0 ${classes.description}`}>
          {data.description}
        </p>
      </div>
    </div>
  );
};

const InviteUserForm = ({
  viewMode,
  closeModal,
  submitValues,
  isModalOpen,
  editValues,
  quickStartTour,
  isLoading,
  selectionChange,
  setSelectionChange,
  inviteClient = false,
  title = 'Invite Members',
  confirmBtnTitle = 'Invite',
  cancelBtnTitle = 'Cancel',
  resendInviteAction,
  cancelInviteAction,
  isEdit = false,
}) => {
  const dispatch = useDispatch();
  let isInviting;
  const [btnClick, setBtnClick] = useState('');
  const userid = editValues && editValues.userid;
  const { data } = useSelector(state => state.clientProfile.clientTeam);
  const userData = data?.data?.find(element => element.id === userid);
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const userRoles = useSelector(({ auth }) => get(auth, 'user.roles', []));
  const status = get(userData, 'status.status', '');
  const isFinancialSME = userRoles?.includes('Financial_SME');

  const roles = useSelector(user => {
    isInviting = get(user.user.inviteUsersState, 'isInProgress', false);
    let rolesData;
    if (inviteClient) {
      const updatedRoles = get(user.user.clientRoleOptions, 'data.data', []);
      if (isFinancialSME) {
        const rolesDataClone = [...updatedRoles];
        remove(rolesDataClone, { name: 'Client_Administrator' });

        rolesData = rolesDataClone;
      } else {
        rolesData = updatedRoles;
      }
    } else {
      rolesData = get(user.user.roleOptions, 'data.data', []);
    }
    rolesData && rolesData.sort((a, b) => a.id - b.id);
    return rolesData.map(role => {
      switch (role.name) {
        case 'Technical_SME':
          return {
            ...role,
            Icon: <i className={cs('fas fa-user')} />,
            styleClass: 'administrator',
            selected: false,
            abilities: {
              allowed: [
                'View, upload, and edit any supporting and contemporaneous documents.',
                'Collaborate with other users by leaving comments.',
                'View, upload, and edit documents only in projects assigned by the Master Collaborator.',
              ],
              denied: [
                'View, upload, or edit any financial documents such as W-2s, company’s financial reports.',
                'View, upload, or edit user information.',
                'Remove user from the portal.',
              ],
            },
          };
        case 'Financial_SME':
          return {
            ...role,
            Icon: (
              <div className="d-flex justify-center">
                <i className={cs('fas fa-solid fa-user textLg')} />
                <i
                  className={cs(
                    'fas fa-solid fa-dollar-sign align-self-start textXs mt--2 ml--1'
                  )}
                />
              </div>
            ),
            styleClass: 'financialCollaborator',
            selected: false,
            abilities: {
              allowed: [
                'View, upload, and edit any supporting and contemporaneous documents.',
                'View, upload, or edit any financial documents such as W-2s, company’s financial reports.',
                'Collaborate with other users by leaving comments.',
                'View, upload, and edit documents only in projects assigned by the Master Collaborator.',
              ],
              denied: [
                'View, upload, or edit user information.',
                'Remove user from the portal.',
              ],
            },
          };
        case 'Client_Administrator':
          return {
            ...role,
            Icon: (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <i className={cs('fas fa-solid fa-star textXxs')} />
                <i className={cs('fas fa-solid fa-users textXl')} />
              </div>
            ),
            styleClass: 'masterCollaborator',
            selected: false,
            abilities: {
              allowed: [
                'Do anything in the portal!',
                'View, upload, and edit any financial documents such as tax returns, W-2s, general ledgers, and other sensitive financial documents.',
                'View, upload, and edit any supporting and contemporaneous project documents.',
                'View or edit user information.',
                'Invite or remove user from the portal.',
              ],
              denied: [],
            },
          };
        default:
          return {
            ...role,
            Icon: <i className={cs('fa', role.icon)} />,
          };
      }
    });
  });

  const InviteUserSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    role: Yup.object().required(),
    projects: Yup.array(),
    workflow: Yup.boolean().required(),
  });

  const reInviteSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    role: Yup.object().required(),
  });

  const initialRole = () => {
    let defaultRole;
    if (inviteClient) {
      defaultRole = isFinancialSME ? 'Financial_SME' : 'Client_Administrator';
    } else {
      defaultRole = 'Company_Administrator';
    }
    if (roles.find(x => x.name.includes(defaultRole))) {
      return roles.find(r => r.name === defaultRole);
    }
    return roles.find(r => r.name === accessRoles.BASIC_TEAM_MEMBER);
  };

  const editRole = role => {
    return roles.find(r => r.name === role);
  };

  let initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: initialRole(),
    workflow: true,
    projects: editValues ? editValues.projects : [],
    avatarImageBase64: '',
  };

  if (viewMode === 're-invite') {
    initialValues = {
      ...initialValues,
      firstName: editValues.name.split(' ')[0],
      lastName: editValues.name.split(' ')[1],
      email: editValues ? editValues.email : '',
    };
  }

  if (isEdit) {
    initialValues = {
      ...initialValues,
      firstName: editValues ? editValues.firstName : '',
      lastName: editValues ? editValues.lastName : '',
      email: editValues ? editValues.email : '',
      role: editValues ? editRole(editValues.roles.name) : [],
    };
  }

  const handleCloseModal = () => {
    closeModal();
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'user_invitation'
    ) {
      dispatch(
        updateActiveTourStatus({
          step: 2,
        })
      );
    }
  };

  const handleCloseValidModal = () => {
    setSelectionChange({
      data: null,
      agreeToInvite: false,
      openValidModal: false,
      selectedValue: selectionChange.selectedValue,
    });
  };

  const RenderForm = ({
    handleSubmit,
    isValid,
    handleReset,
    values,
    setFieldValue,
  }) => {
    const [inviteEmail, setInviteEmail] = useState('');
    const [showUserPermission, setShowUserPermission] = useState(false);
    const handleInviteClick = e => {
      setBtnClick('submit');
      if (
        !isEdit &&
        (selectionChange.selectedValue.name ===
          'Client_Financial_Collaborator' ||
          selectionChange.selectedValue.name === 'Client_Master_Collaborator')
      ) {
        e.stopPropagation();
        setInviteEmail(values.email);
        setSelectionChange({
          ...selectionChange,
          openValidModal: true,
          selectedValue: selectionChange.selectedValue,
        });
      } else {
        handleSubmit();
        handleCloseValidModal();
      }
    };

    const userType = values.role.name === 'Client' ? 'client' : 'strike';
    const avatarImg = getDefaultPhotoThumbnail(
      userType,
      `${values.firstName} ${values.lastName}`,
      40
    );

    useEffect(() => {
      setFieldValue('avatarImageBase64', avatarImg);
    }, [avatarImg, setFieldValue]);

    return (
      <>
        <ModalBody className="pb-0">
          <Form
            role="form"
            onSubmit={handleSubmit}
            className={classes.FormData}
          >
            <Row>
              <Col>
                <h5>{isEdit ? 'User Information' : 'First Name'}</h5>
                <FormikInput
                  inputId="usersFirstNameInput"
                  groupClassName="mb-3"
                  name="firstName"
                  placeholder="Enter First Name"
                  type="text"
                  onChange={value => {
                    setFieldValue('firstName', value ? value : '');
                  }}
                />
              </Col>
              <Col className={cs({ [classes.lastNameLabel]: isEdit })}>
                {!isEdit && <h5>Last Name</h5>}
                <FormikInput
                  inputId="usersLastNameInput"
                  groupClassName="mb-3"
                  name="lastName"
                  placeholder="Enter Last Name"
                  type="text"
                  onChange={value => {
                    setFieldValue('lastName', value ? value : '');
                  }}
                />
              </Col>
            </Row>
            {!isEdit && <h5>Email Address</h5>}
            <FormikInput
              inputId="usersEmailInput"
              disabled={viewMode === 're-invite'}
              groupClassName="mb-3"
              name="email"
              placeholder="Enter Email Address"
              type="text"
              onChange={value => {
                setFieldValue('email', value ? value : '');
              }}
            />
            <Row className="flex-nowrap">
              <Col>
                <h5>{isEdit ? 'User Role' : 'Role'}</h5>
              </Col>
            </Row>
            {/* <Row className="flex-nowrap pl-4 mb-4">
              {avatarImg && <Avatar url={avatarImg} />}
            </Row> */}
            <Row>
              <Col>
                <Field name="role">
                  {({ field, form: { touched, errors } }) => {
                    return (
                      <RSelect
                        isSearchable={false}
                        isClearable={false}
                        touched={touched}
                        {...field}
                        options={roles}
                        getOptionLabel={option => (
                          <OptionComponent
                            additionalClassName={cs(classes.textWrap, 'h-100')}
                            data={option}
                          />
                        )}
                        onChange={selectedOption => (
                          setSelectionChange({
                            ...selectionChange,
                            openValidModal: false,
                            selectedValue: selectedOption,
                          }),
                          setFieldValue('role', selectedOption)
                        )}
                        error={get(errors, 'role')}
                        components={{
                          Control: ({ children, ...props }) => {
                            return (
                              <components.Control
                                {...props}
                                className={classes.control}
                              >
                                {children}
                              </components.Control>
                            );
                          },
                          SingleValue: ({ children, ...props }) => {
                            return (
                              <components.SingleValue
                                {...props}
                                className={classes.singleValue}
                              >
                                {children}
                              </components.SingleValue>
                            );
                          },
                          ValueContainer: ({ children, ...props }) => {
                            return (
                              <components.ValueContainer
                                {...props}
                                className={classes.valueContainer}
                              >
                                {children}
                              </components.ValueContainer>
                            );
                          },
                          Option: ({
                            innerProps,
                            data,
                            getStyles,
                            isDisabled,
                            isFocused,
                            isSelected,
                            cx,
                            ...allProps
                          }) => {
                            return (
                              <OptionComponent
                                innerProps={innerProps}
                                additionalClassName={cx({
                                  ...getStyles('option', {
                                    ...allProps,
                                    innerProps,
                                    data,
                                  }),
                                  option: true,
                                  'option--is-disabled': isDisabled,
                                  'option--is-focused': isFocused,
                                  'option--is-selected': isSelected,
                                })}
                                data={data}
                              />
                            );
                          },
                        }}
                      />
                    );
                  }}
                </Field>
              </Col>
            </Row>
            {inviteClient && (
              <>
                <Row>
                  <Col className="ml-auto" xs="auto">
                    <Button
                      id="toggler"
                      color="link"
                      className="p-0 font-weight-normal user-permissions"
                      onClick={() => {
                        setShowUserPermission(
                          currentPermission => !currentPermission
                        );
                      }}
                    >
                      <i
                        className={cs(
                          'fas ml-1 text-xs',
                          `fa-caret-${showUserPermission ? 'up' : 'down'}`
                        )}
                      />{' '}
                      {showUserPermission ? 'Close' : 'View'} User Permissions
                    </Button>
                  </Col>
                </Row>
                <Collapse isOpen={showUserPermission}>
                  <Row
                    className={cs(classes.permissionListContainer)}
                    noGutters
                  >
                    <Col
                      className={cs(
                        'px-3 py-4 text-sm',
                        classes[get(values, 'role.styleClass')]
                      )}
                    >
                      <AllowedAbilites role={values.role} />
                      <BlockedAbilities role={values.role} />
                    </Col>
                  </Row>
                </Collapse>
              </>
            )}
            {!isEdit ? (
              <>
                {inviteClient &&
                  values.role.name === 'Client_Master_Collaborator' && (
                    <Row>
                      <Col
                        className={cs(
                          'd-flex justify-content-between mt-4',
                          classes.workFlowSection
                        )}
                      >
                        <h5>Present the onboarding workflow to this user? </h5>
                        <ToggleCheckBox
                          checkedLabel={'Yes'}
                          unCheckedLabel={'No'}
                          checked={values.workflow}
                          onChange={event => {
                            setFieldValue(
                              'workflow',
                              event.target.checked
                                ? event.target.checked
                                : false
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  )}

                <Row>
                  <Col className="position-relative">
                    <FormikInput
                      name="note"
                      label="Add a personal note to the invitation email (optional)"
                      labelClassName={cs('mt-3', classes.noteFieldLabel)}
                      type="textarea"
                      className={classes.noteField}
                      rows={4}
                    />
                    <div
                      className={cs(
                        'text-xs text-light position-absolute',
                        classes.descriptionCharacterCount
                      )}
                    >
                      {get(values, 'note.length', 0)} / 250
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="d-flex mt-3">
                <div>
                  <p className={classes.inputLabel}>Status</p>
                </div>
                <div className="d-flex flex-column">
                  <div className={classes.statusLabel}>
                    {status !== 'Invited'
                      ? status
                      : 'Invite sent, not yet accepted'}
                  </div>
                  {status !== 'Active' && (
                    <>
                      <div className="d-flex justify-content-start mt-1">
                        <Button
                          onClick={() => {
                            setBtnClick('resend');
                            resendInviteAction(userid);
                          }}
                          color="primary"
                          size="sm"
                          className={cs('float-right', classes.resendBtn)}
                          loading={btnClick === 'resend' && isInviting}
                        >
                          Resend Invite
                        </Button>
                      </div>
                      <div className={classes.statusTimeLabel}>
                        <span>Sent on</span>
                        {userData.invited_at &&
                          moment(userData.invited_at)
                            .tz(userTimezone)
                            .format('MM/DD/YYYY hh:mm a')}
                        {status === 'Invited' && (
                          <Button
                            onClick={() => {
                              setBtnClick('cancel');
                              cancelInviteAction(userid);
                            }}
                            color="link"
                            className={'float-right p-0 ml-2'}
                            loading={btnClick === 'cancel' && isInviting}
                          >
                            Cancel Invite
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </Form>
        </ModalBody>
        <ModalFooter
          className={cs(
            'd-flex pt-0 mt-2',
            isEdit ? 'justify-content-end' : 'justify-content-between'
          )}
        >
          {!isEdit && (
            <Button
              onClick={() => {
                handleReset();
                handleCloseModal();
              }}
              color="link"
              className={'float-right'}
            >
              {cancelBtnTitle}
            </Button>
          )}
          <Button
            type="submit"
            id="inviteUsersBtn"
            onClick={handleInviteClick}
            disabled={!isValid || isInviting}
            color={!isValid || isInviting ? 'secondary' : 'primary'}
            className={'float-left'}
            loading={(btnClick === 'submit' && isInviting) || isLoading}
          >
            {confirmBtnTitle}
          </Button>
        </ModalFooter>
        <Modal
          toggle={handleCloseValidModal}
          centered
          id="inviteUserConfirmationModal"
          isOpen={!isEdit && selectionChange.openValidModal}
          title={`Invite a new ${selectionChange.selectedValue.title}`}
          size="md"
        >
          <ModalBody>
            <Row>
              <Col>
                <div>
                  <p className="font-weight-normal">
                    {`Are you sure you’d like to invite ${inviteEmail}`}
                    <br />
                    as a <b>{selectionChange.selectedValue.title}</b>?
                    <br />
                    This teammate will have access to{' '}
                    <b>all financial documents</b>
                    <br />
                    such as <b>tax returns, W-2s,</b> etc.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      checked={selectionChange.agreeToInvite}
                      onChange={e =>
                        setSelectionChange({
                          ...selectionChange,
                          agreeToInvite: e.target.checked,
                        })
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        Yes, I agree to invite.
                      </span>
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <div className="float-right">
                  <Button
                    disabled={false}
                    color="link"
                    onClick={() => {
                      setSelectionChange({
                        ...selectionChange,
                        openValidModal: false,
                        selectedValue: selectionChange.selectedValue,
                      });
                    }}
                  >
                    No, Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="text-white inviteButton"
                    disabled={!selectionChange.agreeToInvite || isInviting}
                    color="primary"
                    onClick={() => {
                      handleSubmit();
                      handleCloseValidModal();
                    }}
                  >
                    Yes, Invite
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  };

  return (
    <>
      <Modal
        toggle={handleCloseModal}
        centered
        id="inviteUserModal"
        isOpen={isModalOpen}
        title={title}
        size="md"
      >
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={
            viewMode === 're-invite' ? reInviteSchema : InviteUserSchema
          }
          enableReinitialize
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            const res = await submitValues(values);
            if (res) {
              resetForm();
            }
          }}
        >
          {RenderForm}
        </Formik>
      </Modal>
    </>
  );
};

export default InviteUserForm;
