import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import cs from 'classnames';
import queryString from 'query-string';
import classes from './DealDeskModal.module.scss';
import CloseIcon from 'assets/img/icons/close-icon.svg';
import DropdownIcon from 'assets/img/icons/dropdown-icon.svg';
import ContractIcon from 'assets/img/icons/deal-desk/contract-icon.svg';
import ActivityIcon from 'assets/img/icons/deal-desk/activity-icon.svg';
import CallIcon from 'assets/img/icons/deal-desk/call-icon.svg';
import CommentIcon from 'assets/img/icons/deal-desk/comment-icon.svg';
import ReviewIcon from 'assets/img/icons/deal-desk/review-icon.svg';
import UnReadIcon from 'assets/img/icons/deal-desk/unread-icon.svg';

import { TabContract } from './Tabs/TabContract';
import { TabDiscoveryCall } from './Tabs/TabDiscoveryCall';
import { TabELReview } from './Tabs/TabELReview';
import { TabActivity } from './Tabs/TabActivity';
import { TabComments } from './Tabs/TabComments';
import ApproveModal from './ApproveModal/ApproveModal';
import DenyModal from './DenyModal/DenyModal';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';

import {
  callGetDealDeskById,
  callGetListComments,
  callPostUpdateStatusDealDeskById,
  callGetDealDeskActivitiesById,
  getOptions,
} from 'store/actions/dealDeskActions';
import Loading from 'components/Loading';
import DealDeskModalAddComment from './DealDeskModalAddComment';
import VoidModal from './VoidModal/VoidModal';
import SuccessModal from './SuccessModal/SuccessModal';
import Button from 'components/Button';
import { isLightHexColor } from 'helpers/color';
import { useHistory, useLocation } from 'react-router';
import socket from 'helpers/socket';
import { TabRAReview } from './Tabs/TabRAReview';

//type: deals | referrals
const DealDeskModal = forwardRef(({ type = 'deals' }, ref) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => get(auth, 'user'));
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendButtonLoading, setResendButtonLoading] = useState(false);
  const [tabIndex, setIndex] = useState(1);
  const [id, setId] = useState(null);

  const approveRef = useRef(null);
  const denyRef = useRef(null);
  const voidRef = useRef(null);
  const successRef = useRef(null);

  const params = queryString.parse(location.search);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        setLoading(true);
        if (type == 'referrals') {
          await Promise.all([
            dispatch(callGetDealDeskById(id, 'partner-deal-desk')),
            dispatch(callGetListComments(id, 'PartnerReferral')),
            dispatch(
              callGetDealDeskActivitiesById(id, 'partner-deal-desk', {
                page: 1,
                limit: 20,
              })
            ),
          ]);
        } else {
          await Promise.all([
            dispatch(callGetDealDeskById(id, 'deal-desk')),
            dispatch(callGetListComments(id)),
            dispatch(
              callGetDealDeskActivitiesById(id, 'deal-desk', {
                page: 1,
                limit: 20,
              })
            ),
            dispatch(getOptions()),
          ]);
        }

        setLoading(false);
      };

      socket.joinAndListenComments('Initiative', id);
      fetchData();
    }

    return () => {
      socket.leaveComments();
      setIndex(1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useImperativeHandle(ref, () => ({
    open: id => {
      setId(id);
      setOpen(true);
    },
  }));

  const toggleModal = () => {
    setOpen(prev => {
      if (!prev === false) {
        // update URL, remove param deal_id
        history.push(`${location.pathname}?tab=${params.tab}`);
      }

      return !prev;
    });
  };

  const openSuccessModal = () => successRef.current.open();

  const tabs = [
    {
      id: 1,
      label: 'Contract',
      icon: (
        <img src={ContractIcon} title="Contract" alt="Contract" action="tab" />
      ),
    },
    ...(type === 'deals'
      ? [
          {
            id: 2,
            label: 'Discovery Call',
            icon: (
              <img
                src={CallIcon}
                title="Discovery Call"
                alt="Discovery Call"
                action="tab"
              />
            ),
          },
        ]
      : []),

    ...(type === 'deals'
      ? [
          {
            id: 3,
            label: 'EL for Review',
            icon: (
              <img
                src={ReviewIcon}
                title="EL for Review"
                alt="EL for Review"
                action="tab"
              />
            ),
          },
        ]
      : [
          {
            id: 6,
            label: 'RA for Review',
            icon: (
              <img
                src={ReviewIcon}
                title="RA for Review"
                alt="RA for Review"
                action="tab"
              />
            ),
          },
        ]),

    {
      id: 4,
      label: 'Activity',
      icon: (
        <img src={ActivityIcon} title="Activity" alt="Activity" action="tab" />
      ),
    },
    {
      id: 5,
      label: 'Comments',
      icon: (
        <img src={CommentIcon} title="Comments" alt="Comments" action="tab" />
      ),
    },
  ];

  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );

  const data = dealDesk?.data;

  const handleStatusUpdate = async (id, status) => {
    setResendButtonLoading(true);
    const resp = await dispatch(
      callPostUpdateStatusDealDeskById(
        id,
        {
          status: status,
          reason: '',
        },
        type == 'deals' ? 'deal-desk' : 'partner-deal-desk'
      )
    );
    if (resp.status === 1) {
      openSuccessModal();
    }
    setResendButtonLoading(false);
  };

  const renderFooter = () => {
    const status = data?.status?.status;
    const userEmail = user.email;
    const userExceptions = ['cortney@striketax.com', 'chris@striketax.com'];
    const isAdmin =
      user.is_partner === false &&
      user.is_client === false &&
      (user.roles.includes('Company_Administrator') ||
        user.roles.includes('Partner_Manager'));

    if (isAdmin || userExceptions.includes(userEmail)) {
      switch (status) {
        case 'Needs Review':
        case 'Changes Requested':
          return (
            <ModalFooter className={classes.footer}>
              <button
                type="button"
                className={cs('btn btn-secondary', classes.deny)}
                onClick={() => denyRef.current.open()}
              >
                Deny
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => approveRef.current.open()}
              >
                Approve
              </button>
            </ModalFooter>
          );
        case 'Signed':
          return (
            <ModalFooter className={classes.footer}>
              <div className={classes.signed}>Contract has been signed</div>
            </ModalFooter>
          );
        case 'Approved':
        case 'Sent':
        case 'Opened':
        case 'Viewed':
        case 'Expanded':
        case 'T&Cs viewed':
        case 'Forwarded':
        case 'Not Signor':
        case 'Denied':
        case 'Comment':
        case 'Resent':
          return (
            <ModalFooter className={cs(classes.footer, classes.resend)}>
              <button
                type="button"
                className={cs('btn btn-secondary', classes.voidButton)}
                onClick={() => voidRef.current.open()}
              >
                Void
              </button>
              <Button
                loading={resendButtonLoading}
                type="button"
                className={cs('btn', classes.resentButton)}
                onClick={() => handleStatusUpdate(id, 'resent')}
              >
                Resend Contract
              </Button>
            </ModalFooter>
          );

        default:
          return <></>;
      }
    } else {
      switch (status) {
        case 'Changes Requested':
          return (
            <ModalFooter className={classes.footer}>
              <Button
                loading={resendButtonLoading}
                type="button"
                className={cs('btn btn-secondary', classes.deny)}
                onClick={() => handleStatusUpdate(id, 'needs_review')}
              >
                Submit for review
              </Button>
            </ModalFooter>
          );
        default:
          return <></>;
      }
    }
  };

  const renderContent = () => {
    if (loading)
      return (
        <>
          <Loading wrapperClass={classes.loading} />
        </>
      );

    const status = data?.status?.status;
    const unread = data?.unread_comments || 0;

    return (
      <>
        {dealDesk?.loading && <Loading wrapperClass={classes.loading} />}
        <ModalHeader className={classes.header}>
          <div className="leftHeader">
            <div className="title">{data?.client?.name}</div>
            {/* <div>ERTC1001</div> */}
            <div
              className="status"
              style={{
                background: data?.status?.color,
                color: isLightHexColor(data?.status?.color)
                  ? '#000000'
                  : '#FFFFFF',
              }}
            >
              {status}
            </div>
          </div>

          <div className="rightHeader">
            <div className="time">
              Submitted by {data?.submitted_by} -{' '}
              {moment(data?.created_at).format('MM/DD/YYYY')}
            </div>

            <button aria-label="Close" type="button">
              <img src={DropdownIcon} alt="" />
            </button>
            <button aria-label="Close" type="button" onClick={toggleModal}>
              <img src={CloseIcon} alt="" />
            </button>
          </div>
        </ModalHeader>
        <ModalHeader className={cs(classes.header, classes.tabs)}>
          <ul>
            {tabs.map(t => {
              return (
                <li
                  key={t.id}
                  onClick={() => setIndex(t.id)}
                  className={cs(t.id === tabIndex && classes.active)}
                >
                  {t.icon}
                  {t.label === 'Comments' && unread > 0 && (
                    <img
                      src={UnReadIcon}
                      style={{ transform: 'translateY(-5px)' }}
                    />
                  )}
                  <div>{t.label}</div>
                </li>
              );
            })}
          </ul>
        </ModalHeader>
        <ModalBody>
          {tabIndex === 1 && <TabContract type={type} id={id} />}
          {tabIndex === 2 && <TabDiscoveryCall id={id} />}
          {tabIndex === 3 && <TabELReview type={type} id={id} />}
          {tabIndex === 4 && <TabActivity id={id} />}
          {tabIndex === 5 && <TabComments setTab={setIndex} id={id} />}
          {tabIndex === 6 && <TabRAReview id={id} />}
        </ModalBody>
        {/* Tab comment */}
        {tabIndex === 5 && <DealDeskModalAddComment type={type} />}
        {renderFooter()}
        <ApproveModal
          ref={approveRef}
          type={type}
          openSuccessModal={openSuccessModal}
          id={id}
        />
        <DenyModal ref={denyRef} id={id} type={type} />
        <VoidModal
          ref={voidRef}
          openSuccessModal={openSuccessModal}
          id={id}
          type={type}
        />
        <SuccessModal ref={successRef} id={id} />
      </>
    );
  };

  return (
    <Modal
      size="xl"
      scrollable
      fade={false}
      isOpen={isOpen}
      toggle={toggleModal}
      className={classes.modalWrapper}
    >
      {renderContent()}
    </Modal>
  );
});

export default DealDeskModal;
