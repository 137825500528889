import React, { useEffect } from 'react';
import classes from './ProgressBarPhase.module.scss';
import { Progress } from 'reactstrap';
import cx from 'classnames';

const ProgressBarPhase = ({ phase, currentPhase }) => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--progress-bar-color',
      phase.color
    );
  }, [phase.color]);
  return (
    <div className={classes.barWrapper}>
      <p
        className={cx(classes.title, {
          [classes.active]: phase.progress !== 0 && !phase?.completed_at,
        })}
      >
        {phase.name}
      </p>
      <div
        className={cx(classes.bar, {
          [classes.active]: phase.name === currentPhase || phase.progress,
        })}
      >
        <Progress
          style={{ '--progress-bar-color': phase.color }}
          className="mb-0"
          value={phase.progress}
        ></Progress>
      </div>
    </div>
  );
};

export default ProgressBarPhase;
