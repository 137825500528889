import { Formik } from 'formik';
import React, { useState } from 'react';
import classes from './ModalFooterInput.module.scss';
import * as Yup from 'yup';
import { Form, Input } from 'reactstrap';
import { AlertPopupHandler } from 'components/AlertPopup';
import cx from 'classnames';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { updateContractStatus } from 'store/actions/contract';
import { doLogout } from 'store/actions/authActions';

const ModalFooterInput = ({
  handleClose,
  handleOpenDeclineExitModal,
  handleGetEmail,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const initiativeId = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.initiative.id', null)
  );

  const type = useSelector(({ auth }) =>
    get(auth, 'clientContract.type', null)
  );

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
  });
  return (
    <div>
      <Formik
        validateOnMount={true}
        initialValues={{ email: '' }}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          isValid,
          values,
          handleChange,
          errors,
          touched,
          handleBlur,
          handleReset,
        }) => {
          return (
            <Form className={classes.inputWrapper}>
              <div>
                <Input
                  placeholder="Enter email address"
                  name="email"
                  error={errors.email}
                  value={values.email}
                  onChange={handleChange}
                  touched={touched.email}
                  onBlur={handleBlur}
                  className={classes.input}
                />
              </div>

              <div className={classes.buttonWrapper}>
                <Button
                  loading={loading}
                  onClick={async () => {
                    setLoading(true);
                    if (isValid) {
                      const { status } = await dispatch(
                        updateContractStatus(
                          initiativeId,
                          {
                            status: 'forwarded',
                            email: values.email,
                          },
                          type == 'deals' ? 'deal-desk' : 'partner-deal-desk'
                        )
                      );

                      if (status) {
                        handleClose();
                        handleOpenDeclineExitModal();
                        handleGetEmail(values.email);
                        handleReset();
                      }
                    }
                    setLoading(false);
                  }}
                  disabled={!isValid || loading}
                  className={cx({ [classes.disabled]: !isValid })}
                  color={'success'}
                >
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ModalFooterInput;
