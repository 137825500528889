import Button from 'components/Button';
import React, { useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Modal } from 'reactstrap';
import cx from 'classnames';
import classes from './DeclineModal.module.scss';
import ModalFooterInput from '../ModalFooterInput';
import Input from 'components/FormFields/Input';
import { AlertPopupHandler } from 'components/AlertPopup';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { updateContractStatus } from 'store/actions/contract';
import { doLogout } from 'store/actions/authActions';
import PartnerManager from '../PartnerManager';

const DeclineModal = ({
  isOpen = false,
  showBottomFooter,
  bottomFooterClose,
  closeModal,
  handleOpenDeclineExitModal,
  handleGetEmail,
}) => {
  const dispatch = useDispatch();
  const contract = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.contract', {})
  );

  const [loading, setLoading] = useState(false);
  const initiativeId = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.initiative.id', null)
  );
  const partnerManager = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.partner_manager', null)
  );

  const type = useSelector(({ auth }) =>
    get(auth, 'clientContract.type', null)
  );

  const [showFooter, setShowFooter] = useState(false);
  const [showOtherInputDialog, setShowOtherInputDialog] = useState(false);
  const [otherReason, setOtherReason] = useState('');

  const handleSubmitReason = async () => {
    setLoading(true);
    const { status } = await dispatch(
      updateContractStatus(
        initiativeId,
        {
          status: type == 'deals' ? 'comment' : 'ra_declined',
          reason: otherReason,
        },
        type == 'deals' ? 'deal-desk' : 'partner-deal-desk'
      )
    );
    setLoading(false);
    if (status) {
      closeModal();

      AlertPopupHandler.openCustom({
        title: <p className={classes.thanksText}>Thanks for letting us know</p>,
        showCancel: false,
        confirmBtnText: 'Exit this page',
        confirmBtnBsStyle: 'success',
        closeOnClickOutside: false,
        text: (
          <div className={classes.alertContent}>
            <p className={classes.description}>
              Don’t hesitate to reach out to your assigned Strike Expert if you
              have any questions or need further assistance! .
            </p>
            {partnerManager && (
              <div>
                <PartnerManager />
              </div>
            )}
          </div>
        ),
        customClass: classes.referralAlert,
        onConfirm: () => {
          dispatch(doLogout(false, true, true));
          window.location.href = 'https://www.striketax.com/';
        },
      });
    }
  };

  const handleBack = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        backdrop="static"
        centered
        id="submitModal"
        size="lg"
        noHeader={true}
        isOpen={isOpen}
        toggle={closeModal}
        className={classes.modal}
        title=""
      >
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader}>
            <Button onClick={handleBack} className={classes.backButton}>
              <i className="fa fa-chevron-left" aria-hidden="true"></i>Back
            </Button>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <div className={classes.title}>
              <p>Let us know why you declined</p>
            </div>
            {!showOtherInputDialog && (
              <div className={classes.optionButtons}>
                <Button
                  onClick={() => {
                    setShowFooter(true);
                    setOtherReason('no_authority');
                  }}
                  className="btn-outline-default"
                >
                  I’m not a signing authority
                </Button>
                <Button
                  onClick={() => {
                    setShowOtherInputDialog(true);
                    setShowFooter(false);
                    setOtherReason('');
                    bottomFooterClose();
                  }}
                  className="btn-outline-default"
                >
                  Other...
                </Button>
              </div>
            )}
            {showOtherInputDialog && (
              <div className={classes.otherInput}>
                <Input
                  placeholder="Other..."
                  name="otherReason"
                  value={otherReason}
                  onChange={e => setOtherReason(e.target.value)}
                  className={classes.input}
                />
                <div className={classes.buttons}>
                  <Button
                    onClick={() => {
                      setShowOtherInputDialog(false);
                      setShowFooter(false);
                    }}
                    className="btn-outline-default"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!otherReason || loading}
                    loading={loading}
                    color={'success'}
                    onClick={handleSubmitReason}
                    className={cx({
                      [classes.disabled]: !otherReason,
                    })}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </CardBody>
          <CardFooter
            className={cx(classes.cardFooter, {
              [classes.showCardFooter]: showFooter || showBottomFooter,
            })}
          >
            <ModalFooterInput
              handleClose={closeModal}
              handleOpenDeclineExitModal={handleOpenDeclineExitModal}
              handleGetEmail={handleGetEmail}
            />
          </CardFooter>
        </Card>
      </Modal>
    </>
  );
};

export default DeclineModal;
