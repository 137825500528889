import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import AuthFooter from 'components/Footers/AuthFooter.js';
import get from 'lodash/get';
import routes from 'routes.js';
import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import history from 'helpers/history';
import { withRouter } from 'react-router';
import includes from 'lodash/includes';
import { doLogout } from 'store/actions/authActions';
import some from 'lodash/some';
import Helmet from 'components/Helmet';

class Auth extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
    const location = get(this.props, 'location.pathname');
    const routesToAvoidDefaultRedirect = [
      'invite',
      'company',
      'verify',
      'codat',
    ];
    const { isActiveSession, isClient, isPartner, userRoles } = this.props;
    if (location === '/auth/client/sla-sign' && isActiveSession) {
      //logout for Sla flow without redirecting to login page
      this.props.logout(true, true, true);
      return;
    }
    const isPartnerManager = userRoles.includes('Partner_Manager');
    if (
      isActiveSession === true &&
      !some(routesToAvoidDefaultRedirect, path => includes(location, path)) &&
      location !== '/auth/subscription/outdated'
    ) {
      if (isClient) {
        history.push('/admin/dashboard');
      } else if (isPartner) {
        history.push('/admin/referral/clients');
      } else if (isPartnerManager) {
        history.push('/admin/partners');
      } else {
        history.push('/admin/dashboard');
      }
    }
  }

  componentDidUpdate(e) {
    if (get(e.history, 'location.pathname') !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };
  getTitle = routes => {
    const pathName = this.props.location.pathname;
    const routesArr = routes.map(r => r.views ?? r).flat();
    const findRoute = routesArr.find(
      r =>
        pathName.toLowerCase().indexOf((r.layout + r.path).toLowerCase()) > -1
    );
    return findRoute?.title;
  };
  render() {
    return (
      <>
        <Helmet title={this.getTitle(routes)} />
        <div className="main-content" ref="mainContent">
          <AuthNavbar
            login
            register={includes(this.props.location.pathname, 'register')}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/auth/login" />
          </Switch>
        </div>
        <AuthFooter />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isActiveSession: state.auth.isActiveSession,
    isClient: get(state, 'auth.user.is_client', false) || false,
    isPartner: get(state, 'auth.user.is_partner', false),
    userRoles: get(state, 'auth.user.roles', []),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: (
      isLogoutClick = true,
      shouldCallApi = true,
      stopRedirect = false
    ) => {
      dispatch(doLogout(isLogoutClick, shouldCallApi, stopRedirect));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
